import React, { useContext, useState, useEffect, useRef } from "react"
import { Button } from "antd"
import ReactTable from "react-table-v6"
import CustomPagination from "components/CustomPagination/CustomPagination"

import DetailsSalesBox from "./DetailsSalesBox"

import API from "components/common/api"
import { StateContext, DispatchContext } from "components/userContext"
import { columns as columnsDefault, columnsSmall } from "./columns"
import { Loading } from "components/Main/Loading"

import closeIcon from "images/close-menu.svg"

const handleSortedField = (value) => {
  if (!value.length) return ""

  const elem = value[0]
  if (!elem) return ""

  const direction = elem.desc ? "" : "-"

  return `${direction}${elem.id}`
}

const SalesTab = ({ id, detailsVisible, setDetailsVisible, formVariant }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const [isLoading, setIsLoading] = useState()
  const [columns, setColumns] = useState(columnsDefault)

  const removeItem = (data) => {
    const tmp = [...state.selectedRowsSale]
    const index = tmp.indexOf(data)

    if (index !== -1) {
      tmp.splice(index, 1)
      dispatch({ type: "handleState", field: "selectedRowsSale", payload: tmp })
    }
  }

  const addItem = (index) => {
    dispatch({ type: "handleState", field: "selectedRowsSale", payload: [...state.selectedRowsSale, index] })
  }

  const originalHandleClick = (_, handleOriginal) => {
    if (handleOriginal) {
      handleOriginal()
    }
  }

  useEffect(() => {
    if (state.selectedRowsSale.length === 1) {
      setColumns(columnsSmall)
      setDetailsVisible(true)
    } else {
      setColumns(columnsDefault)
      setDetailsVisible(false)
    }

    if (state.selectedRowsSale.length !== 1) {
      dispatch({ type: "handleState", field: "salesElem", payload: null })
      return
    }

    const [activeRow] = state.selectedRowsSale
    const [newElem] = state.detailsTableData.filter(({ id }) => id === activeRow)

    dispatch({ type: "handleState", field: "salesElem", payload: newElem })
  }, [state.selectedRowsSale])

  return (
    <div className="details-table-wrapper">
      {!state.salesEdit && (
        <ReactTable
          className={`vatTable vatDetailsTable ${detailsVisible ? "detailVisible" : ""}`}
          data={state.detailsTableData}
          columns={columns}
          multiSort={false}
          manual
          loading={isLoading}
          noDataText={""}
          NoDataComponent={() => null}
          LoadingComponent={(props) => {
            if (props.loading) {
              return <Loading absolute />
            }
            return <></>
          }}
          resizable={false}
          PaginationComponent={CustomPagination}
          pages={state.detailsTableOptions.totalPagesNumber}
          defaultPageSize={10}
          filtered={state.detailsTableOptions.filtered}
          onFetchData={(innerState) => {
            setIsLoading(true)

            const [allValue] = innerState.filtered.filter((e) => e.id === "all")

            const filterValue = allValue ? allValue.value : ""
            const [typeValue] = innerState.filtered.filter((e) => e.id === "type")

            const filterValueType = typeValue ? typeValue.value : ""
            const filteredValueRoles = !typeValue ? "" : `,typ_dokumentu@=${filterValueType}`

            const sortedValue = handleSortedField(innerState.sorted)

            API.get(
              `/VatRecordSale/getVatRecordSaleList?VatRecordId=${id}&sorts=${sortedValue}&page=${innerState.page + 1
              }&pageSize=${innerState.pageSize}&filters=search@=${filterValue}${filteredValueRoles ? filteredValueRoles : ""
              }`
            )
              .then(
                ({
                  data: {
                    stateObject: { results: stateObject, ...tableParams },
                  },
                }) => {
                  setIsLoading(false)
                  dispatch({ type: "handleState", field: "detailsTableData", payload: stateObject })
                  dispatch({
                    type: "handleState",
                    field: "detailsTableOptions",
                    payload: {
                      ...state.detailsTableOptions,
                      ...tableParams,
                      currentPage: innerState.page + 1,
                      pageSize: innerState.pageSize,
                    },
                  })
                }
              )
              .catch((error) => {
                console.error(error)
              })
          }}
          getTdProps={(_, rowInfo, column, instance) => {
            return {
              onClick: ({ ctrlKey }) => {
                // Expand row on expand cell click
                if (typeof rowInfo !== "undefined") {
                  // add row selection on row click, except click on expand arrow
                  if (column.id && state.selectedRowsSale.includes(rowInfo.original.id)) {
                    removeItem(rowInfo.original.id)
                  } else {
                    if (ctrlKey) {
                      addItem(rowInfo.original.id)
                    } else if (column.id) {
                      dispatch({ type: "handleState", field: "selectedRowsSale", payload: [rowInfo.original.id] })
                    }
                  }
                }
              },
            }
          }}
          getTrProps={(_, rowInfo) => {
            if (typeof rowInfo !== "undefined") {
              return {
                style: {
                  fontWeight: state.selectedRowsSale.includes(rowInfo.original.id) && "bold",
                  background: state.selectedRowsSale.includes(rowInfo.original.id) && "#f4f4f4",
                },
              }
            } else {
              return {
                onClick: originalHandleClick,
              }
            }
          }}
        />
      )}

      {!state.salesEdit && detailsVisible && (<>

        <DetailsSalesBox elem={state.salesElem || {}} formVariant={formVariant}/>
        <Button className="closeButton" onClick={() => {
                  dispatch({ type: "handleState", field: "selectedRowsSale", payload: [] });
                }}><img src={closeIcon} alt="" /></Button>
      </>)}
    </div>
  )
}

export default SalesTab
