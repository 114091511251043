import React, { useRef, useState } from "react"
import { handlePostGenerateType } from "./GateActionButtons"
import { Checkbox, Form, Modal } from "antd"
import FormModal from "components/common/FormModal"

interface GenerateModalProps {
  loading: boolean
  visible: boolean
  closeModal: () => void
  handlePostGenerate: (values: handlePostGenerateType) => void
}

export const GenerateModal = (props: GenerateModalProps) => {
  const exportRef = useRef(null)

  const { visible, closeModal, handlePostGenerate, loading } = props
  const [ewidencja, setEwidencja] = useState(false)
  const [deklaracja, setDeklaracja] = useState(false)

  const setters = {
    setEwidencja,
    setDeklaracja,
  }

  return (
    //@ts-ignore
    <FormModal
      loading={loading}
      destroyOnClose
      afterClose={() => {
        setEwidencja(false)
        setDeklaracja(false)
      }}
      visible={visible}
      header={<h3>Wybierz zakres korekty do wysyłki:</h3>}
      hasForm={
        <GenerateModalContent
          exportRef={exportRef}
          ewidencja={ewidencja}
          deklaracja={deklaracja}
          setters={setters}
          closeModal={closeModal}
          handlePostGenerate={handlePostGenerate}
        />
      }
      // @ts-ignore
      onAccept={() => exportRef.current.submit()}
      onDecline={() => {
        Modal.destroyAll()
        closeModal()
      }}
      addClass="exportModal"
      okButtonName="Generuj"
      acceptDisabled={!ewidencja && !deklaracja}
    />
  )
}

//@ts-ignore
export const GenerateModalContent = (props) => {
  const {
    ewidencja,
    deklaracja,
    setters: { setEwidencja, setDeklaracja },
    closeModal,
    handlePostGenerate,
    exportRef,
  } = props
  return (
    <>
      {/* @ts-ignore */}
      <Form
        //@ts-ignore
        layout={{
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        }}
        name="SalesEdit"
        initialValues={{
          ewidencja: false,
          deklaracja: false,
        }}
        onFinish={() => {
          handlePostGenerate({ generate_vatrecord: ewidencja, generate_declaration: deklaracja })
          closeModal()
        }}
        className="sales-edit-form"
        ref={exportRef}
      >
        <Form.Item name="ewidencja" hasFeedback className="details-item" valuePropName="checked">
          <Checkbox checked={ewidencja} onChange={() => setEwidencja(!ewidencja)}>
            <span className="label">Ewidencja - dokumenty sprzedazy i zakupu</span>
          </Checkbox>
        </Form.Item>

        <Form.Item name="deklaracja" hasFeedback className="details-item" valuePropName="checked">
          <Checkbox checked={deklaracja} onChange={() => setDeklaracja(!deklaracja)}>
            <span className="label">Deklaracja</span>
          </Checkbox>
        </Form.Item>
      </Form>
    </>
  )
}
