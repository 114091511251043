import React, { useContext, useEffect, useState, useMemo, useRef } from "react"
import { debounce } from "lodash"
import { Input, Button, Dropdown, Menu } from "antd"

import API from "components/common/api"

import Context from "context"
import { DispatchContext, StateContext } from "components/userContext"
import { CloudTaxRangePicker } from "components/common/RangePicker/CloudTaxRangePicker"

import MultiSelectDropDown from "components/common/DropDown/MultiSelectDropDwon"

import "moment/locale/pl"

import "./JobFilter.less"
import "components/common/DropDown/MultiSelectDropDwon.less"

const { Search } = Input

function handleButtonClick(e) {
  e.stopPropagation()
}

function JobsHistoryFilters({ tableOptionsId }) {
  const dispatch = useContext(DispatchContext)
  const state = useContext(StateContext)
  const { ctx } = useContext(Context)

  const handleDates = (from, to) => {
    saveDateTimeRangeFilter(from, to)
  }

  const handleFillterAll = (elem) => {
    const filtered = [{ id: "all", value: elem }]
    dispatch({
      type: "handleState",
      field: "jobsTableOptions",
      payload: { ...state.jobsTableOptions, filtered },
    })
  }

  const saveApplicationsFilter = (name, val) => {
    const filtered = [...state[tableOptionsId].filtered]
    let payload = { ...state[tableOptionsId], filtered }
    payload[name] = val
    dispatch({
      type: "handleState",
      field: tableOptionsId,
      payload
    })
  }

  useEffect(() => {
    if (state.jobsTableOptions.filtered.length === 0) {
      inputRef.current.state.value = ""
    }
  }, [state.jobsTableOptions.filtered])


  const saveDateTimeRangeFilter = (filterDateTimeFrom, filterDateTimeUntil) => {
    const filtered = [...state[tableOptionsId].filtered]
    dispatch({
      type: "handleState",
      field: tableOptionsId,
      payload: { ...state[tableOptionsId], filtered, filterDateTimeFrom, filterDateTimeUntil },
    })
  }

  const inputRef = useRef(null)

  const debounceHanlderInput = useMemo(() => debounce(() => handleFillterAll(inputRef.current.input.value), 800))

  useEffect(() => {
    const fetchData = async () => {
      const url = `/Jobs/getJobsUsers?CurrentOrganizationGuid=${ctx.currentOrganization.guid}`
      API.get(url)
        .then(
          ({ data: { stateObject } }) => {
            const updated = {
              ...state.jobsTableOptions, users: stateObject
            }
            dispatch({
              type: "handleState",
              field: "jobsTableOptions",
              payload: updated,
            })
          })
    }
    fetchData()
  }, [])


  const applicaationDropDownOptions = [
    { id: 3, label: "Sprawdź JPK_VAT" },
    { id: 5, label: "Biała lista" },
    { id: 7, label: "Weryfikacja NIP" }
  ]

  const operationsDropDownOptions = [
    { id: "import", label: "import" },
    { id: "raport", label: "raport" },
    { id: "badanie ewidencji", label: "badanie ewidencji" },
    { id: "weryfikacja BL", label: "weryfikacja BL" },
    { id: "weryfikacja NIP", label: "weryfikacja NIP" }
  ]

  const statusesDropDownOptions = [
    { id: 1, label: "oczekuje" },
    { id: 3, label: "zakończona" },
    { id: 2, label: "błąd" }
  ]

  return (
    <div className="jobs-filters-panel">
      <div className="flex-container">
        <div className="filter-header"><b>Wpisz dowolną frazę</b></div>
        <div className="filter-header"><b>Użytkownik</b></div>
        <div className="filter-header"><b>Status</b></div>
        <div className="filter-header"><b>Data i godzina</b></div>
      </div>
      <div className="flex-container">
        <Search
          allowClear={true}
          placeholder={null}
          onChange={(e) => { debounceHanlderInput(e) }}
          onSearch={debounceHanlderInput}
          ref={inputRef}
          className={["filters-search","filter-field"]}

          onClick={handleButtonClick}
        />
        <MultiSelectDropDown
          className="filter-field"
          options={state[tableOptionsId].users}
          labelSelector={x => x.name}
          selected={state[tableOptionsId].filteredUsers}
          setSelected={(v) => saveApplicationsFilter("filteredUsers", v)}
          header="Wybierz użytkownika"
        ></MultiSelectDropDown>
        <MultiSelectDropDown
          className="filter-field"
          options={statusesDropDownOptions}
          selected={state[tableOptionsId].filteredOperationStatuses}
          setSelected={(v) => saveApplicationsFilter("filteredOperationStatuses", v)}
          header="Wybierz status"
        ></MultiSelectDropDown>
        <CloudTaxRangePicker handleDates={handleDates}
          className="filter-field"
          from={state[tableOptionsId].filterDateTimeFrom}
          to={state[tableOptionsId].filterDateTimeUntil}
          table={state[tableOptionsId]}
        />
      </div>
      <div className="flex-container" style={{ paddingTop: "30px" }}>
        <div className="filter-header"><b>Aplikacja</b></div>
        <div className="filter-header"><b>Operacja</b></div>
        <div className="filter-header"/>
        <div className="filter-header"/>
      </div>
      <div className="flex-container">
        <MultiSelectDropDown
          className="filter-field"
          options={applicaationDropDownOptions}
          selected={state[tableOptionsId].filteredApplications}
          setSelected={(v) => saveApplicationsFilter("filteredApplications", v)}
          header="Wybierz aplikację"
        ></MultiSelectDropDown>
        <MultiSelectDropDown
          className="filter-field"
          options={operationsDropDownOptions}
          selected={state[tableOptionsId].filteredOperations}
          setSelected={(v) => saveApplicationsFilter("filteredOperations", v)}
          header="Wybierz operację"
        ></MultiSelectDropDown>
        <div className="filter-field"></div>
        <div className="filter-field"></div>
      </div>
    </div>
  )
}

export default JobsHistoryFilters