import { useEffect } from "react"

const WhiteListRow = ({
  data,
  fetched,
  setFetched
}) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!fetched.includes(data.original.id)) {
          setFetched([...fetched, data.original.id])
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])
}

export default WhiteListRow
