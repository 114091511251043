import React, { useContext } from "react"

import { Form, InputNumber, Checkbox } from "antd"

import { StateContext } from "components/userContext"
import { dash, pouczenie, p_63, p_64, p_65, p_66, p_660, p_67, p_68, p_69, p_ORDZU } from "./DeclarationsTabFix"

import infoIcon from "images/info.svg"
import TextArea from "antd/lib/input/TextArea"

export const TaxAdditionalInfo = (props: any) => {
  const { editMode, declarationFormFixRef, initialValues, type, formVariant } = props
  const state = useContext(StateContext)
  const group1 = formVariant === 1 ? [p_63, p_64, p_65, p_66, p_67] : [p_63, p_64, p_65, p_66, p_660, p_67]

  return (
    <div className="Taxadd">
      <div className="Tax-header">
        <h4>Podatnik wykonał w okresie rozliczeniowym czynności, o których mowa w :</h4>
      </div>
      <div className="Tax-content">
        {group1.map((fieldName) => {
          const { short_description: SD, full_description: FD } = state.declarationDictionaryFix.find(
            (row: { code: string }) => fieldName === row.code.toLowerCase()
          )

          return (
            <div key={fieldName} className="add-wrapper">
              <div className="Tax-col Tax-col_one" style={{ padding: "0 100px 0 0" }}>
                <p>{SD}</p>
              </div>
              <div className="Tax-col Tax-col_two">
                <span className="const-name">{fieldName.toUpperCase()}</span>
                <div className="value" style={{ display: "flex" }}>
                  <Form.Item name={fieldName} className="radio-buttons checkbox" valuePropName="checked">
                    <Checkbox disabled={!editMode} />
                  </Form.Item>
                  <div
                    className="info"
                    style={{
                      right: "-10px",
                      position: "relative",
                    }}
                  >
                    <img src={infoIcon} data-tip={fieldName.toUpperCase() + ": " + FD} />
                  </div>
                </div>
              </div>
            </div>
          )
        })}

        {[p_68, p_69].map((fieldName) => {
          const { short_description: SD, full_description: FD } = state.declarationDictionaryFix.find(
            (row: { code: string }) => fieldName === row.code.toLowerCase()
          )

          return (
            <div key={fieldName} className="add-wrapper">
              <div className="Tax-col Tax-col_one" style={{ padding: "0 100px 0 0" }}>
                <p>{SD}</p>
              </div>
              <div className="Tax-col Tax-col_two">
                <span className="const-name">{fieldName.toUpperCase()}</span>
                <span className="const-value">{state.declarationData[fieldName] || dash}</span>
                <div className="value" style={{ display: "flex" }}>
                  <Form.Item
                    name={fieldName}
                    hasFeedback
                    validateTrigger="onBlur"
                    rules={[
                      (e) => ({
                        validator() {
                          const fieldValue = e.getFieldValue(fieldName)

                          if (fieldValue > 0) {
                            return Promise.reject("Wartość musi być miejsza bądź równa 0!")
                          }

                          const p68field = e.getFieldValue(p_68)
                          const p69field = e.getFieldValue(p_69)

                          if ((p68field !== null && p69field !== null) || (p68field === null && p69field === null)) {
                            declarationFormFixRef.current.setFields([
                              {
                                name: p_68,
                                errors: [],
                              },
                              {
                                name: p_69,
                                errors: [],
                              },
                            ])
                            return Promise.resolve()
                          }

                          if (fieldName === p_68) {
                            if ((p68field === null && p69field === null) || (p68field !== null && p69field !== null)) {
                              return Promise.resolve()
                            } else {
                              return Promise.reject("Wymagane w parze z polem P_69!")
                            }
                          }

                          if (fieldName === p_69) {
                            if ((p68field === null && p69field === null) || (p68field !== null && p69field !== null)) {
                              return Promise.resolve()
                            } else {
                              return Promise.reject("Wymagane w parze z polem P_68!")
                            }
                          }

                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      type="number"
                      step={1}
                      bordered={editMode}
                      readOnly={!editMode}
                      precision={0}
                      placeholder={!editMode ? dash : ""}
                      onBlur={() => {}}
                    />
                  </Form.Item>
                  <div
                    className="info"
                    style={{
                      right: "-10px",
                      position: "relative",
                    }}
                  >
                    <img src={infoIcon} data-tip={fieldName.toUpperCase() + ": " + FD} />
                  </div>
                </div>
              </div>
            </div>
          )
        })}

        {[p_ORDZU].map((fieldName) => {
          const { short_description: SD, full_description: FD } = state.declarationDictionaryFix.find(
            (row: { code: string }) => fieldName.toLocaleLowerCase() === row.code.toLowerCase()
          )

          return (
            <div key={fieldName} className="add-wrapper">
              <div className="Tax-col Tax-col_one" style={{ padding: "0 100px 0 0" }}>
                <p>{SD}</p>
              </div>
              <div className="Tax-col Tax-col_two">
                <span className="const-name"></span>
                <div className="value" style={{ display: "flex" }}>
                  <Form.Item
                    name={fieldName}
                    hasFeedback
                    className="textarea"
                    rules={[
                      () => ({
                        validator(_, fieldValue) {
                          if (type !== 1) {
                            if (!fieldValue) {
                              return Promise.reject("Pole wymagane dla korekty!")
                            }
                          }

                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <TextArea bordered readOnly={!editMode} disabled={!editMode || type === 1} maxLength={240} />
                  </Form.Item>
                  <div
                    className="info"
                    style={{
                      right: "-10px",
                      position: "relative",
                    }}
                  >
                    <img src={infoIcon} data-tip={fieldName.toUpperCase() + ": " + FD} />
                  </div>
                </div>
              </div>
            </div>
          )
        })}

        {[pouczenie].map((fieldName) => {
          const { short_description: SD, full_description: FD } = state.declarationDictionaryFix.find(
            (row: { code: string }) => fieldName === row.code.toLowerCase()
          )

          return (
            <div key={fieldName} className="add-wrapper">
              <div className="Tax-col Tax-col_one" style={{ padding: "0 100px 0 0" }}>
                <p>{SD}</p>
              </div>
              <div className="Tax-col Tax-col_two">
                <span className="const-name"></span>
                <div className="value" style={{ display: "flex" }}>
                  <Form.Item
                    name={fieldName}
                    className="radio-buttons checkbox"
                    valuePropName="checked"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject("Wymagane zaznaczenie!")
                          }

                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <Checkbox disabled={!editMode} />
                  </Form.Item>
                  <div
                    className="info"
                    style={{
                      right: "-10px",
                      position: "relative",
                    }}
                  >
                    <img src={infoIcon} data-tip={fieldName.toUpperCase() + ": " + FD} />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
