import React from "react"

import linia from "../../../../images/linia.svg"

const EmptyCell = () => <img src={linia} alt="brak danych" />

const handleDateFormat = (data) => {
  if (!data) {
    return false
  }

  const [date, time] = data.split("T")
  const [h, m] = time.split(":")

  return `${date.replaceAll("-", ".")} ${h}:${m}`
}

export const columns = [
  {
    Header: "Nazwa ewidencji",
    accessor: "",
    Cell: ({ value }) => {
      if (!value.pelnaNazwa || value.pelnaNazwa === '') return <EmptyCell />
      return <><span className="gateTableNameColumn ellipsis">{value.pelnaNazwa}</span>
        <span className="gateTableNameColumn"> - {value.rokMiesiac}</span></>
    },
  },
  {
    Header: "Plik wygenerowany",
    accessor: "dataWygenerowania",
    Cell: ({ value }) => {
      return handleDateFormat(value) || <EmptyCell />
    },
  },
  {
    Header: "Plik podpisany",
    accessor: "dataPodpisaniaMetadanych",
    Cell: ({ value }) => {
      return handleDateFormat(value) || <EmptyCell />
    },
  },
  {
    Header: "Plik wysłany",
    accessor: "dataWyslania",
    Cell: ({ value }) => {
      return handleDateFormat(value) || <EmptyCell />
    },
  },
  {
    Header: "UPO pobrane",
    accessor: "dataPobraniaUpo",
    Cell: ({ value }) => {
      return handleDateFormat(value) || <EmptyCell />
    },
  },
]
