import React, { useContext, useState, useMemo, useRef } from "react";
import { Menu, Dropdown, Button, Input } from "antd";
import { DownOutlined, CloseCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { DispatchContext, StateContext } from "components/userContext"
import { dateTimeToString } from "helpers/dates"
import debounce from "lodash.debounce"
import API from "components/common/api"

const { Search } = Input

function handleMenuClick(e) {

    try {
        e.domEvent.stopPropagation()
    } catch (error) { }
}

function handleButtonClick(e) {
    try {
        e.stopPropagation()
    } catch (error) { }
}

const getSelectedString = (n) => {
    if (n === 1) return n + " wybrany"
    if (n < 5) return n + " wybrane"
    return n + " wybranych"
}

function ImportsDropDown({ lastImportsName, header, tableOptionsId }) {
    const dispatch = useContext(DispatchContext)
    const state = useContext(StateContext)
    const [searchedImports, setSerachedImports] = useState([])
    const inputRef = useRef(null)
    let filteredImportIds = state[tableOptionsId].filteredImportIds

    const saveFilter = (filteredImportIds) => {
        const filtered = [...state.wlTableOptions.filtered]
        dispatch({
            type: "handleState",
            field: tableOptionsId,
            payload: { ...state.wlTableOptions, filtered, filteredImportIds },
        })
    }

    const search = (value) => {
        if (value === undefined || value.length < 4) {
            setSerachedImports([])
            return
        }
        API.get(`WhiteList/GetUserImports?page=1&pageSize=100&filters=search@=` + value)
            .then((res) => {
                setSerachedImports(res.data.stateObject.results)
            })
            .catch(e => {
                console.log(e)
            })
    }

    const debounceHanlderInput = useMemo(() => debounce(() => search(inputRef.current.input.value), 800))
    const debounceSaveFilter = useMemo(() => debounce((filteredImportIds) => saveFilter(filteredImportIds), 100))

    const handleOkFilterClick = (e, date, source) => {
        filteredImportIds = { ...filteredImportIds }
        if (filteredImportIds[date] !== undefined) {
            delete filteredImportIds[date]

        } else {
            filteredImportIds[date] = source
        }
        debounceSaveFilter(filteredImportIds)
        e.domEvent.stopPropagation()
        return false
    }

    const clearFilter = () => debounceSaveFilter({})

    const lastImports = state[lastImportsName]

    const getMenuPositions = (lastImports) => {
        let ret = []
        if (lastImports !== null) {
            for (let i = 0; i < lastImports.length; i++) {
                const element = lastImports[i];
                const isChecked = filteredImportIds[element.creation_date] !== undefined
                ret.push(<Menu.Item key={"menuItem" + i}
                    className={"wl-imports-dropdown-row " + (isChecked ? "wl-imports-dropdown-row-checked" : "")}
                    onClick={e => (handleOkFilterClick(e, element.creation_date, element.data_source))}
                    style={{
                        "height": "25px",
                        "lineHeight": "25px"
                    }}>
                    <div style={{ "display": "contents" }}>
                        <span style={{
                            "textOverflow": "ellipsis",
                            "width": "auto",
                            "overflow": "hidden"
                        }}>{element.data_source}</span><span>({dateTimeToString(element.creation_date)})</span>
                    </div>
                    <CheckOutlined className={"wl-imports-dropdown-row-symbol wl-imports-dropdown-row-symbol" + (isChecked ? "-checked" : "-unchecked")} />
                </Menu.Item>)
            }
        }
        return ret
    }

    let searchedImportsMenuItems = []
    if (searchedImports.length > 0) {
        searchedImportsMenuItems = getMenuPositions(searchedImports)
    } else if (Object.keys(filteredImportIds).length > 0) {
        let selectedToShow = Object.assign({}, filteredImportIds);
        if (lastImports === null) return <></>
        lastImports.forEach(element => {
            delete selectedToShow[element.creation_date]
        });
        let objectsToShow = Object.keys(selectedToShow).map(k => ({ creation_date: k, data_source: selectedToShow[k] }))
        searchedImportsMenuItems = getMenuPositions(objectsToShow)
    }

    let lastImportedMenuItems = getMenuPositions(lastImports)


    const menu = (
        <div className="wl-imports-dropdown-content noselect" onClick={handleMenuClick}>

            <div className="search-input" style={{ display: "flex" }}>
                <Search
                    allowClear
                    placeholder={null}
                    onSearch={debounceHanlderInput}
                    onChange={debounceHanlderInput}

                    ref={inputRef}
                    className="broken-search"
                    onClick={handleButtonClick}


                />
            </div>
            <Menu onClick={handleMenuClick} className="wl-imports-dropdown-inner-selected-list">
                {searchedImportsMenuItems}
            </Menu>
            <span className="wl-imports-dropdown-inner-span">ostatnie:</span>
            <Menu onClick={handleMenuClick}>
                {lastImportedMenuItems}
            </Menu>
        </div>

    );

    const filterSize = Object.values(filteredImportIds).length
    const anyFilterSelected = filterSize > 0

    return <Dropdown overlay={menu}
        trigger="click"
        className="wl-imports-dropdown">

        <Button onClick={handleButtonClick} className="wl-import-filter-button"
            style={{
                "minWidth": "135px",
                "maxWidth": "135px",
                "display": "flex",
                "justifyContent": "space-between",
                "alignItems": "center",
                "color": "#233B53",
                "borderColor": "#DDDDDD"
            }}>
            <span style={{
                "overflow": "hidden",
                "textOverflow": "ellipsis"
            }}>
                {filterSize === 0 ? header :
                    filterSize === 1 ? Object.values(filteredImportIds)[0] :
                        getSelectedString(filterSize)}
            </span>
            {!anyFilterSelected && <DownOutlined />}
            {anyFilterSelected && <CloseCircleOutlined onClick={clearFilter} />}
        </Button>
    </Dropdown>
}

export default ImportsDropDown