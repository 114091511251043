import API from "components/common/api"

export const isUserActionPossible = async () => {
  const response = await API.get(`/Dashboard/canAddUserToOrganization?orgGuid=${localStorage.getItem("currentGuid")}`)
  return response.status === 200
}

export const isAffiliatedActionPossible = async () => {
  const response = await API.get(
    `/CustomerPanel/canAddRelatedParty?organizationGuid=${localStorage.getItem("currentGuid")}`
  )

  return response.status === 200
}
