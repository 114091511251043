import React from "react"

export const objToMap = {
  data_sprzedazy: "Data sprzedaży",
  dowod_sprzedazy: "Numer dokumentu",
  data_wystawienia: "Data wystawienia",
  kod_kraju_nadania_tin: "Kod kraju",
  nazwa_kontrahenta: "Nazwa kontrahenta",
  nr_kontrahenta: "Numer kontrahenta",
  clear: "",
  typ_dokumentu: "Typ dokumentu",
}

export const sortedKeys = [
  "kod_kraju_nadania_tin",
  "dowod_sprzedazy",
  "nr_kontrahenta",
  "data_sprzedazy",
  "nazwa_kontrahenta",
  "data_wystawienia",
  "clear",
  "typ_dokumentu",
]

const SalesDefault = ({ elem }) => {
  return (
    <div className="details-box-default">
      {sortedKeys.map((key) => {
        let value = elem[key]

        if (key.includes("data")) {
          if (!elem[key]) {
            value = "-"
          } else {
            const [YY, MM, D] = elem[key].split("-")
            const [DD] = D.split("T")

            value = `${YY}.${MM}.${DD}`
          }
        }

        if (!elem[key]) {
          value = "-"
        }

        if (key === "clear") {
          value = ""
        }

        return (
          <div key={key} className="details-item present">
            <span>{objToMap[key]}</span>
            <span>{value}</span>
          </div>
        )
      })}
    </div>
  )
}

export default SalesDefault
