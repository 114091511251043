import React, { useContext, useRef, useState } from "react"
import { Form, Input } from "antd"
import "./ChangePassword.less"

import Button from "../common/Button"
import API from "../common/api"

import { DispatchContext } from "../userContext"

import { PasswordPattern, PasswordTip } from "helpers/password"

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const ChangePassword = ({ setPassChange }) => {
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  const dispatch = useContext(DispatchContext)
  const form = useRef(null)

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const data = {
        currentPassword: values.currentPassword,
        password: values.newPassword,
      }
      const response = await API.post("/Dashboard/changePassword", data)
      setLoading(false)
      if (response.status === 200) {
        setPassChange(true)
        dispatch({ type: "handleState", field: "editPassModal", payload: false })
      } else {
        setPassChange(false)
        setErrorMessages(response.data.errors)
      }
    } catch (error) {
      setLoading(false)

      setPassChange(false)
      setErrorMessages(error.response.data.errors)
    }
  }

  const handleCancelPass = () => {
    dispatch({ type: "handleState", payload: false, field: "editPassModal" })
    form.current.resetFields()
    setErrorMessages([])
  }

  return (
    <div className="ChangePassword">
      <Form {...layout} name="login" initialValues={{ remember: true }} onFinish={onFinish} ref={form}>
        <h3
          style={{
            marginBottom: "30px",
            font: "normal normal bold 18px/24px Lato",
            color: "#233B53",
          }}
        >
          Zmień hasło
        </h3>

        <Form.Item
          name="currentPassword"
          validateTrigger="onBlur"
          hasFeedback
          rules={[
            {
              whitespace: true,
              required: true,
              message: "Wprowadź swoje hasło!",
            },
          ]}
        >
          <Input.Password placeholder="obecne hasło" />
        </Form.Item>

        <Form.Item
          name="newPassword"
          dependencies={["currentPassword"]}
          validateTrigger="onBlur"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Wprowadź nowe hasło!",
            },
            {
              pattern: PasswordPattern,
              message: PasswordTip
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue("currentPassword") !== value) {
                  return Promise.resolve()
                }

                return Promise.reject("Nowe hasło musi być różne od poprzedniego!")
              },
            }),
          ]}
        >
          <Input.Password placeholder="nowe hasło" />
        </Form.Item>

        <Form.Item
          name="confirm-new-password"
          dependencies={["newPassword"]}
          hasFeedback
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Potwierdź swoje hasło!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve()
                }

                return Promise.reject("Wprowadzone hasła nie są identyczne!")
              },
            }),
          ]}
        >
          <Input.Password placeholder="powtórz nowe hasło" />
        </Form.Item>

        <div className="edit-action-buttons">
          <Button
            name="Anuluj"
            type="button"
            styling="button-small-white"
            style={{
              marginRight: "45px",
            }}
            loading={loading}
            onClick={handleCancelPass}
          />
          <Button name="Zapisz" type="submit" styling="button-small" loading={loading} />
        </div>
      </Form>

      {errorMessages &&
        errorMessages.map((errorMessage) => (
          <h2 key={errorMessage} className="errorMessage" style={{ color: "red" }}>
            {errorMessage}
          </h2>
        ))}
    </div>
  )
}

export default ChangePassword
