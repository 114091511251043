import React, { useState, useEffect, useRef } from "react"
import { Form, InputNumber, Select } from "antd"

import selectArrow from "images/icons/select_arrow.png"

const getDate = (date) => {
  return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear()
}

const getTime = (time) => {
  let hours = time.getHours()
  hours = ("0" + hours).slice(-2)

  let minutes = time.getMinutes()
  minutes = ("0" + minutes).slice(-2)

  return hours + "." + minutes
}

const VatDetailsForm = ({ onChange, fields, editMode, recordData, subjects, setBlockSave, detailsEditForm }) => {
  const { cel_zlozenia, data_utworzenia, data_wytworzenia_jpk, miesiac, nip, pelna_nazwa, rok } = recordData

  const [options, setOptions] = useState(null)
  const [activeData, setActiveData] = useState({})
  const { Option } = Select

  const creationDate = getDate(new Date(data_utworzenia)) + " " + getTime(new Date(data_utworzenia))
  const exportDate = data_wytworzenia_jpk ? getDate(new Date(data_wytworzenia_jpk)) : "-"

  // Make Options for select
  useEffect(() => {
    if (!subjects.length) return

    const tmp = []
    subjects.map((e) =>
      tmp.push({
        id: e.id,
        label: `${e.nip} - ${e.fullName}`,
        value: e.id,
      })
    )

    // IF ONLY 1 NIP SET AS DEFAULT
    if (subjects.length === 1) {
      handleSetNip(subjects[0].id, true)
    }

    setOptions(tmp)
  }, [subjects])

  useEffect(() => {
    const activeSubject = subjects.find((e) => e.id === recordData.related_party_id)

    setActiveData(activeSubject)
    handleCheckIfIsValid(activeSubject)
  }, [editMode])

  useEffect(() => {
    if (typeof activeData === "undefined") return
    if (!Object.keys(activeData).length) return

    handleCheckIfIsValid(activeData)
  }, [activeData])

  const handleSetNip = (value, set = false) => {
    const activeSubject = subjects.find((e) => e.id === value)
    setActiveData(activeSubject)

    // IF ONLY 1 NIP SET AS DEFAULT
    if (set) {
      detailsEditForm.current.setFields([
        {
          name: "podmiot",
          value: activeSubject.id,
        },
      ])
    }
  }

  const handleSetError = (msg, hasLink) => {
    let actionMsg = null
    let infoMsg = msg

    if (msg.includes("tutaj")) {
      actionMsg = msg.substring(msg.indexOf("tutaj"))
      infoMsg = msg.split("tutaj")[0]
    }

    const errorElement = hasLink ? (
      <div>
        {infoMsg}{" "}
        <span
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
          onClick={() => {
            window.location.href = `/panel-administratora?id=${activeData.id}`
          }}
        >
          {actionMsg}
        </span>
      </div>
    ) : (
      msg
    )

    detailsEditForm.current.setFields([
      {
        name: "podmiot",
        errors: [errorElement],
      },
    ])
  }

  const handleCheckIfIsValid = (entityObj) => {
    if (subjects.length === 0 || typeof entityObj === "undefined") return

    // HANDLE ERROR FIX HOT
    const { isComplete, canEdit, message } = subjects.find((el) => el.id === entityObj.id)

    if (!isComplete) {
      // USER NOT ALLOWED
      setBlockSave(true)
      if (!canEdit) {
        return handleSetError(message, false)
      } else {
        handleSetError(
          "Brak wymaganych danych dla wybranego podmiotu. Proszę uzupełnić brakujące dane klikając tutaj.",
          true
        )
      }
    } else {
      detailsEditForm.current.setFields([
        {
          name: "podmiot",
          errors: [],
        },
      ])

      setBlockSave(false)
    }
  }

  return (
    <Form
      ref={detailsEditForm}
      className="details-col details-col__first"
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields)
      }}
    >
      <div className="inner-row">
        <p>Data utworzenia:</p>
        <span>{creationDate}</span>
      </div>
      <div className={`inner-row ${editMode && "inner-row__edit"}`}>
        <p>Rok:</p>
        {editMode ? (
          <Form.Item name="rok" hasFeedback validateTrigger="onBlur" rules={rules["year"]}>
            <InputNumber step={1} min={2020} max={2021} />
          </Form.Item>
        ) : (
          <span>{rok}</span>
        )}
      </div>
      <div className="inner-row">
        <p>Miesiąc:</p>
        {editMode ? (
          <Form.Item name="miesiac" hasFeedback validateTrigger="onBlur" rules={rules["month"]}>
            <InputNumber
              type="number"
              step={1}
              min={1}
              max={12}
              formatter={(value) => {
                return ("0" + value).slice(-2)
              }}
            />
          </Form.Item>
        ) : (
          <span>{miesiac}</span>
        )}
      </div>
      <div className="inner-row">
        <p>Cel złożenia:</p>
        <span>{cel_zlozenia === 1 ? "złożenie" : "korekta"}</span>
      </div>
      <div className="inner-row">
        <p>Data eksportu JPK:</p>
        <span>{exportDate}</span>
      </div>
      <div className="inner-row">
        <p>Podmiot:</p>

        {editMode ? (
          <Form.Item name="podmiot">
            <Select
              style={{ width: 248 }}
              dropdownStyle={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                padding: "15px 10px",
              }}
              placeholder="Wybierz Podmiot"
              suffixIcon={<img src={selectArrow} alt="Wybierz podmiot" />}
              onChange={(value) => handleSetNip(value)}
            >
              {options &&
                options.map((option) => (
                  <Option key={option.id} value={option.value}>
                    {option.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        ) : (
          <>
            <span className="nip">{nip}</span>
            <span>{pelna_nazwa}</span>
          </>
        )}
      </div>
    </Form>
  )
}

const rules = {
  year: [
    {
      whitespace: true,
      required: true,
      pattern: /\d{4}/,
      message: "Rok musi się składać z czterech cyfr!",
    },
    // {
    //   min: 2020,
    //   message: "Minimalna wartość pola to 2020",
    // },
    // {
    //   max: 2021,
    //   message: "Minimalna wartość pola to 2020",
    // },
  ],
  month: [{ required: true, message: "Miesiąć jest obowiązkowy!" }],
}

export default VatDetailsForm
