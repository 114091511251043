export const getFileNameFromContentDisposition = (str) => {
    var filename = "";
    if (str && str.indexOf('attachment') !== -1) {
        var filenameUtf8Regex = /filename\*[^;=\n]*=UTF-8'((['"]).*?\2|[^;\n]*)/;
        var matches = filenameUtf8Regex.exec(str);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
            filename = decodeURIComponent(filename)
        } else {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;;
            matches = filenameRegex.exec(str);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
    }
    return filename
}