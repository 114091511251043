import React, { useState, useContext, useEffect } from "react"
import { Router, Switch, Route } from "react-router-dom"

import AuthenticatedRoute from "./helpers/authenticatedRoute"
import UnAuthenticatedRoute from "./helpers/unAuthenticatedRoute"
import * as Sentry from "@sentry/react"

import "./App.less"

import FormModal from "./components/common/FormModal"
import { Checkbox } from "antd"
import UserReducer from "./components/userContext"
import { DispatchContext, StateContext } from "components/userContext"

import { ChangePassLoginUser } from "./components/ChangePassword/ChangePassLoginUser.jsx"

import Main from "./components/Main/Main"
import Login from "./components/Login/Login"
import Register from "./components/Register/Register"
import ResetPassword from "./components/ResetPassword/ResetPassword"
import ChangePasswordSinglePage from "./components/ChangePassword/ChangePasswordSinglePage"
import ChangeEmail from "./components/ChangeEmail/ChangeEmail"
import Expired from "./components/Expired/Expired"
import UserSetup from "./components/UserSetup/UserSetup"
import { createBrowserHistory } from "history"

import Context from "./context/index"
import LeftSide from "./components/common/LeftSide"
import API, { isProductionEnvironment } from "components/common/api"

import ReactGA from "react-ga"
import { Loading } from "components/Main/Loading"

export const history = createBrowserHistory()

const handleAPICall = (ctx, setCtx, dispatch) => {
  return API.get(`/Dashboard/User`)
    .then((res) => {
      if (res) {
        const featureFlags = {}
        res.data.stateObject.feature_flags.forEach(flag => {
          featureFlags[flag.name] = flag.active
        });
        const jobsCounter = res.data.stateObject.jobs_counter
        setCtx({ ...ctx, featureFlags, jobsCounter })
        return true
      }
      return false
    })
    .catch(() => {
      return false
    })
}

const App = () => {
  const [ctx, setCtx] = useState({})
  const [cookie, setCoookie] = useState(false)
  const [loading, setLoading] = useState(true)
  const dispatch = useContext(DispatchContext)

  const [modal, setModal] = useState(localStorage.getItem("allow_cookies") === null)
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  const handleAcceptCookie = () => {
    localStorage.setItem("allow_cookies", cookie)
    setModal(false)
  }

  useEffect(() => {
    if (isProductionEnvironment) {
      ReactGA.initialize("UA-185941953-4")
    }

    ReactGA.pageview(window.location.pathname)
    ReactGA.set({ anonymizeIp: true })

    handleAPICall(ctx, setCtx, dispatch)
      .then((resp) => {
        setIsAuthenticated(resp)

        const [_, cameFrom] = window.location.pathname.split("login/")
        return cameFrom
      })
      .then((resp) => {
        setTimeout(() => setLoading(false), 1500)

        if (!resp) return
        if (isAuthenticated) {
          history.push(`/${resp}`)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  if (isAuthenticated === null) return <></>

  if (loading) {
    return (
      <div className="Login-container landing-page">
        <LeftSide />
        <Loading />
      </div>
    )
  }

  return (
    <Sentry.ErrorBoundary fallback="An error has occurred">
      <>
        <FormModal
          visible={modal}
          isCookie
          addClass="cookie-modal"
          okButtonName="Zapisz ustawienia"
          cookieModal={() => {
            return (
              <div className="cookie-body">
                <h2>Pliki cookie oraz inne podobne technologie w naszym serwisie</h2>
                <p>
                  Używamy niezbędnych plików cookie lub innych podobnych technologii, w tym przetwarzamy zawarte w nich
                  dane, aby nasz serwis działał poprawnie i bezpiecznie. Jeżeli wyrazisz na to zgodę, będziemy również
                  używać opcjonalnych analitycznych i statystycznych plików cookie lub innych podobnych technologii w
                  celu ulepszania naszego serwisu. W każdej chwili możesz cofnąć swoją zgodę. Administratorem Twoich
                  danych osobowych zbieranych w serwisie poprzez pliki cookie lub inne podobne technologie jest JPK
                  Insight Spółka z o.o. z siedzibą w Warszawie. Więcej informacji dotyczących administratora,
                  przetwarzania danych oraz wykorzystywanych przez nas plików cookies i innych podobnych technologii
                  znajdziesz w naszej{" "}
                  <ReactGA.OutboundLink
                    eventLabel="Polityka cookies - podgląd dokumentu"
                    // to={cookiesPDF}
                    to="https://taxinsight.pl/polityka-prywatnosci-cloudtax/"
                    target="_blank"
                  >
                    Polityce prywatności i cookies.
                  </ReactGA.OutboundLink>
                </p>
                <h2>Niezbędne pliki cookies oraz inne podobne technologie</h2>
                <p>
                  Niezbędne pliki cookies oraz inne podobne technologie zapewniają możliwość realizowania podstawowych
                  funkcji takich jak bezpieczeństwo, dostępność i użyteczność serwisu (służą m.in. utrzymaniu i
                  zapisaniu sesji użytkownika, uwierzytelnianiu użytkownika, pozwalają dostosowywać nasze usługi do
                  Twoich wyborów mających techniczne znaczenie dla działania serwisu np. zapamiętania Twoich preferencji
                  dotyczących plików cookie lub innych podobnych technologii). Jeżeli je wyłączysz zmieniając ustawienia
                  Twojej przeglądarki, może to uniemożliwić prawidłowe działanie naszego serwisu.
                </p>

                <h2 className="custom">
                  Analityczne i statystyczne pliki cookies lub inne podobne technologie{" "}
                  <Checkbox
                    onClick={() => {
                      setCoookie(!cookie)
                    }}
                  >
                    <span>Zgadzam się</span>
                  </Checkbox>
                </h2>

                <p>
                  Chcielibyśmy ustawić opcjonalne analityczne i statystyczne pliki cookie lub inne podobne technologie,
                  w tym umieszczane przez naszych zewnętrznych dostawców, w celu ulepszenia naszego serwisu poprzez
                  zbieranie i raportowanie informacji o tym, jak z niego korzystasz. Pliki cookie lub inne podobne
                  technologie zbierają informacje w sposób, który nie identyfikuje bezpośrednio użytkownika naszego
                  serwisu. Więcej informacji na ten temat możesz znaleźć w naszej{" "}
                  <ReactGA.OutboundLink
                    eventLabel="Polityka cookies - podgląd dokumentu"
                    // to={cookiesPDF}
                    to="https://taxinsight.pl/polityka-prywatnosci-cloudtax/"
                    target="_blank"
                  >
                    Polityce prywatności i cookies.
                  </ReactGA.OutboundLink>
                </p>
              </div>
            )
          }}
          onAccept={() => {
            handleAcceptCookie()
          }}
        />
      </>

      <UserReducer>
        <Context.Provider value={{ ctx, setCtx }}>
          <Router history={history}>
            <Switch>
              <Route path="/setup-password" render={() => <ChangePasswordSinglePage />} />

              <UnAuthenticatedRoute path="/login" component={Login} appProps={{ isAuthenticated }} redirectTo="" />

              {/* <UnAuthenticatedRoute
                path="/change-password"
                component={ChangePasswordSinglePage}
                appProps={{ isAuthenticated }}
                redirectTo=""
              /> */}

              <UnAuthenticatedRoute
                path="/user-setup"
                component={UserSetup}
                appProps={{ isAuthenticated }}
                redirectTo=""
              />

              <UnAuthenticatedRoute
                path="/register"
                component={Register}
                appProps={{ isAuthenticated }}
                redirectTo=""
              />

              <UnAuthenticatedRoute
                path="/link-expired"
                component={Expired}
                appProps={{ isAuthenticated }}
                redirectTo=""
              />

              <UnAuthenticatedRoute
                path="/password-changed"
                component={ChangePassLoginUser}
                appProps={{ isAuthenticated }}
                redirectTo=""
              />

              <UnAuthenticatedRoute
                path="/reset-password"
                component={ResetPassword}
                appProps={{ isAuthenticated }}
                redirectTo=""
              />

              <UnAuthenticatedRoute
                path="/change-email"
                component={ChangeEmail}
                appProps={{ isAuthenticated }}
                redirectTo=""
              />

              <AuthenticatedRoute path="/" component={Main} appProps={{ isAuthenticated }} redirectTo="" />
            </Switch>
          </Router>
        </Context.Provider>
      </UserReducer>
    </Sentry.ErrorBoundary>
  )
}

export default App
