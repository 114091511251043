import { useState } from 'react';

const AddItem = (selectedRows, setSelectedRows, index) => {
    selectedRows = [...selectedRows, index]
    setSelectedRows(selectedRows)
}


const RemoveItem = (selectedRows, setSelectedRows, data) => {
    const tmp = [...selectedRows]
    const index = tmp.indexOf(data)

    if (index !== -1) {
        tmp.splice(index, 1)
        selectedRows = tmp
        setSelectedRows(selectedRows)
    }
}

const IsSelected = (selectedRows, allSelectedMode, rowId) => {
    if (!allSelectedMode && selectedRows.includes(rowId)) return true
    if (allSelectedMode && !selectedRows.includes(rowId)) return true
    return false
}

const Select = (setSelectedRosw, rows) => {
    setSelectedRosw(rows)
}

const getRowsBeetween = (currentRowsIds, lastClickedRowId, rowId) => {
    if (!currentRowsIds.includes(lastClickedRowId) || !currentRowsIds.includes(rowId)) return []
    let ret = []
    let inRange = false
    currentRowsIds.forEach(id => {
        if (inRange) ret.push(id)
        if (id === lastClickedRowId || id === rowId) {
            inRange = !inRange
            if (inRange) ret.push(id)
        }
    });
    return ret
}

let checkIfIncluded = (arr, target) => target.every(v => arr.includes(v));

const OnRowClick = (selectedRows, setSelectedRows, allSelectedMode, setAllSelectedMode,
    lastClickedRowId, setLastClickedRowId, rowId, currentRowsIds, shiftKey, ctrlKey) => {
    const range = getRowsBeetween(currentRowsIds, lastClickedRowId, rowId)

    const selectRange = () => {
        const allIncluded = checkIfIncluded(selectedRows, range)
        if (ctrlKey) {
            if (allIncluded) {
                Select(setSelectedRows, selectedRows.map(x => range.includes(x) ? -1 : x).filter(x => x >= 0))

            } else {
                Select(setSelectedRows, [...new Set(selectedRows.concat(range))])
            }
        } else {
            if (allIncluded) {
                DeselectAll(setSelectedRows, setAllSelectedMode, setLastClickedRowId)
            } else {
                Select(setSelectedRows, range)
            }
        }
    }


    if (allSelectedMode) {
        if (shiftKey && range.length > 0) {
            selectRange()
        } else {
            if (!ctrlKey) {
                setAllSelectedMode(false)
                setSelectedRows([rowId])
            }
            if (!selectedRows.includes(rowId)) {
                if (ctrlKey) {
                    AddItem(selectedRows, setSelectedRows, rowId)
                }
            }
        }
    } else {

        if (shiftKey && range.length > 0) {
            selectRange()
        } else {
            if (selectedRows.includes(rowId)) {
                RemoveItem(selectedRows, setSelectedRows, rowId)
            } else {
                if (ctrlKey) {
                    AddItem(selectedRows, setSelectedRows, rowId)
                } else {
                    Select(setSelectedRows, [rowId])
                }
            }
        }
    }
    setLastClickedRowId(rowId)
}

const SelectAll = (setSelectedRows, setAllSelectedMode) => {
    setSelectedRows([])
    setAllSelectedMode(true)
}

const DeselectAll = (setSelectedRows, setAllSelectedMode, setLastClickedRowId) => {
    setAllSelectedMode(false)
    setSelectedRows([])
    setLastClickedRowId(-1)
}

const CountSelected = (selectedRows, allSelectedMode, countAvailableRows) => {
    if (allSelectedMode) {
        return countAvailableRows() - selectedRows.length
    } else {
        return selectedRows.length
    }
}

const ResetLastClick = (setLastClickedRowId) => {
    setLastClickedRowId(-1)
}

export const useRowsSelector = (countAvailableRows) => {
    const [lastClickedRowId, setLastClickedRowId] = useState(false)
    const [allSelectedMode, setAllSelectedMode] = useState(false)

    // allSelectedMode == true => marked rows contains selected rows
    // allSelectedMode == false => marked rows contains excluded rows
    const [markedRows, setMarkedRows] = useState([])

    return {
        removeItem: (rowId) => RemoveItem(markedRows, setMarkedRows, rowId),

        addItem: (rowId) => AddItem(markedRows, setMarkedRows, rowId),

        isSelected: (rowId) => IsSelected(markedRows, allSelectedMode, rowId),

        selectRows: (rows) => Select(setMarkedRows, rows),

        onRowClick: (rowId, currentRowsIds, shiftKey, ctrlKey) => OnRowClick(markedRows, setMarkedRows, allSelectedMode, setAllSelectedMode,
            lastClickedRowId, setLastClickedRowId, rowId, currentRowsIds, shiftKey, ctrlKey),

        deselectAllRows: () => DeselectAll(setMarkedRows, setAllSelectedMode, setLastClickedRowId),

        selectAllRows: () => SelectAll(setMarkedRows, setAllSelectedMode),

        countSelected: () => CountSelected(markedRows, allSelectedMode, countAvailableRows),

        resetLastClick: () => ResetLastClick(setLastClickedRowId),

        allSelectedMode,

        markedRows
    }
}

export const shouldShowRowDetailsHelper = (selector, data) => {
    if (selector.countSelected() !== 1 || data.length === 0) return false
    if (selector.allSelectedMode) {
        return data.some(row => !selector.markedRows.includes(row.id))
    } else {
        return data.some(e => e.id === selector.markedRows[0])
    }
}

export const setSelectedSingleItemWhenSelectAllClicked = (selector, data, selectedSingleItem, setSelectedSingleItem) => { 
    if (selector.allSelectedMode && shouldShowRowDetailsHelper(selector, data)) {
        data.forEach(row => {
            let isSingleSelected = !selector.markedRows.includes(row.id)
            if (isSingleSelected && selectedSingleItem.id !== row.id) {
                setSelectedSingleItem(row)
                return
            }
        });
    }
}