import React from "react"
import { Modal } from "antd"
import Button from "./Button"
import "./common.less"
import fourDots from "images/four_dots.svg"

const FormModal = ({
  onAccept,
  onDecline,
  title,
  visible,
  content,
  loading,
  cancelButton,
  addClass,
  okButtonName,
  header,
  hasForm,
  acceptDisabled,
  destroyOnClose,
  afterClose,
  error,
  actionLink,
  cookieModal,
  isCookie,
}) => {
  return (
    <Modal
      afterClose={afterClose}
      destroyOnClose={destroyOnClose}
      onAccept={onAccept}
      onDecline={onDecline}
      title={title}
      visible={visible}
      footer={null}
      className={`FormModal ${typeof addClass !== "undefined" ? addClass : ""}`}
      centered
      loading={loading}
    >
      <img src={fourDots} alt="Four small dots in modal corner" className="leftTopDots" />
      <img src={fourDots} alt="Four small dots in modal corner" className="rightBottomDots" />

      {header ? header : ""}
      {content ? <p>{content}</p> : ""}
      {isCookie ? cookieModal() : ""}
      {hasForm ? hasForm : ""}

      {error && <span className="error-message">{error}</span>}

      <div className="buttons">
        {onDecline && (
          <Button name="Anuluj" onClick={onDecline} styling="button-small-white" style={{ marginRight: "20px" }} />
        )}

        {!actionLink ? (
          <Button
            name={okButtonName ? okButtonName : "OK"}
            onClick={onAccept}
            styling="button-large"
            loading={loading}
            disabled={error || acceptDisabled || loading}
          />
        ) : (
          <Button
            disabled={loading}
            name={okButtonName ? okButtonName : "OK"}
            onClick={() => window.open(actionLink, "_blank")}
            styling="button-large"
          />
        )}
      </div>
    </Modal>
  )
}

export default FormModal
