import React, { useContext } from "react"

import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"

import Context from "../../context/index"

const handleDateFormat = (data) => {
  if (!data) {
    return false
  }

  const [date] = data.split("T")

  return `${date.replaceAll("-", ".")}`
}

const columns = [
  {
    Header: "Aplikacja",
    accessor: "applicationName",
  },
  {
    Header: "",
    sortable: false,
    show: false,
    Cell: ({ row }) => {
      return <ActionButtons {...row} />
    },
  },
  {
    Header: "Data ważności",
    accessor: "expirationDate",
    Cell: ({ row: { expirationDate } }) => {
      return handleDateFormat(expirationDate)
    },
  },
]

const ApplicationsTable = () => {
  const { ctx } = useContext(Context)

  return (
    <div className="content-wrapper inner-shadow">
      <div className="ClientPanel">
        <div className="header">
          <span>Aplikacje</span>
        </div>
        <ReactTable
          style={{
            marginTop: "35px",
          }}
          className="clean"
          showPagination={false}
          resizable={false}
          data={ctx.currentOrganization.licences.filter(
            ({ applicationName }) => applicationName !== "Weryfikator kontrahenta" && applicationName !== "Dashboard"
          )}
          columns={columns}
          minRows={1}
        />
      </div>
    </div>
  )
}

const btnStyles = {
  margin: "0 5px 0 10px",
}

const ActionButtons = (row) => (
  <>
    <button style={btnStyles}>Edit</button>
    <button>Delete</button>
  </>
)

export default ApplicationsTable
