import React, { useContext, useEffect, useState, useRef, useCallback } from "react"
import "./UserPanel.less"

import ReactTooltip from "react-tooltip"

import { Button, Modal, Avatar } from "antd"
import { useHistory } from "react-router-dom"

import API, { baseURL } from "../common/api"
import { StateContext, DispatchContext } from "../userContext"
import Context from "../../context"

import ChangeEmail from "../ChangeEmail/ChangeEmail"
import ChangePassword from "../ChangePassword/ChangePassword"

import UserEdit from "./subcomponents/UserEdit"
import Notification from "./subcomponents/Notification"

import saveIcon from "../../images/icons/save_icon.svg"
import editIcon from "../../images/edit.svg"
import deleteIcon from "images/usun.svg"
import closeIcon from "../../images/icons/cancel_icon.svg"
import FormModal from "../common/FormModal"
import ReactGA from "react-ga"
import { tracker } from "helpers/tracker"
import { Loading } from "components/Main/Loading"

const queryString = require("query-string")

const UserPanel = () => {
  const { ctx, setCtx } = useContext(Context)
  const [qs, setQs] = useState()
  const [passwordChanged, setPasswordChanged] = useState(false)
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const avatarRef = useRef(null)
  const [passChange, setPassChange] = useState(false)
  const [avatarModal, setAvatarModal] = useState(false)
  const [confirmDeleteAvatarModal, setConfirmDeleteAvatarModal] = useState(false)
  const [tmpImage, setTmpImage] = useState()
  const [imgLoading, setImgLoading] = useState()

  const fetchUserData = async () => {
    try {
      // FETCHING USER DATA
      const {
        data: { stateObject: userData },
      } = await API.get("/Dashboard/User")

      dispatch({ type: "fetchUserData", payload: { data: userData, buttonVisible: true } })
    } catch (error) {
      window.location.href = "/login/panel-uzytkownika"
    }
  }

  const closeNotification = () => {
    dispatch({ type: "handleState", payload: false, field: "handleNotification" })
  }

  const handleUploadAvatar = useCallback(async () => {
    const data = new FormData()

    data.append("file", tmpImage, tmpImage.name)
    setImgLoading(true)
    try {
      await API.post("/Dashboard/addUserAvatar", data, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })

      // DELETE FILE
      avatarRef.current.value = null
      setTmpImage()
      setImgLoading(false)
      await fetchUserData()
    } catch (error) {
      console.error(error)
      setImgLoading(false)
    }
  }, [avatarRef, tmpImage, setTmpImage])

  const handleUploadTempAvatar = (event) => {
    event.persist()
    const [fileToSend] = [...event.target.files]

    if (fileToSend.size > 1048576) {
      return alert("Plik jest za duży, zmiejsz wielkość")
    }

    setTmpImage(fileToSend)
    setAvatarModal(true)
  }

  const handleAcceptAvatar = () => {
    setAvatarModal(false)
    handleUploadAvatar()
  }

  const handleDeclineAvatar = async () => {
    setAvatarModal(false)
    avatarRef.current.value = null
    setTmpImage()
  }

  const handleDeleteAvatar = async () => {
    setImgLoading(true)
    await API.post("Dashboard/deleteUserAvatar")
    await fetchUserData()
    setTmpImage()
    avatarRef.current.value = null
    setConfirmDeleteAvatarModal(false)
    setImgLoading(false)
  }

  useEffect(() => {
    setCtx({ ...ctx, currentApp: "Panel Użytkownika" })
    fetchUserData()
    setQs(queryString.parse(window.location.search))
    dispatch({ type: "mountUserPanel" })

    ReactGA.pageview(window.location.pathname)

    return () => {
      dispatch({ type: "handleCleanBtn" })
      setCtx({ ...ctx, currentApp: "Dashboard" })
    }
  }, [])

  const history = useHistory()

  useEffect(() => {
    if (!qs) {
      return
    } else if (qs.changeEmail) {
      history.push("/panel-uzytkownika")
      setPasswordChanged(true)
    }
  }, [qs])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!state.userData) return <></>

  const initials = `${state.userData.name && state.userData.name[0]}${
    state.userData.surname && state.userData.surname[0]
  }`

  if (!state.userData.name || !state.userData.surname) return <></>

  return (
    <div className="User">
      <ReactTooltip
        effect="solid"
        className="tooltip"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />

      {tmpImage && (
        <FormModal
          visible={avatarModal}
          hasForm={
            <div className="tmp-image">
              <img src={URL.createObjectURL(tmpImage)} alt="avatar" style={{ maxWidth: "100%" }} />
            </div>
          }
          onAccept={handleAcceptAvatar}
          onDecline={handleDeclineAvatar}
          okButtonName="Zapisz"
        />
      )}

      <FormModal
        visible={confirmDeleteAvatarModal}
        content="Czy na pewno usunąć awatar?"
        onAccept={handleDeleteAvatar}
        onDecline={() => setConfirmDeleteAvatarModal(false)}
        okButtonName="Potwierdź"
      />

      <div className="content-wrapper inner-shadow">
        <div className="ClientPanel with-dots-3">
          <div className="header">
            <span>Użytkownik</span>
            <div className="actions">
              {state.moduleOneFromUserPanel.visible && (
                <>
                  {/* CHANGE EMAIL */}
                  <Button
                    className={ctx.currentOrganization.owner ? `btnDisabled` : ""}
                    data-tip={
                      ctx.currentOrganization.owner
                        ? "Jako użytkownik, który założył organizację w CloudTax, nie masz możliwości zmiany adresu email."
                        : ""
                    }
                    onClick={() => {
                      tracker("Panel użytkownika Zmień email")
                      if (!ctx.currentOrganization.owner) {
                        dispatch({ type: "handleState", payload: true, field: "editEmailModal" })
                      }
                    }}
                  >
                    Zmień e-mail
                  </Button>
                  {/* CHANGE PASSWORD */}
                  <Button
                    onClick={() => {
                      tracker("Panel użytkownika Zmień hasło")
                      dispatch({ type: "handleState", payload: true, field: "editPassModal" })
                    }}
                  >
                    Zmień hasło
                  </Button>
                </>
              )}

              {state.moduleOneFromUserPanel.visible && state.showEditModal && (
                <img
                  alt="action icon"
                  className="action-icon"
                  src={closeIcon}
                  style={{
                    marginRight: "30px",
                  }}
                  onClick={() => {
                    dispatch({ type: "handleCancelEditChecker" })
                  }}
                />
              )}

              {state.moduleOneFromUserPanel.visible && state.editButtonVisible && (
                <img
                  alt="action icon"
                  className="action-icon"
                  src={state.showEditModal ? saveIcon : editIcon}
                  onClick={() => {
                    if (state.showEditModal) {
                      // HANDLE SAVE
                      dispatch({ type: "handleSave" })
                    } else {
                      // HANDLE EDIT
                      tracker("Panel użytkownika Edytuj")
                      dispatch({ type: "handleEdit", payload: true })
                    }
                  }}
                />
              )}
            </div>
          </div>

          {passwordChanged && (
            <Notification title="Adres email poprawnie zmieniony." onClick={() => setPasswordChanged(false)} />
          )}

          {passChange && <Notification title="Hasło zostało zmienione." onClick={() => setPassChange(false)} />}

          {state.handleNotification && (
            <Notification
              title="W celu dokończenia zmiany adresu, wykonaj kroki opisane w wysłanym mailu."
              onClick={closeNotification}
            />
          )}
          <div className="image-container">
            {/* <Avatar size="large" icon={<div className="initials-img">{initials}</div>} /> */}
            <div className="image-container-wrapper">
              <div className="initials-img">
                {imgLoading ? (
                  <Loading />
                ) : state.userData.avatar ? (
                  <img
                    src={`${baseURL}${state.userData.avatar}?${new Date().getTime()}`}
                    alt="avatar"
                    style={{ maxWidth: "210px" }}
                  />
                ) : (
                  initials
                )}
              </div>
              <input
                type="file"
                name="avatar"
                style={{ display: "none" }}
                accept="image/x-png,image/jpeg"
                ref={avatarRef}
                onChange={handleUploadTempAvatar}
              />
            </div>

            {!imgLoading && (
              <img
                data-tip="Edytuj awatar"
                alt="edit avatar icon"
                className="action-icon avatar-edit"
                src={editIcon}
                onClick={() => {
                  if (avatarRef && avatarRef.current) {
                    avatarRef.current.click()
                  }
                }}
              />
            )}

            {!imgLoading && state.userData.avatar && (
              <img
                data-tip="Usuń awatar"
                alt="delete avatar icon"
                className="action-icon avatar-delete"
                src={deleteIcon}
                onClick={() => {
                  setConfirmDeleteAvatarModal(true)
                }}
              />
            )}
          </div>

          {state.showEditModal && <UserEdit />}

          {!state.showEditModal && (
            <>
              <div className="basic-info">
                <h3>
                  {state.userData.name} {state.userData.surname}
                </h3>
                <h3>{ctx.currentOrganization.name}</h3>
                <h3>{ctx.currentOrganization.roles[0] === "OrganizationAdmin" ? "Admin" : "Użytkownik"}</h3>
              </div>

              <div className="additional-info">
                <h3>{state.userData.email}</h3>
                <h3>{state.userData.phone}</h3>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        visible={state.editEmailModal}
        footer=""
        bodyStyle={{ padding: 0 }}
        closable={false}
        width="fit-content"
        onCancel={() => dispatch({ type: "handleState", payload: false, field: "editEmailModal" })}
      >
        <ChangeEmail />
      </Modal>

      <Modal
        visible={state.editPassModal}
        footer=""
        bodyStyle={{ padding: 0 }}
        closable={false}
        width="fit-content"
        onCancel={() => dispatch({ type: "handleState", payload: false, field: "editPassModal" })}
      >
        <ChangePassword setPassChange={setPassChange} />
      </Modal>
    </div>
  )
}

export default UserPanel
