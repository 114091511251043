/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react"
import { Menu } from "antd"
import "../../Navbar.less"
import Context from "../../../../context/index"

const SwitcherContent = () => {
  const { ctx, setCtx } = useContext(Context)

  const handleCurrentOrg = (guid: string) => {
    if (guid === ctx.currentOrganization.guid) return setCtx({ ...ctx, orgSwircherVisible: false })

    const [newCurrentOrg] = ctx.otherOrganizations.filter((org: { guid: string }) => org.guid === guid)
    const otherOrgs = ctx.otherOrganizations.filter((org: any) => org.guid !== guid)

    // TODO: CHECK IF USER CAN SWITCH APP

    // if (ctx.currentApp === "Panel klienta") {
    localStorage.setItem("currentGuid", newCurrentOrg.guid)
    return (window.location.href = "/")
    // }

    otherOrgs.push(ctx.currentOrganization)
    localStorage.setItem("currentGuid", newCurrentOrg.guid)

    setCtx({
      ...ctx,
      currentOrganization: newCurrentOrg,
      otherOrganizations: otherOrgs,
      fetchNewOrg: !ctx.fetchNewOrg,
      orgSwircherVisible: false,
    })
  }

  const allOrgs = [ctx.currentOrganization, ...ctx.otherOrganizations]

  return (
    <Menu className="switcherContent">
      {allOrgs.map((org: any, id) => {
        return (
          <Menu.Item key={org.guid} onClick={() => handleCurrentOrg(org.guid)} className="item">
            <div className={id === 0 ? "currentOrg inner" : "inner"}>
              <div className="char">{ctx.currentOrganization.name[0]}</div>
              <span>{org.name}</span>
            </div>
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

export default SwitcherContent
