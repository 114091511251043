import React from "react"
import FormModal from "components/common/FormModal"

interface SendModalProps {
  visible: boolean
  handlePostSend: () => void
  closeModal: () => void
  loading: boolean
}

export const SendModal = (props: SendModalProps) => {
  const { visible, handlePostSend, closeModal, loading } = props

  return (
    //@ts-ignore
    <FormModal
      loading={loading}
      destroyOnClose
      visible={visible}
      content={
        "Czy na pewno chcesz wysłać ten plik na środowisko produkcyjne? Spowoduje to oficjalne złożenie pliku do Ministerstwa Finansów. Potencjalne zmiany do danego rejestru należy będzie wysłać w formie korekty."
      }
      // @ts-ignore
      onAccept={handlePostSend}
      addClass="exportModal gateExportModal"
      onDecline={closeModal}
      okButtonName="Wyślij"
    />
  )
}
