import React from "react"
import { CloseCircleOutlined } from "@ant-design/icons"

import closeIcon from "images/icons/close-notification.svg"

const Notification = (props) => {
  return (
    <div className="notification">
      <h3>{props.title}</h3>
      <img className="close-icon" src={closeIcon} onClick={props.onClick} />
    </div>
  )
}

export default Notification
