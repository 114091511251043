import React, { useContext, useCallback, useEffect } from "react"

import { StateContext, DispatchContext } from "components/userContext"
import API from "components/common/api"
import { Checkbox, Form } from "antd"
import { sellProceduresVariant1, sellProceduresVariant2, purchaseProceduresVariant1, purchaseProceduresVariant2 } from "components/VatDetails/VatDefinintions"

import infoIcon from "../../../images/info.svg"

const ProcedureAdd = ({ purchase, formVariant }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const dictionary = !purchase ? "procedureDictionary" : "shoppingProcedureDictionary"
  const procedures = formVariant ?
    (purchase ?
      (formVariant === 1 ? purchaseProceduresVariant1 : purchaseProceduresVariant2) :
      (formVariant === 1 ? sellProceduresVariant1 : sellProceduresVariant2))
    : []

  const fetchData = useCallback(async () => {
    // dictionary fetch once
    try {
      const route = !purchase
        ? `/VatRecordSale/getSellProcedureDictionary`
        : `/VatRecordPurchase/getPurchaseProcedureDictionary`
      const {
        data: { stateObject },
      } = await API.get(route, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid"),
        },
      })

      dispatch({ type: "handleState", field: dictionary, payload: stateObject })
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (state.procedureDictionary) return
    fetchData()
  }, [])

  if (!purchase ? !state.procedureDictionary : !state.shoppingProcedureDictionary) return <></>

  return (
    <div className="transaction scroll">
      {state[dictionary].map(({ code, short_description, full_description, included_in_schemas }) => {
        if (!procedures.includes(code)) return <></>
        return (
          <div key={code} className="gtu-row">
            <Form.Item name={code.toLowerCase()} hasFeedback className="details-item" valuePropName="checked">
              <Checkbox>
                <>
                  <span style={{ fontWeight: "bold" }}>{code}</span>
                  <span>{short_description}</span>
                  <img src={infoIcon} alt="info" data-tip={full_description} />
                </>
              </Checkbox>
            </Form.Item>
          </div>
        )
      })}
    </div>
  )
}

export default ProcedureAdd
