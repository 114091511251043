import React, { useContext, useEffect, useState } from "react"
import "./Dashboard.less"
import { UserStore } from "../../mobx/userStore"
import { Link } from "react-router-dom"

import ReactGA from "react-ga"

import Context from "../../context/index"

import getAppUrl from "../../helpers/getAppUrl"

// import exampleApps from "./subcomponents/exampleApps"
// import exampleapp from "../../images/exampleapp.svg"

const Dashboard = () => {
  const { ctx, setCtx } = useContext(Context)
  const { stateObject: applications } = ctx
  const [apps, setApps] = useState(applications)

  const setApplication = (application) => {
    UserStore.setApplication(application)
  }

  useEffect(() => {
    setApps(applications)
  }, [ctx.stateObject])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    setCtx({ ...ctx, currentApp: "Dashboard" })
  }, [])

  const onTileClick = (name) => {
    setCtx({ ...ctx, currentApp: name })
    setApplication(name)
  }

  // const nope = () => {
  //   throw new Error("error test")
  // }

  const blockedApps = ["Weryfikator kontrahenta", "Dashboard"]

  if (!apps || !apps.length)
    return (
      <span
        style={{
          width: "100%",
          textAlign: "center",
          display: "block",
          position: "relative",
          top: "150px",
        }}
      >
        Brak dostępnych aplikacji
      </span>
    )

  return (
    <div className="Dashboard">
      {/* <button onClick={nope}>xxx</button> */}
      <div className="Dashboard-app-switcher inner-shadow">
        {apps
          .filter((app) => !blockedApps.includes(app.name))
          .map(({ name, image, colorTheme, description }) => {
            const fixedName = name === "Sprawdź JPK_VAT" ? "J" : name[0]
            return (
              <Link key={name} to={getAppUrl(name)} onClick={() => onTileClick(name)} className="Dashboard-app">
                <div className="content">
                  <div className="left">
                    <div className="header">
                      <div className="char" style={{ backgroundColor: colorTheme ? colorTheme : "#2D98DA" }}>
                        {fixedName}
                      </div>
                      <h4>{name}</h4>
                    </div>
                    <p>
                      {description
                        ? description
                        : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum quod nesciunt reiciendis quia, fugiat omnis."}
                    </p>
                  </div>

                  <img src={image} alt="application's graphic" />
                </div>
              </Link>
            )
          })}
      </div>
    </div>
  )
}

export default Dashboard
