import React, { useState, useContext, useEffect } from "react"
import Context from "../../context/index"

import "./ClientPanel.less"

import API, { baseApiURL } from "../common/api"

import * as actionTypes from "../../helpers/types"

import ApplicationsTable from "../Tables/ApplicationsTable"
import UsersTable from "../Tables/UsersTable"

import AffiliatedEntityTable from "../Tables/AffiliatedEntityTable"

import ClientEdit from "../ClientEdit/ClientEdit"
import { StateContext, DispatchContext } from "../userContext"

import { Loading } from "../Main/Loading"

import saveIcon from "../../images/icons/save_icon.svg"
import editIcon from "../../images/edit.svg"
import closeIcon from "../../images/icons/cancel_icon.svg"

import ReactGA from "react-ga"

const ClientPanel = () => {
  const { ctx, setCtx } = useContext(Context)

  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [loading, setLoading] = useState(true)

  // FETCH ORG DETAILS
  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true)

      try {
        const { data } = await API.get(
          `${baseApiURL}/Dashboard/organizationDetalis/?orgGuid=${ctx.currentOrganization.guid}`
        )

        const { stateObject: organizationDetalis } = data

        setLoading(false)
        dispatch({ type: "mountClientPanel" })
        setCtx({ ...ctx, organizationDetalis, clientEdit: true, currentApp: "Panel administratora" })
      } catch (error) {
        setLoading(false)
        window.location.href = "/login/panel-administratora"
      }
    }

    if (window.location.search.includes("id")) {
      const [, id] = window.location.search.split("=")

      dispatch({
        type: actionTypes.HANDLE_MODULE_TWO_USER_PANEL,
        payload: { url: window.location.pathname, visible: true, preSelectedId: id },
      })
    }

    fetchDetails()
    dispatch({ type: "handleState", field: "sidebarVisible", payload: true })

    ReactGA.pageview(window.location.pathname)

    return () => {
      setCtx({ ...ctx, clientEdit: false })
      dispatch({ type: actionTypes.HANDLE_RESET_MODULE })
    }
  }, [])

  if (loading) return <Loading />

  if (!ctx.organizationDetalis) return <></>
  if (state.moduleTwoFromUserPanel.visible) return <AffiliatedEntityTable />
  if (state.moduleThreeFrom.visible) return <ApplicationsTable />
  if (state.moduleTwoFrom.visible) return <UsersTable />

  return (
    <div className="content-wrapper inner-shadow">
      <div className={`ClientPanel with-dots-1`}>
        <div className="header">
          <span>Organizacja</span>
          <div className="actions">
            {!state.moduleTwoFromUserPanel.visible && state.moduleOneFrom.visible && ctx.clientEditModal && (
              // CANCEL
              <img
                alt="action icon"
                className="action-icon"
                src={closeIcon}
                style={{
                  marginRight: "30px",
                }}
                onClick={() => {
                  setCtx({ ...ctx, handleSubmitClientForm: true, save: false })
                }}
              />
            )}

            {!state.moduleTwoFromUserPanel.visible && state.moduleOneFrom.visible && ctx.clientEdit && (
              // EDIT
              <img
                alt="action icon"
                className="action-icon"
                src={ctx.clientEditModal ? saveIcon : editIcon}
                onClick={() => {
                  if (ctx.clientEditModal) {
                    setCtx({ ...ctx, handleSubmitClientForm: true, save: true })
                  } else {
                    setCtx({ ...ctx, clientEditModal: true })
                  }
                }}
              />
            )}
          </div>
        </div>
        {ctx.clientEditModal ? <ClientEdit /> : <PresentClient />}
      </div>
    </div>
  )
}

const PresentClient = () => {
  const {
    ctx: {
      currentOrganization: { licenceType, licenceDate },
      organizationDetalis: { organization_name, nip, address, city, post_code, country },
    },
  } = useContext(Context)

  return (
    <div className="content has-before">
      <div className="image-container">
        <div className="initials-img">{organization_name}</div>
      </div>
      <ul>
        <li>
          <span className="label">Nazwa organizacji</span>
          <span className="label">{organization_name}</span>
        </li>
        <li>
          <span className="label">NIP</span>
          <span className="label">{nip}</span>
        </li>
        <li>
          <span className="label">Adres</span>
          <span className="label">{address}</span>
        </li>
        <li>
          <span className="label">Miasto</span>
          <span className="label">{city}</span>
        </li>
        <li>
          <span className="label">Kod pocztowy</span>
          <span className="label">{post_code}</span>
        </li>
        <li>
          <span className="label">Kraj</span>
          <span className="label">{country}</span>
        </li>
        <li>
          <span className="label">Poziom licencji</span>
          <span className="label">{licenceType}</span>
        </li>
        <li>
          <span className="label">Data ważności licencji</span>
          <span className="label">{licenceDate.replaceAll("-", ".")}</span>
        </li>
      </ul>
    </div>
  )
}

export default ClientPanel
