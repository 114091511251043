import React from "react"
import FormModal from "components/common/FormModal"

interface BeforeDeleteModalProps {
  visible: boolean
  handlePostBeforeDelete: () => void
  closeModal: () => void
  loading: boolean
}

export const BeforeDeleteModal = (props: BeforeDeleteModalProps) => {
  const { visible, handlePostBeforeDelete, closeModal, loading } = props

  return (
    //@ts-ignore
    <FormModal
      loading={loading}
      destroyOnClose
      visible={visible}
      content={
        "Wskazany plik został już wysłany do MF. Usunięcie pliku spowoduje utracenie danych bez możliwości ich przywrócenia. Czy na pewno chcesz kontynuować?"
      }
      // @ts-ignore
      onAccept={handlePostBeforeDelete}
      addClass="exportModal gateExportModal"
      onDecline={closeModal}
      okButtonName="Kontynuuj"
    />
  )
}
