import React, { useContext, useState, useEffect } from "react"
import NipVerifierTable from "./NipVerifierMain/NipVerifierTable"
import NipVerifierHistory from "./NipVerifierHistory/NipVerifierHistory"
import Context from "context"
import { DispatchContext, StateContext } from "components/userContext"
import * as actionTypes from "../../helpers/types"



const NipVerifier = () => {
    const [mainMode, setMainMode] = useState(true)
    const dispatch = useContext(DispatchContext)
    const { ctx, setCtx } = useContext(Context)

    useEffect(() => {
        dispatch({ type: "handleState", field: "sidebarVisible", payload: true })

        // handle active and visible entities
        dispatch({ type: "handleState", payload: true, field: "wlTableVisible" })
        setCtx({ ...ctx, currentApp: "Weryfikacja NIP" })

        return () => {
            dispatch({
                type: "handleState",
                payload: false,
                field: "wlTableVisible",
            })
            setCtx({ ...ctx, currentApp: "Dashboard" })

            dispatch({ type: actionTypes.HANDLE_RESET_MODULE })
        }
    }, [])


    //setReportMode(true)
    if (mainMode) {
        return <> <NipVerifierTable showReport={() => setMainMode(false)}></NipVerifierTable></>
    } else {
         return <NipVerifierHistory goBack={() => setMainMode(true)} />
    }
}

export default NipVerifier