import React, { useContext, useState, useEffect, useRef } from "react"
import { Button } from "antd"
import ReactTable from "react-table-v6"
import CustomPagination from "components/CustomPagination/CustomPagination"

import DetailsShoppingBox from "./DetailsShoppingBox"

import API from "components/common/api"
import { StateContext, DispatchContext } from "components/userContext"
import { columnsShopping as columnsDefault, columnsShoppingSmall } from "../SalesTab/columns"
import { Loading } from "components/Main/Loading"

import closeIcon from "images/close-menu.svg"

const handleSortedField = (value) => {
  if (!value.length) return ""

  const elem = value[0]
  if (!elem) return ""

  const direction = elem.desc ? "" : "-"

  return `${direction}${elem.id}`
}

const ShoppingTab = ({ id, detailsVisible, setDetailsVisible }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const [isLoading, setIsLoading] = useState()
  const [columns, setColumns] = useState(columnsDefault)

  const removeItem = (data) => {
    const tmp = [...state.selectedRowsShopping]
    const index = tmp.indexOf(data)

    if (index !== -1) {
      tmp.splice(index, 1)
      dispatch({ type: "handleState", field: "selectedRowsShopping", payload: tmp })
    }
  }

  const addItem = (index) => {
    dispatch({
      type: "handleState",
      field: "selectedRowsShopping",
      payload: [...state.selectedRowsShopping, index],
    })
  }

  const originalHandleClick = (_, handleOriginal) => {
    if (handleOriginal) {
      handleOriginal()
    }
  }

  useEffect(() => {
    if (state.selectedRowsShopping.length === 1) {
      setColumns(columnsShoppingSmall)
      setDetailsVisible(true)
    } else {
      setColumns(columnsDefault)
      setDetailsVisible(false)
    }

    if (state.selectedRowsShopping.length !== 1) {
      dispatch({ type: "handleState", field: "shoppingElem", payload: null })
      return
    }

    const [activeRow] = state.selectedRowsShopping
    const [newElem] = state.detailsTabledataShopping.filter(({ id }) => id === activeRow)

    dispatch({ type: "handleState", field: "shoppingElem", payload: newElem })
  }, [state.selectedRowsShopping])

  return (
    <div className="details-table-wrapper">
      {!state.shoppingEdit && (
        <ReactTable
          className={`vatTable vatDetailsTable ${detailsVisible ? "detailVisible" : ""}`}
          data={state.detailsTabledataShopping}
          columns={columns}
          multiSort={false}
          manual
          loading={isLoading}
          noDataText={""}
          NoDataComponent={() => null}
          LoadingComponent={(props) => {
            if (props.loading) {
              return <Loading absolute />
            }
            return <></>
          }}
          resizable={false}
          PaginationComponent={CustomPagination}
          pages={state.detailsTableShoppingOptions.totalPagesNumber}
          defaultPageSize={10}
          filtered={state.detailsTableShoppingOptions.filtered}
          onFetchData={(innerState) => {
            setIsLoading(true)

            const [allValue] = innerState.filtered.filter((e) => e.id === "all")

            const filterValue = allValue ? allValue.value : ""
            const [typeValue] = innerState.filtered.filter((e) => e.id === "type")

            const filterValueType = typeValue ? typeValue.value : ""
            const filteredValueRoles = !typeValue ? "" : `,dokument_zakupu@=${filterValueType}`

            const sortedValue = handleSortedField(innerState.sorted)

            API.get(
              `/VatRecordPurchase/getVatRecordPurchaseList?VatRecordId=${id}&sorts=${sortedValue}&page=${innerState.page + 1
              }&pageSize=${innerState.pageSize}&filters=search@=${filterValue}${filteredValueRoles ? filteredValueRoles : ""
              }`
            )
              .then(
                ({
                  data: {
                    stateObject: { results: stateObject, ...tableParams },
                  },
                }) => {
                  dispatch({ type: "handleState", field: "detailsTabledataShopping", payload: stateObject })
                  dispatch({
                    type: "handleState",
                    field: "detailsTableShoppingOptions",
                    payload: {
                      ...state.detailsTableShoppingOptions,
                      ...tableParams,
                      currentPage: innerState.page + 1,
                      pageSize: innerState.pageSize,
                    },
                  })
                }
              )
              .then(() => {
                setIsLoading(false)
              })
              .catch((error) => {
                console.error(error)
              })
          }}
          getTdProps={(_, rowInfo, column, instance) => {
            return {
              onClick: ({ ctrlKey }) => {
                // Expand row on expand cell click
                if (typeof rowInfo !== "undefined") {
                  // add row selection on row click, except click on expand arrow
                  if (column.id && state.selectedRowsShopping.includes(rowInfo.original.id)) {
                    removeItem(rowInfo.original.id)
                  } else {
                    if (ctrlKey) {
                      addItem(rowInfo.original.id)
                    } else if (column.id) {
                      dispatch({
                        type: "handleState",
                        field: "selectedRowsShopping",
                        payload: [rowInfo.original.id],
                      })
                    }
                  }
                }
              },
            }
          }}
          getTrProps={(_, rowInfo) => {
            if (typeof rowInfo !== "undefined") {
              return {
                style: {
                  fontWeight: state.selectedRowsShopping.includes(rowInfo.original.id) && "bold",
                  background: state.selectedRowsShopping.includes(rowInfo.original.id) && "#f4f4f4",
                },
              }
            } else {
              return {
                onClick: originalHandleClick,
              }
            }
          }}
        />
      )}

      {!state.shoppingEdit && detailsVisible && (<>
        <DetailsShoppingBox elem={state.shoppingElem || {}} />
        <Button className="closeButton" onClick={() => {
          dispatch({ type: "handleState", field: "selectedRowsShopping", payload: [] });
        }}><img src={closeIcon} alt="" /></Button>
      </>)}
    </div>
  )
}

export default ShoppingTab
