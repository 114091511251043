import React, { useContext } from "react"
import { Input } from "antd"

import debounce from "lodash.debounce"

import IconBox from "components/common/IconBox/IconBox"

import exportIcon from "images/icons/Records_icons/export_record_icon.svg"
import calcIcon from "images/icons/Records_icons/calc_record_icon.svg"
import addIcon from "images/icons/Records_icons/add_record_icon.svg"
import delIcon from "images/icons/Records_icons/del_record_icon.svg"
import backIcon from "images/back_circle.svg"
import research from "images/research.svg"
import send from "images/send.svg"
import vatUE from "images/vat_ue.svg"
import verifyIcon from "images/weryfikuj.svg"

import "./VatDetailsNavbar.less"
import { DispatchContext, StateContext } from "components/userContext"
import Context from "context"

const VatDetailsNavbar = ({
  title,
  exit,
  handleAddNewDocument,
  isDocumentBlocked,
  isDeleteBlocked,
  disabledFilter,
  handleDelete,
  isDeleteable,
  handleExport,
  handleCalc,
  handleResearch,
  handleSend,
  handleVatUE,
  verifyNip,
  handleVerify
}) => {
  const { ctx } = useContext(Context)
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const debounceHanlderInput = debounce((value) => handleFillterAll(value), 800)

  const handleFillterAll = (elem) => {
    let filtered = []

    if (!elem) {
      filtered = [...state.detailsTableOptions.filtered.filter((e) => e.id !== "all")]
    } else {
      filtered = [
        ...state.detailsTableOptions.filtered.filter((e) => e.id !== "all"),
        elem && { id: "all", value: elem },
      ]
    }

    dispatch({
      type: "handleState",
      field: "detailsTableOptions",
      payload: { ...state.detailsTableOptions, filtered },
    })
  }

  return (
    <div className="VatDetails-navbar">
      <div className="left-panel">
        <div className="arrow-wrap" onClick={exit}>
          <img src={backIcon} alt="powrót do listy ewidencji" />
        </div>
        <h3>{title}</h3>
        <Input
          disabled={disabledFilter}
          allowClear
          placeholder={null}
          onChange={({ target: { value } }) => debounceHanlderInput(value)}
          style={{ width: 169, height: 38 }}
        />
      </div>
      <div className="right-panel">
        <IconBox name="eksport" icon={exportIcon} click={handleExport} />
        <IconBox name="obliczenia" icon={calcIcon} click={() => handleCalc(true)} disabled={isDocumentBlocked} />
        <IconBox name="badania" icon={research} click={handleResearch} />
        {verifyNip && <IconBox name="weryfikuj NIP" icon={verifyIcon} click={handleVerify}/>}
        {!ctx.featureFlags.VatUeUekDisabled && <IconBox name="VAT UE" icon={vatUE} click={handleVatUE}/>}
        {!ctx.featureFlags.Generator_GateDisabled && <IconBox name="wyślij" icon={send} click={handleSend} />}
        <IconBox
          name="dokument"
          icon={addIcon}
          click={handleAddNewDocument}
          disabled={isDocumentBlocked || disabledFilter}
        />
        <IconBox name="usuń" icon={delIcon} click={handleDelete} disabled={!isDeleteable() || isDeleteBlocked} />
      </div>
    </div>
  )
}

export default VatDetailsNavbar
