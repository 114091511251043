import React, { FC } from "react";
import logo from "../../images/JPK_logo.png";
import "./common.less";

interface Iprops {
  style?: React.CSSProperties;
}

const Logo: FC<Iprops> = (iProps) => {
  return (
    <div>
      <img
        src={logo}
        alt="JPK Insight brand logo"
        className="logo"
        style={iProps.style}
      />
    </div>
  );
};

export default Logo;
