import React, { useContext, useState, useEffect } from "react"
import Context from "context"
import { DispatchContext, StateContext } from "components/userContext"
import { Tabs } from "antd"
import { historyImportColumns } from "./history_imports_data"
import { historyVerificationColumns } from "./history_verifications_data"
import { getVerificationFilter } from "../UrlHelper"


import WhiteListHistoryTable from "./WhiteListHistoryTable"
import API from "components/common/api"

import "./WhiteListHistory.less"
import backIcon from "images/back_circle.svg"
const { TabPane } = Tabs



const WhiteListHistory = (props) => {
  const { ctx, setCtx } = useContext(Context)

  const state = useContext(StateContext)
  const [loading, setLoading] = useState(true)

  const dispatch = useContext(DispatchContext)

  const fetchImportData = (sortData) => {
    setLoading(true)
    API.get(
      `/WhiteList/GetContractorImports/?Id=` + ctx.currentContractor.id +
      `&sorts=${sortData.sortedValue}&page=${sortData.innerPage + 1}&pageSize=${sortData.innerPageSize}`
    )
      .then(
        ({
          data: {
            stateObject: { results: stateObject, ...tableParams },
          },
        }) => {

          dispatch({
            type: "handleState",
            field: "whiteListImportHistoryData",
            payload: stateObject,
          })
          dispatch({
            type: "handleState",
            field: "wlImportsHistoryOptions",
            payload: {
              ...state.wlImportsHistoryOptions,
              ...tableParams,
              currentPage: sortData.innerPage + 1,
              pageSize: sortData.innerPageSize
            },
          })

          // setWlData(stateObject)
          setLoading(false)
        }
      )
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const fetchVerificationData = (sortData) => {
    setLoading(true)
    const statusFilter = getVerificationFilter(state.wlVerificationHistoryOptions.filteredVerificationState)
    let filterUrlPart = ""
    if (statusFilter.length > 0) {
      filterUrlPart = "&filters=" + statusFilter
    }
    const url = `/WhiteList/GetContractorBankAccountVerifications/?Id=` + ctx.currentContractor.id +
      `&sorts=${sortData.sortedValue}&page=${sortData.innerPage + 1}&pageSize=${sortData.innerPageSize}` +
      filterUrlPart
    API.get(url)
      .then(
        ({
          data: {
            stateObject: { results: stateObject, ...tableParams },
          },
        }) => {

          dispatch({
            type: "handleState",
            field: "whiteVerificationHistoryData",
            payload: stateObject,
          })
          dispatch({
            type: "handleState",
            field: "wlVerificationHistoryOptions",
            payload: {
              ...state.wlVerificationHistoryOptions,
              ...tableParams,
              currentPage: sortData.innerPage + 1,
              pageSize: sortData.innerPageSize
            },
          })

          // setWlData(stateObject)
          setLoading(false)
        }
      )
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }


  return <div className="WhiteListHistory content-wrapper">
    <div className="WlDetails-navbar">
      <div className="left-panel">
        <div className="arrow-wrap" onClick={props.goBack}>
          <img src={backIcon} alt="powrót do listy ewidencji" />
        </div>
        <h3>{ctx.currentContractor.contractor_name}</h3>
      </div>
    </div>
    <div className="VatDetails-box">  <Tabs>
      <TabPane tab="Dane zaimportowane" key="1" forceRender>
        <WhiteListHistoryTable
          data={state.whiteListImportHistoryData}
          columns={historyImportColumns}
          multiSort={false}
          loading={loading}
          options={state.wlImportsHistoryOptions}
          fetchSortData={filters => fetchImportData(filters)}
        />
      </TabPane>
      <TabPane tab="Weryfikacja rachunku" key="2" forceRender>
        <WhiteListHistoryTable
          data={state.whiteVerificationHistoryData}
          columns={historyVerificationColumns}
          multiSort={false}
          loading={loading}
          options={state.wlVerificationHistoryOptions}
          fetchSortData={filters => fetchVerificationData(filters)}
        />
      </TabPane>
    </Tabs>
    </div>
  </div>
}


export default WhiteListHistory