// funkcje pomocnicze

export const returnTaxDueEl = (el, obj) => {
  const stiffValue = obj[el.name + ["W"]]
  el.value = obj[el.name]

  if (typeof stiffValue !== "undefined") {
    el.constValue = stiffValue
  }

  return el
}

export const returnTaxDueDictionary = (el, obj) => {
  obj.forEach((item) => {
    if (el.name.toUpperCase() === item.code.toUpperCase()) {
      el.full_description = item.full_description
      el.short_description = item.short_description
    }
  })

  return el
}

export const returnRenderData = (array, obj) => {
  return array.map((el) => {
    if (!Array.isArray(el)) {
      return returnTaxDueEl(el, obj)
    } else {
      const newEl = el.map((item) => {
        return returnTaxDueEl(item, obj)
      })
      return newEl
    }
  })
}

export const fetchWithDictionaries = (arr, obj) => {
  return arr.map((el) => {
    if (!Array.isArray(el)) {
      return returnTaxDueDictionary(el, obj)
    } else {
      const newEl = el.map((item) => {
        if (!item || !item.name) return
        return returnTaxDueDictionary(item, obj)
      })
      return newEl
    }
  })
}

// check if any of formFiled was edit

export const checkIfTouched = (arr) => {
  return arr.map((item) => item.hasOwnProperty("touched")).some((item) => item === true)
}

export const summaryIfTouched = (arr, obj) => {
  return arr.map((el) => parseInt(el.value) === obj[el.name]).some((el) => el === false)
}

export const ifRadiosIfTouched = (arr, obj) => {
  return arr.map((el) => el.value === obj[el.name]).some((el) => el === false)
}

export const returnFormatedNumber = (number) => {
  if (number % 1 != 0) {
    return number.toLocaleString("pl-PL", { minimumFractionDigits: 2 })
  }

  return number.toLocaleString("pl-PL", { minimumFractionDigits: 0 })
}

export const helperRowValue = (row, empty, valueKind) => {
  let helperValue
  if (!empty) {
    if (valueKind === "value") {
      helperValue = row[0][valueKind] === null ? "-" : row[0][valueKind]
    } else {
      helperValue = row[0][valueKind] === null ? "-" : row[0][valueKind]
    }
  } else {
    helperValue = ""
  }

  return returnFormatedNumber(helperValue)
}

export const sumArray = (arr, formValues) => {
  return arr
    .map((el) => (el = formValues[el]))
    .filter((item) => item != null && item !== "")
    .reduce((a, b) => a + b, 0)
}

// return valid state for individual declaration tabs
export const returnTabValid = (notValidArray, arr) => {
  return notValidArray.map((el) => arr.some((item) => el === item)).some((el) => el)
}

// return valid state for individual declaration tabs when summary inputs are invalid
export const returnTabSumValid = (summaryArr, arr) => {
  return arr.map((el) => summaryArr.filter((item) => el === item.name)).some((rc) => !rc[0].valid)
}

// Validators for declaration form

// Linked two form fields for declaration tabs

export const twoFieldsValidator = (e, row, formRef) => {
  if (row.some((el) => typeof el === "undefined")) return Promise.resolve()

  const elementValue = e.getFieldValue(row[0].name)
  const nextElementValue = e.getFieldValue(row[1].name)

  const firstValid = elementValue !== null
  const nextValid = nextElementValue !== null

  const bothValid = (firstValid && nextValid) || (!firstValid && !nextValid)

  if (bothValid) {
    formRef.current.setFields([
      {
        name: row[0].name,
        errors: [],
      },
      {
        name: row[1].name,
        errors: [],
      },
    ])

    return Promise.resolve()
  } else {
    if (firstValid && !nextValid) {
      return Promise.reject("Uzupełnij wartość podatku!")
    }
    if (!firstValid && nextValid) {
      return Promise.reject("Uzupełnij wartość podstawy!")
    }
  }
}

export const blockNumberScrool = () => {
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur()
    }
  })
}

export const p_51valueReturn = (summaryState, declarationFormRef) => {
  let fieldValue =
    parseInt(summaryState[1].value) -
    parseInt(summaryState[2].value) -
    declarationFormRef.current.getFieldValue("p_49") -
    declarationFormRef.current.getFieldValue("p_50")

  fieldValue = fieldValue > 0 ? fieldValue : 0

  return fieldValue
}

export const p_53valueReturn = (summaryState, declarationFormRef) => {
  let fieldValue =
    (parseInt(summaryState[2].value) || 0) +
    (declarationFormRef.current.getFieldValue("p_52") || 0) -
    (parseInt(summaryState[1].value) || 0)

  fieldValue = fieldValue > 0 ? fieldValue : 0

  return fieldValue
}

export const p_62valueReturn = (p_53, p_54) => {
  let fieldValue = p_53 - p_54
  if (fieldValue < 0) fieldValue = 0

  return fieldValue
}

export const returnSummaryFields = (field) => (field === null ? 0 : Math.round(field))

// Calculation Tabs Valid Rules

export const calcValidator = {
  greaterThanZero: (e, name) => {
    return e.getFieldValue(name) >= 0 ? Promise.resolve() : Promise.reject("Wartość musi być większa lub równa 0.")
  },
  p_49_valid: (e, name, summaryState) => {
    const elementValue = e.getFieldValue(name)
    const sumToValid = summaryState[1].value - summaryState[2].value

    if (elementValue < 0) {
      return Promise.reject("Wartość musi być większa lub równa 0.")
    } else {
      if (sumToValid <= 0) {
        if (elementValue === 0) {
          return Promise.resolve()
        } else {
          return Promise.reject("Podana kwota musi się równać 0")
        }
      }

      if (elementValue > sumToValid) {
        return Promise.reject("Podana kwota nie może przekraczać różnicy kwot P_38-P_48.")
      } else {
        return Promise.resolve()
      }
    }
  },
  p_50_valid: (e, name, summaryState) => {
    const elementValue = e.getFieldValue(name)
    let sumToValid = summaryState[1].value - summaryState[2].value - e.getFieldValue("p_49")

    sumToValid = sumToValid < 0 ? 0 : sumToValid

    if (elementValue < 0) {
      return Promise.reject("Wartość musi być większa lub równa 0.")
    } else {
      if (elementValue > sumToValid) {
        return Promise.reject("Podana kwota nie może przekraczać różnicy kwot P_38-P_48 - P_49.")
      } else {
        return Promise.resolve()
      }
    }
  },
  p_52_valid: (e, name, summaryState) => {
    const elementValue = e.getFieldValue(name)
    const sumToValid = summaryState[1].value - summaryState[2].value
    const p_49 = e.getFieldValue("p_49")

    if (elementValue < 0) {
      return Promise.reject("Wartość musi być większa lub równa 0.")
    } else {
      if (sumToValid > 0 && sumToValid - p_49 > 0 && elementValue > 0) {
        return Promise.reject("Wartość musi być równa 0.")
      }

      return Promise.resolve()
    }
  },
  p_54_valid: (e, name, summaryState) => {
    const elementValue = e.getFieldValue(name)
    const fieldToValid = summaryState[1].value

    if (elementValue < 0) {
      return Promise.reject("Wartość musi być większa od 0.")
    } else {
      if (elementValue > fieldToValid) {
        return Promise.reject("Podana kwota musi być mniejsza bądź równa wartości podanej w polu P_53.")
      } else {
        return Promise.resolve()
      }
    }
  },
  p_60_valid: (e, name) => {
    const elementValue = e.getFieldValue(name)
    const fieldToValid = e.getFieldValue("p_54")

    if (elementValue < 0) {
      return Promise.reject("Wartość musi być większa od 0.")
    } else {
      if (elementValue > fieldToValid) {
        return Promise.reject("Podana kwota musi być mniejsza bądź równa wartości podanej w polu P_54.")
      } else {
        return Promise.resolve()
      }
    }
  },
  textareas_valid: (e, name) => {
    const elementValue = e.getFieldValue(name)
    if (elementValue === null) {
      return Promise.resolve()
    }

    if (elementValue.length > 240) {
      return Promise.reject("Maksymalna ilość znaków wynosi 240")
    } else return Promise.resolve()
  },
  p_68_valid: (e, name) => {
    const elementValue = e.getFieldValue(name)
    const fieldToValid = e.getFieldValue("p_69")

    if (elementValue > 0) {
      return Promise.reject("Wartość musi być mniejsza lub równa 0.")
    } else {
      if (elementValue === null || elementValue === "") {
        return Promise.reject("Uzupełnij pole")
      }

      if (fieldToValid === null || fieldToValid === "") {
        return Promise.reject("Uzupełnij pole p_69")
      } else {
        return Promise.resolve()
      }
    }
  },
  p_69_valid: (e, name) => {
    const elementValue = e.getFieldValue(name)
    const fieldToValid = e.getFieldValue("p_68")

    if (elementValue > 0) {
      return Promise.reject("Wartość musi być mniejsza lub równa 0.")
    } else {
      if (elementValue === null || elementValue === "") {
        return Promise.reject("Uzupełnij pole")
      }

      if (fieldToValid === null || fieldToValid === "") {
        return Promise.reject("Uzupełnij pole p_68")
      } else {
        return Promise.resolve()
      }
    }
  },
}
