import React, { useEffect, useCallback, useContext, useState } from "react"

import API from "components/common/api"
import { DispatchContext, StateContext } from "components/userContext"

import ReactTooltip from "react-tooltip"

import infoIcon from "../../../images/info.svg"
import { Loading } from "components/Main/Loading"

const SalesGtu = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const fetchData = useCallback(async () => {
    // dictionary fetch once
    setLoading(true)
    try {
      const {
        data: { stateObject },
      } = await API.get(`/VatRecordSale/getGtusDictionary`, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid"),
        },
      })

      setLoading(false)
      dispatch({ type: "handleState", field: "gtuDictionary", payload: stateObject })
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (state.gtuDictionary) {
      setLoading(false)
      return
    }
    fetchData()
  }, [])

  if (loading) return <Loading />

  if (!state.gtuDictionary || !state.sellValues) return <></>

  const { sellValues } = state

  const hasGtu = state.gtuDictionary.filter(({ code }) => sellValues[code.toLowerCase()])

  return (
    <div className="transaction scroll full-width">
      <ReactTooltip
        effect="solid"
        className="tooltip-vat"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />
      {!hasGtu.length ? (
        <span>Brak GTU</span>
      ) : (
        state.gtuDictionary.map(({ code, short_description, full_description }) => {
          const elemValue = sellValues[code.toLowerCase()]

          if (!elemValue) return

          return (
            <div key={code} className="gtu-row represent">
              <span>{code}</span>
              <span>{short_description}</span>
              <img src={infoIcon} alt="info" data-tip={full_description} />
            </div>
          )
        })
      )}
    </div>
  )
}

export default SalesGtu
