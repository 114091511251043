import React from "react"

import loading from "../../images/icons/loading.gif"

export const Loading = ({ fullSize, absolute }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: fullSize ? "100vh" : "80%",
        ...(absolute && {
          position: "absolute",
          opacity: ".5",
          background: "#fff",
        }),
      }}
    >
      <img style={{ maxWidth: "100px" }} src={loading} alt="loading-icon" className="is-loading" />
    </div>
  )
}
