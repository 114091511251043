import React, { useEffect, useCallback, useContext, useState } from "react"

import infoIcon from "images/info.svg"

import API from "components/common/api"
import { DispatchContext, StateContext } from "components/userContext"

import ReactTooltip from "react-tooltip"
import { Loading } from "components/Main/Loading"

const ShoppingTransactions = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const fetchData = useCallback(async () => {
    // dictionary fetch once
    setLoading(true)
    try {
      const {
        data: { stateObject },
      } = await API.get(`VatRecordPurchase/getPurchaseTransactionDictionary`, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid"),
        },
      })

      setLoading(false)
      dispatch({ type: "handleState", field: "shoppingDictionary", payload: stateObject })
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
    if (state.shoppingDictionary) {
      setLoading(false)
      return
    }

    fetchData()
  }, [])

  if (loading) return <Loading />

  if (!state.shoppingDictionary || !state.shoppingValues) return <></>

  const { shoppingValues } = state
  const codesWithVals = []

  const hasAnyK = Object.keys(shoppingValues)
    .map((key) => {
      if (!key.includes("k_")) return
      if (key === "nr_dok_wew") return
      if (shoppingValues[key] === null) return

      if (!isNaN(shoppingValues[key])) {
        codesWithVals.push(key)
        return true
      }

      return false
    })
    .filter(Boolean)

  const middleSectionDash = codesWithVals.filter((e) => e.split("k_")[1] * 1 >= 44 && e.split("k_")[1] * 1 <= 47)
  const showHeaderSection = codesWithVals.filter((e) => e.split("k_")[1] * 1 < 44).length > 0
  const [hasAnyVals] = [...new Set(hasAnyK)]

  return (
    <div className="transaction">
      <ReactTooltip
        effect="solid"
        className="tooltip-vat"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />
      {showHeaderSection && (
        <>
          <span className="transaction-header">
            Nabycie towarów i usług oraz podatek naliczony z uwzględnieniem korekt
          </span>

          <div className="transaction-header-values">
            <span />
            <div style={{ marginTop: "30px" }}>
              <span>Wartość netto w zł</span>
              <span>Podatek naliczony w zł</span>
            </div>
          </div>
        </>
      )}

      {hasAnyVals
        ? state.shoppingDictionary.map(({ code, short_description, full_description }, index) => {
            // SKIP IF NULL VALUE
            if (shoppingValues[code.toLowerCase()] === null) return
            if (code === "zakup_vat_marza") return

            const [, codeNumber] = code.split("_")

            const paddingLeft = short_description.includes("w tym") && "20px"

            const nextElem = state.shoppingDictionary[index + 1]
            const prevElem = state.shoppingDictionary[index - 1]

            const anything = !isNaN(shoppingValues[code.toLowerCase()]) ? shoppingValues[code.toLowerCase()] : ""
            const antythingNext =
              nextElem && !isNaN(parseFloat(shoppingValues[nextElem.code.toLowerCase()]))
                ? nextElem
                  ? shoppingValues[nextElem.code.toLowerCase()]
                  : ""
                : ""

            const elemValue = anything.toString().replace(".", ",")
            const nextValue = antythingNext.toString().replace(".", ",")

            // SKIP DUPLICATED PREV ROWS
            if (prevElem && prevElem.short_description === short_description) return

            // HANDLE DOUBLE-VALUE ROWS
            if (nextElem && short_description === nextElem.short_description) {
              return (
                <div key={code} className="transaction-row">
                  <span>{short_description}</span>
                  <div>
                    <span>
                      <span className="code-name-custom">{code}</span>
                      {elemValue}
                      <img src={infoIcon} alt="info" data-tip={full_description} />
                    </span>
                    <span>
                      <span className="code-name-custom">{nextElem.code}</span>
                      {nextValue}
                      <img src={infoIcon} alt="info" data-tip={nextElem.full_description} />
                    </span>
                  </div>
                </div>
              )
            }

            // HANDLE REGULAR ROW
            return (
              <div
                key={code}
                className={`transaction-row ${middleSectionDash[0] === code.toLowerCase() ? "has-dash" : ""}`}
              >
                <span
                  style={{
                    paddingLeft,
                  }}
                >
                  {short_description}
                </span>
                <span>
                  <span className="code-name-custom">{code}</span>
                  {elemValue}
                  <img src={infoIcon} alt="info" data-tip={full_description} />
                </span>
              </div>
            )
          })
        : isNaN(shoppingValues.zakup_vat_marza) || shoppingValues.zakup_vat_marza === null
        ? "Brak transakcji"
        : ""}

      {!isNaN(shoppingValues.zakup_vat_marza) && shoppingValues.zakup_vat_marza !== null && (
        <div className="transaction-row custom has-dash">
          <span>Wartość zakupu (brutto) związana ze sprzedażą opodatkowaną na zasadach marży (art. 120 ustawy)</span>
          <div>
            <span />
            <span>
              {shoppingValues.zakup_vat_marza}
              <img
                src={infoIcon}
                alt="info"
                data-tip={
                  state.shoppingDictionary.find(({ code }) => code === "zakup_vat_marza")
                    ? state.shoppingDictionary.find(({ code }) => code === "zakup_vat_marza").full_description
                    : ""
                }
              />
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default ShoppingTransactions
