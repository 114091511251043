import React from "react"
import { Button } from "antd"

import { setWhiteListLastImportData } from "components/WhiteList/WhiteListMain/WhiteListTable.jsx"
import { setNipVerifierLastImportData } from "components/NipVerifier/NipVerifierMain/NipVerifierTable.jsx"

import { AppIds, DownloadFile, DownloadTextFileFromString } from "helpers/cloudTax"

import downloadIcon from "images/pobierz.svg"
import errorIcon from "images/blad_wysylki.svg"
import goToIcon from "images/podglad.svg"


const getStatusString = (status) => {
  if ([0, 1, 2].includes(status)) {
    return "oczekuje"
  }
  if ([3, 4].includes(status)) {
    return "błąd"
  }
  if (status === 5) {
    return "zakończona"
  }
  return "-"
}

const getDownloadButton = (key, action, state, dispatch) => {
  if (action === undefined) return
  return <Button key={key} className="jobActionButton" onClick={() => DownloadFile(action.Value, action.FileName, action.Source, state, dispatch)}
    data-tip="pobierz"
  >
    <img src={downloadIcon} alt="pobierz plik" />
  </Button>
}

const getErrorButton = (key, action) => {
  if (action === undefined) return
  return <Button key={key} className="jobActionButton" onClick={() => { DownloadTextFileFromString("errorLog.txt", action.Value) }}
    data-tip="błąd">
    <img src={errorIcon} alt="błąd" />
  </Button>
}

const goTo = (action, history, state, dispatch) => {
  switch (action.Source) {
    case AppIds.JPK_VAT:
      history.push('/ewidencja-vat?id=' + action.Value)
      break
    case AppIds.WHITE_LIST:
      setWhiteListLastImportData(state, dispatch, JSON.parse(action.Value))
      history.push('/biala-lista')
      break;
    case AppIds.NIP_VERIFICATION:
      setNipVerifierLastImportData(state, dispatch, JSON.parse(action.Value))
      history.push('/weryfikacja-nip')
      break;
    default:
      break;
  }
}

const getGoToButton = (key, action, history, state, dispatch) => {
  if (action === undefined) return
  return <Button key={key} className="jobActionButton" onClick={() => goTo(action, history, state, dispatch)}
    data-tip="zobacz">
    <img src={goToIcon} alt="zobacz" />
  </Button>
}

const getActions = (actionsStr, history, state, dispatch) => {
  if (actionsStr === null || actionsStr === 'null') return <span className="noResultJob">—</span>
  const actions = JSON.parse(actionsStr)

  const buttons = []
  let i = 0
  actions.forEach(action => {
    let key = (i++).toString()
    switch (action.Type) {
      case "file":
        buttons.push(getDownloadButton(key, action, state, dispatch))
        break;
      case "error":
        buttons.push(getErrorButton(key, action))
        break
      case "goto":
        buttons.push(getGoToButton(key, action, history, state, dispatch))
        break
      default:
        break;
    }
  });

  return buttons
}

export const columns = (history, state, dispatch) => [
  {
    expander: true,
    Expander: ({ isExpanded, ...row }) => {
      return null
    },
  },
  {
    Header: "Data",
    accessor: "creationDateTime",
    width: 120,
    Cell: ({ value }) => {
      return <span> {value ? value.substring(0, 10).replaceAll("-", ".") : "-"} </span>
    },
  },
  {
    Header: "Godzina",
    accessor: "creationTime",
    sortable: false,
    width: 90,
    Cell: ({ value }) => {
      return <span> {value ?? "-"} </span>
    },
  },
  {
    Header: "Aplikacja",
    width: 150,
    accessor: "applicationName"
  },
  {
    Header: "Operacja",
    width: 150,
    accessor: "operationName"
  },
  {
    Header: "Nazwa",
    accessor: "jobName"
  },
  {
    Header: "Użytkownik",
    width: 250,
    accessor: "userName"
  },
  {
    Header: "Status operacji",
    width: 100,
    accessor: "status",
    Cell: ({ value }) => {
      return <span>{getStatusString(value)}</span>
    },
  },
  {
    Header: "",
    width: 100,
    accessor: "result",
    sortable: false,
    Cell: ({ value }) => {
      return <span className="jobActionButtons">{getActions(value, history, state, dispatch)}</span>
    }
  }
]
