import React from "react"
import TaxNotification from "../DeclarationsTab/subcomponents/TaxNotification"
import FormModal from "../../common/FormModal"

//@ts-ignore
export const Modals = (props) => {
  const {
    infoState,
    message,
    handleCalc,
    setInfoState,
    setEditMode,
    confirmationExitModal,
    setConfirmationExitModal,
    confirmEditModal,
    setConfirmEditModal,
    setEditConfirmed,
    editConfirmed,
    declarationFormFixRef,
  } = props
  return (
    <>
      {infoState ? <TaxNotification message={message} handleCalc={handleCalc} setInfoState={setInfoState} /> : null}
      {/* HANDLE ESCAPE MODAL */}
      {/* @ts-ignore */}
      <FormModal
        visible={confirmationExitModal}
        content={"Czy na pewno chcesz wyjść bez zapisywania?"}
        onDecline={() => setConfirmationExitModal(false)}
        onAccept={() => {
          setEditMode(false)
          setConfirmationExitModal(false)
          // @ts-ignore
          declarationFormFixRef.current.resetFields()
        }}
        addClass="delModal"
        okButtonName={"Wyjdź"}
      />
      {/* HANDLE ESCAPE MODAL */}
      {/* CONFIRM EDIT MODAL */}
      {/* @ts-ignore */}
      <FormModal
        visible={confirmEditModal.p_37}
        content={"To pole zostało wyliczone automatycznie, czy na pewno chcesz edytować wartość zapisaną w tym polu?"}
        onDecline={() =>
          setConfirmEditModal({
            ...confirmEditModal,
            p_37: false,
          })
        }
        onAccept={() => {
          setEditConfirmed({
            ...editConfirmed,
            p_37: true,
          })

          setConfirmEditModal({ ...confirmEditModal, p_37: false })
        }}
        addClass="delModal"
        okButtonName={"Edytuj"}
      />
      {/* @ts-ignore */}
      <FormModal
        visible={confirmEditModal.p_38}
        content={"To pole zostało wyliczone automatycznie, czy na pewno chcesz edytować wartość zapisaną w tym polu?"}
        onDecline={() =>
          setConfirmEditModal({
            ...confirmEditModal,
            p_38: false,
          })
        }
        onAccept={() => {
          setEditConfirmed({
            ...editConfirmed,
            p_38: true,
          })

          setConfirmEditModal({ ...confirmEditModal, p_38: false })
        }}
        addClass="delModal"
        okButtonName={"Edytuj"}
      />
      {/* @ts-ignore */}
      <FormModal
        visible={confirmEditModal.p_48}
        content={"To pole zostało wyliczone automatycznie, czy na pewno chcesz edytować wartość zapisaną w tym polu?"}
        onDecline={() =>
          setConfirmEditModal({
            ...confirmEditModal,
            p_48: false,
          })
        }
        onAccept={() => {
          setEditConfirmed({
            ...editConfirmed,
            p_48: true,
          })

          setConfirmEditModal({ ...confirmEditModal, p_48: false })
        }}
        addClass="delModal"
        okButtonName={"Edytuj"}
      />
      {/* @ts-ignore */}
      <FormModal
        visible={confirmEditModal.p_51}
        content={"To pole zostało wyliczone automatycznie, czy na pewno chcesz edytować wartość zapisaną w tym polu?"}
        onDecline={() =>
          setConfirmEditModal({
            ...confirmEditModal,
            p_51: false,
          })
        }
        onAccept={() => {
          setEditConfirmed({
            ...editConfirmed,
            p_51: true,
          })

          setConfirmEditModal({ ...confirmEditModal, p_51: false })
        }}
        addClass="delModal"
        okButtonName={"Edytuj"}
      />
      {/* @ts-ignore */}
      <FormModal
        visible={confirmEditModal.p_53}
        content={"To pole zostało wyliczone automatycznie, czy na pewno chcesz edytować wartość zapisaną w tym polu?"}
        onDecline={() =>
          setConfirmEditModal({
            ...confirmEditModal,
            p_53: false,
          })
        }
        onAccept={() => {
          setEditConfirmed({
            ...editConfirmed,
            p_53: true,
          })

          setConfirmEditModal({ ...confirmEditModal, p_53: false })
        }}
        addClass="delModal"
        okButtonName={"Edytuj"}
      />
      {/* @ts-ignore */}
      <FormModal
        visible={confirmEditModal.p_62}
        content={"To pole zostało wyliczone automatycznie, czy na pewno chcesz edytować wartość zapisaną w tym polu?"}
        onDecline={() =>
          setConfirmEditModal({
            ...confirmEditModal,
            p_62: false,
          })
        }
        onAccept={() => {
          setEditConfirmed({
            ...editConfirmed,
            p_62: true,
          })

          setConfirmEditModal({ ...confirmEditModal, p_62: false })
        }}
        addClass="delModal"
        okButtonName={"Edytuj"}
      />
      {/* CONFIRM EDIT MODAL */}
    </>
  )
}
