import React, { useState, useEffect } from "react"
import { Form, Input } from "antd"
import FormButton from "../common/FormButton"

import Spinner from "../common/Spinner/Spinner"
import { Center } from "../common/Styled/helpers"

import API from "../common/api"

import FormModal from "../common/FormModal"

import "../Login/Login.less"

import LeftSide from "../common/LeftSide"

import { PasswordPattern, PasswordTip } from "helpers/password"

const queryString = require("query-string")

const ChangePasswordSinglePage = () => {
  const [loading, setLoading] = React.useState(false)
  const [modalVisible, setModalVisible] = React.useState(false)
  const [errorMessages, setErrorMessages] = React.useState([])
  const [qs, setQs] = useState()
  const [f, setF] = useState(false)

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const data = { password: values.password, code: qs.code, user: qs.user, redirect_url: qs.redirect_url }

      const response = await API.post("/Users/reminderPasswordCallback", data)

      const {
        data: {
          stateObject: { redirect_url },
        },
      } = response

      if (redirect_url) {
        return (window.location.href = redirect_url)
      }

      setLoading(false)
      setModalVisible(true)
    } catch (error) {
      // HANDLE EXPIRED LINK

      setLoading(false)
      console.error(error)
      setErrorMessages(error.response.data.errors)
    }
  }

  const handleCancelPass = () => {
    setModalVisible(false)
    // history.push("/panel-administratora")
  }

  useEffect(() => {
    const checkCode = async () => {
      try {
        const { user, code } = queryString.parse(window.location.search)
        const {
          data: { status },
        } = await API.post(`/Users/checkcode`, { code, user })

        if (status !== "200") {
          // return (window.location.href = "/link-expired")
        }

        setF(true)
      } catch (error) {
        // do zmiany na prawidłowy komponent
        // window.location.href = "/link-expired"
      }
    }

    checkCode()

    setQs(queryString.parse(window.location.search))
  }, [])

  // JEZELI CODE JEST NIE WAZNY TO OD RAZU WYSLAC USERA NA NIEAKTWYNY
  if (!f)
    return (
      <Center>
        <Spinner />
      </Center>
    )

  return (
    <div className="Login-container">
      <LeftSide />
      <div className="Login">
        {/* display error messages from backend, if any */}
        {errorMessages &&
          errorMessages.map((errorMessage) => (
            <p key={errorMessage} className="errorMessage">
              {errorMessage}
            </p>
          ))}

        <span className="header-section">Zmień hasło</span>
        <FormModal
          visible={modalVisible}
          content="Hasło zostało poprawnie zmienione."
          title={"Zmiana hasła"}
          onOk={handleCancelPass}
          onAccept={handleCancelPass}
        />

        <Form name="setPassword" initialValues={{ remember: true }} onFinish={onFinish}>
          <Form.Item name="password" validateTrigger="onBlur" hasFeedback rules={Rules.password}>
            <Input.Password placeholder="nowe hasło" type="password" />
          </Form.Item>

          <Form.Item
            name="confirm-password"
            dependencies={["password"]}
            validateTrigger="onBlur"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Potwierdź swoje hasło!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve()
                  }

                  return Promise.reject("Wprowadzone hasła nie są identyczne!")
                },
              }),
            ]}
          >
            <Input.Password placeholder="powtórz nowe hasło" />
          </Form.Item>

          <div className="login-button-container">
            <FormButton name="Zapisz" loading={loading} />
          </div>
        </Form>
      </div>
    </div>
  )
}

const Rules = {
  password: [
    {
      required: true,
      message: "Wprowadź swoje hasło!",
    },
    {
      pattern: PasswordPattern,
      message: PasswordTip
    },
  ],
}

export default ChangePasswordSinglePage
