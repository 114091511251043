import React from "react"
import NipVerifierDropDown from "./NipVerifierDropDown"
import ImportsDropDown from "./ImportsDropDown"
import { tracker } from "helpers/tracker"
import { VerificationStatusMfMap, VerificationStatusVIESMap } from "../VerificationStatusMap"

const mfSettings = [
  { id: "Active", label: "czynny", style: "wl-status-right", notSelectedStyle: "wl-status-not-selected" },
  { id: "Unregistered", label: "niezarejestrowany", style: "", notSelectedStyle: "wl-status-not-selected" },
  { id: "Discharged", label: "zwolniony", style: "", notSelectedStyle: "wl-status-not-selected" },
  { id: "Wrong", label: "błędny", style: "wl-status-wrong", notSelectedStyle: "wl-status-not-selected" },
  { id: "Error", label: "błąd weryfikacji", style: "wl-status-wrong", notSelectedStyle: "wl-status-not-selected" },
  { id: "Unexecuted", label: "niewykonana", style: "wl-status-error", notSelectedStyle: "wl-status-not-selected" },
]

const viesSettings = [
  { id: "Registered", label: "zarejestrowany", style: "wl-status-right", notSelectedStyle: "wl-status-not-selected" },
  { id: "Unregistered", label: "niezarejestrowany", style: "", notSelectedStyle: "wl-status-not-selected" },
  { id: "Wrong", label: "błędny", style: "wl-status-wrong", notSelectedStyle: "wl-status-not-selected" },
  { id: "Error", label: "błąd weryfikacji", style: "wl-status-wrong", notSelectedStyle: "wl-status-not-selected" },
  { id: "Unexecuted", label: "niewykonana", style: "wl-status-error", notSelectedStyle: "wl-status-not-selected" },
]

// id
// contractor_name
// country_code
// nip
// regon
// import_date
// data_source
// mf_status
// vies_status
// entity_verification
// verification_date
// mf_verification_request_id
// vies_verification_request_id
// entity_download_base
// entity_download_date
// entity_download_request_id
// pesel
// krs
// company_premises_address
// registration_date
// bank_account_no
// place_of_business

export const columns = [
  {
    showWithDetails: true,
    expander: true,
    Expander: ({ isExpanded, ...row }) => {
      return null
    },
  },
  {
    Header: "Nazwa kontrahenta",
    accessor: "entity_name",
    showWithDetails: true
  },
  {
    Header: "Kod kraju",
    accessor: "country_code",
    showWithDetails: true
  },
  {
    Header: "NIP",
    accessor: "nip",
    showWithDetails: true,
  },
  {
    Header: "Data importu",
    accessor: "import_date",
    showWithDetails: true,
    Cell: ({ value }) => {
      return <span> {value ? value.substring(0, 10) : "-"} </span>
    },
  },
  {
    accessor: "data_source",
    showWithDetails: false,

    Header: ({ value }) => {
      return <ImportsDropDown
        tableOptionsId="nipTableOptions"
        header="Źródło importu"
        lastImportsName="nipLastImports"
        lastImportsSerachName="wlLastImportsSearch"
      ></ImportsDropDown>
    },
  },
  {
    accessor: "mf_status",
    showWithDetails: false,
    
    Header: ({ value }) => {
      return <NipVerifierDropDown
        tableOptionsId="nipTableOptions"
        settings={mfSettings}
        filter = "MF"
      ></NipVerifierDropDown>
    },

    Cell: ({ value }) => {
      if (value === null) return <span>-</span>
      const n = VerificationStatusMfMap[value]
      if(n === undefined) return value
      return <span className={n.style}>{n.name}</span>
    },
  },
  {
    accessor: "vies_status",
    showWithDetails: false,
    Header: ({ value }) => {
      return <NipVerifierDropDown
        tableOptionsId="nipTableOptions"
        settings={viesSettings}
        filter = "VIES"
      ></NipVerifierDropDown>
    },

    Cell: ({ value }) => {
      if (value === null) return <span>-</span>
      const n = VerificationStatusVIESMap[value]
      if(n === undefined) return value
      return <span className={n.style}>{n.name}</span>
    },
  }
]

export const originalHandleClick = (_, handleOriginal) => {
  if (handleOriginal) {
    handleOriginal()
  }
}

export const inputStyles = {
  padding: "3px 15px",
  margin: "10px auto 10px 35px",
  WIDTH: "150PX",
  height: "40px",
  outline: "none",
  borderRadius: "5px",
  border: "1px solid #dedede",
}
