import React, { useContext, useState, useRef } from "react"

import { Input, Modal, Form, Select as AntSelect } from "antd"
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"

import Context from "../../context/index"
import API, { routes } from "../common/api"

import Select from "react-select"

import debounce from "lodash.debounce"
import CustomPagination from "components/CustomPagination/CustomPagination"

import newUser from "../../images/new-user.svg"
import usun from "../../images/usun.svg"
import edit from "../../images/edit.svg"

import selectArrow from "../../images/icons/select_arrow.png"

import FormModal from "components/common/FormModal"

import { StateContext, DispatchContext } from "components/userContext"
import { Loading } from "components/Main/Loading"

import { isUserActionPossible } from "./helpers/helpers"

const filedsToSearch = ["name", "surname", "email"]

const { Search } = Input
const { Option } = AntSelect

const handleSortedField = (value) => {
  if (!value.length) return ""

  const elem = value[0]
  const direction = elem.desc ? "" : "-"

  if (filedsToSearch.includes(elem.id)) {
    return `${direction}${elem.id}`
  } else {
    if (elem.id === "rok_i_miesiac") {
      return `${direction}rok,${direction}miesiac`
    }
  }
}

const flex = {
  display: "flex",
  margin: "35px 0",
}

const customStyles = {
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: "2px 15px",
    }
  },
  container: (provided) => {
    return {
      ...provided,
      width: "350px",
      margin: "0px 5px",
      outline: "none",
    }
  },
  control: (provided) => {
    return {
      ...provided,
      height: "45px",
      overflow: "hidden",
      borderColor: "initial !important",
      boxShadow: "none",
    }
  },
  menu: (provided) => {
    return {
      ...provided,
      zIndex: "999",
    }
  },
  option: (provided) => {
    return {
      ...provided,
      textOverflow: "ellipsis",
      display: "block",
      overflow: "hidden",
    }
  },
}

const rolesTable = ["Użytkownik", "Administrator"]
const allRoles = rolesTable.map((elem, y) => {
  return { id: y, value: elem, label: elem }
})

const columns = [
  {
    Header: "Nr Rekordu",
    accessor: "id",
    show: false,
  },
  {
    Header: "Imię",
    accessor: "name",
  },
  {
    Header: "Nazwisko",
    accessor: "surname",
  },
  {
    Header: "E-mail",
    accessor: "email",
  },
  {
    Header: "Rola",
    accessor: "roles",
    Cell: (row) => {
      return row.value
    },
  },
]

const originalHandleClick = (_, handleOriginal) => {
  if (handleOriginal) {
    handleOriginal()
  }
}

const UsersTable = () => {
  const { ctx } = useContext(Context)

  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [loading, setLoading] = useState(true)
  const [addNew, setAddNew] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)

  const [backendError, setBackendErorr] = useState()

  const addNewRef = useRef(null)
  const editRef = useRef(null)

  const debounceHanlderInput = debounce((value) => handleFillterAll(value), 800)

  const removeItem = (data) => {
    const tmp = [...selectedRows]
    const index = tmp.indexOf(data)

    if (index !== -1) {
      tmp.splice(index, 1)
      setSelectedRows(tmp)
    }
  }

  const addItem = (index) => {
    setSelectedRows([...selectedRows, index])
  }

  const handleFillterAll = (elem) => {
    let filtered = []

    if (!elem) {
      filtered = [...state.usersTableOptions.filtered.filter((e) => e.id !== "all")]
    } else {
      filtered = [...state.usersTableOptions.filtered.filter((e) => e.id !== "all"), elem && { id: "all", value: elem }]
    }

    dispatch({
      type: "handleState",
      field: "usersTableOptions",
      payload: { ...state.usersTableOptions, filtered },
    })
  }

  const setConfirmDelete = () => {
    API.post(`/Dashboard/deleteUserFormOrganization`, { ids: [...selectedRows], orgGuid: ctx.currentOrganization.guid })
      .then((response) => {
        if (response.status === 400) {
          setBackendErorr(response.data.errors[0])
          return response
        }

        return response
      })
      .then((response) => {
        if (response.status === 200) {
          setBackendErorr()
          const newUsers = state.usersTableData.filter(({ id }) => !selectedRows.includes(id))

          dispatch({ type: "handleState", field: "usersTableData", payload: newUsers })

          setDeleteModal(false)
          setSelectedRows([])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <div className="content-wrapper inner-shadow">
      {/* ERROR MODAL */}
      <FormModal
        visible={errorModal}
        content="Dla obecnego pakietu licencji zmiana danych możliwa z poziomu edycji danych profilu zalogowanego użytkownika. Sprawdź dostępne licencje CloudTax w celu dodania większej ilości użytkowników do organizacji."
        actionLink={ctx.currentOrganization.resellerLink}
        onDecline={() => {
          setErrorModal(false)
        }}
        addClass="delModal"
        okButtonName="Licencje"
      />
      {/* ERROR USER MODAL */}

      <FormModal
        visible={editModal}
        destroyOnClose
        afterClose={() => {
          setEditModal(false)
        }}
        header={<h3>Edytuj użytkownika</h3>}
        hasForm={
          <EditUserForm
            selecetdRow={selectedRows[0]}
            dispatch={dispatch}
            usersTableData={state.usersTableData}
            editRef={editRef}
            setEditModal={setEditModal}
          />
        }
        onAccept={() => editRef.current.submit()}
        onDecline={() => {
          Modal.destroyAll()
          setBackendErorr()
          setEditModal(false)
        }}
        addClass="exportModal researchModal addNewUserModal"
        okButtonName="Zapisz"
      />
      {/* EDIT USER MODAL */}

      {/* DELETE USERS MODAL */}
      <FormModal
        visible={deleteModal}
        content="Czy na pewno usunąć wskazanych użytkowników?"
        error={backendError}
        onAccept={setConfirmDelete}
        onDecline={() => {
          setBackendErorr()
          setDeleteModal(false)
        }}
        addClass="delModal"
        okButtonName="Usuń"
      />
      {/* DELETE USERS MODAL */}

      {/* ADD NEW USER MODAL */}
      <FormModal
        visible={addNew}
        destroyOnClose
        afterClose={() => {
          setAddNew(false)
        }}
        header={<h3>Dodaj użytkownika</h3>}
        hasForm={
          <AddNewUserForm
            dispatch={dispatch}
            usersTableData={state.usersTableData}
            addNewRef={addNewRef}
            setAddNew={setAddNew}
          />
        }
        onAccept={() => addNewRef.current.submit()}
        onDecline={() => {
          Modal.destroyAll()
          setAddNew(false)
        }}
        addClass="exportModal researchModal addNewUserModal"
        okButtonName="Dodaj"
      />
      {/* ADD NEW USER MODAL */}
      <div className="ClientPanel">
        <div className="header">
          <span>Użytkownicy</span>
          <div className="actions">
            <div className="fake-button">
              <label
                htmlFor="file-input"
                onClick={async () => {
                  if (await isUserActionPossible()) {
                    setAddNew(true)
                  } else {
                    setErrorModal(true)
                  }
                }}
              >
                <img src={newUser} alt="new user" />
                dodaj
              </label>
            </div>
            <div className={`fake-button ${selectedRows.length !== 1 ? "disabled" : ""}`}>
              <label
                htmlFor="file-input"
                onClick={async () => {
                  if (selectedRows.length !== 1) return

                  if (await isUserActionPossible()) {
                    setEditModal(true)
                  } else {
                    setErrorModal(true)
                  }
                }}
              >
                <img src={edit} alt="edit user" />
                edytuj
              </label>
            </div>
            <div className={`fake-button ${!selectedRows.length ? "disabled" : ""}`}>
              <label
                htmlFor="file-input"
                onClick={async () => {
                  if (!selectedRows.length) return

                  if (await isUserActionPossible()) {
                    setDeleteModal(true)
                  } else {
                    setErrorModal(true)
                  }
                }}
              >
                <img src={usun} alt="delete user" />
                usuń
              </label>
            </div>
          </div>
        </div>
        <div style={flex} className="content">
          <Search
            className="ant-input-affix-wrapper broken-search"
            placeholder={null}
            onChange={({ target: { value } }) => debounceHanlderInput(value)}
            style={{ width: 169, height: 45, marginRight: 30 }}
          />

          <Select
            placeholder="Wybierz role"
            className="cutom-select"
            onChange={(role) => {
              let filtered = []

              if (!role) {
                filtered = [...state.usersTableOptions.filtered.filter((e) => e.id !== "roles")]
              } else {
                filtered = [
                  ...state.usersTableOptions.filtered.filter((e) => e.id !== "roles"),
                  { id: "roles", value: role.map((r) => r.value).join("|") },
                ]
              }

              dispatch({
                type: "handleState",
                field: "usersTableOptions",
                payload: { ...state.usersTableOptions, filtered },
              })
            }}
            isMulti
            options={allRoles}
            styles={customStyles}
          />
        </div>

        <ReactTable
          data={state.usersTableData}
          columns={columns}
          className="clean"
          resizable={false}
          loading={loading}
          noDataText={""}
          NoDataComponent={() => null}
          LoadingComponent={(props) => {
            if (props.loading) {
              return <Loading absolute />
            }
            return <></>
          }}
          PaginationComponent={CustomPagination}
          style={{
            maxHeight: "calc(100% - 200px)",
          }}
          manual
          pages={state.usersTableOptions.totalPagesNumber}
          defaultPageSize={10}
          filtered={state.usersTableOptions.filtered}
          onFetchData={(innerState) => {
            const [allValue] = innerState.filtered.filter((e) => e.id === "all")

            const filterValue = allValue ? allValue.value : null
            const filteredValue = !allValue
              ? ""
              : `(${filedsToSearch.reduce((acc, v) => `${acc}${acc.length ? "|" : ""}${v}`, "")})` + `@=*${filterValue}`

            const [rolesValue] = innerState.filtered.filter((e) => e.id === "roles")
            const filterValueRoles = rolesValue ? rolesValue.value : null
            const filteredValueRoles = !rolesValue ? "" : `,roles@=${filterValueRoles}`

            const sortedValue = handleSortedField(innerState.sorted)

            API.get(
              `/Dashboard/getUsersByOrganization?CurrentOrganizationGuid=${
                ctx.currentOrganization.guid
              }&sorts=${sortedValue}&page=${innerState.page + 1}&pageSize=${
                innerState.pageSize
              }&filters=${filteredValue}${filteredValueRoles ? filteredValueRoles : ""}`
            )
              .then(
                ({
                  data: {
                    stateObject: { results: stateObject, ...tableParams },
                  },
                }) => {
                  setLoading(false)

                  dispatch({ type: "handleState", field: "usersTableData", payload: stateObject })
                  dispatch({
                    type: "handleState",
                    field: "usersTableOptions",
                    payload: {
                      ...state.usersTableOptions,
                      ...tableParams,
                      currentPage: innerState.page + 1,
                      pageSize: innerState.pageSize,
                    },
                  })
                }
              )
              .catch((error) => {
                console.error(error)
                setLoading(false)
              })
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: ({ shiftKey }) => {
                // Expand row on expand cell click
                if (typeof rowInfo !== "undefined") {
                  // add row selection on row click, except click on expand arrow
                  if (column.id && selectedRows.includes(rowInfo.original.id) && column.id !== "buttons") {
                    removeItem(rowInfo.original.id)
                  } else {
                    if (shiftKey && column.id !== "buttons") {
                      addItem(rowInfo.original.id)
                    } else if (column.id && column.id !== "buttons") {
                      setSelectedRows([rowInfo.original.id])
                    }
                  }
                }
              },
            }
          }}
          getTrProps={(_, rowInfo) => {
            if (typeof rowInfo !== "undefined") {
              return {
                style: {
                  fontWeight: selectedRows.includes(rowInfo.original.id) && "bold",
                  background: selectedRows.includes(rowInfo.original.id) && "#f4f4f4",
                },
              }
            } else {
              return {
                onClick: originalHandleClick,
              }
            }
          }}
        />
      </div>
    </div>
  )
}

const EditUserForm = ({ editRef, setEditModal, dispatch, usersTableData, selecetdRow }) => {
  const { ctx } = useContext(Context)

  const currentUser = usersTableData.find(({ id }) => id === selecetdRow)

  const initialValues = {
    ...currentUser,
    // roles: currentUser.roles === "Użytkownik" ? "OrganizationUser" : "OrganizationAdmin",
  }

  return (
    <Form
      ref={editRef}
      layout={{
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      }}
      initialValues={initialValues}
      className="add-new-user-form"
      name="addNewUser"
      requiredMark={false}
      onFinish={async (values) => {
        try {
          const response = await API.post(`Dashboard/editUserFormOrganization`, {
            ...values,
            orgGuid: ctx.currentOrganization.guid,
          })

          if (response.status === 200) {
            const newUsers = [...usersTableData.filter(({ id }) => id !== selecetdRow)]
            newUsers.push(...response.data.stateObject)

            dispatch({
              type: "handleState",
              field: "usersTableData",
              payload: newUsers,
            })

            setEditModal(false)
          }

          if (response.status === 400) {
            if (editRef && editRef.current) {
              editRef.current.setFields([
                {
                  name: "email",
                  errors: [response.data.errors],
                },
              ])
            }
          }
        } catch (error) {
          console.error(error)
          setEditModal(false)
        }
      }}
    >
      <Form.Item
        key="name"
        label="Imię"
        name="name"
        hasFeedback
        validateTrigger="onBlur"
        labelAlign="left"
        rules={[
          {
            required: true,
            message: "Podaj imię!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key="surname"
        label="Nazwisko"
        name="surname"
        hasFeedback
        validateTrigger="onBlur"
        labelAlign="left"
        rules={[
          {
            required: true,
            message: "Podaj nazwisko!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key="email"
        label="E-mail"
        name="email"
        hasFeedback
        validateTrigger="onBlur"
        labelAlign="left"
        rules={[
          {
            required: true,
            message: "Podaj e-mail!",
          },
          {
            type: "email",
            message: "Wprowadzony e-mail jest niepoprawny!",
          },
          () => ({
            validator(_, email) {
              // TODO: fetch backend data here
              return Promise.resolve()
              // return Promise.reject("Użytkownik z podanym adresem email istnieje już w systemie!")
            },
          }),
        ]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        key="roles"
        label="Rola"
        name="roles"
        hasFeedback
        validateTrigger="onBlur"
        labelAlign="left"
        rules={[
          {
            required: true,
            message: "Wybierz role!",
          },
        ]}
      >
        <AntSelect
          placeholder="wybierz rolę"
          dropdownStyle={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #DDDDDD",
            borderRadius: "5px",
            padding: "15px 10px",
          }}
          name="roles"
          suffixIcon={<img src={selectArrow} />}
        >
          <Option key="1" value="Użytkownik">
            Użytkownik
          </Option>
          <Option key="2" value="Administrator">
            Administrator
          </Option>
        </AntSelect>
      </Form.Item>
    </Form>
  )
}

const AddNewUserForm = ({ addNewRef, setAddNew, dispatch, usersTableData }) => {
  const { ctx } = useContext(Context)

  return (
    <Form
      ref={addNewRef}
      layout={{
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      }}
      className="add-new-user-form"
      name="addNewUser"
      requiredMark={false}
      onFinish={async (values) => {
        try {
          const response = await API.post(`/Dashboard/addUserToOrganization`, {
            ...values,
            orgGuid: ctx.currentOrganization.guid,
          })

          if (response.status === 200) {
            dispatch({
              type: "handleState",
              field: "usersTableData",
              payload: [...usersTableData, ...response.data.stateObject],
            })

            setAddNew(false)
            // USER ADDED, EMAIL SENT CONFIRMATION
            API.post(`${routes.signalApi}api/Notyfication/SentToUser`, {
              message:
                "Na podany adres e-mail zostały wysłane informacje niezbędne do zakończenia dodawania użytkownika.",
              notyficationType: 1,
              isSuccess: true,
            })
          }

          if (response.status === 400) {
            if (addNewRef && addNewRef.current) {
              addNewRef.current.setFields([
                {
                  name: "email",
                  errors: [response.data.errors],
                },
              ])
            }
          }
        } catch (error) {
          console.error(error)
          setAddNew(false)
        }
      }}
    >
      <Form.Item
        key="name"
        label="Imię"
        name="name"
        hasFeedback
        validateTrigger="onBlur"
        labelAlign="left"
        rules={[
          {
            required: true,
            message: "Podaj imię!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key="surname"
        label="Nazwisko"
        name="surname"
        hasFeedback
        validateTrigger="onBlur"
        labelAlign="left"
        rules={[
          {
            required: true,
            message: "Podaj nazwisko!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key="email"
        label="E-mail"
        name="email"
        hasFeedback
        validateTrigger="onBlur"
        labelAlign="left"
        rules={[
          {
            required: true,
            message: "Podaj e-mail!",
          },
          {
            type: "email",
            message: "Wprowadzony e-mail jest niepoprawny!",
          },
          () => ({
            validator(_, email) {
              // TODO: fetch backend data here
              return Promise.resolve()
              // return Promise.reject("Użytkownik z podanym adresem email istnieje już w systemie!")
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key="roles"
        label="Rola"
        name="roles"
        hasFeedback
        validateTrigger="onBlur"
        labelAlign="left"
        rules={[
          {
            required: true,
            message: "Wybierz role!",
          },
        ]}
      >
        <AntSelect
          placeholder="wybierz rolę"
          dropdownStyle={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #DDDDDD",
            borderRadius: "5px",
            padding: "15px 10px",
          }}
          name="roles"
          suffixIcon={<img src={selectArrow} />}
        >
          <Option key="1" value="Użytkownik">
            Użytkownik
          </Option>
          <Option key="2" value="Administrator">
            Administrator
          </Option>
        </AntSelect>
      </Form.Item>
    </Form>
  )
}

export default UsersTable
