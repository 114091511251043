// podatek należny
// tax Due
const taxDueArray = [
  [
    {
      name: "p_10",
    },
  ],
  [
    {
      name: "p_11",
    },
  ],
  [
    {
      name: "p_12",
    },
  ],
  [
    {
      name: "p_13",
    },
  ],
  [
    {
      name: "p_14",
    },
  ],
  [
    {
      name: "p_15",
    },
    {
      name: "p_16",
    },
  ],
  [
    {
      name: "p_17",
    },
    {
      name: "p_18",
    },
  ],
  [
    {
      name: "p_19",
    },
    {
      name: "p_20",
    },
  ],
  [
    {
      name: "p_21",
    },
  ],
  [
    {
      name: "p_22",
    },
  ],
  [
    {
      name: "p_23",
    },
    {
      name: "p_24",
    },
  ],
  [
    {
      name: "p_25",
    },
    {
      name: "p_26",
    },
  ],
  [
    {
      name: "p_27",
    },
    {
      name: "p_28",
    },
  ],
  [
    {
      name: "p_29",
    },
    {
      name: "p_30",
    },
  ],
  [
    {
      name: "p_31",
    },
    {
      name: "p_32",
    },
  ],
  [
    { name: null },
    {
      name: "p_33",
    },
  ],
  [
    { name: null },
    {
      name: "p_34",
    },
  ],
  [
    { name: null },
    {
      name: "p_35",
    },
  ],
  [
    { name: null },
    {
      name: "p_36",
    },
  ],
  [
    {
      name: "p_37",
    },
    {
      name: "p_38",
    },
  ],
]

const leftDueSum = [
  "p_10",
  "p_11",
  "p_13",
  "p_15",
  "p_17",
  "p_19",
  "p_21",
  "p_22",
  "p_23",
  "p_25",
  "p_27",
  "p_29",
  "p_31",
]

const rightDueSum = ["p_16", "p_18", "p_20", "p_24", "p_26", "p_28", "p_30", "p_32", "p_33", "p_34", "p_35", "p_36"]

// podatek nalilczony
const taxInputArray = [
  [
    {
      name: "p_40",
    },
    {
      name: "p_41",
    },
  ],
  [
    {
      name: "p_42",
    },
    {
      name: "p_43",
    },
  ],
  [
    { name: null },
    {
      name: "p_44",
    },
  ],
  [
    { name: null },
    {
      name: "p_45",
    },
  ],
  [
    { name: null },
    {
      name: "p_46",
    },
  ],
  [
    { name: null },
    {
      name: "p_47",
    },
  ],
  [
    { name: null },
    {
      name: "p_48",
    },
  ],
]

const inputTaxSum = ["p_39", "p_41", "p_43", "p_44", "p_45", "p_46", "p_47"]
const calcTaxSum = ["p_49", "p_50", "p_52"]

const allCheckboxes = ["p_63", "p_64", "p_65", "p_66", "p_67", "pouczenie", "p_59"]

const allRadios = ["p_55", "p_56", "p_57", "p_58"]

const taxTabValid = ["p_40", "p_42", "p_41", "p_43", "p_44", "p_45", "p_46", "p_47"]

const calcTabValid = ["p_39", "p_49", "p_50", "p_52", "p_54", "p_58", "p_60", "p_61", "p_62"]

const addTabValid = ["p_68", "p_69", "p_ordzu"]

// wyliczenie zobowiązania
const taxCalculationArray = [
  {
    name: "p_39",
    elementConfig: {
      type: "input",
      kind: "number",
      validRule: "greaterThanZero",
      isValid: true,
    },
  },
  {
    name: "p_49",
    elementConfig: {
      type: "input",
      kind: "number",
      validRule: "p_49_valid",
      isValid: true,
    },
  },
  {
    name: "p_50",
    elementConfig: {
      type: "input",
      kind: "number",
      validRule: "p_50_valid",
      isValid: true,
    },
  },
  {
    name: "p_51",
    elementConfig: {
      type: "input",
      kind: "number",
      isValid: true,
    },
  },
  {
    name: "p_52",
    elementConfig: {
      type: "input",
      kind: "number",
      validRule: "p_52_valid",
      isValid: true,
    },
  },
  {
    name: "p_53",
    elementConfig: {
      type: "input",
      kind: "number",
      isValid: true,
    },
  },
  {
    name: "p_54",
    elementConfig: {
      type: "input",
      kind: "number",
      validRule: "p_54_valid",
      isValid: true,
    },
  },
  {
    name: "p_55",
    elementConfig: {
      type: "radio",
      id: "p_55",
      isValid: true,
    },
  },
  {
    name: "p_56",
    elementConfig: {
      type: "radio",
      id: "p_56",
      isValid: true,
    },
  },
  {
    name: "p_57",
    elementConfig: {
      type: "radio",
      id: "p_57",
      isValid: true,
    },
  },
  {
    name: "p_58",
    elementConfig: {
      type: "radio",
      id: "p_58",
      isValid: true,
    },
  },
  {
    name: "p_59",
    elementConfig: {
      type: "checkbox",
      isValid: true,
    },
  },
  {
    name: "p_60",
    elementConfig: {
      type: "input",
      kind: "number",
      validRule: "p_60_valid",
      isValid: true,
    },
  },
  {
    name: "p_61",
    elementConfig: {
      type: "textarea",
      validRule: "textareas_valid",
      isValid: true,
    },
  },
  {
    name: "p_62",
    elementConfig: {
      type: "input",
      kind: "number",
      isValid: true,
    },
  },
]

// informacje dodatkowe
const taxAdditionalArray = [
  {
    name: "p_63",
    elementConfig: {
      type: "checkbox",
    },
  },
  {
    name: "p_64",
    elementConfig: {
      type: "checkbox",
    },
  },
  {
    name: "p_65",
    elementConfig: {
      type: "checkbox",
    },
  },
  {
    name: "p_66",
    elementConfig: {
      type: "checkbox",
    },
  },
  {
    name: "p_67",
    elementConfig: {
      type: "checkbox",
    },
  },
  {
    name: "p_68",
    elementConfig: {
      type: "input",
      kind: "number",
      validRule: "p_68_valid",
    },
  },
  {
    name: "p_69",
    elementConfig: {
      type: "input",
      kind: "number",
      validRule: "p_69_valid",
    },
  },
  {
    name: "p_ORDZU",
    elementConfig: {
      type: "textarea",
      validRule: "textareas_valid",
      isValid: true,
    },
  },
  {
    name: "pouczenie",
    elementConfig: {
      type: "checkbox",
      isValid: true,
    },
  },
]

const declarationMessage = {
  obliczonaInfo:
    "Deklaracja nie została jeszcze obliczona dla tej ewidencji. Wybierz “Oblicz deklarację” w celu obliczenia.",
  obliczonaAction:
    "Deklaracja nie została jeszcze obliczona dla tej ewidencji. Kliknij ten komunikat lub wybierz “Oblicz deklarację” w celu obliczenia.",
  edytowanaInfo:
    "Od ostatniego obliczenia deklaracji wprowadzono zmiany na liście dokumentów. Wybierz “Oblicz deklarację",
  edytowanaAction:
    "Od ostatniego obliczenia deklaracji wprowadzono zmiany na liście dokumentów. Kliknij ten komunikat lub wybierz “Oblicz deklarację” w celu aktualizacji kwot na deklaracji. Wykonanie obliczenia spowoduje nadpisanie wartości w polach P_10-P-38, P_40-P_48, P_51, P_53, P_68 oraz P_69.",
}

const firstTab = [...leftDueSum, rightDueSum, "p_37", "p_38"]
const secondTab = ["p_40", "p_41", "p_42", "p_43", "p_44", "p_45", "p_46", "p_47", "p_48"]
const thirdTab = [
  "p_39",
  "p_49",
  "p_50",
  "p_51",
  "p_52",
  "p_53",
  "p_54",
  "radio_buttons",
  "p_59",
  "p_60",
  "p_61",
  "p_62",
]
const fourthTab = ["p_63", "p_64", "p_65", "p_66", "p_67", "p_68", "p_69", "pouczenie", "p_ORDZU"]
const checkboxFields = ["p_59", "p_63", "p_64", "p_65", "p_66", "p_67", "pouczenie"]
export {
  allRadios,
  leftDueSum,
  calcTaxSum,
  rightDueSum,
  taxDueArray,
  inputTaxSum,
  taxTabValid,
  addTabValid,
  calcTabValid,
  allCheckboxes,
  taxInputArray,
  taxCalculationArray,
  taxAdditionalArray,
  declarationMessage,
  firstTab,
  secondTab,
  thirdTab,
  fourthTab,
  checkboxFields,
}
