import React, { useState, useEffect, useCallback, useContext, useRef } from "react"

import ReactTooltip from "react-tooltip"
import FormModal from "components/common/FormModal"

import { history } from "App"

import { Tabs } from "antd"

import Button from "components/common/Button"

import VatDetailsNavbar from "./DetailsTab/VatDetailsNavbar"
import VatDetailsEditIcons from "./DetailsTab/VatDetailsEditIcons"
import VatDetailsForm from "./DetailsTab/VatDetailsForm"

import EditSells from "./SalesTab/EditSells"
import EditShopping from "./ShoppingTab/EditShopping"
import SalesEditNav from "./SalesTab/SalesEditNav"

import SalesTab from "./SalesTab/SalesTab"
import ShoppingTab from "./ShoppingTab/ShoppingTab"
import DeclarationsTabFix from "./DeclarationsTabFix/DeclarationsTabFix"

import API, { nipVerificationApiURL } from "components/common/api"

import AddNewDocument from "./AddNewDocument/AddNewDocument"
import AddNewDocumentPurchase from "./AddNewDocumentPurchase/AddNewDocumentPurchase"

import { DispatchContext, StateContext } from "components/userContext"
import { declarationMessage } from "./DeclarationsTab/dataBlueprint"
import Context from "context"
import { blockNumberScrool } from "./DeclarationsTab/helpers"
import { Loading } from "components/Main/Loading"

import { tracker } from "helpers/tracker"
import warningIcon from "images/uwaga.svg"

import "./VatDetails.less"
const queryString = require("query-string")

const updatedFormFields = (year, month, subject) => {
  return [
    {
      name: "rok",
      value: year,
    },
    {
      name: "miesiac",
      value: month,
    },
    {
      name: "podmiot",
      value: subject,
    },
  ]
}

const tabNames = ["Szczegóły", "Sprzedaż", "Zakupy", "Deklaracja", "Deklaracja II"]

const returnFirmName = (nip, name) => nip + " - " + name

const VatDetails = ({ setDetailsBack, handleExport, handleCalc, handleResearch, handleVatUE, verifyNip, handleVerify }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const { ctx } = useContext(Context)
  const [loading, setLoading] = useState(true)

  const [editMode, setEditMode] = useState(false)
  const [recordData, setRecordData] = useState({})
  const [fields, setFields] = useState([])
  const [subjects, setSubjects] = useState([])
  const [initialSubject, setInitialSubject] = useState("")

  const [localElem, setLocalElem] = useState(state.salesElem || {})
  const [blockSave, setBlockSave] = useState(false)
  const [deleteSalesModal, setDeleteSalesModal] = useState(false)

  const [messageInfo, setMessageInfo] = useState({ message: "" })

  const [declaractionCount, setDeclarationCount] = useState(0)

  const sellEditForm = useRef(null)
  const shoppingEditForm = useRef(null)

  const [detailsVisible, setDetailsVisible] = useState(false)
  const [detailsShopVisible, setDetailsShopVisible] = useState(false)
  const addForm = useRef(null)
  const addFormPurchase = useRef(null)

  const detailsEditForm = useRef(null)

  const [errorModal, showErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const [{ id }] = useState(queryString.parse(window.location.search))
  const { TabPane } = Tabs

  const [nipVerificationSummary, setNipVerificationSummary] = useState({})
  const [blockVerificationDetails, setBlockVerificationDetails] = useState(false)

  const {
    liczba_wierszy_sprzedazy,
    liczba_wierszy_zakupow,
    miesiac,
    pelna_nazwa,
    podatek_nalezny,
    podatek_naliczony,
    rok,
    skorygowana,
    zablokowana,
    cel_zlozenia,
    wariant_formularza
  } = recordData

  const {
    unexecuted_mf,
    active_mf,
    unregistered_mf,
    discharged_mf,
    wrong_mf,
    error_mf,
    unexecuted_vies,
    registered_vies,
    unregistered_vies,
    wrong_vies,
    error_vies,
    unrecognized,
    last_user_import,
    last_user_import_ds,
  } = nipVerificationSummary

  const detailsTitle = pelna_nazwa + " - " + rok + "/" + miesiac
  const blockDetailsEdit = zablokowana || skorygowana || cel_zlozenia === 2
  const blockDeclarationEdit = zablokowana || skorygowana

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  useEffect(() => {
    fetchData()
    fetchSubjects()
    blockNumberScrool()
    fetchNipVerificationSummary()

    return () => {
      dispatch({ type: "handleSalesCleanUp" })
    }
  }, [])

  useEffect(() => {
    if (!state.salesElem) {
      setLocalElem(null)
      return
    }

    const fixedElem = {}

    Object.keys(state.salesElem).map((field) => {
      if (field === "data_wystawienia") {
        if (!state.salesElem[field]) {
          return null
        }

        const [YY, MM] = state.salesElem[field].split("-")
        return (fixedElem[field] = `${YY}/${MM}`)
      }

      fixedElem[field] = state.salesElem[field]
    })

    setLocalElem(fixedElem)
  }, [state.salesElem])

  useEffect(() => {
    if (!state.shoppingElem) {
      setLocalElem(null)
      return
    }

    const fixedElem = {}
    Object.keys(state.shoppingElem).map((field) => {
      if (field === "data_wplywu") {
        if (!state.shoppingElem[field]) {
          return null
        }

        const [YY, MM] = state.shoppingElem[field].split("-")
        return (fixedElem[field] = `${YY}/${MM}`)
      }

      fixedElem[field] = state.shoppingElem[field]
    })

    setLocalElem(fixedElem)
  }, [state.shoppingElem])

  useEffect(() => {
    if (state.refreshDetails) {
      fetchData()
      fetchSubjects()
      fetchNipVerificationSummary()
    }
    return () => {
      dispatch({ type: "handleDeclarationCleanUp" })
    }
  }, [state.refreshDetails])

  // Get record data
  const fetchData = async () => {
    try {
      const resp = await API.get(`VatGenerator/getVatRecord?id=${id}`)

      const firmField = returnFirmName(resp.data.stateObject.nip, resp.data.stateObject.pelna_nazwa)

      setInitialSubject(firmField)
      setFields(updatedFormFields(resp.data.stateObject.rok, resp.data.stateObject.miesiac, firmField))
      const currentElem = resp.data.stateObject

      setRecordData(currentElem)

      const obliczona = currentElem.obliczona
      const edytowana = currentElem.edytowana

      if ((!obliczona && !edytowana) || (obliczona && !edytowana)) {
        setMessageInfo({ message: "" })
        return
      }
      if (!obliczona && edytowana) {
        setMessageInfo({ message: "obliczona" })
        return
      }
      if (obliczona && edytowana) {
        setMessageInfo({ message: "edytowana" })
        return
      }
    } catch (error) {
      console.error(error)
      // setLoading(false)
    }
  }

  // Get User Subjects
  const fetchSubjects = async () => {
    try {
      const {
        data: { stateObject },
      } = await API.get(`/CustomerPanel/getRelatedPartiesForVatRecord`, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid"),
        },
      })

      setSubjects(stateObject)
      setDeclarationCount((declaractionCount) => declaractionCount + 1)

      setTimeout(() => setLoading(false), 400)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  // Get User Subjects
  const fetchNipVerificationSummary = async () => {
    let url = `${nipVerificationApiURL}/NipVerification/VatRecordSalesPurchasesStatuses?vatRecordId=${id}`

    try {
      const {
        data
      } = await API.get(url, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid"),
        },
      })
      const nipVerificationSummary = data.stateObject
      setNipVerificationSummary(nipVerificationSummary)

      if (nipVerificationSummary.last_user_import == null)
        setBlockVerificationDetails(true)
      else
        setBlockVerificationDetails(false)

      setTimeout(() => setLoading(false), 400)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  // Save Record Data
  const saveData = async (data) => {
    setLoading(true)
    try {
      const response = await API.post("/VatGenerator/updateVatRecord", data)
      setTimeout(() => setLoading(false), 400)

      if (response.status === 200) {
        fetchData()
        exitEditMode()
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
      exitEditMode()
    }
  }

  const saveEditMode = useCallback(async () => {
    // setLoading(true)

    const touched = fields.map((item) => item.hasOwnProperty("touched")).some((item) => item === true)

    if (!touched) {
      exitEditMode()
      setLoading(false)
      return
    }

    const dataToSend = { ...recordData }

    dataToSend.rok = fields[0].value
    dataToSend.miesiac = fields[1].value

    let subjectSplit

    // if subject was not changed (from select)
    if (typeof fields[2].value !== "number") {
      subjectSplit = fields[2].value.split(" - ")

      dataToSend.nip = subjectSplit[0]
      dataToSend.pelna_nazwa = subjectSplit[1]

      // if subject was changed
    } else {
      subjectSplit = subjects.find((el) => el.id === fields[2].value)

      dataToSend.nip = subjectSplit.nip
      dataToSend.pelna_nazwa = subjectSplit.fullName
      dataToSend.related_party_id = fields[2].value
    }

    try {
      const {
        data: { stateObject },
      } = await API.get(
        `/CustomerPanel/GetRelatedParty?id=${dataToSend.related_party_id}&organizationGuid=${localStorage.getItem(
          "currentGuid"
        )}`
      )

      // setLoading(false)
      dataToSend.typ_podmiotu = stateObject.related_party_type
      saveData(dataToSend)
      // setTimeout(() => setLoading(false), 400)
    } catch (error) {
      // setLoading(false)
      console.error(error)
    }
  })

  const exitDetails = () => {
    setDetailsBack(true)
    history.push("/ewidencja-vat")
  }

  const handleSend = () => {
    history.push(`/bramka?id=${id}`)
  }

  const exitEditSales = () => {
    dispatch({ type: "handleState", field: "salesEdit", payload: false })
  }

  const exitEditMode = () => {
    setFields(updatedFormFields(rok, miesiac, initialSubject))
    setBlockSave(false)
    setEditMode(false)
  }

  const setNipVerifierLastImportData = (state, dispatch, userImport, userImportDs) => {
    const filtered = [...state.nipTableOptions.filtered]
    let filteredImportIds = {}
    let filteredVerificationState = { ...state.nipTableOptions.filteredVerificationState }
    Object.keys(filteredVerificationState).forEach(key => {
      filteredVerificationState[key] = { ...filteredVerificationState[key], selected: false }
    });
    const fixedDate = userImport.substring(0, 27)
    filteredImportIds[fixedDate] = userImportDs

    dispatch({
      type: "handleState",
      field: "nipTableOptions",
      payload: { ...state.nipTableOptions, filtered, filteredVerificationState, filteredImportIds },
    })
  }

  const detailsVerifyNip = () => {
    setNipVerifierLastImportData(state, dispatch, last_user_import, last_user_import_ds)
    history.push('/weryfikacja-nip')
  }

  if (!Object.keys(recordData).length) return <></>

  let declarationTooltip = null

  if (messageInfo.message === "edytowana") {
    declarationTooltip = declarationMessage.edytowanaInfo
  }
  if (messageInfo.message === "obliczona") {
    declarationTooltip = declarationMessage.obliczonaInfo
  }

  if (loading) return <Loading />

  return (
    <>
      {/* ERROR LIMIT MODAL */}
      <FormModal
        visible={errorModal}
        content={errorMessage}
        onAccept={() => {
          window.open(ctx.currentOrganization.resellerLink, "_blank")
        }}
        onDecline={() => {
          showErrorModal(false)
          setErrorMessage()
        }}
        addClass="delModal demoModal"
        okButtonName="Licencje i pakiety"
      />
      {/* ERROR LIMIT MODAL */}

      <ReactTooltip
        effect="solid"
        className="tooltip-vat"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />
      <div className="VatDetails">
        <FormModal
          visible={deleteSalesModal}
          content={
            state.selectedRowsSale.length === 1
              ? `Czy na pewno chcesz usunąć wybrany dokument ${state.lastActiveVatTab == 2 ? "sprzedaży" : "zakupu"}?`
              : `Czy na pewno chcesz usunąć wybrane dokumenty ${state.lastActiveVatTab == 2 ? "sprzedaży" : "zakupu"}?`
          }
          onAccept={async () => {
            const salesTab = state.lastActiveVatTab == 2
            const route = salesTab
              ? "/VatRecordSale/deleteVatRecordSales"
              : "/VatRecordPurchase/deleteVatRecordPurchases"
            const ids = salesTab ? state.selectedRowsSale : state.selectedRowsShopping
            const field = salesTab ? "detailsTableData" : "detailsTabledataShopping"

            await API.post(route, { ids })

            dispatch({
              type: "handleState",
              field,
              payload: state[field].filter(({ id }) => !ids.includes(id)),
            })

            dispatch({
              type: "handleState",
              field: "selectedRowsSale",
              payload: [],
            })
            dispatch({
              type: "handleState",
              field: "selectedRowsShopping",
              payload: [],
            })
            dispatch({
              type: "handleState",
              payload: true,
              field: "refreshDetails",
            })

            setDeleteSalesModal(false)
            setDetailsVisible(false)
            setDetailsShopVisible(false)
          }}
          onDecline={() => setDeleteSalesModal(false)}
          addClass="delModal"
          okButtonName="Usuń"
        />

        {state.salesEdit || state.shoppingEdit ? (
          <>
            <FormModal
              visible={state.handleCloseEditSections}
              content="Czy na pewno chcesz wyjść bez zapisywania?"
              onAccept={() =>
                dispatch({
                  type: "handleCancelEditSalesConfirm",
                })
              }
              onDecline={() =>
                dispatch({
                  type: "handleState",
                  field: "handleCloseEditSections",
                  payload: false,
                })
              }
              addClass="delModal"
              okButtonName="Wyjdź"
            />

            <SalesEditNav
              getTitle={() => {
                if (state.shoppingEdit) {
                  return `${detailsTitle} - ${localElem.nazwa_dostawcy} - ${localElem.dowod_zakupu}`
                }

                return `${detailsTitle} - ${localElem.nazwa_kontrahenta} - ${localElem.dowod_sprzedazy}`
              }}
              exit={exitEditSales}
            />
          </>
        ) : !state.showAddNewDocument && !state.showAddNewDocumentPurchase ? (
          <VatDetailsNavbar
            title={detailsTitle}
            id={id}
            handleSend={handleSend}
            exit={exitDetails}
            handleExport={handleExport}
            handleCalc={handleCalc}
            activeTab={state.lastActiveVatTab}
            handleResearch={handleResearch}
            handleVatUE={handleVatUE}
            verifyNip={verifyNip}
            handleVerify={handleVerify}
            handleDelete={() => {
              const activeTab = state.lastActiveVatTab == "2" ? "Sprzedaż" : "Zakupy"
              tracker(` Sprawdź_JPK - Szczegóły ewidencji - Zakładka ${activeTab} - Usuń dokument`)

              setDeleteSalesModal(true)
            }}
            isDeleteable={() => {
              switch (state.lastActiveVatTab) {
                case "2":
                case 2:
                  return state.selectedRowsSale.length > 0
                case "3":
                case 3:
                  return state.selectedRowsShopping.length > 0
                default:
                  return false
              }
            }}
            handleAddNewDocument={async () => {
              switch (state.lastActiveVatTab) {
                case "2":
                case 2:
                  tracker("Sprawdź_JPK - Szczegóły ewidencji - Zakładka Sprzedaż - Dodaj dokument")

                  const responseSale = await API.get(`/VatRecordSale/canAddVatRecordSale?id=${id}`)

                  if (responseSale.status === 400) {
                    showErrorModal(true)
                    setErrorMessage(responseSale.data.errors[0])
                  }

                  if (responseSale.status === 200) {
                    return dispatch({
                      type: "handleState",
                      field: "showAddNewDocument",
                      payload: true,
                    })
                  }

                case "3":
                case 3:
                  tracker("Sprawdź_JPK - Szczegóły ewidencji - Zakładka Zakupy - Dodaj dokument")

                  const responsePurchase = await API.get(`/VatRecordPurchase/canAddVatRecordPurchase?id=${id}`)

                  if (responsePurchase.status === 400) {
                    showErrorModal(true)
                    setErrorMessage(responsePurchase.data.errors[0])
                  }

                  if (responsePurchase.status === 200) {
                    return dispatch({
                      type: "handleState",
                      field: "showAddNewDocumentPurchase",
                      payload: true,
                    })
                  }

                default:
                  return false
              }
            }}
            isDocumentBlocked={recordData.skorygowana || recordData.zablokowana}
            isDeleteBlocked={recordData.skorygowana || recordData.zablokowana}
            disabledFilter={
              state.lastActiveVatTab === "1" || state.lastActiveVatTab === "4" || state.lastActiveVatTab === "5"
            }
          />
        ) : (
          <>
            <FormModal
              visible={state.handleCloseAddSalesPurchase}
              content="Czy na pewno chcesz wyjść bez zapisywania?"
              onAccept={() =>
                dispatch({
                  type: "handleCancelAddSalesConfirm",
                })
              }
              onDecline={() =>
                dispatch({
                  type: "handleState",
                  field: "handleCloseAddSalesPurchase",
                  payload: false,
                })
              }
              addClass="delModal"
              okButtonName="Wyjdź"
            />

            <SalesEditNav
              getTitle={() => {
                switch (state.lastActiveVatTab) {
                  case "2":
                  case 2:
                    return "Nowy dokument sprzedaży"
                  case "3":
                  case 3:
                    return "Nowy dokument zakupu"
                  default:
                    return ""
                }
              }}
              exit={() => {
                dispatch({
                  type: "handleState",
                  field: "showAddNewDocument",
                  payload: false,
                })
                dispatch({
                  type: "handleState",
                  field: "showAddNewDocumentPurchase",
                  payload: false,
                })
              }}
            />
          </>
        )}

        <div className="VatDetails-box">
          {!state.showAddNewDocument && !state.showAddNewDocumentPurchase ? (
            <>
              {state.salesEdit && <EditSells sellEditForm={sellEditForm} mainId={id} formVariant={wariant_formularza} />}
              {state.shoppingEdit && <EditShopping shoppingEditForm={shoppingEditForm} mainId={id} formVariant={wariant_formularza} />}

              {localElem && ["2", "3"].includes(state.lastActiveVatTab) && (
                <VatDetailsEditIcons
                  editMode={state.salesEdit || state.shoppingEdit}
                  onCancel={() =>
                    dispatch({
                      type: "handleState",
                      field: "handleCloseEditSections",
                      payload: true,
                    })
                  }
                  onSave={() => {
                    const isOnSales = state.lastActiveVatTab == "2"
                    const currForm = isOnSales ? sellEditForm : shoppingEditForm

                    return currForm.current.submit()
                  }}
                  onEdit={() => {
                    const isOnSales = state.lastActiveVatTab == "2"

                    dispatch({
                      type: "handleState",
                      field: isOnSales ? "salesEdit" : "shoppingEdit",
                      payload: true,
                    })
                  }}
                  blockEdit={blockDeclarationEdit}
                />
              )}

              {!state.salesEdit && !state.shoppingEdit && (
                <Tabs
                  activeKey={state.lastActiveVatTab || "1"}
                  onTabClick={(e) => {
                    tracker(`Sprawdź_JPK - Szczegóły ewidencji - Zakładka ${tabNames[e - 1]}`)
                    dispatch({
                      type: "handleClearActiveRows",
                    })

                    dispatch({
                      type: "handleState",
                      field: "lastActiveVatTab",
                      payload: e,
                    })
                  }}
                  className="main-tab-panel"
                >
                  <TabPane tab="Szczegóły" key="1">
                    <VatDetailsEditIcons
                      editMode={editMode}
                      onCancel={exitEditMode}
                      onSave={saveEditMode}
                      onEdit={
                        !blockDetailsEdit
                          ? () => {
                            tracker("Sprawdź_JPK - Szczegóły ewidencji - Zakładka Szczegóły - Edytuj")
                            setEditMode(true)
                          }
                          : null
                      }
                      blockEdit={blockDetailsEdit}
                      blockSave={blockSave}
                    />

                    <div className="Details">
                      <VatDetailsForm
                        fields={fields}
                        onChange={(newFields) => {
                          setFields(newFields)
                        }}
                        editMode={editMode}
                        recordData={recordData}
                        subjects={subjects}
                        setBlockSave={setBlockSave}
                        detailsEditForm={detailsEditForm}
                      />
                      <div className="details-col details-col__second">
                        <div className="inner-row">
                          <p>Liczba wierszy sprzedaży:</p>
                          <span>{liczba_wierszy_sprzedazy}</span>
                        </div>
                        <div className="inner-row">
                          <p>Liczba wierszy zakupów:</p>
                          <span>{liczba_wierszy_zakupow}</span>
                        </div>
                      </div>
                      <div className="details-col details-col__third">
                        <div className="inner-row">
                          <p>Podatek należny:</p>
                          <span>
                            {podatek_nalezny
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                              .replace(".", ",")}
                          </span>
                        </div>
                        <div className="inner-row">
                          <p>Podatek naliczony:</p>
                          <span>
                            {podatek_naliczony
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                              .replace(".", ",")}
                          </span>
                        </div>
                        <div className="inner__bottom">
                          <div className="inner-row">
                            <p>Zablokowana:</p>
                            <span>{zablokowana ? "tak" : "nie"}</span>
                          </div>
                          <div className="inner-row">
                            <p>Skorygowana:</p>
                            <span>{skorygowana ? "tak" : "nie"}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Sprzedaż" key="2">
                    <SalesTab id={id} detailsVisible={detailsVisible} setDetailsVisible={setDetailsVisible} formVariant={wariant_formularza} />
                  </TabPane>
                  <TabPane tab="Zakupy" key="3">
                    <ShoppingTab
                      id={id}
                      detailsVisible={detailsShopVisible}
                      setDetailsVisible={setDetailsShopVisible}
                    />
                  </TabPane>

                  <TabPane
                    tab="Deklaracja"
                    key="4"
                    tab={
                      <span className={messageInfo.message !== "" ? "withWarning" : ""}>
                        Deklaracja
                        {messageInfo.message !== "" ? (
                          <img
                            src={warningIcon}
                            alt=""
                            data-tip={declarationTooltip}
                            onClick={(event) => {
                              event.stopPropagation()
                            }}
                          />
                        ) : null}
                      </span>
                    }
                  >
                    <DeclarationsTabFix
                      declaractionCount={declaractionCount}
                      id={id}
                      type={cel_zlozenia}
                      handleCalc={handleCalc}
                      message={messageInfo.message}
                      blockDetailsEdit={blockDeclarationEdit}
                      formVariant={wariant_formularza}
                    />
                  </TabPane>
                  {verifyNip &&
                    <TabPane tab="Weryfikacja NIP" key="5">
                      <div className="Details">
                        <div className="details-col details-col__first_prim">
                          <div className="inner-row">
                            <div className="header">Status weryfikacji NIP w MF</div>
                          </div>
                          <div className="inner-row">
                            <p>czynny:</p>
                            <span>{active_mf ?? "-"}</span>
                          </div>
                          <div className="inner-row">
                            <p>niezarejestrowany:</p>
                            <span>{unregistered_mf ?? "-"}</span>
                          </div>
                          <div className="inner-row">
                            <p>zwolniony:</p>
                            <span>{discharged_mf ?? "-"}</span>
                          </div>
                          <div className="inner-row">
                            <p>błędny:</p>
                            <span>{wrong_mf ?? "-"}</span>
                          </div>
                          <div className="inner-row">
                            <p>błąd weryfikacji:</p>
                            <span>{error_mf ?? "-"}</span>
                          </div>
                          <div className="inner-row">
                            <p>niewykonana:</p>
                            <span>{unexecuted_mf ?? "-"}</span>
                          </div>
                          <div className="inner__bottom">
                            <div className="inner-row">
                              <div className="nip_verification_button">
                                <Button
                                  name="Szczegóły"
                                  disabled={blockVerificationDetails}
                                  type="button"
                                  styling="button-small"
                                  className="client-action-button"
                                  onClick={detailsVerifyNip}
                                >
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="details-col details-col__second_prim">
                          <div className="inner-row">
                            <div className="header">Status weryfikacji NIP w VIES</div>
                          </div>
                          <div className="inner-row">
                            <p>zarejestrowany:</p>
                            <span>{registered_vies ?? "-"}</span>
                          </div>
                          <div className="inner-row">
                            <p>niezarejestrowany:</p>
                            <span>{unregistered_vies ?? "-"}</span>
                          </div>
                          <div className="inner-row">
                            <p>błędny:</p>
                            <span>{wrong_vies ?? "-"}</span>
                          </div>
                          <div className="inner-row">
                            <p>błąd weryfikacji:</p>
                            <span>{error_vies ?? "-"}</span>
                          </div>
                          <div className="inner-row">
                            <p>niewykonana:</p>
                            <span>{unexecuted_vies ?? "-"}</span>
                          </div>
                        </div>
                        <div className="details-col details-col__third_prim">
                          <div className="inner-row">
                            <div className="header">Nierozpoznany format</div>
                          </div>
                          <div className="inner-row">
                            <p>błędne numery NIP:</p>
                            <span>{unrecognized ?? "-"}</span>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  }
                </Tabs>
              )}
            </>
          ) : (
            <>
              <VatDetailsEditIcons
                editMode={true}
                onCancel={() =>
                  dispatch({
                    type: "handleState",
                    field: "handleCloseAddSalesPurchase",
                    payload: true,
                  })
                }
                onSave={() => {
                  switch (state.lastActiveVatTab) {
                    case "2":
                    case 2:
                      tracker("Sprawdź_JPK - Szczegóły ewidencji - Zakładka Sprzedaż - Zapisz dokument")
                      return addForm.current.submit()
                    case "3":
                    case 3:
                      tracker("Sprawdź_JPK - Szczegóły ewidencji - Zakładka Zakupy - Zapisz dokument")
                      return addFormPurchase.current.submit()
                    default:
                      return false
                  }
                }}
              />
              {state.lastActiveVatTab == 2 ? (
                <AddNewDocument mainId={id} addForm={addForm} formVariant={wariant_formularza} />
              ) : (
                <AddNewDocumentPurchase mainId={id} addFormPurchase={addFormPurchase} formVariant={wariant_formularza} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default VatDetails
