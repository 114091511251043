import React from "react";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined, CheckOutlined, CloseCircleFilled } from "@ant-design/icons";



function handleMenuClick(e) {
    e.domEvent.stopPropagation()
}

function handleButtonClick(e) {
    e.stopPropagation()
}

function MultiSelectDropDown({ options, selected, setSelected, header, labelSelector, idSelector, className }) {
    const labelSelect = labelSelector !== undefined ? labelSelector : x => x.label
    const idSelect = idSelector !== undefined ? idSelector : x => x.id
    const handleOkFilterClick = (e, n) => {
        if (e.domEvent.altKey) {
            setSelected([n])
        }
        else {
            var newSelected
            if (selected.includes(n)) {
                newSelected = selected.filter(x => x !== n)
                setSelected(newSelected)
            } else {
                newSelected = [...selected]
                newSelected.push(n)
                setSelected(newSelected)
            }
        }

        e.domEvent.stopPropagation()
        return false
    }

    const clearFilter = (e) => {
        setSelected([])
        e.stopPropagation()
    }

    let menuItems = []
    let selectedCount = 0
    let singleSelected = ""
    for (let i = 0; i < options.length; i++) {
        const element = options[i];
        const isChecked = selected.includes(idSelect(element))
        if (isChecked) {
            selectedCount++
            singleSelected = labelSelect(element)
        }
        menuItems.push(<Menu.Item key={"menuItem" + i} className={"wlx-import-status " + element.style + (isChecked ? " wlx-status-selected" : " " + element.notSelectedStyle)}
            onClick={e => (handleOkFilterClick(e, idSelect(element)))}>
            {labelSelect(element)}
            <CheckOutlined className={"wlx-status-symbol wlx-status-dropdown-row-symbol-" + (isChecked ? "checked" : "unchecked")} />
        </Menu.Item>)
    }

    const menu = (
        <Menu onClick={handleMenuClick} className="noselect">
            {menuItems}
        </Menu>
    );

    const status = selectedCount === 0 ? header : (selectedCount === 1) ? singleSelected : (selectedCount + " wybrane")

    return <Dropdown overlay={menu}
        trigger="click"
        className={["wlx-status-dropdown", className]}
        overlayStyle={{
            border: "1px solid #DDDDDD",
            borderRadius: "1px",
        }}>
        <Button onClick={handleButtonClick} class="wlx-status-button"

            style={{
                "width": "23%",
                "display": "flex",
                "justifyContent": "space-between",
                "alignItems": "center",
                "color": "#233B53",
                "borderColor": "#DDDDDD",
                "height": "45px",
                "padding-right": "1%",
            }}
        >
            {status}
            {selectedCount === 0 && <DownOutlined />}
            {selectedCount !== 0 && <CloseCircleFilled onClick={clearFilter} style={{ color: "#BFBFBF" }} />}
        </Button>
    </Dropdown>
}

export default MultiSelectDropDown