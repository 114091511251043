export const VerificationStatusMF = {
    "Unexecuted": { value: 0, selected: false },
    "Active": { value: 1, selected: false },
    "Unregistered": { value: 2, selected: false },
    "Discharged": { value: 3, selected: false },
    "Wrong": { value: 4, selected: false },
    "Error": { value: 5, selected: false }
}

export const VerificationStatusMfMap = {
    0: { name: "-", style: "" },
    1: { name: "czynny", style: "wl-status-right" },
    2: { name: "niezarejestrowany", style: "" },
    3: { name: "zwolniony", style: "" },
    4: { name: "błędny", style: "wl-status-wrong" },
    5: { name: "błąd weryfikacji", style: "wl-status-error" }
}

export const VerificationStatusVIES = {
    "Unexecuted": { value: 0, selected: false },
    "Registered": { value: 1, selected: false },
    "Unregistered": { value: 2, selected: false },
    "Wrong": { value: 3, selected: false },
    "Error": { value: 4, selected: false }
}

export const VerificationStatusVIESMap = {
    0: { name: "-", style: "" },
    1: { name: "zarejestrowany", style: "wl-status-right" },
    2: { name: "niezarejestrowany", style: "wl-status-wrong" },
    3: { name: "błędny", style: "wl-status-wrong" },
    4: { name: "błąd weryfikacji", style: "wl-status-wrong" }
}