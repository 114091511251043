import React, { useContext, useEffect, useRef } from "react"
import { Form, Input } from "antd"
import "../UserPanel.less"
import { StateContext, DispatchContext } from "../../userContext"
import API from "../../common/api"

import Context from "../../../context/index"
import FormModal from "components/common/FormModal"

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const UserEdit = () => {
  const form = useRef()

  const { ctx } = useContext(Context)
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { name, surname, phone, email, role } = state.userData

  const handleDataSend = async () => {
    try {
      const data = { email: state.userData.email, ...state.newUserData }
      const resp = await API.post("Dashboard/User", data)

      dispatch({ type: "handleSaveUser", payload: resp.data.stateObject })
    } catch (error) {
      alert("err")
      // window.location.href = "/login/panel-uzytkownika"
    }
  }

  const onFinishFailed = (errorInfo) => {
    dispatch({ type: "handleError" })
  }

  useEffect(() => {
    if (!state.errors) return

    if (!state.save && state.errors) {
      // FORM ERRORS CANCEL ACTION
      return dispatch({ type: "handleState", payload: true, field: "cancelModal" })
    }
  }, [state.errors])

  useEffect(() => {
    if (state.newUserData) {
      const inputNames = Object.keys(state.newUserData)
      const hasChanged = []

      inputNames.map((name) => {
        if (state.newUserData[name] !== state.userData[name]) {
          hasChanged.push(name)
        }
      })

      if (!hasChanged.length) {
        return dispatch({ type: "handleRestoreEdit" })
      }

      if (!state.save) {
        // ANULUJ
        return dispatch({ type: "handleState", payload: true, field: "cancelModal" })
      } else {
        // ZAPISZ

        // POST WITH DATA TO SEND
        handleDataSend()
      }
    }
  }, [state.newUserData])

  const onFinish = async (values) => {
    dispatch({ type: "saveUserData", payload: values })
  }

  useEffect(() => {
    if (!state.checkForm) return

    form.current.submit()
  }, [state.checkForm])

  useEffect(() => {
    dispatch({ type: "handleUserEdit", payload: { cancelButtonVisible: true } })
  }, [])

  return (
    <>
      <Form
        {...layout}
        name="UserEdit"
        ref={form}
        initialValues={{
          name,
          surname,
          phone,
          email,
          role: ctx.currentOrganization.roles[0],
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="edit-form user"
      >
        <Form.Item
          name="name"
          hasFeedback
          validateTrigger="onBlur"
          rules={[
            {
              whitespace: true,
              required: true,
              message: "Podaj swoje imię!",
            },
          ]}
        >
          <Input placeholder="Imię" />
        </Form.Item>

        <Form.Item
          name="surname"
          hasFeedback
          validateTrigger="onBlur"
          rules={[
            {
              whitespace: true,
              required: true,
              message: "Podaj swoje nazwisko!",
            },
          ]}
        >
          <Input placeholder="Nazwisko" />
        </Form.Item>

        {/* <Form.Item
          name="phone"
          hasFeedback
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Podaj swój numer telefonu!",
            },
            {
              pattern: /(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/,
              message: "Numer telefonu jest niepoprawny!",
            },
          ]}
        >
          <Input placeholder="Nr. telefonu" />
        </Form.Item> */}

        <Form.Item name="email">
          <Input disabled />
        </Form.Item>

        <Form.Item name="role">
          <Input disabled />
        </Form.Item>
      </Form>

      <FormModal
        visible={state.cancelModal}
        onAccept={() => dispatch({ type: "handleRestoreEdit" })}
        onDecline={() => dispatch({ type: "handleCloseModal" })}
        content="Czy chcesz porzucić zmiany?"
        cancelButton
      />
    </>
  )
}

export default UserEdit
