import API, { generatorApiURL } from "components/common/api"
import Context from "context"
import React, { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"

import { DispatchContext } from "components/userContext"
import { GateNavigation } from "./components/GateNavigation"
import { GateTable } from "./components/GateTable"
import { gateInfoType, rowInfoType } from "./components/types"


interface GateProps {
  id: string
}

export const Gate = (props: GateProps) => {
  const { id } = props
  const { ctx } = useContext(Context)
  const history = useHistory()
  const [rowsInfo, setRowsInfo] = useState<rowInfoType[]>()
  let [gateInfo, setGateInfo] = useState<gateInfoType>()
  const dispatch = useContext(DispatchContext)

  const fetchVatRecord = async () => {
    try {
      const response = await API.get(`VatGenerator/getVatRecord?id=${id}`)

      if (response.status === 200) {
        setGateInfo(response.data.stateObject)
        gateInfo = response.data.stateObject
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchData = async () => {
    try {
      const response = await API.get(`${generatorApiURL}/VatRecordGate/getGateStatus?id=${id}`, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid") ?? "",
        },
      })

      if (response.status === 200) {
        const singleRow: rowInfoType = response.data.stateObject
        singleRow.rokMiesiac = gateInfo?.rok + "/" + gateInfo?.miesiac
        setRowsInfo([singleRow])
        dispatch({ type: "handleState", field: "selectedRowsGate", payload: [response.data.stateObject.id] })
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (ctx.featureFlags.Generator_GateDisabled) {
      history.push("/dashboard")
      return
    }

    if (!id) return
    fetchVatRecord().then(() => {
      fetchData()
    })
  }, [id])

  if (!rowsInfo || !gateInfo) return null

  return (
    <div className="content-wrapper inner-shadow full-page-container">
      <GateNavigation rowsInfo={rowsInfo} gateInfo={gateInfo} fetchData={fetchData} />
      <GateTable rowsInfo={rowsInfo} />
    </div>
  )
}
