import React, { useContext, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

import * as actionTypes from "../../helpers/types"
import * as APPS from "../../helpers/appNames"

import "./Sidebar.less"
import { Layout, Menu, Button, Modal, Form, Input, Divider, Badge } from "antd"
import Context from "../../context/index"
import { SwitcherStore } from "../../mobx/switcherStore"

import consent from "../../images/weryfikacja.svg"
import profile from "../../images/profile.svg"
import contact from "../../images/contact.svg"
import jobs_history from "../../images/historia_operacji.svg"

import ConsentsModal from "./Modals/ConsentsModal"
import WienerConsentModal from "./Modals/WienerConsentModal"

import logout from "../../images/logout.svg"
import FormModal from "../common/FormModal"
import { DispatchContext, StateContext } from "../userContext"
import API from "../common/api"

import PA_1 from "../../images/PA-icon01.svg"
import PA_2 from "../../images/PA-icon02.svg"
import PA_3 from "../../images/PA-icon03.svg"
import PA_4 from "../../images/PA-icon04.svg"
import hamburger from "../../images/hamburger.svg"

import ReactTooltip from "react-tooltip"
import { tracker } from "helpers/tracker"
import data from "components/Tables/VatTable/subcomponents/VatTableButtons/subcomponents/data"

const statusCodeOK = 200

const { Sider } = Layout

const handleDataTip = (place, index) => {
  switch (place) {
    case "Panel administratora":
      switch (index) {
        case 1:
          return `
          <span>
            <span class='tooltip-header'>Organizacja</span></br>
            <span class='tooltip-content'>Dane twojej organizacji.</small>
          </span>
          `
        case 2:
          return `
          <span>
            <span class='tooltip-header'>Użytkownicy</span></br>
            <span class='tooltip-content'>Lista użytkowników w twojej organizacji.</small>
          </span>
          `
        case 3:
          return `
          <span>
            <span class='tooltip-header'>Aplikacje</span></br>
            <span class='tooltip-content'>Lista aplikacji dostępnych w ramach licencji.</small>
          </span>
          `
        case 4:
          return `
          <span>
            <span class='tooltip-header'>Podmioty</span></br>
            <span class='tooltip-content'>Dane podmiotów dostępnych w ramach licencji.</small>
          </span>
          `
        default:
          return
      }
    case "Biała lista":
      return ``
    case "Weryfikacja NIP":
      return ``
    default:
      return
  }
}

const Sidebar = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { ctx } = useContext(Context)

  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = React.useState(false)

  const [consentsModal, setConsentsModal] = React.useState(false)
  const [wienerConsentModal, setWienerConsentModal] = React.useState(false)
  const [helpModal, setHelpModal] = React.useState(false)

  const [title, setTitle] = React.useState(null)
  const [message, setMessage] = React.useState(null)

  const [wienerAnyOptionSelected, setWienerAnyOptionSelected] = React.useState(false)
  const [canSaveConsents, setCanSaveConsents] = React.useState(false)

  const helpRef = React.useRef(null)

  useEffect(() => {
    const url = `/Jobs/getJobsCounter?CurrentOrganizationGuid=${ctx.currentOrganization.guid}`
    setTimeout(() => {
      API.get(url)
      .then(
        ({data}) => {
          dispatch({
            type: "handleState",
            field: "jobsCounter",
            payload: data.stateObject,
          })
        }
      )
      .catch((error) => {
        console.error(error)
      })
    }, 500);
  },[])

  const handleOk = async () => {
    setLoading(true)
    try {
      const { status } = await API.post("/Users/logout", { refreshToken: localStorage.getItem("refresh_token") })
      setLoading(false)

      if (status === statusCodeOK) {
        localStorage.removeItem("token")
        localStorage.removeItem("refresh_token")
        localStorage.removeItem("userRole")
        localStorage.removeItem("userEmail")
        localStorage.removeItem("currentGuid")

        window.location.href = "/login"
      }
    } catch (err) {
      setLoading(false)

      localStorage.removeItem("token")
      localStorage.removeItem("refresh_token")
      localStorage.removeItem("userRole")
      localStorage.removeItem("userEmail")
      localStorage.removeItem("currentGuid")
      window.location.href = "/login"
    }
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const history = useHistory()

  const logoutUser = () => {
    setModalVisible(true)
    tracker("Wyloguj")
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  useEffect(() => {
    if (!ctx.featureFlags.InsurancesDisabled) {

      API.get(`Dashboard/getConsentsByUser?CurrentOrganizationGuid=${ctx.currentOrganization.guid}`).then(async (resp) => {
        if (resp.status === 200 && resp.data.stateObject.wienerCheckbox && resp.data.stateObject.wiener === null) {
          tracker("Komunikat Wiener")
          setWienerConsentModal(true)
        }
      }).catch(e => console.log(e))
    }
  }, [])

  const getJobsCounterValueToShow = () =>{
    const val = state.jobsCounter
    return val >= 0 ? val : 0
  }

  return (
    <Sider width={75} className="Sidebar">
      {ctx.currentApp === "Panel administratora" && (
        <ReactTooltip
          effect="solid"
          className="tooltip"
          backgroundColor="#fff"
          textColor="#163567"
          borderColor="#163567"
          border
          html
        />
      )}

      {/* Sam hamburger button */}
      <button
        className="hamburger-wrapper"
        onClick={() => {
          SwitcherStore.setSwitcherVisible(true)
          tracker("App switcher")
        }}
      >
        <img src={hamburger} className="Navbar-hamburger" alt="menu icon - hamburger" />
      </button>

      {/* Sidebar pod hamburgerem */}
      <div className="sidebar-content">
        <FormModal
          visible={helpModal}
          destroyOnClose
          acceptDisabled={!title || !message}
          afterClose={() => {
            setHelpModal(false)
            setTitle(null)
            setMessage(null)
          }}
          header={<h3>Zgłoś błąd lub sugestię</h3>}
          hasForm={
            <HelpModal
              helpRef={helpRef}
              setHelpModal={setHelpModal}
              title={title}
              setTitle={setTitle}
              message={message}
              setMessage={setMessage}
            />
          }
          onAccept={() => helpRef.current.submit()}
          onDecline={() => {
            Modal.destroyAll()
            setHelpModal(false)
          }}
          addClass="exportModal researchModal helpModal"
          okButtonName="Wyślij"
        />

        <FormModal
          visible={consentsModal}
          destroyOnClose
          afterClose={() => {
            setConsentsModal(false)
          }}
          acceptDisabled={!canSaveConsents}
          header={<h3>Zgody i oświadczenia</h3>}
          hasForm={<ConsentsModal
            helpRef={helpRef}
            closeModal={() => {
              Modal.destroyAll()
              setConsentsModal(false)
            }}
            setCanSaveConsents={setCanSaveConsents}
          />}
          onAccept={() => helpRef.current.submit()}
          onDecline={() => {
            Modal.destroyAll()
            setConsentsModal(false)
          }}
          addClass="ConsentFormModal"
          okButtonName="Zapisz"
        />

        <FormModal
          visible={wienerConsentModal}
          destroyOnClose
          acceptDisabled={!wienerAnyOptionSelected}
          afterClose={() => {
            setWienerConsentModal(false)
          }}
          hasForm={<WienerConsentModal
            helpRef={helpRef}
            closeModal={() => {
              Modal.destroyAll()
              setWienerConsentModal(false)
            }}
            setWienerAnyOptionSelected={setWienerAnyOptionSelected}
          />}
          onAccept={() => helpRef.current.submit()}
          onDecline={() => {
            Modal.destroyAll()
            setWienerConsentModal(false)
          }}
          addClass="WienerConsentFormModal"
          okButtonName="Zapisz"
        />

        <>
          {!state.sidebarVisible ? (
            <></>
          ) : !state.vatTableVisible ? (
            (ctx.currentApp !== "Biała lista" && ctx.currentApp !== "Weryfikacja NIP") ?
              <Menu theme="dark" mode="inline" selectedKeys={state.selectedModule} className="topButtons">
                <Menu.Item
                  style={{ padding: "0", marginBottom: "30px" }}
                  key="1"
                  icon={
                    <img
                      src={PA_1}
                      alt="icon 1"
                      className="side-icon"
                      data-tip={handleDataTip(ctx.currentApp, 1)}
                    // data-event="click"
                    // data-event-off="dblclick"
                    />
                  }
                  onClick={() => {
                    if (ctx.clientEditModal) return
                    if (state.showEditModal) return

                    if (window.location.pathname === APPS.USER_PANEL) {
                      dispatch({
                        type: actionTypes.HANDLE_MODULE_ONE_USER_PANEL,
                        payload: { url: window.location.pathname, visible: true },
                      })

                      return
                    }

                    dispatch({
                      type: actionTypes.HANDLE_MODULE_ONE,
                      payload: { url: window.location.pathname, visible: true },
                    })
                  }}
                ></Menu.Item>
                <Menu.Item
                  key="2"
                  style={{ padding: "0", marginBottom: "30px" }}
                  icon={<img src={PA_2} alt="icon 2" className="side-icon" data-tip={handleDataTip(ctx.currentApp, 2)} />}
                  onClick={() => {
                    tracker("Panel administratora - Użytkownicy")

                    if (ctx.clientEditModal) return
                    if (state.showEditModal) return

                    // if (window.location.pathname === APPS.USER_PANEL) {
                    //   dispatch({
                    //     type: actionTypes.HANDLE_MODULE_TWO_USER_PANEL,
                    //     payload: { url: window.location.pathname, visible: true },
                    //   })
                    //   return
                    // }

                    dispatch({
                      type: actionTypes.HANDLE_MODULE_TWO,
                      payload: { url: window.location.pathname, visible: true },
                    })
                  }}
                ></Menu.Item>
                <Menu.Item
                  key="3"
                  style={{ padding: "0", marginBottom: "30px" }}
                  icon={<img src={PA_3} alt="icon 3" className="side-icon" data-tip={handleDataTip(ctx.currentApp, 3)} />}
                  onClick={() => {
                    tracker("Panel administratora - Aplikacje")

                    if (ctx.clientEditModal) return
                    if (state.showEditModal) return

                    dispatch({
                      type: actionTypes.HANDLE_MODULE_THREE,
                      payload: { url: window.location.pathname, visible: true },
                    })
                  }}
                ></Menu.Item>
                <Menu.Item
                  style={{ padding: "0", marginBottom: "30px" }}
                  key="4"
                  icon={<img src={PA_4} alt="icon 4" className="side-icon" data-tip={handleDataTip(ctx.currentApp, 4)} />}
                  onClick={() => {
                    tracker("Panel administratora - Podmioty")

                    if (ctx.clientEditModal) return
                    if (state.showEditModal) return

                    if (window.location.pathname === APPS.CLIENT_PANEL) {
                      dispatch({
                        type: actionTypes.HANDLE_MODULE_TWO_USER_PANEL,
                        payload: { url: window.location.pathname, visible: true },
                      })
                      return
                    }

                    dispatch({
                      type: actionTypes.HANDLE_MODULE_TWO,
                      payload: { url: window.location.pathname, visible: true },
                    })
                  }}
                ></Menu.Item>
              </Menu>
              :
              <Menu theme="dark" mode="inline" selectedKeys={state.selectedModule} className={"topButtons " + (ctx.currentApp === "Biała lista" ? "wlTopButtons" : "nipTopButtons")}
                selectedKeys={["1"]}>
                <Menu.Item
                  style={{ padding: "0", marginBottom: "30px" }}
                  isSelected={true}
                  key="1"
                  icon={
                    <img
                      src={PA_1}
                      alt="icon 1"
                      className="side-icon"
                      data-tip={handleDataTip(ctx.currentApp, 1)}
                    />
                  }
                ></Menu.Item>
              </Menu>

          ) : (
            <Menu theme="dark" mode="inline" selectedKeys={state.selectedModule}></Menu>
          )}
        </>

        <div className="bottomButtons">
          {!ctx.featureFlags.JobsHistoryDisabled && (
            <>
              <Button
                onClick={() => {
                  tracker("Historia operacji")
                  history.push("/historia-operacji")
                }}
                style={{ marginBottom: "0px" }}
              >
                <Badge count={getJobsCounterValueToShow()} offset={[5, -5]} style={{
                  background: "#233B53"
                }}>
                  <img src={jobs_history} alt="history icon" data-tip="Historia operacji." />
                </Badge>
              </Button>

              <Divider
                style={{
                  width: "21px",
                  minWidth: "21px",
                  borderTop: "1px solid #DDDDDD"
                }}
              ></Divider>
            </>)}

          <Button
            onClick={() => {
              setHelpModal(true)
              tracker("Zgłoś błąd")
            }}
            style={{ marginBottom: "10px" }}
          >
            <img src={contact} alt="contact icon" />
          </Button>

          <Button onClick={() => {
            setConsentsModal(true)
            tracker("Zgody i oświadczenia")
          }} style={{ marginBottom: "10px" }}>
            <img src={consent} alt="profile icon" data-tip="Zgody i oświadczenia." />
          </Button>

          <Button onClick={() => history.push("/panel-uzytkownika")} style={{ marginBottom: "10px" }}>
            <img src={profile} alt="profile icon" data-tip="Twoje dane kontaktowe i logowania." />
          </Button>

          <Button onClick={logoutUser}>
            <img src={logout} alt="logout icon" data-tip="Wyloguj." />
          </Button>
        </div>
      </div>

      <FormModal
        visible={modalVisible}
        content="Czy chcesz się wylogować?"
        onAccept={handleOk}
        onDecline={handleCancel}
        loading={loading}
        cancelButton
      />
    </Sider >
  )
}



const HelpModal = ({ helpRef, setHelpModal, title, message, setTitle, setMessage }) => {
  return (
    <Form
      ref={helpRef}
      layout={{
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      }}
      name="SalesEdit"
      onFinish={async (values) => {
        try {
          await API.post(
            `/Users/sendRaport`,
            { ...values },
            { headers: { tenant_id: localStorage.getItem("currentGuid") } }
          )
          setHelpModal(false)
        } catch (error) {
          console.error(error)
          setHelpModal(false)
        }
      }}
      className="sales-edit-form"
    >
      <Form.Item name="title" hasFeedback className="details-item">
        <Input placeholder="temat" value={title} onChange={(e) => setTitle(e.target.value)} />
      </Form.Item>

      <Form.Item name="content" hasFeedback className="details-item">
        <Input.TextArea
          placeholder="treść zgłoszenia"
          autoSize={{ minRows: 8 }}
          value={message}
          maxLength={240}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Form.Item>
    </Form>
  )
}

export default Sidebar
