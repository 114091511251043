import { objectValues } from "@azure/ms-rest-js/es/lib/util/utils";

export const getVerificationFilter = options => {
    const entries = Object.entries(options)
    let allTrue = true
    if (entries !== undefined && entries.length > 0) {
        let selectedIndices = []
        for (let i = 0; i < entries.length; i++) {
            const element = entries[i][1];
            if (element.selected) {
                selectedIndices.push(element.value)
            } else {
                allTrue = false
            }
        }
        if (!allTrue && selectedIndices.length > 0) {
            return "verification_status==" + selectedIndices.join("|")
        }
    }
    return "";
}

export const getImportSourceFilter = (filteredImportIds) => {
    const keys = Object.keys(filteredImportIds)
    if (keys.length === 0) return ""
    let i = 0
    const segments = keys.map(x => "userImports[" + i++ + "]=" + encodeURIComponent(x))
    return segments.join("&")
}

export const getExceptedIds = (exceptedIds) => {
    let i = 0
    const segments = exceptedIds.map(x => "exceptIds[" + i++ + "]=" + x)
    return segments.join("&")
}