import React, { useContext, useEffect } from "react"
import { Form, Input } from "antd"
import "./Login.less"
import API from "../common/api"
import { Link } from "react-router-dom"
import FormButton from "../common/FormButton"
import { UserStore } from "../../../src/mobx/userStore"
import { useObserver } from "mobx-react"

import Rules from "../common/Rules"
import LeftSide from "../common/LeftSide"

import Context from "../../context"
import { DispatchContext } from "../userContext"

import ReactGA from "react-ga"

const Login = () => {
  const { ctx, setCtx } = useContext(Context)
  const [loading, setLoading] = React.useState(false)
  const [errorMessages, setErrorMessages] = React.useState([])

  const dispatch = useContext(DispatchContext)

  const onFinish = (values) => {
    setLoading(true)

    API.post("/Users/login", values)
      .then((res) => {
        if (res.status === 200) {
          const stateObject = res.data.stateObject

          localStorage.setItem("token", stateObject.token)
          localStorage.setItem("refresh_token", stateObject.refreshToken)

          localStorage.setItem("userRole", stateObject.roles)
          localStorage.setItem("userEmail", stateObject.username)

          UserStore.setUsername(stateObject.username)
          UserStore.addUser(stateObject)
          UserStore.setApplication("Dashboard")
          UserStore.setOrganization(UserStore.users[0].organizations[0].name)

          setLoading(false)
          dispatch({ type: "saveUserData", payload: { email: stateObject.username, roles: stateObject.roles } })

          const [_, cameFrom] = window.location.pathname.split("login/")

          window.location.href = `/${cameFrom || ""}`
        } else {
          if (res.status === 400) {
            // console.error(error)
            setErrorMessages(["Błędny login lub hasło"])
            setLoading(false)
          }
        }
      })
      .catch((error) => {
        console.error(error)
        if (error.response) {
          setErrorMessages(error.response.data.errors)
        }

        setLoading(false)
      })
  }

  const onFinishFailed = (errorInfo) => {}

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    setCtx({ ...ctx, redirectedFromHelper: window.location.href })
  }, [])

  return useObserver(() => {
    return (
      <div className="Login-container">
        <LeftSide />
        <div className="Login">
          {/* display error messages from backend, if any */}
          {errorMessages &&
            errorMessages.map((errorMessage) => (
              <p key={errorMessage} className="errorMessage">
                {errorMessage}
              </p>
            ))}

          <Form name="login" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item validateTrigger="onBlur" name="login" hasFeedback rules={Rules.email}>
              <Input placeholder="e-mail" />
            </Form.Item>
            <Form.Item
              name="password"
              hasFeedback
              validateTrigger="onBlur"
              className="password"
              rules={[{ required: true, message: "Wpisz swoje hasło!" }]}
            >
              <Input.Password placeholder="hasło" />
            </Form.Item>
            <div className="reset-container">
              <Link className="login-link body-2-bold" to="/reset-password">
                Resetuj hasło
              </Link>
            </div>
            <div className="login-button-container">
              <FormButton name="Zaloguj się" loading={loading} />
            </div>
            <div className="create">
              <p className="body-2">
                Jeśli nie masz jeszcze konta{" "}
                <Link className="login-link body-2-bold" to="/register">
                  zarejestruj się.
                </Link>
              </p>
            </div>
          </Form>
        </div>
      </div>
    )
  })
}

export default Login
