import React, { useContext } from "react"

import { Form, InputNumber } from "antd"
import { StateContext } from "components/userContext"

import infoIcon from "images/info.svg"
import { dash, p_37, p_38, p_48, myIdea } from "./DeclarationsTabFix"

const TabRenderer = (props: any) => {
  const {
    data,
    declarationFormFixRef,
    editMode,
    tab,
    handleCalculateSummary,
    editConfirmed,
    setEditConfirmed,
    confirmEditModal,
    setConfirmEditModal,
    rightCol,
    handleSummaryChange,
    isBlocked,
  } = props

  const state = useContext(StateContext)

  return (
    <div>
      {data.map((rows: { name: string; value: string }[]) => {
        const [firstCol, secondCol] = rows

        const firstColName = firstCol?.name
        const secondColName = secondCol?.name

        const summaryLeft = firstColName === p_37
        const summaryRight = secondColName === p_38
        const summaryRight2 = secondColName === p_48

        const desc1 = state.declarationDictionaryFix.find(
          (row: { code: string }) => firstColName === row.code.toLowerCase()
        )

        const desc2 = state.declarationDictionaryFix.find(
          (row: { code: string }) => secondColName === row.code.toLowerCase()
        )

        const { short_description: FSD = "", full_description: FFD = "" } = desc1 || {
          short_description: "",
          full_description: "",
        }
        const { short_description: SSD = "", full_description: SFD = "" } = desc2 || {
          short_description: "",
          full_description: "",
        }

        if (!secondColName && firstColName) {
          return (
            <div key={firstColName} className="content-row">
              <div className="Tax-col Tax-col_one">
                <p>{FSD}</p>
              </div>
              <div className="Tax-col Tax-col_two">
                <span className="const-name">{firstColName.toUpperCase()}</span>
                <span className="const-value">{state.declarationData[firstColName] || dash}</span>
                <div className="value">
                  <Form.Item name={firstColName} hasFeedback validateTrigger="onBlur">
                    <InputNumber
                      type="number"
                      step={1}
                      bordered={editMode}
                      readOnly={!editMode}
                      precision={0}
                      onBlur={() =>
                        editMode &&
                        handleCalculateSummary(firstColName, tab, rightCol, () =>
                          declarationFormFixRef.current.getFieldsValue()
                        )
                      }
                      placeholder={!editMode ? dash : ""}
                    />
                  </Form.Item>
                </div>
                <div className="info">
                  <img src={infoIcon} data-tip={firstColName.toUpperCase() + ": " + FFD} />
                </div>
              </div>
              <div className="Tax-col Tax-col_third"></div>
            </div>
          )
        } else {
          if (!secondColName) return

          return (
            <div key={secondColName} className="content-row">
              <div className="Tax-col Tax-col_one">
                <p>{FSD || SSD}</p>
              </div>

              <div className="Tax-col Tax-col_two">
                {firstColName && (
                  <>
                    <span className="const-name">{firstColName.toUpperCase()}</span>
                    <span className="const-value">
                      {!summaryLeft ? state.declarationData[firstColName] || dash : null}
                    </span>
                    <div className="value">
                      {summaryLeft && editMode && (
                        <div
                          className={`${editConfirmed[p_37] ? "lock-open" : "lock-closed"}`}
                          onClick={() => {
                            if (!editConfirmed.p_37) {
                              setConfirmEditModal({
                                ...confirmEditModal,
                                p_37: true,
                              })
                            } else {
                              setEditConfirmed({ ...editConfirmed, p_37: !editConfirmed[p_37] })
                            }
                          }}
                        />
                      )}

                      <Form.Item
                        name={firstColName}
                        hasFeedback
                        validateTrigger="onBlur"
                        rules={[
                          (e) => ({
                            validator() {
                              // FIRST COL INPUTS
                              if (!secondColName || [p_37].includes(firstColName)) return Promise.resolve()

                              const msg = "Uzupełnij kwotę podatku!"

                              const elemCode = firstColName
                              const nextElemCode = secondColName

                              const elementValue = parseInt(e.getFieldValue(elemCode))
                              const secondColValueParsed = parseInt(e.getFieldValue(nextElemCode))

                              const thisColValue = e.getFieldValue(elemCode)
                              const secondColValue = e.getFieldValue(nextElemCode)

                              // ARE BOTH VALID
                              const areValid =
                                (!isNaN(elementValue) && !isNaN(secondColValueParsed)) ||
                                (thisColValue === "" && secondColValue === "") ||
                                (thisColValue === null && secondColValue === null)

                              if (areValid) {
                                declarationFormFixRef.current.setFields([
                                  {
                                    name: nextElemCode,
                                    errors: [],
                                  },
                                ])

                                return Promise.resolve()
                              }

                              // HANDLE FIRST NOT VALID
                              if (!isNaN(secondColValue) && thisColValue === null) {
                                const msg = tab === 1 ? "Uzupełnij kwotę postawy!" : "Uzupełnij kwotę netto!"
                                return Promise.reject(msg)
                              }

                              declarationFormFixRef.current.setFields([
                                {
                                  name: nextElemCode,
                                  errors: [msg],
                                },
                              ])

                              return Promise.resolve()
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          type="number"
                          step={1}
                          bordered={editMode}
                          readOnly={!editMode}
                          precision={0}
                          onBlur={() => {
                            if (editMode) {
                              handleCalculateSummary(firstColName, tab, rightCol, () =>
                                declarationFormFixRef.current.getFieldsValue()
                              )
                            }

                            if (summaryLeft && myIdea) {
                              setEditConfirmed({
                                ...editConfirmed,
                                p_37: false,
                              })
                            }
                          }}
                          placeholder={!editMode ? dash : ""}
                          onChange={() => summaryLeft && handleSummaryChange(p_37)}
                          disabled={summaryLeft ? isBlocked(p_37) : false}
                        />
                      </Form.Item>
                    </div>

                    <div className="info">
                      <img src={infoIcon} data-tip={firstColName.toUpperCase() + ": " + FFD} />
                    </div>
                  </>
                )}
              </div>
              <div className="Tax-col Tax-col_third">
                <span className="const-name">{secondColName.toUpperCase()}</span>
                <span className="const-value">
                  {!summaryRight ? state.declarationData[secondColName] || dash : null}
                </span>
                <div className="value">
                  {summaryRight && editMode && (
                    <div
                      className={`${editConfirmed[p_38] ? "lock-open" : "lock-closed"}`}
                      onClick={() => {
                        if (!editConfirmed.p_38) {
                          setConfirmEditModal({
                            ...confirmEditModal,
                            p_38: true,
                          })
                        } else {
                          setEditConfirmed({ ...editConfirmed, p_38: !editConfirmed[p_38] })
                        }
                      }}
                    />
                  )}

                  {summaryRight2 && editMode && (
                    <div
                      className={`${editConfirmed[p_48] ? "lock-open" : "lock-closed"}`}
                      onClick={() => {
                        if (!editConfirmed.p_48) {
                          setConfirmEditModal({
                            ...confirmEditModal,
                            p_48: true,
                          })
                        } else {
                          setEditConfirmed({ ...editConfirmed, p_48: !editConfirmed[p_48] })
                        }
                      }}
                    />
                  )}

                  <Form.Item
                    name={secondColName}
                    hasFeedback
                    validateTrigger="onBlur"
                    rules={[
                      (e) => ({
                        validator() {
                          // SECOND COL INPUTS
                          if (!firstColName || [p_38, p_48].includes(secondColName)) return Promise.resolve()

                          const msg = tab === 1 ? "Uzupełnij kwotę podstawy!" : "Uzupełnij kwotę netto!"

                          const elemCode = secondColName
                          const prevElemCode = firstColName

                          const thisColValParsed = parseInt(e.getFieldValue(elemCode))
                          const firstColValParsed = parseInt(e.getFieldValue(prevElemCode))

                          const thisColVal = e.getFieldValue(elemCode)
                          const firstColVal = e.getFieldValue(prevElemCode)

                          // ARE BOTH VALID
                          const areValid =
                            (!isNaN(thisColValParsed) && !isNaN(firstColValParsed)) ||
                            (thisColVal === "" && firstColVal === "") ||
                            (thisColVal === null && firstColVal === null)

                          if (areValid) {
                            declarationFormFixRef.current.setFields([
                              {
                                name: prevElemCode,
                                errors: [],
                              },
                            ])

                            return Promise.resolve()
                          }

                          // HANDLE SECOND NOT VALID
                          if (!isNaN(firstColVal) && thisColVal === null) {
                            return Promise.reject("Uzupełnij kwotę podatku!")
                          }

                          declarationFormFixRef.current.setFields([
                            {
                              name: prevElemCode,
                              errors: [msg],
                            },
                          ])

                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      type="number"
                      step={1}
                      bordered={editMode}
                      readOnly={!editMode}
                      precision={0}
                      onBlur={() => {
                        if (editMode) {
                          handleCalculateSummary(secondColName, tab, rightCol, () =>
                            declarationFormFixRef.current.getFieldsValue()
                          )
                        }

                        if (summaryRight && myIdea) {
                          setEditConfirmed({
                            ...editConfirmed,
                            p_38: false,
                          })
                        }

                        if (summaryRight2 && myIdea) {
                          setEditConfirmed({
                            ...editConfirmed,
                            p_48: false,
                          })
                        }
                      }}
                      placeholder={!editMode ? dash : ""}
                      onChange={() => {
                        summaryRight && handleSummaryChange(p_38)
                        summaryRight2 && handleSummaryChange(p_48)
                      }}
                      disabled={summaryRight ? isBlocked(p_38) : summaryRight2 ? isBlocked(p_48) : false}
                    />
                  </Form.Item>
                </div>

                <div className="info">
                  <img src={infoIcon} data-tip={secondColName.toUpperCase() + ": " + SFD} />
                </div>
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}

export default TabRenderer
