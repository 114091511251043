import React, { useContext, useEffect, useState } from "react"
import { DispatchContext, StateContext } from "components/userContext"

import BasicEdit from "../SalesTab/BasicEdit"
import TransactionEdit from "../SalesTab/TransactionEdit"

import ProcedureEdit from "../SalesTab/ProcedureEdit"
import { Tabs, Form } from "antd"
import API from "components/common/api"

import ReactTooltip from "react-tooltip"
import { Loading } from "components/Main/Loading"

const { TabPane } = Tabs

export const firstTab = [
  "kod_kraju_nadania_tin",
  "dowod_zakupu",
  "nr_dostawcy",
  "data_zakupu",
  "nazwa_dostawcy",
  "data_wplywu",
  "clear",
  "dokument_zakupu",
]

export const secondTab = ["k_40", "k_41", "k_42", "k_43", "k_44", "k_45", "k_46", "k_47"]

export const thirdTab = [
  "b_mpv_prowizja",
  "b_spv",
  "b_spv_dostawa",
  "ee",
  "i_42",
  "i_63",
  "mpp",
  "mr_t",
  "mr_uz",
  "sw",
  "tp",
  "tt_d",
  "tt_wnt",
]

export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const EditShopping = ({ shoppingEditForm, mainId, formVariant }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  useEffect(() => {
    return () => {
      dispatch({ type: "handleClearEditSells" })
    }
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const fixedValues = {}

      Object.keys(values).map((key) => {
        if (typeof values[key] === "boolean") {
          fixedValues[key] = values[key] ? 1 : null
        } else {
          if ((key.includes("k_") || key === "zakup_vat_marza") && key !== "nr_dok_wew") {
            fixedValues[key] = values[key] === "" ? null : values[key] ? parseFloat(values[key]) : values[key]
          } else {
            fixedValues[key] = values[key]
          }
        }
      })

      const data = {
        ...fixedValues,
        id_ewidencja_vat: parseInt(mainId),
        id: state.shoppingValues.id,
        lp_zakupu: state.shoppingValues.lp_zakupu,
      }

      const response = await API.post(`/VatRecordPurchase/updateVatRecordPurchase`, data, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid"),
        },
      })

      setLoading(false)

      if (response.status === 200) {
        dispatch({ type: "handleReturnSaveEdit", payload: "3" })
        dispatch({
          type: "handleState",
          payload: true,
          field: "refreshDetails",
        })
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const onFinishFailed = (err) => {
    const formWrap = document.getElementById("SalesEdit")
    const [scollableForm] = formWrap.getElementsByClassName("ant-tabs")

    const errorTabs = []

    err.errorFields.map((e) => {
      firstTab.map((tabName) => {
        if (e.name.includes(tabName)) {
          errorTabs.push(1)
        }
      })

      secondTab.map((tabName) => {
        if (e.name.includes(tabName)) {
          errorTabs.push(2)
        }
      })

      thirdTab.map((tabName) => {
        if (e.name.includes(tabName)) {
          errorTabs.push(3)
        }
      })
    })

    scollableForm.scrollTo(0, 0)
    dispatch({
      type: "handleState",
      field: "tabsWithError",
      payload: [...new Set(errorTabs)],
    })
  }

  if (loading) return <Loading />

  const hasError = (id) => state.tabsWithError.includes(id)

  return (
    <div className="edit-sales">
      <ReactTooltip
        effect="solid"
        className="tooltip-vat"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />
      <Form
        {...layout}
        name="SalesEdit"
        className="sales-edit-form"
        initialValues={state.shoppingValues}
        ref={shoppingEditForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
      >
        <Tabs
          activeKey={state.activeEditKey || "1"}
          onTabClick={(e) =>
            dispatch({
              type: "handleState",
              field: "activeEditKey",
              payload: e,
            })
          }
        >
          <TabPane tab={<span className={hasError(1) ? "hasError" : ""}>Dane podstawowe</span>} key="1" forceRender>
            <div className="edit-wrapper">
              <div className="details-item custom">
                <span className="ant-form-item-label">Lp.</span>
                <span>{state.shoppingElem.lp_zakupu}</span>
              </div>
              <div className="details-box-default">
                <BasicEdit
                  sellEditForm={shoppingEditForm}
                  purchase
                  sortedKeys={[
                    "kod_kraju_nadania_tin",
                    "dowod_zakupu",
                    "data_zakupu",
                    "nr_dostawcy",
                    "nr_dok_wew",
                    "data_wplywu",
                    "nazwa_dostawcy",
                    "dokument_zakupu",
                    "clear",
                  ]}
                  options={["MK", "VAT_RR", "WEW"]}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab={<span className={hasError(2) ? "hasError" : ""}>Rodzaj transakcji</span>} key="2" forceRender>
            <div className="edit-wrapper">
              <div className="details-box-default">
                <TransactionEdit sellEditForm={shoppingEditForm} purchase />
              </div>
            </div>
          </TabPane>
          <TabPane tab={<span className={hasError(4) ? "hasError" : ""}> Procedury</span>} key="3" forceRender>
            <div className="edit-wrapper">
              <ProcedureEdit sellEditForm={shoppingEditForm} purchase formVariant={formVariant} />
            </div>
          </TabPane>
        </Tabs>
      </Form>
    </div>
  )
}

export default EditShopping
