import React, { useEffect } from "react"
import { codeCountry } from "../../../components/Register/subcomponents/data"

import { Select, Input, DatePicker, Form, Space } from "antd"


import moment from "moment"
import "moment/locale/pl"

import locale from "antd/es/date-picker/locale/pl_PL"

import ReactTooltip from "react-tooltip"

import infoIcon from "../../../images/info.svg"

const { Option } = Select
const dateFormat = "DD.MM.YYYY"

const objToMap = {
  data_sprzedazy: "Data sprzedaży",
  dowod_sprzedazy: "Numer dokumentu",
  data_wystawienia: "Data wystawienia",
  kod_kraju_nadania_tin: "Kod kraju",
  nazwa_kontrahenta: "Nazwa kontrahenta",
  nr_kontrahenta: "Numer kontrahenta",
  typ_dokumentu: "Typ dokumentu",
  nr_dok_wew: "Nr dokumentu wewnętrznego",
  clear: "",
  dowod_zakupu: "Numer dokumentu",
  data_zakupu: "Data zakupu",
  data_wplywu: "Data otrzymania",
  nazwa_dostawcy: "Nazwa kontrahenta",
  nr_dostawcy: "Numer kontrahenta",
  clear: "",
  dokument_zakupu: "Typ dokumentu",
}

const BasicAdd = ({
  addForm,
  sortedKeys = [
    "kod_kraju_nadania_tin",
    "dowod_sprzedazy",
    "data_wystawienia",
    "nr_kontrahenta",
    "nr_dok_wew",
    "data_sprzedazy",
    "nazwa_kontrahenta",
    "typ_dokumentu",
    "clear",
  ],
  options = ["RO", "WEW", "FP"],
}) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const handleDate = (m, j, name) => {
    addForm.current.setFields([
      {
        name,
        value: m ? m.utc().format() : null,
      },
    ])
  }

  return (
    <>
      <ReactTooltip
        effect="solid"
        className="tooltip-vat"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />
      {sortedKeys.map((key) => {
        if (key === "clear") return <span key={key} className="details-item"></span>

        if (key === "kod_kraju_nadania_tin") {
          return (
            <Form.Item
              key={key}
              hasFeedback
              name={key}
              validateTrigger="onBlur"
              rules={rules[key]}
              className="details-item"
              label={objToMap[key]}
            >
              <Select className="dropdown-select" showSearch style={{ minWidth: "100px" }}>
                {codeCountry.sort().map((country) => (
                  <Option value={country} key={country}>
                    {country}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )
        }

        if (key === "typ_dokumentu" || key === "dokument_zakupu") {
          return (
            <Form.Item
              key={key}
              hasFeedback
              validateTrigger="onBlur"
              rules={rules[key]}
              className="details-item"
              label={objToMap[key]}
              name={key}
            >
              <Select allowClear className="dropdown-select">
                {options.map((o) => (
                  <Option key={o} value={o}>
                    {o}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )
        }

        if (key.includes("data")) {
          return (
            <Form.Item
              key={key}
              hasFeedback
              validateTrigger="onBlur"
              rules={rules[key]}
              className="details-item"
              label={objToMap[key]}
              name={key}
            >
              <Space direction="vertical">
                <DatePicker
                  name={key}
                  locale={locale}
                  onChange={(m, _, e) => handleDate(m, e, key)}
                  format={dateFormat}
                  placeholder="DD/MM/RRRR"
                  showToday={false}
                  defaultPickerValue={moment(new Date(), dateFormat)}
                />
              </Space>
            </Form.Item>
          )
        }

        if (key === "nr_dok_wew") {
          return (
            <Form.Item
              key={key}
              hasFeedback
              validateTrigger="onBlur"
              rules={rules[key]}
              className="details-item"
              label={objToMap[key]}
              name={key}
            >
              <Input
                type="text"
                name={key}
                suffix={
                  <img
                    src={infoIcon}
                    alt="info"
                    data-tip="Pole do użytku wewnętrznego, na potrzeby referencji do dokumentów z systemów księgowo-rachunkowych"
                  />
                }
              />
            </Form.Item>
          )
        }

        return (
          <Form.Item
            key={key}
            hasFeedback
            validateTrigger="onBlur"
            rules={rules[key]}
            className="details-item"
            label={objToMap[key]}
            name={key}
          >
            <Input type="text" name={key} />
          </Form.Item>
        )
      })}
    </>
  )
}

const rules = {
  // kod_kraju_nadania_tin: [
  //   {
  //     required: true,
  //     message: "Podaj kod kraju!",
  //   },
  // ],
  dowod_sprzedazy: [
    {
      required: true,
      message: "Podaj numer dokumentu!",
    },
  ],
  data_wystawienia: [
    (e) => ({
      required: true,
      validator() {
        const dob = e.getFieldValue("data_wystawienia")
        if (dob && dob.length >= 10) {
          return Promise.resolve()
        }

        return Promise.reject("Podaj datę wystawienia!")
      },
    }),
  ],
  // data_wplywu: [
  //   (e) => ({
  //     required: true,
  //     validator() {
  //       const dob = e.getFieldValue("data_wplywu")
  //       if (dob && dob.length >= 10) {
  //         return Promise.resolve()
  //       }

  //       return Promise.reject("Podaj date otrzymania!")
  //     },
  //   }),
  // ],
  data_zakupu: [
    (e) => ({
      required: true,
      validator() {
        const dob = e.getFieldValue("data_zakupu")
        if (dob && dob.length >= 10) {
          return Promise.resolve()
        }

        return Promise.reject("Podaj datę zakupu!")
      },
    }),
  ],
  nr_kontrahenta: [
    {
      required: true,
      message: "Podaj numer kontrahenta!",
    },
  ],
  nr_dostawcy: [
    {
      required: true,
      message: "Podaj numer kontrahenta!",
    },
  ],
  nazwa_kontrahenta: [
    {
      required: true,
      message: "Podaj nazwę kontrahenta!",
    },
  ],
  nazwa_dostawcy: [
    {
      required: true,
      message: "Podaj nazwę kontrahenta!",
    },
  ],
  dowod_zakupu: [
    {
      required: true,
      message: "Podaj numer dokumentu!",
    },
  ],
}

export default BasicAdd
