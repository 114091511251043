import React from "react"

import backIcon from "images/back_circle.svg"

const SalesEditNav = ({ getTitle, exit }) => {
  return (
    <div className="VatDetails-navbar">
      <div className="left-panel">
        {/* <div className="arrow-wrap" onClick={exit}>
          <img src={backIcon} alt="powrót do listy ewidencji" />
        </div> */}
        <h3 style={{ width: "auto", textTransform: "inherit" }}>{getTitle()}</h3>
      </div>
    </div>
  )
}

export default SalesEditNav
