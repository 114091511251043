import React, { useEffect } from "react"
import API from "../common/api"

import Spinner from "../common/Spinner/Spinner"

import { Center } from "../common/Styled/helpers"

const queryString = require("query-string")

// password-changed route
export const ChangePassLoginUser = () => {
  useEffect(() => {
    const loginUser = async () => {
      try {
        const { redirect_url, access_token: accessToken, refresh_token: refreshToken } = queryString.parse(
          window.location.search
        )

        const response = await API.post(
          `/Users/autologon`,
          { refreshToken },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )

        const { token: accessTokenNew, refreshToken: refreshTokenNew, roles, username } = response.data.stateObject

        localStorage.setItem("token", accessTokenNew)
        localStorage.setItem("refresh_token", refreshTokenNew)
        localStorage.setItem("userRole", roles[0])
        localStorage.setItem("userEmail", username)

        return (window.location.href = `${window.location.origin}/${redirect_url}`)
      } catch (error) {
        // HANDLE EXPIRED LINK
        window.location.href = "/link-expired"
        console.error(error)
      }
    }

    loginUser()
  }, [])

  return (
    <Center>
      <Spinner />
    </Center>
  )
}
