import React, { useContext, useEffect, useState, useMemo, useRef } from "react"
import { Modal, Input, Button } from "antd"
import FormModal from "components/common/FormModal"
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"

import { debounce } from "lodash"

import ReactTooltip from "react-tooltip"

import CustomPagination from "components/CustomPagination/CustomPagination"

import NipVerifierRowDetails from "./NipVerifierRowDetails"
import { getVerificationFilter, getImportSourceFilter, getDeleteEntitiesArray, getExceptedIds } from "../UrlHelper"
import { getFileNameFromContentDisposition } from "helpers/httpHelpers"

import DownloadTemplateModal from "./DownloadTemplateModal"
import SelectVerificationModal from "../Actions/Verification/SelectVerificationModal"

import { columns, originalHandleClick } from "./data"

import API from "components/common/api"
import Context from "context"
import { DispatchContext, StateContext } from "components/userContext"

import templateIcon from "images/szablon.svg"
import importIcon from "images/import.svg"
import verifyIcon from "images/weryfikuj.svg"
import reportIcon from "images/eksport.svg"
import historyIcon from "images/historia.svg"
import deleteIcon from "images/usun.svg"
import closeIcon from "images/close-menu.svg"

import ReactGA from "react-ga"


import { Loading } from "components/Main/Loading"
import { tracker } from "helpers/tracker"

import { useRowsSelector, shouldShowRowDetailsHelper, setSelectedSingleItemWhenSelectAllClicked } from "helpers/rowsSelector"

import "../../Tables/ReactTable.less"
import "./NipVerifier.less"
import { stat } from "fs"


const { Search } = Input

const downloadTemplateFile = async (csv) => {
  let url = "/NipVerification/Template"
  if (csv) {
    url += "?type=csv"
  }
  await API
    .request({
      url: url,
      method: "GET",
      responseType: 'blob', //important
    })
    .then((response) => {
      if (response.status !== 200) return
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      let fileName = getFileNameFromContentDisposition(response.headers["content-disposition"])
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
}

// filter after import

export const setNipVerifierLastImportData = (state, dispatch, lastImportData) => {
  const filtered = [...state.nipTableOptions.filtered]
  let filteredImportIds = {}
  let filteredVerificationState = { ...state.nipTableOptions.filteredVerificationState }
  Object.keys(filteredVerificationState).forEach(key => {
    filteredVerificationState[key] = { ...filteredVerificationState[key], selected: false }
  });
  const fixedDate = lastImportData.import_date.substring(0, 27)
  filteredImportIds[fixedDate] = lastImportData.data_source

  dispatch({
    type: "handleState",
    field: "nipTableOptions",
    payload: { ...state.nipTableOptions, filtered, filteredVerificationState, filteredImportIds },
  })
}

const NipVerifierTable = (props) => {
  const dispatch = useContext(DispatchContext)
  const state = useContext(StateContext)

  const [loading, setLoading] = useState(true)

  const { ctx, setCtx } = useContext(Context)

  const [selectedSingleItem, setSelectedSingleItem] = useState({})

  const [delModal, setDelModal] = useState({ visible: false })

  const [verificationModal, setVerificationModal] = useState({ visible: false })

  const [downloadTemplateModal, setDownloadTemplateModal] = useState({ visible: false })

  const [anyDownloadTypeSelected, setAnyDownloadTypeSelected] = useState(false)

  const [csvTemplateTypeSelected, setCsvTemplateTypeSelected] = useState(false)

  const [filtersState, setFilterState] = useState({})

  const [reportInProgress, setReportInProgress] = useState(false)

  const [verificationInProgress, setVerificationInProgress] = useState(false)

  const fileRef = useRef(null)

  const selector = useRowsSelector(() => state.nipTableOptions.rowCount)

  let [currentPageIndex, setCurrentPageIndex] = useState(0)

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [window.location.search])

  useEffect(() => {
    selector.deselectAllRows()
  }, [state.nipTableOptions.filtered, state.nipTableOptions.filteredVerificationState, state.nipTableOptions.filteredImportIds])

  useEffect(() => {
    setSelectedSingleItemWhenSelectAllClicked(selector, state.nipData, selectedSingleItem, setSelectedSingleItem)
  }, [selector.allSelectedMode])

  const debounceHanlderInput = useMemo(() => debounce(() => handleFillterAll(inputRef.current.input.value), 800))

  const inputRef = useRef(null)

  useEffect(() => {
    let searchValue = state.nipTableOptions.filtered.find(x => x.id === "all")
    if (searchValue !== undefined) {
      inputRef.current.state.value = searchValue.value
    }
  }, [])

  const handleFillterAll = (elem) => {
    const filtered = [{ id: "all", value: elem }]
    dispatch({
      type: "handleState",
      field: "nipTableOptions",
      payload: { ...state.nipTableOptions, filtered },
    })
  }

  const getFilterQuery = () => {
    const filter = state.nipTableOptions.filtered.length > 0 ? state.nipTableOptions.filtered[0].value : ""
    const verificationFilterQuery = [
      filter !== "" ? "search@=" + filter : "",
      getVerificationFilter(state.nipTableOptions.filteredVerificationStateMf, "verificationMf"),
      getVerificationFilter(state.nipTableOptions.filteredVerificationStateVies, "verificationVies")
    ].filter(x => x.length > 0)

    let filterQuery = ""
    if (verificationFilterQuery.length > 0) {
      filterQuery = "&filters=" + verificationFilterQuery.join(",")
    }

    const importSourceQuery = getImportSourceFilter(state.nipTableOptions.filteredImportIds)
    if (importSourceQuery !== "") {
      if (filterQuery !== "") {
        filterQuery = filterQuery + "&"
      }
      filterQuery = filterQuery + importSourceQuery
    }

    if (selector.allSelectedMode && selector.markedRows.length > 0) {
      const exceptIds = getExceptedIds(selector.markedRows)
      if (filterQuery !== "") {
        filterQuery = filterQuery + "&"
      }
      filterQuery = filterQuery + exceptIds
    }

    return filterQuery
  }

  const fetchSortData = (sortData) => {
    setLoading(true)
    selector.resetLastClick()
    const filterQuery = getFilterQuery()
    const url = `/NipVerification/Entities?sorts=${sortData.sortedValue}&page=${sortData.innerPage + 1}&pageSize=${sortData.innerPageSize}&${filterQuery}`
    API.get(url)
      .then(
        ({
          data: {
            stateObject: { results: stateObject, ...tableParams },
          },
        }) => {
          if (stateObject.length === 0 && sortData.innerPage > 0) { 
            fetchSortData({ ...sortData, innerPage: sortData.innerPage - 1 })
          }
          else{
            dispatch({
              type: "handleState",
              field: "nipData",
              payload: stateObject,
            })
            dispatch({
              type: "handleState",
              field: "nipTableOptions",
              payload: {
                ...state.nipTableOptions,
                ...tableParams,
                currentPage: sortData.innerPage + 1,
                pageSize: sortData.innerPageSize
              },
            })
            setLoading(false)
          }
        }
      )
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const fetchLastImports = () => {
    return API.get(`NipVerification/UserImports?page=1&pageSize=5&sorts=&data_source`)
      .then((res) => {
        dispatch({
          type: "handleState",
          field: "nipLastImports",
          payload: res.data.stateObject.results
        })
      })
      .catch(e => {
        console.log(e)
      })
  }


  // Get last imports 
  useEffect(() => {
    if (state.nipLastImports === null) {
      fetchLastImports()
    }
  }, [])

  const downloadTemplate = async () => {
    setDownloadTemplateModal({ visible: true })
  }

  const importFile = async (event) => {
    tracker("Weryfikacja NIP - Import")
    const data = new FormData()
    const fileTosend = Array.from(event.target.files)
    if (fileTosend.length === 0) return
    const [file] = fileTosend
    data.append("file", fileTosend[0], fileTosend[0].name)

    await API.post("/NipVerification/Import", data, {
      headers: {
        "Content-Type": `multipart/form-data boundary=${data._boundary}`,
      },
    }).then(() => {
      // fetch after import is now triggered by notification
      // fetchSortData(filtersState)
      // fetchLastImports()
    }).catch(() => {

    })
  }

  const askDeleteSelected = async () => {
    if (selector.countSelected() === 0) return
    setDelModal({ visible: true, data: selector.markedRows })
  }

  const deleteSelected = async () => {
    let url = ""
    if (selector.allSelectedMode) {
      url = "/NipVerification/EntitiesByFilter?" + getFilterQuery()
    } else {
      url = "/NipVerification/Entities?" + getDeleteEntitiesArray(selector.markedRows)
    }
    API.delete(url)
      .then(() => {
        fetchSortData(filtersState)
      })
    setDelModal({ visible: false })
    selector.deselectAllRows()
  }

  const getReport = (ids, CheckupStatusDataFilter, VerificationKindDataFilter, ignoreSelectionMode) => {
    setReportInProgress(true)
    let url = ""
    if (selector.allSelectedMode && !ignoreSelectionMode) {
      url = "/NipVerification/ReportByFilter?" + getFilterQuery()
    } else {
      url = "/NipVerification/Report?"
    }
    API.post(url, {
      ids,
      CheckupStatusDataFilter,
      VerificationKindDataFilter
    }, {
      responseType: 'blob', //important
    }).then((response) => {
      if (response.status !== 200) return
    }).finally(() => {
      setReportInProgress(false)
    })
  }

  const showHistory = () => {
    setCtx({ ...ctx, currentContractor: selectedSingleItem })
    props.showReport()
  }


  const executeVerification = (selectedMethods) => {
    setVerificationModal({ visible: false })
    const now = new Date().toISOString()
    setVerificationInProgress(true)
    let url = ""
    if (selector.allSelectedMode) {
      url = "/NipVerification/VerifyByFilter?" + getFilterQuery()
    } else {
      url = "/NipVerification/Verify"
    }
    API.post(url, {
      ids: selector.allSelectedMode ? [] : selector.markedRows,
      verificationDate: now,
      mfVerification: selectedMethods.mf,
      viesVerification: selectedMethods.vies,
    }).then(() => {
      fetchSortData(filtersState)
    }).finally(() => {
      setVerificationInProgress(false)
    })

  }

  useEffect(() => {
    let clearEvent = false
    if (state.notificationEvent && state.notificationEvent.actionName === "CreateVerificationReport") {
      getReport(state.notificationEvent.args.Ids,
        state.notificationEvent.args.CheckupStatusDataFilter,
        state.notificationEvent.args.VerificationKindDataFilter,
        true)
      clearEvent = true
    }
    if (clearEvent) {
      dispatch({
        type: "handleState",
        field: "notificationEvent",
        payload: null
      })
    }
  }, [state.notificationEvent])


  const shouldShowRowDetails = () => {
    return shouldShowRowDetailsHelper(selector, state.nipData)
  }

  useEffect(() => {
    if (state.commandEvent && state.commandEvent.actionName === "nip_fetch_data") {
      fetchLastImports()
      fetchSortData(filtersState)
    }
  }, [state.commandEvent])

  useEffect(() => {
    let clearEvent = false
    if (state.notificationEvent && state.notificationEvent.actionName === "NipImportSuccessful") {
      setNipVerifierLastImportData(state, dispatch, state.notificationEvent.args)
      clearEvent = true
    }
    if (clearEvent) {
      dispatch({
        type: "handleState",
        field: "notificationEvent",
        payload: null
      })
    }
  }, [state.notificationEvent])


  return (
    <div className="table-wrapper">
      <div className="vatTableContainer content-wrapper inner-shadow">


        <ReactTooltip
          effect="solid"
          className="tooltip"
          backgroundColor="#fff"
          textColor="#163567"
          borderColor="#163567"
          border
          html
        />

        <div className="table-action-section">
          <div className="search-input" style={{ display: "flex" }}>
            <Search
              allowClear
              placeholder={null}
              onSearch={debounceHanlderInput}
              onChange={debounceHanlderInput}
              style={{ width: 169, height: 38 }}
              ref={inputRef}
              className="broken-search"
            />
          </div>

          <div className="action-section-buttons">

            <button onClick={downloadTemplate}
              disabled={false}
            >
              <img src={templateIcon} alt="" />
              szablon
            </button>

            <button>
              <div>
                <input
                  ref={fileRef}
                  id="import-nip"
                  onChange={importFile}
                  type="file"
                  accept=".csv,.xlsx"
                />
                <label
                  htmlFor="import-nip"
                  onClick={() => {

                  }}
                >
                  <img src={importIcon} alt="import button" />
                  import
                </label>
              </div>
            </button>

            <div className="dash" />

            <button onClick={() => {
              setVerificationModal({ visible: true })
              tracker("Weryfikacja NIP - Weryfikuj")
            }}
              disabled={verificationInProgress || selector.countSelected() === 0}>
              <img src={verifyIcon} alt="" />
              weryfikuj
            </button>

            <button onClick={() => getReport(selector.markedRows, 0, 0, false)} disabled={reportInProgress || selector.countSelected() === 0}>
              <img src={reportIcon} alt="" />
              raport
            </button>

            <div className="dash" />

            <button onClick={() => showHistory()} disabled={selector.countSelected() !== 1}>
              <img src={historyIcon} alt="" />
              historia
            </button>

            <button onClick={askDeleteSelected} disabled={selector.countSelected() === 0}>
              <img src={deleteIcon} alt="" />
              usuń
            </button>
          </div>
        </div>

        <div className="NipVerifierEntityPanel">
          <div className="NipVerifierSelectionBox">
            {selector.countSelected() === 0 && <div className="NipVerifierSelectionButton noselect" onClick={() => {
              selector.selectAllRows()
            }}>Zaznacz wszystko</div>}
            {selector.countSelected() !== 0 && <>
              <div className="NipVerifierSelectionButton noselect" onClick={selector.deselectAllRows}>Odznacz wszystko</div>
              <div className="NipVerifierSelectionCounter">{"Zaznaczono: " + selector.countSelected()}</div></>
            }
          </div>
          {/* del record modal */}
          {delModal.visible ? (
            <FormModal
              visible={delModal.visible}
              content={`Czy na pewno usunąć wskazanych kontrahentów ?`}
              onAccept={deleteSelected}
              onDecline={() => setDelModal({ visible: false })}
              addClass="delModal"
              okButtonName="Usuń"
            />
          ) : null}

          {/* download template modal */}
          {downloadTemplateModal.visible ? (
            <FormModal
              visible={downloadTemplateModal.visible}
              acceptDisabled={!anyDownloadTypeSelected}
              hasForm={<DownloadTemplateModal
                setAnyDownloadTypeSelected={setAnyDownloadTypeSelected}
                setCsvTemplateTypeSelected={setCsvTemplateTypeSelected}
              />}
              onAccept={() => {
                Modal.destroyAll()
                downloadTemplateFile(csvTemplateTypeSelected)
                setAnyDownloadTypeSelected(false)
                setDownloadTemplateModal({ visible: false })
              }}
              onDecline={() => {
                setAnyDownloadTypeSelected(false)
                setDownloadTemplateModal({ visible: false })
              }}
              addClass="templateModal"
              okButtonName="Pobierz"
            />
          ) : null}

          {/* Verification method modal */}
          {verificationModal.visible ? (<SelectVerificationModal
            onDecline={() => setVerificationModal({ visible: false })}
            onAccept={(selected) => { executeVerification(selected) }}
          ></SelectVerificationModal>) : null}

          <div className="NipVerifierTableWithDetails">
            <ReactTable
              manual
              className="vatTable wlTable_records"
              data={state.nipData}
              columns={columns.filter(c => c.showWithDetails === true || (selector.countSelected() !== 1))}
              multiSort={false}
              loading={loading}
              noDataText={""}
              NoDataComponent={() => null}
              LoadingComponent={(props) => {
                if (props.loading) {
                  return <Loading absolute />
                }
                return <></>
              }}
              resizable={false}
              PaginationComponent={(params) => (
                <CustomPagination currentPage={state.nipTableOptions.currentPage} {...params} />
              )}
              expanded={state.nipTableOptions.expanded}
              //page={currentPageIndex}
              onPageChange={newPage => {
                currentPageIndex = newPage
                setCurrentPageIndex(newPage)
              }}
              pages={state.nipTableOptions.totalPagesNumber}
              defaultPageSize={10}
              filtered={state.nipTableOptions.filtered}
              onFetchData={(innerState) => {
                setLoading(true)

                const filterValue = (innerState.filtered.length && innerState.filtered[0].value) || ""
                const sortedValue = innerState.sorted.length === 1 ?
                  (innerState.sorted[0].desc ? "-" : "") + innerState.sorted[0].id : ""
                const filters = {
                  filterValue,
                  sortedValue,
                  innerPage: currentPageIndex,
                  innerPageSize: innerState.pageSize,
                }
                setFilterState(filters)
                fetchSortData(filters)
              }}
              getTdProps={(tabeState, rowInfo, column, instance) => {
                return {
                  onClick: ({ shiftKey, ctrlKey }) => {
                    if (typeof rowInfo !== "undefined") {
                      if (column.id) {
                        let rowId = rowInfo.original.id
                        selector.onRowClick(rowId,
                          state.nipData ? state.nipData.map(x => x.id) : []
                          , shiftKey, ctrlKey)
                      }
                      setSelectedSingleItem(rowInfo.original);
                    }
                  },
                }
              }}
              getTrProps={(_, rowInfo) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    style: {
                      fontWeight: (selector.isSelected(rowInfo.original.id)) && "bold",
                      background: (selector.isSelected(rowInfo.original.id)) && "#f4f4f4",
                    },
                  }
                } else {
                  return {
                    onClick: originalHandleClick,
                  }
                }
              }}
            />

            {shouldShowRowDetails() && (
              <div className="NipDetails">
                <NipVerifierRowDetails row={selectedSingleItem}></NipVerifierRowDetails>
                <Button className="closeButton" onClick={() => {
                  selector.deselectAllRows()
                }}><img src={closeIcon} alt="" /></Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NipVerifierTable