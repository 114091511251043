import React from "react"

import "./IconBox.less"

const IconBox = ({ name, icon, click, disabled }) => {
  return (
    <button disabled={disabled} className="icon-box" onClick={!disabled ? click : null}>
      <img src={icon} alt="image" />
      <p>{name}</p>
    </button>
  )
}

export default IconBox
