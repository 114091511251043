import { PasswordPattern, PasswordTip } from "helpers/password"

const Rules = {
  name: [
    {
      whitespace: true,
      required: true,
      message: "Podaj swoje imię!",
    },
  ],
  surname: [
    {
      whitespace: true,
      required: true,
      message: "Podaj swoje nazwisko!",
    },
  ],
  email: [
    {
      required: true,
      message: "Podaj swój e-mail!",
    },
    {
      type: "email",
      message: "Wprowadzony e-mail jest niepoprawny!",
    },
  ],
  password: [
    {
      required: true,
      message: "Wprowadź swoje hasło!",
    },
    {
      pattern: PasswordPattern,
      message: PasswordTip
    },
  ],
  phone: [
    {
      required: true,
      message: "Podaj swój numer telefonu!",
    },
    {
      pattern: /(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/,
      message: "Numer telefonu jest niepoprawny!",
    },
  ],
  nip: [
    {
      required: true,
      message: "Podaj swój NIP!",
    },
    {
      // eslint-disable-next-line
      pattern: /[0-9\-]{13}/g,
      message: "NIP musi się składać z 10 cyfr!",
    },
  ],
  organization_name: [
    {
      whitespace: true,
      required: true,
      message: "Podaj nazwę firmy!",
    },
  ],
  address: [
    {
      whitespace: true,
      required: true,
      message: "Podaj adres firmy!",
    },
  ],
  post_code: [
    { required: true, message: "Podaj kod pocztowy!" },
    {
      // eslint-disable-next-line
      pattern: /[0-9\-]{6}/g,
      message: "Niepoprawny kod pocztowy!",
    },
  ],
  city: [{ whitespace: true, required: true, message: "Podaj miejscowość!" }],
  country: [
    {
      required: true,
      message: "Podaj swój kraj!",
    },
  ],
}

export default Rules
