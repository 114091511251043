import React from "react"

import Button from "components/common/Button"

import "./Notifications.less"

const NotificationsModal = ({ title, messageLine1, messageLine2, fileLines, contentLines, fileName, closeModal, borderColor, titleColor }) => {
  const showContent = (error, idx) => <li key={idx}>{error}</li>

  // save content to txt file
  const saveFile = () => {
    const element = document.createElement("a")
    const file = new Blob([fileLines], { type: "text/plain" })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()

    closeModal()
  }

  let borderStyle = {}
  if (borderColor !== undefined) {
    borderStyle = {
      borderColor: borderColor
    }
  }

  let titleStyle = {}
  if (titleColor !== undefined) {
    titleStyle = {
      color: titleColor
    }
  }

  return (
    <div className="Modal-box">
      <div className="Modal-inner">
        <div className="Modal-content" style={borderStyle}>
          <h3 style={titleStyle}>{title}</h3>
          <p>{messageLine1}</p>
          {messageLine2 && <p>{messageLine2}</p>}
          <ul>{contentLines.map(showContent)}</ul>
          <div className="buttons-box">
            <Button
              name="Zamknij"
              type="button"
              styling="button-small-white"
              onClick={closeModal}
              txtColor="#4b6584"
            />
            <Button
              name="Zapisz"
              type="submit"
              styling="button-small"
              onClick={saveFile}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationsModal
