export const VerificationStatusMap = {
    0: { name: "-", style: "" },
    1: { name: "błąd weryfikacji", style: "wl-status-error" },
    2: { name: "zgodny", style: "wl-status-right" },
    3: { name: "niezgodny", style: "wl-status-wrong" }
}

export const AllVerificationStatus = {
    "Unexecuted": { value: 0, selected: false },
    "Error": { value: 1, selected: false },
    "Right": { value: 2, selected: false },
    "Wrong": { value: 3, selected: false }
}

export const VerificationReasonMap = {
    0: "Automatyczna",
    1: "NIP",
    2: "REGON"
}