export const getUsersFilter = options => {
    const entries = Object.entries(options)
    let allTrue = true
    if (entries !== undefined && entries.length > 0) {
        let selectedIndices = []
        for (let i = 0; i < entries.length; i++) {
            const element = entries[i][1];
            if (element.selected) {
                selectedIndices.push(element.value)
            } else {
                allTrue = false
            }
        }
        if (!allTrue && selectedIndices.length > 0) {
            return "userId==" + selectedIndices.join("|")
        }
    }
    return "";
}

export const getOperationStatusesFilter = options => {
    const entries = Object.entries(options)
    let allTrue = true
    if (entries !== undefined && entries.length > 0) {
        let selectedIndices = []
        for (let i = 0; i < entries.length; i++) {
            const element = entries[i][1];
            if (element.selected) {
                selectedIndices.push(element.value)
            } else {
                allTrue = false
            }
        }
        if (!allTrue && selectedIndices.length > 0) {
            return "status==" + selectedIndices.join("|")
        }
    }
    return "";
}

export const getFilterSegment = (suffix, elements) => {
    if (elements === null || elements.length === 0) return ""
    return suffix + "==" + elements.join("|")
}

export const getDateTimeRangeFilter = (filterDateTimeFrom, filterDateTimeUntil) => {
    var segments = []
    if (filterDateTimeFrom !== null && filterDateTimeFrom !== undefined) {
        const fromStr = filterDateTimeFrom.toISOString()
        segments.push("filterDateTimeFrom=" + encodeURIComponent(fromStr))
    }
    if (filterDateTimeUntil !== null && filterDateTimeUntil !== undefined) {
        const toStr = filterDateTimeUntil.toISOString()
        segments.push("filterDateTimeUntil=" + encodeURIComponent(toStr))
    }
    return segments.join("&")
}
