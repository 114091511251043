import React, { useEffect, useState, useRef } from "react"

import { Form, Input, Checkbox } from "antd"
import API, { isProductionEnvironment } from "components/common/api"

import FormButton from "../../components/common/FormButton"
import MaskedInput from "antd-mask-input"
import FormModal from "../../components/common/FormModal"
import Countries from "../Register/subcomponents/Countries"

import "./UserSetup.less"
import "../../components/common/common.less"
import LeftSide from "components/common/LeftSide"

import { PasswordPattern, PasswordTip } from "helpers/password"

import ReactGA from "react-ga"

const queryString = require("query-string")
const UserSetup = () => {
  const { organization, token, username } = queryString.parse(window.location.search)
  const [f, setF] = useState(false)
  const [initial, setInitial] = useState({ email: "ccvskdeffs@es.pl", insurance_consent: false })
  const [country, setCountry] = useState()

  const [insuranceConsent, setInsuranceConsent] = useState(false)
  const [insuranceConsentModal, setInsuranceConsentModal] = useState(false)
  const [insuranceConsentVisible, setInsuranceConsentVisible] = useState(false)
  const [insuranceConsentSelected, setInsuranceConsentChosen] = useState(false)

  const form = useRef(null)

  useEffect(() => {
    if (isProductionEnvironment) {
      ReactGA.initialize("UA-185941953-4")
    }

    ReactGA.pageview(window.location.pathname)
    ReactGA.set({ anonymizeIp: true })

    const fetchInitialData = async () => {
      try {
        const response = await API.post(`/Users/addUserToOrganizationCallBack`, {
          organization,
          code: token,
          user: username,
        })

        if (response.status === 200) {
          setInitial(response.data.stateObject)
          setInsuranceConsentVisible(response.data.stateObject.insurance_consent_visible)
          setF(true)
        }

        if (response.status == 400) {
          // window.location.href = "/login"
          alert(response.data.errors[0])
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchInitialData()
  }, [])

  const handleAutoLogon = async (data) => {
    const { redirect_url, access_token: accessToken, refresh_token: refreshToken } = data

    const response = await API.post(
      `/Users/autologon`,
      { refreshToken },
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    )
    if (!response.data.stateObject) {
      return alert(response.data.errors[0])
    }

    const { token: accessTokenNew, refreshToken: refreshTokenNew, roles, username } = response.data.stateObject

    localStorage.setItem("token", accessTokenNew)
    localStorage.setItem("refresh_token", refreshTokenNew)
    localStorage.setItem("userRole", roles[0])
    localStorage.setItem("userEmail", username)

    return (window.location.href = redirect_url)
  }

  const handleFinish = async (values) => {
    if (!insuranceConsentSelected && insuranceConsentVisible && !insuranceConsent) {
      setInsuranceConsentModal(true)
      return
    }

    try {
      const route = organization ? `/Users/editUserOrganizationCallBack` : `/Users/editUserCallBack`

      const response = await API.post(route, { ...values, code: token, user: username, organization })

      if (response.status === 200) {
        handleAutoLogon(response.data.stateObject)
        setInsuranceConsentModal(false)
        setInsuranceConsentChosen(false)
      }
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }

  if (!f) return <></>

  return (
    <>
      <LeftSide small />

      <div className="wrapper">
        <FormModal
          visible={insuranceConsentModal}
          content="W ramach wykupionej licencji przysługuje Ci prawo do ubezpieczenia dla wykonywanych badań merytorycznych plików JPK VAT. Aby skorzystać z ubezpieczenia niezbędne jest zaakceptowanie Ogólnych warunków ubezpieczenia na formularzu rejestracji. Czy na pewno chcesz kontynuować bez oświadczenia?"
          onDecline={() => {
            setInsuranceConsentModal(false)
          }}
          addClass="delModal"
          okButtonName="Tak"
          onAccept={() => {
            setInsuranceConsentChosen(true)
            form.current.submit()
          }}
        />
        <Form
          ref={form}
          name="UserSetup"
          initialValues={initial}
          className={`Addentity-form ${!organization ? "form" : "form-extended"}`}
          onFinish={handleFinish}
          onFinishFailed={() => console.error("fail")}
        >
          <div className={`${organization ? "section-extended" : "section"} `}>
            <h3>Dane użytkownika</h3>
            <Form.Item name="name" hasFeedback validateTrigger="onBlur" rules={Rules.name}>
              <Input placeholder="Imię" autoComplete="off" />
            </Form.Item>
            <Form.Item name="surname" hasFeedback validateTrigger="onBlur" rules={Rules.surname}>
              <Input placeholder="Nazwisko" autoComplete="off" />
            </Form.Item>
            <Form.Item name="email" hasFeedback validateTrigger="onBlur" rules={Rules.email}>
              <Input placeholder="E-mail" disabled autoComplete="off" />
            </Form.Item>
          </div>

          {organization && (
            <div className={`${organization ? "section-extended" : "section"} `}>
              <h3>Dane organizacji</h3>
              <Form.Item name="nip" hasFeedback validateTrigger="onBlur" rules={Rules.nip}>
                <MaskedInput mask="111-111-11-11" name="nip" placeholder="NIP" autoComplete="off" />
              </Form.Item>

              <Form.Item name="organization_name" hasFeedback validateTrigger="onBlur" rules={Rules.organization_name}>
                <Input placeholder="nazwa firmy" autoComplete="off" />
              </Form.Item>

              <Form.Item name="address" hasFeedback validateTrigger="onBlur" rules={Rules.address}>
                <Input placeholder="adres firmy" autoComplete="off" />
              </Form.Item>

              <Form.Item name="post_code" hasFeedback validateTrigger="onBlur" rules={Rules.post_code}>
                <MaskedInput mask="11-111" name="postCode" placeholder="kod pocztowy" autoComplete="off" />
              </Form.Item>

              <Form.Item name="city" validateTrigger="onBlur" rules={Rules.city} hasFeedback>
                <Input placeholder="miejscowość" autoComplete="off" />
              </Form.Item>

              <Form.Item name="country" hasFeedback validateTrigger="onBlur" rules={Rules.country}>
                <Countries country={country} handleCountry={setCountry} />
              </Form.Item>
            </div>
          )}

          <div className={`${organization ? "section-extended" : "section"} `}>
            <h3>Ustaw hasło</h3>
            <Form.Item name="password" hasFeedback validateTrigger="onBlur" rules={Rules.password}>
              <Input.Password placeholder="hasło" autoComplete="off" />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              dependencies={["password"]}
              hasFeedback
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Potwierdź swoje hasło!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve()
                    }

                    return Promise.reject("Wprowadzone hasła nie są identyczne!")
                  },
                }),
              ]}
            >
              <Input.Password placeholder="potwierdź hasło" autoComplete="off" />
            </Form.Item>
          </div>

          <div className="agree">
            <Form.Item
              name="personal_data_process_consent"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject("Aby kontynuować zaakceptuj regulamin."),
                },
              ]}
            >
              <Checkbox>
                <p>
                  Zapoznałem/am się i akceptuję{" "}
                  <ReactGA.OutboundLink
                    eventLabel="Rejestracja - podgląd regulaminu"
                    to="https://taxinsight.pl/regulamin-cloudtax/"
                    target="_blank"
                  >
                    Regulamin{" "}
                  </ReactGA.OutboundLink>
                  oraz{" "}
                  <ReactGA.OutboundLink
                    eventLabel="Rejestracja - podgląd umowa"
                    to="https://taxinsight.pl/umowa-powierzenia-cloudtax/"
                    target="_blank"
                  >
                    Umowę powierzenia przetwarzania danych osobowych.{" "}
                  </ReactGA.OutboundLink>
                </p>
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="cookies_policy"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject("Aby kontynuować zaakceptuj politykę prywatności i cookies."),
                },
              ]}
            >
              <Checkbox>
                <p>
                  Zapoznałem/am się i akceptuję{" "}
                  <ReactGA.OutboundLink
                    eventLabel="Rejestracja - podgląd cooklies"
                    // to={regulationsPDF}
                    to="https://taxinsight.pl/polityka-prywatnosci-cloudtax/"
                    target="_blank"
                  >
                    Politykę prywatności i cookies,{" "}
                  </ReactGA.OutboundLink>
                  zawierającą informacje dotyczące przetwarzania moich danych osobowych.{" "}
                </p>
              </Checkbox>
            </Form.Item>

            {insuranceConsentVisible && (
              <Form.Item name="insurance_consent" valuePropName="checked">
                <Checkbox checked={insuranceConsent} onChange={() => setInsuranceConsent((prev) => !prev)}>
                  <p>
                    Zapoznałem/am się i akceptuję{" "}
                    <ReactGA.OutboundLink
                      eventLabel="Rejestracja - podgląd cooklies"
                      // to={regulationsPDF}
                      to="https://taxinsight.pl/warunki-ogolne-ubezpieczenia-cloudtax/"
                      target="_blank"
                    >
                      Ogólne warunki ubezpieczenia Wiener{" "}
                    </ReactGA.OutboundLink>
                  </p>
                </Checkbox>
              </Form.Item>
            )}
          </div>

          <div className="login-button-container">
            <FormButton name="Zapisz" />
          </div>
        </Form>
      </div>
    </>
  )
}

const Rules = {
  name: [
    {
      whitespace: true,
      required: true,
      message: "Podaj swoje imię!",
    },
  ],
  surname: [
    {
      whitespace: true,
      required: true,
      message: "Podaj swoje nazwisko!",
    },
  ],
  email: [
    {
      required: true,
      message: "Podaj swój e-mail!",
    },
    {
      type: "email",
      message: "Wprowadzony e-mail jest niepoprawny!",
    },
  ],
  password: [
    {
      required: true,
      message: "Wprowadź swoje hasło!",
    },
    {
      pattern: PasswordPattern,
      message: PasswordTip
    },
  ],
  phone: [
    {
      required: true,
      message: "Podaj swój numer telefonu!",
    },
    {
      pattern: /(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/,
      message: "Numer telefonu jest niepoprawny!",
    },
  ],
  nip: [
    {
      required: true,
      message: "Podaj swój NIP!",
    },
    {
      // eslint-disable-next-line
      pattern: /[0-9\-]{13}/g,
      message: "NIP musi się składać z 10 cyfr!",
    },
  ],
  organization_name: [
    {
      whitespace: true,
      required: true,
      message: "Podaj nazwę firmy!",
    },
  ],
  address: [
    {
      whitespace: true,
      required: true,
      message: "Podaj adres firmy!",
    },
  ],
  post_code: [
    { required: true, message: "Podaj kod pocztowy!" },
    {
      // eslint-disable-next-line
      pattern: /[0-9\-]{6}/g,
      message: "Niepoprawny kod pocztowy!",
    },
  ],
  city: [{ whitespace: true, required: true, message: "Podaj miejscowość!" }],
  country: [
    {
      required: true,
      message: "Podaj swój kraj!",
    },
  ],
}

export default UserSetup

// https://cloudtaxapp.ec2dev.pl/user-setup?username=antoni.aleksander@gmail.com&token=Q2ZESjhCSGd0enltWm9KRXR5eGdlVWcxRUhDTXE1ZFIvcVJ3MnNwMWNpUWNLeG9zbWx6WjdCdUNQTm5DTVRkc0tuYlROTGhpa3VOc0xpbmdmNjZvcWV4a3ZkamE5T0JpbUpSTDNLTjlUbWZsdkV0dWFYQUd6aWMvZzdZVWZITnMyckZRNTRYcHZ3MlE2c2dPRThCWmJzTXNDMTlJRkNOdWF4VzNTZUpld0drbEhzNnhzR2I2ek1jcmFZOVQ5UzVQUExidkl1OHlyeHZheUNkV1pBb0hMaGFXV1B2OWN4T2cwQ0k4a3RuYmtmbzM5cUV5SHg1blNIRHZnTG5KU2YrMWtERmVMdz09

// https://cloudtaxapp.ec2dev.pl/user-setup?username=antoni.aleksander@gmail.com&token=Q2ZESjhFMEplTzI2dE50SHZONlh1UllNd2M0V3JqZ1V1em1HRUFxL2FQNmp2UllLL05JOUNJS1lJSkFEd2I3ZjZVT3E0aUd1eG40S2w0KzdmTzFYZnN5RktCQUErUVRna1YxcVE2TnlSUWhDSjQxblQwdUcyR2U4NGRWYXpweXdtVWhhRGMwQnBJbndBU3krQ2dqR3AxTmJHdGtJKy9HbE93d3F3aDh5cUFNNmNveW1SUmpMRXdRL2doRktnRDRoQmFEekpIZ0wrMVZzbTU5UzdZdXBhQzQzamVuR1lKSkdnK0VnZXlxWE9jdjQ0bDY0NjlpRTRUaS9FTjM0TW40UDFFb2JBQT09
