import React from "react"

import { useHistory } from "react-router-dom"

import backIcon from "images/back_circle.svg"

import "./GoBack.less"

export const GoBack = () => {
  const history = useHistory()

  return (
    <div className="back">
      <img src={backIcon} alt="powrot do listy ewidencji" onClick={history.goBack} />
      <span>Podpisz i wyślij plik JPK_VAT</span>
    </div>
  )
}
