import React, { useContext, useEffect } from "react"
import { Form, Input } from "antd"
import "./ResetPassword.less"
import Button from "../common/Button"
import API from "../common/api"
import { useHistory } from "react-router-dom"
import Rules from "../common/Rules"

import FormModal from "../common/FormModal"
import Context from "../../context/index"
import LeftSide from "../common/LeftSide"

import ReactGA from "react-ga"

const ResetPassword = () => {
  const { ctx } = useContext(Context)
  const [loading, setLoading] = React.useState(false)
  const [modalVisible, setModalVisible] = React.useState(false)
  const [redirectUrl, setRedirectUrl] = React.useState("")
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  let history = useHistory()

  const redirectToLogin = () => {
    history.push("/login")
  }

  const onFinish = (values) => {
    setLoading(true)

    const tmpValues = { ...values, redirect_url: redirectUrl }

    API.post("/Users/reminderPassword", tmpValues)
      // obojętnie czy 200 czy 400, zawsze pokaż modal że na podany adres został wysłany mail
      .then((res) => {
        setLoading(false)
        setModalVisible(true)
      })
      .catch(() => {
        setLoading(false)
        setModalVisible(true)
      })
  }

  const onFinishFailed = (errorInfo) => {}

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
    if (ctx.redirectedFromHelper) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, cameFrom] = ctx.redirectedFromHelper.replace(window.location.href, "").split("login/")
      setRedirectUrl(cameFrom)
    }
  }, [])

  return (
    <div className="ResetPassword-container">
      <LeftSide />
      <div className="ResetPassword">
        <Form
          {...layout}
          validateTrigger="onBlur"
          name="resetPassword"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p>
            Jeśli zapomniałeś hasła do swojego konta, wystarczy, że podasz nam swój e-mail, a my wyślemy Ci link do
            zmiany hasła.
          </p>
          <Form.Item name="email" hasFeedback validateTrigger="onBlur" rules={Rules.email} style={{ width: "100%" }}>
            <Input placeholder="e-mail" />
          </Form.Item>
          <div className="buttons">
            <Button
              name="Anuluj"
              loading={loading}
              styling="button-small-white"
              onClick={redirectToLogin}
              style={{ width: 120 }}
            />
            <Button name="Zatwierdź" loading={loading} styling="button-small" style={{ width: 120 }} type="submit" />
          </div>
        </Form>

        <FormModal
          visible={modalVisible}
          onAccept={redirectToLogin}
          content="Na podany adres został wysłany link do resetu hasła."
        />
      </div>
    </div>
  )
}

export default ResetPassword
