import React from "react"
import { Radio, Space, Form } from "antd"


const DownloadTemplateModal = ({ setAnyDownloadTypeSelected, setCsvTemplateTypeSelected }) => {
    return (
        <div>
            <h3>Wybierz format pliku, który chcesz pobrać:</h3>

            <Form name="DownloadTemplateForm">
                <Form.Item>
                    <Radio.Group onChange={e => {
                        setAnyDownloadTypeSelected(true)
                        setCsvTemplateTypeSelected(e.target.value === "csv")
                    }}>
                        <Space direction="vertical">
                            <Radio value="csv"><span class="templateTypeName">csv</span></Radio>
                            <Radio value="xlsx"><span class="templateTypeName">xlsx</span></Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </div>
    )
}
export default DownloadTemplateModal