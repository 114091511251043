import React, { useContext, useState, useEffect } from "react"
import { Form, Checkbox } from "antd"
import Context from "context/index"
import API from "../../common/api"
import { Loading } from "components/Main/Loading"
import "./ConsentsModal.less"
import ReactGA from "react-ga"

const RegulationsUrl = "https://taxinsight.pl/regulamin-cloudtax/"
const GDPRUrl = "https://taxinsight.pl/umowa-powierzenia-cloudtax/"
const CookiesUrl = "https://taxinsight.pl/polityka-prywatnosci-cloudtax/"
const WienerUrl = "https://taxinsight.pl/warunki-ogolne-ubezpieczenia-cloudtax/"


const ConsentModal = ({ helpRef, closeModal, setCanSaveConsents }) => {
  const [loading, setLoading] = useState(true)
  const [showWiener, setShowWiener] = useState(false)
  const [wienerChecked, setWienerChecked] = useState(false)
  const [wienerAlreadyAccepted, setWienerAlreadyAccepted] = useState(false)
  const { ctx } = useContext(Context)

  useEffect(() => {
    API.get(`Dashboard/getConsentsByUser?CurrentOrganizationGuid=${ctx.currentOrganization.guid}`).then(async (resp) => {
      if (resp.status === 200) {
        setWienerChecked(resp.data.stateObject.wiener)
        setWienerAlreadyAccepted(resp.data.stateObject.wiener)
        const wienerAvailable = resp.data.stateObject.wienerCheckbox && !ctx.featureFlags.InsurancesDisabled
        setShowWiener(wienerAvailable)
        setCanSaveConsents(wienerAvailable && !resp.data.stateObject.wiener)
        setLoading(false)
      } else {
        closeModal()
      }
    }).catch(e => closeModal())
  }, [])

  if (loading) return <Loading />
  return (
    <Form
      ref={helpRef}
      name="ConsentEdit"
      onFinish={async (values) => {
        await API.post(
          `/Dashboard/saveUserConsents?CurrentOrganizationGuid=${ctx.currentOrganization.guid}`,
          { "wiener": wienerChecked },
          { headers: { tenant_id: localStorage.getItem("currentGuid") } }
        ).then((resp) => {
          if (resp.status === 200) {
            closeModal()
          }
        }).catch(e => {
          console.error(e)
        })
      }
      }

    >
      <Form.Item>
        <Checkbox checked={true} disabled>
          <p>
            <ReactGA.OutboundLink
              eventLabel="Zgody i oświadczenia - regulamin"
              to={RegulationsUrl}
              target="_blank"
              rel="noopener noreferrer"
            >Regulamin</ReactGA.OutboundLink>
            <span> oraz </span>
            <ReactGA.OutboundLink
              eventLabel="Zgody i oświadczenia - umowa powierzenia przetwarzania danych osobowych"
              to={GDPRUrl}
              target="_blank" rel="noopener noreferrer">Umowa powierzenia przetwarzania danych osobowych.</ReactGA.OutboundLink>
          </p>
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Checkbox checked={true} disabled>
          <p>
            <ReactGA.OutboundLink
              eventLabel="Zgody i oświadczenia - polityka prywatności i cookies"
              to={CookiesUrl}
              target="_blank" rel="noopener noreferrer">Polityka prywatności i cookies.</ReactGA.OutboundLink>
          </p>
        </Checkbox>
      </Form.Item>
      {showWiener &&
        <Form.Item>
          <Checkbox checked={wienerChecked}
            disabled={wienerAlreadyAccepted}
            onChange={(e =>
              setWienerChecked(!wienerChecked))
            }>
            <p>
              <ReactGA.OutboundLink
                eventLabel="Zgody i oświadczenia - ogólne warunki ubezpieczenia Wiener"
                to={WienerUrl}
                target="_blank" rel="noopener noreferrer">Ogólne warunki ubezpieczenia Wiener.</ReactGA.OutboundLink>
            </p>
          </Checkbox>
        </Form.Item>
      }
    </Form>
  )
}


export default ConsentModal