import React, { useContext, useState } from "react"
import { DispatchContext, StateContext } from "components/userContext"
import CustomPagination from "components/CustomPagination/CustomPagination"
import ReactTable from "react-table-v6"
import { Loading } from "components/Main/Loading"

const NipVerifierHistoryTable = (props) => {
    var [selectedRows, setSelectedRows] = useState([])
    const state = useContext(StateContext)
    const [loading, setLoading] = useState(true)
    const [filtersState, setFilterState] = useState({})

    let [currentPageIndex, setCurrentPageIndex] = useState(0)

    const removeItem = (data) => {
        const tmp = [...selectedRows]
        const index = tmp.indexOf(data)

        if (index !== -1) {
            tmp.splice(index, 1)
            selectedRows = tmp
            setSelectedRows(selectedRows)
        }
    }

    const addItem = (index) => {
        selectedRows = [...selectedRows, index]
        setSelectedRows(selectedRows)
    }

    return <ReactTable
        manual
        className="vatTable wlTable_records"
        data={props.data}
        columns={props.columns}
        multiSort={props.multiSort}
        loading={props.loading}
        noDataText={""}
        NoDataComponent={() => null}
        LoadingComponent={(props) => {
            if (props.loading) {
                return <Loading absolute />
            }
            return <></>
        }}
        resizable={false}
        PaginationComponent={(params) => (
            <CustomPagination currentPage={props.options.currentPage} {...params} />
        )}
        page={currentPageIndex}
              onPageChange={newPage => {
                currentPageIndex = newPage
                setCurrentPageIndex(newPage)
              }}
        pages={props.options.totalPagesNumber}
        defaultPageSize={10}
        filtered={props.options.filtered}
        onFetchData={(innerState) => {
            setLoading(true)

            const filterValue = (innerState.filtered.length && innerState.filtered[0].value) || ""
            const sortedValue = innerState.sorted.length === 1 ?
                (innerState.sorted[0].desc ? "-" : "") + innerState.sorted[0].id : ""
            const filters = {
                filterValue,
                sortedValue,
                innerPage: currentPageIndex,
                innerPageSize: innerState.pageSize,
            }

            setFilterState(filters)
            props.fetchSortData(filters)
        }}
        getTdProps={(state, rowInfo, column, instance) => {
            return {
                onClick: ({ shiftKey }) => {
                    // Expand row on expand cell click
                    if (typeof rowInfo !== "undefined") {

                        //if (column.Header === "Nazwa kontrahenta") { return }

                        // add row selection on row click, except click on expand arrow
                        if (column.id && selectedRows.includes(rowInfo.original.id)) {
                            removeItem(rowInfo.original.id)
                        } else {
                            if (shiftKey) {
                                addItem(rowInfo.original.id)
                            } else if (column.id) {
                                selectedRows = [rowInfo.original.id]
                                setSelectedRows(selectedRows)
                            }
                        }
                    }
                },
            }
        }}
        getTrProps={(_, rowInfo) => {
            if (typeof rowInfo !== "undefined") {
                return {
                    style: {
                        fontWeight: selectedRows.includes(rowInfo.original.id) && "bold",
                        background: selectedRows.includes(rowInfo.original.id) && "#f4f4f4",
                    },
                }
            } else {
                return {
                    onClick: props.originalHandleClick,
                }
            }
        }}
    />
}

export default NipVerifierHistoryTable