import React, { useContext, useEffect, useState, useCallback } from "react"

import { StateContext, DispatchContext } from "components/userContext"

import BasicAdd from "../AddNewDocument/BasicAdd"
import TransactionAdd from "../AddNewDocument/TransactionAdd"
import ProcedureAdd from "../AddNewDocument/ProcedureAdd"

import { Tabs, Form } from "antd"
import API from "components/common/api"

import ReactTooltip from "react-tooltip"

import { firstTab, secondTab, thirdTab, fourthTab, layout } from "../SalesTab/EditSells"
import { Loading } from "components/Main/Loading"

const { TabPane } = Tabs

const AddNewDocumentPurchase = ({ mainId, addFormPurchase, formVariant }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [loading, setLoading] = useState(false)

  const [lpCount, setLpCount] = useState(null)

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  useEffect(() => {
    // FIND LP COUNT
    fetchLpCount()
    return () => {
      dispatch({ type: "handleClearEditSells" })
    }
  }, [])

  const fetchLpCount = useCallback(async () => {
    setLoading(true)
    try {
      const response = await API.get(`/VatRecordPurchase/getVatPurchaseRecordCount?vatRecordId=${mainId}`)
      setLoading(false)
      if (response.status === 200) {
        setLpCount(response.data.stateObject)
      } else {
        setLpCount(null)
      }
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }, [mainId])

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const fixedValues = {}
      Object.keys(values).map((key) => {
        if (typeof values[key] === "boolean") {
          fixedValues[key] = values[key] ? 1 : 0
        } else if (
          (key.includes("k_") || key === "sprzedaz_vat_marza" || key === "zakup_vat_marza") &&
          key !== "nr_dok_wew"
        ) {
          fixedValues[key] = values[key] === "" ? null : values[key] ? parseFloat(values[key]) : values[key]
        } else {
          fixedValues[key] = values[key]
        }
      })

      fixedValues.lp_zakupu = lpCount + 1

      const data = {
        ...fixedValues,
        id_ewidencja_vat: parseInt(mainId),
        id: 0,
      }

      const response = await API.post(`/VatRecordPurchase/addVatRecordPurchase`, data, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid"),
        },
      })

      if (response.status === 200) {
        dispatch({
          type: "handleState",
          payload: true,
          field: "refreshDetails",
        })
        dispatch({ type: "handleReturnSaveAdd", payload: "3" })
      } else {
        if (response.errors.length) {
          // HANDLE SET ERRORS
          // addForm.current.setFields(
          //   Object.keys(response.errorsDictionary).map((key) => {
          //     return {
          //       name: key,
          //       errors: response.errorsDictionary[key],
          //     }
          //   })
          // )
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const onFinishFailed = (err) => {
    const formWrap = document.getElementById("SalesEdit")
    const [scollableForm] = formWrap.getElementsByClassName("ant-tabs")

    const errorTabs = []

    err.errorFields.map((e) => {
      firstTab.map((tabName) => {
        if (e.name.includes(tabName)) {
          errorTabs.push(1)
        }
      })

      secondTab.map((tabName) => {
        if (e.name.includes(tabName)) {
          errorTabs.push(2)
        }
      })

      thirdTab.map((tabName) => {
        if (e.name.includes(tabName)) {
          errorTabs.push(3)
        }
      })

      fourthTab.map((tabName) => {
        if (e.name.includes(tabName)) {
          errorTabs.push(4)
        }
      })
    })

    scollableForm.scrollTo(0, 0)
    dispatch({ type: "handleState", field: "tabsWithErrorAdd", payload: [...new Set(errorTabs)] })
  }

  if (loading) return <Loading />

  const hasError = (id) => state.tabsWithErrorAdd.includes(id)

  return (
    <div className="edit-sales">
      <ReactTooltip
        effect="solid"
        className="tooltip-vat"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />
      <Form
        {...layout}
        name="SalesEdit"
        className="sales-edit-form"
        ref={addFormPurchase}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
      >
        <Tabs>
          <TabPane tab={<span className={hasError(1) ? "hasError" : ""}>Dane podstawowe</span>} key="1" forceRender>
            <div className="edit-wrapper">
              <div className="details-item custom">
                <span className="ant-form-item-label">Lp.</span>
                <span>{lpCount ? lpCount + 1 : null}</span>
              </div>
              <div className="details-box-default">
                <BasicAdd
                  addForm={addFormPurchase}
                  purchase
                  options={["MK", "VAT_RR", "WEW"]}
                  sortedKeys={[
                    "kod_kraju_nadania_tin",
                    "dowod_zakupu",
                    "data_zakupu",
                    "nr_dostawcy",
                    "nr_dok_wew",
                    "data_wplywu",
                    "nazwa_dostawcy",
                    "dokument_zakupu",
                    "clear",
                  ]}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab={<span className={hasError(2) ? "hasError" : ""}>Rodzaj transakcji</span>} key="2" forceRender>
            <div className="edit-wrapper">
              <div className="details-box-default">
                <TransactionAdd addForm={addFormPurchase} purchase />
              </div>
            </div>
          </TabPane>
          <TabPane tab={<span className={hasError(4) ? "hasError" : ""}> Procedury</span>} key="3" forceRender>
            <div className="edit-wrapper">
              <ProcedureAdd addForm={addFormPurchase} purchase formVariant={formVariant} />
            </div>
          </TabPane>
        </Tabs>
      </Form>
    </div>
  )
}

export default AddNewDocumentPurchase
