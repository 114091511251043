import { observable } from "mobx";

export const SwitcherStore = {
  switcherVisible: observable.box<boolean>(false),
  setSwitcherVisible: (value: boolean) =>
    SwitcherStore.switcherVisible.set(value),
  getSwitcherVisible: () => SwitcherStore.switcherVisible.get(),
};

export const createSwitcherStore = () => {
  return SwitcherStore;
};
export type ISwitcherStores = ReturnType<typeof createSwitcherStore>;
