import React from "react"

import editIcon from "images/icons/edit_icon.svg"
import saveIcon from "images/icons/save_icon.svg"
import cancelIcon from "images/icons/cancel_icon.svg"

import "./VatDetailsEditIcons.less"

export const VatDetailsEditIcons = ({ blockEdit, editMode, onCancel, onEdit, onSave }) => {
  const editIcons = editMode ? (
    <>
      <div className="icon" onClick={onCancel}>
        <img src={cancelIcon} alt="Anuluj" />
      </div>
      <div className="icon" onClick={onSave}>
        <img src={saveIcon} alt="Zachowaj zmiany" />
      </div>
    </>
  ) : (
    <div className={`icon${blockEdit ? " blocked" : ""}`} onClick={blockEdit ? null : onEdit}>
      <img src={editIcon} alt="Edycja ewidencji" />
    </div>
  )

  return <div className={`icons${editMode ? " icons__edit" : ""}`}>{editIcons}</div>
}

export default VatDetailsEditIcons
