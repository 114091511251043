import React, { useContext, useEffect, useState, Fragment, useRef } from "react"

import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"
import "./ReactTable.less"
import "./AffiliatedEntityTable.less"

import MaskedInput from "antd-mask-input"

import API, { baseApiURL } from "../common/api"

import Context from "../../context/index"
import { DispatchContext, StateContext } from "../userContext"

import { Select } from "antd"
import CustomSelect from "react-select"

import UnsavedChanges from "../ClientPanelModals/UnsavedChanges"

// DATE PICKER MOMENT
import { DatePicker, Space, Form, Input, Button } from "antd"

import moment from "moment"
import "moment/locale/pl"

import locale from "antd/es/date-picker/locale/pl_PL"
import Spinner from "../common/Spinner/Spinner"
import { useHistory } from "react-router-dom"

import addIcon from "images/icons/Records_icons/add_record_icon.svg"
import delIcon from "images/icons/Records_icons/del_record_icon.svg"

import FormModal from "../common/FormModal"

import IconBox from "components/common/IconBox/IconBox"

import saveIcon from "../../images/icons/save_icon.svg"
import editIcon from "../../images/edit.svg"
import closeIcon from "../../images/icons/cancel_icon.svg"
import closeDetailsIcon from "images/close-menu.svg"

import { Loading } from "components/Main/Loading"
import { tracker } from "helpers/tracker"
import { isAffiliatedActionPossible } from "./helpers/helpers"

moment.locale("pl", {
  week: {
    dow: 1,
  },
})

// DATE PICKER MOMENT

const { Option } = Select

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const entitiesTable = ["Osoba prawna", "Osoba fizyczna"]

const allEntities = entitiesTable.map((elem, y) => {
  // add one to id because of first null in relatedPartyTypes
  return { id: y + 1, value: elem, label: elem }
})

const relatedPartyTypes = {
  LegalPerson: "Osoba Prawna",
  NaturalPerson: "Osoba Fizyczna",
}

const fields = [
  // RELATED_PARTY_TYPE = 0
  null,
  // RELATED_PARTY_TYPE = 1
  [
    ["nip", "full_name", "tax_office_code", "tax_office_name"],
    ["email", "telefon"],
  ],
  // RELATED_PARTY_TYPE = 2
  [
    ["nip", "name", "surname", "full_name", "birth_date", "tax_office_code", "tax_office_name"],
    ["email", "telefon"],
  ],
]

const customStyles = {
  container: (provided) => {
    return {
      ...provided,
      width: "100%",
      margin: "10px 0px",
    }
  },
  menu: (provided) => {
    return {
      ...provided,
      zIndex: "999",
    }
  },
  option: (provided) => {
    return {
      ...provided,
      textOverflow: "ellipsis",
      display: "block",
      overflow: "hidden",
    }
  },
}

const columns = [
  {
    Header: "NIP",
    accessor: "nip",
  },
  {
    Header: "Pełna nazwa",
    accessor: "full_name",
    Cell: (row) => {
      return row.original.fullName || "-"
    },
  },
  {
    Header: "Typ podmiotu",
    accessor: "related_party_type",
    Cell: (row) => relatedPartyTypes[row.original.relatedPartyType] || "-", //relatedPartyTypes[row.value],
  },
]

const columnsSmall = [
  {
    Header: "NIP",
    accessor: "nip",
  },
  {
    Header: "Pełna nazwa",
    accessor: "full_name",
    Cell: (row) => {
      return row.original.fullName || "-"
    },
  },
]

const originalHandleClick = (_, handleOriginal) => {
  if (handleOriginal) {
    handleOriginal()
  }
}

const fieldsKeys = {
  nip: "NIP",
  name: "Imię",
  surname: "Nazwisko",
  full_name: "Pełna nazwa",
  birth_date: "Data urodzenia",
  tax_office_code: "Kod US",
  tax_office_name: "Nazwa US",
  email: "E-mail",
  telefon: "Telefon",
}

const handleFields = (relatedPartyType) => {
  return fields[relatedPartyType]
}

const sectionNames = ["Dane podstawowe", "Dane teleadresowe"]

const AffiliatedEntityTable = () => {
  const { ctx } = useContext(Context)

  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [data, setData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [loading, setLoading] = useState(false)

  const [confirm, setConfirm] = useState(false)
  const [cancel, setCancel] = useState(false)
  const [allFetched, setAllFetched] = useState(false)
  const [activeData, setActiveData] = useState([])
  const [errorMessage, setErrorMessage] = useState()

  const [errorModal, setErrorModal] = useState(false)

  const handleCheckEntities = async () => {
    setLoading(true)
    try {
      const {
        data: {
          stateObject: { results: stateObject },
        },
      } = await API.get(
        `${baseApiURL}/CustomerPanel/getRelatedParties?CurrentOrganizationGuid=${ctx.currentOrganization.guid}`
      )

      if (!stateObject.length) {
        dispatch({ type: "handleAllRelatedParties", payload: [] })
        return
      }

      dispatch({ type: "handleAllRelatedParties", payload: stateObject })
      setLoading(false)
      return stateObject
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const fetchNip = async () => {
    setLoading(true)
    try {
      const {
        data: {
          stateObject: { nipCollection },
        },
      } = await API.get(`/CustomerPanel/GetLicencesNip/?organizationGuid=${ctx.currentOrganization.guid}`)

      dispatch({ type: "handleState", payload: nipCollection, field: "availableNip" })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const fetchDetails = async (id) => {
    // FETCH AFFILIATED ENITITES BASED ON RELATED PARTIES ID
    setLoading(true)
    try {
      const {
        data: { stateObject },
      } = await API.get(
        `${baseApiURL}/CustomerPanel/GetRelatedParty?id=${id}&organizationGuid=${ctx.currentOrganization.guid}`
      )

      setActiveData(stateObject)
      setLoading(false)
      setAllFetched(true)
    } catch (error) {
      setLoading(false)
    }
  }

  const removeItem = (data) => {
    const tmp = [...selectedRows]
    const index = tmp.indexOf(data)

    if (index !== -1) {
      tmp.splice(index, 1)
      setSelectedRows(tmp)
    }

    setAllFetched(false)
  }

  const addItem = (index) => {
    setSelectedRows([...selectedRows, index])
  }

  useEffect(() => {
    const fetchData = async () => {
      // CHECK IF RELATED ARE PRESENT IN STATE
      setLoading(true)
      const relatedPartyElements = await handleCheckEntities()

      if (!relatedPartyElements) {
        setData([])
        setLoading(false)
        return
      }

      setData(relatedPartyElements)
    }

    fetchNip()
    fetchData()
    setLoading(false)
  }, [state.fetchEntitiesData])

  useEffect(() => {
    setErrorMessage()
    if (state.preSelectedId) {
      fetchDetails(state.preSelectedId)

      return () => {
        dispatch({ type: "handleResetEntity" })
      }
    }

    return () => {
      dispatch({ type: "handleResetEntity" })
    }
  }, [])

  useEffect(() => {
    // handle active and visible entities
    const visibleEntities = selectedRows.map((id) => data[id])

    dispatch({ type: "handleState", field: "visibleEntities", payload: visibleEntities })

    if (selectedRows.length >= 2 && Object.keys(activeData).length > 0) {
      return
    } else {
      if (!selectedRows.length) {
        setActiveData([])
        return
      }

      const elemId = state.allRelatedParties.find((e) => e.id === visibleEntities[0].id).id
      fetchDetails(elemId)
    }
  }, [selectedRows])

  useEffect(() => {
    if (!Object.keys(activeData).length) return

    if (state.preSelectedId) {
      dispatch({
        type: "handleFillEntity",
        payload: [activeData],
      })

      return
    }

    dispatch({ type: "handleState", field: "visibleEntities", payload: [activeData] })
  }, [activeData])

  useEffect(() => {
    if (!state.visibleEntities) {
      setSelectedRows([])
      setAllFetched(false)
    }
  }, [state.visibleEntities])

  useEffect(() => {
    const handleDelete = async () => {
      setLoading(true)
      try {
        const idsToDelete = selectedRows.map((rowId) => data[rowId].id)

        const response = await API.post(
          `/CustomerPanel/deleteRelatedParties?organizationGuid=${ctx.currentOrganization.guid}`,
          {
            ids: idsToDelete,
          },
          {
            headers: {
              tenant_id: ctx.currentOrganization.guid,
            },
          }
        )

        if (response.status === 400) {
          setErrorMessage(response.data.errors[0])
          setLoading(false)
          return
        }

        setErrorMessage()
        dispatch({ type: "handleCancelEntity" })
        setLoading(false)
        dispatch({ type: "handleState", payload: false, field: "showEntityDeleteModal" })
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    }

    if (!confirm) return

    setConfirm(false)
    handleDelete()
  }, [confirm])

  useEffect(() => {
    if (!cancel) return

    setConfirm(false)
    setErrorMessage()
    dispatch({ type: "handleState", payload: false, field: "showEntityDeleteModal" })
  }, [cancel])

  if (state.showEntityPanel) return <SingleEntity />

  const { visibleEntities } = state

  const deleteVisible = !state.showEntityPanel && visibleEntities && visibleEntities.length > 0
  const editVisible = !state.showEntityPanel && visibleEntities && visibleEntities.length === 1
  const addVisible = visibleEntities && visibleEntities.length > 0

  return (
    <>
      {/* ERROR MODAL */}
      <FormModal
        visible={errorModal}
        content="Limit podmiotów osiągnięty. Sprawdź dostępne licencje CloudTax w celu dodania większej ilości podmiotów do organizacji."
        actionLink={ctx.currentOrganization.resellerLink}
        onDecline={() => {
          setErrorModal(false)
        }}
        addClass="delModal"
        okButtonName="Licencje"
      />
      {/* ERROR USER MODAL */}

      <div className="content-wrapper inner-shadow">
        <div className={`ClientPanel ${visibleEntities && visibleEntities.length === 1 ? "details-visible" : ""}`}>
          <div className="header">
            <span>Podmioty</span>
            <div className="actions">
              <IconBox
                name="dodaj"
                icon={addIcon}
                disabled={state.showEntityPanel || addVisible}
                click={async () => {
                  tracker("Panel administratora - Podmioty - Dodaj")

                  if (await isAffiliatedActionPossible()) {
                    dispatch({ type: "handleAddEntity" })
                  } else {
                    setErrorModal(true)
                  }
                }}
              />
              <IconBox
                name="edytuj"
                icon={editIcon}
                click={() => {
                  tracker("Panel administratora - Podmioty - Edytuj")
                  dispatch({ type: "handleFillEntity", payload: state.visibleEntities })
                }}
                disabled={!editVisible}
              />
              <IconBox
                name="usuń"
                icon={delIcon}
                disabled={!deleteVisible}
                click={() => {
                  tracker("Panel administratora - Podmioty - Usuń")
                  dispatch({ type: "handleDeleteEntity", payload: true })
                }}
              />
            </div>
          </div>
          <div className="table-wrapper">
            <FormModal
              visible={state.showEntityDeleteModal}
              content="Czy na pewno usunąć wskazane podmioty?"
              onAccept={setConfirm}
              onDecline={setCancel}
              error={errorMessage ? errorMessage : null}
              addClass="delModal"
              okButtonName="Usuń"
            />
            <ReactTable
              style={{
                marginTop: "35px",
              }}
              className="clean"
              resizable={false}
              data={data}
              minRows={0}
              noDataText={""}
              NoDataComponent={() => null}
              loading={loading}
              LoadingComponent={(props) => {
                if (props.loading) {
                  return <Loading absolute />
                }
                return <></>
              }}
              columns={!selectedRows.length ? columns : columnsSmall}
              showPagination={false}
              getTrProps={(_, rowInfo) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    onClick: (e) => {
                      if (selectedRows.includes(rowInfo.index)) {
                        removeItem(rowInfo.index)
                      } else {
                        if (e.shiftKey) {
                          addItem(rowInfo.index)
                        } else {
                          setSelectedRows([rowInfo.index])
                        }
                      }
                    },
                    style: {
                      background: selectedRows.includes(rowInfo.index) && "#f4f4f4",
                    },
                  }
                } else {
                  return {
                    onClick: originalHandleClick,
                  }
                }
              }}
            />

            {allFetched && selectedRows.length === 1 && (
              <div className="table-details">
                <Button className="closeButton" onClick={() => {
                  setSelectedRows([]);
                }}><img src={closeDetailsIcon} alt="" /></Button>
                {!fields[activeData.related_party_type] ? (
                  // BLANK FORM TO FILL
                  <CreateDefaultEntity />
                ) : (
                  handleFields(activeData.related_party_type).map((section, index) => (
                    // ENTITY FORM FILLED
                    <Fragment key={section}>
                      <h2>{sectionNames[index]}:</h2>
                      {section.map((fieldName) => (
                        <div className="input-wrapper" key={fieldName}>
                          <label htmlFor={fieldName}>{fieldsKeys[fieldName]}</label>
                          {fieldName === "birth_date" ? (
                            <input
                              type="input"
                              disabled
                              name={fieldName}
                              value={new Date(activeData.birth_date).toLocaleDateString().split(".").join("/") || "-"}
                            />
                          ) : (
                            <input type="text" disabled name={fieldName} value={activeData[fieldName] || "-"} />
                          )}
                        </div>
                      ))}
                    </Fragment>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const SingleEntity = () => {
  const form = useRef(null)
  const history = useHistory()
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [loading, setLoading] = useState(false)

  const { ctx } = useContext(Context)

  const [formToUpdate] = state.entityToFill || []
  const [entity, setEntity] = useState()
  const [formFields, setFormFields] = useState([])
  const [initial, setInitial] = useState()

  const [confirm, setConfirm] = useState(false)
  const [cancel, setCancel] = useState(false)

  const [uss, setUss] = useState([])
  const [ussAll, setUssAll] = useState([])

  const TMP_NIP = (state.entityToFill && state.entityToFill[0].nip) || state.availableNip[0]

  const fetchNip = async () => {
    try {
      const {
        data: {
          stateObject: { nipCollection },
        },
      } = await API.get(`/CustomerPanel/GetLicencesNip/?organizationGuid=${ctx.currentOrganization.guid}`)

      dispatch({ type: "handleState", payload: nipCollection, field: "availableNip" })
    } catch (error) {
      console.error(error)
    }
  }

  const nipOptions = [TMP_NIP].map((e, i) => ({ id: i, label: e, value: e }))

  const fetchUSCode = async (nip = TMP_NIP, codeVal) => {
    try {
      const tmpCode = codeVal //|| nip.split("-").join("").slice(0, 4)
      const tmpNip = nip.split("-").join("")
      const response = await API.get(`/CustomerPanel/getTaxOfficeData/?nip=${tmpNip}&code=${tmpCode}`)

      if (response.status === 204) {
        return { code: "", name: "" }
      }

      const {
        stateObject: { code, name },
      } = response.data

      return {
        code,
        name,
      }
    } catch (error) {
      // swallow
    }
  }

  useEffect(() => {
    if (!entity) return

    fetchUSCode()
      .then((obj) => {
        const allFields = handleFields(entity.id)
        const tmp = {}

        // SET DEFAULT INPUT VALUES
        allFields.map((section) =>
          section.map((input) => {
            if (input === "nip") return
            return (tmp[input] = formToUpdate ? (formToUpdate[input] !== null ? formToUpdate[input] : "") : "")
          })
        )

        if (formToUpdate && Object.keys(formToUpdate).length > 0) {
          // HANDLE UPDATE ENTITY

          if (!initial) {
            setInitial({
              ...initial,
              ...tmp,
              tax_office_code: tmp.tax_office_code,
              tax_office_name: tmp.tax_office_name,
              nip: TMP_NIP,
            })
          }

          setFormFields(allFields)
        } else {
          // HANDLE NEW ENTITY
          setInitial(tmp)
          setFormFields(allFields)

          if (form && form.current) {
            form.current.setFields([{ name: "nip", value: "" }])
          }
        }

        dispatch({ type: "handleEntity", payload: entity })
      })
      .catch((err) => {
        console.error(err)
        // dispatch({ type: "handleEntity", payload: entity })
        // form &&
        //   form.current.setFields([
        //     {
        //       name: "tax_office_name",
        //       errors: ["Nie znalezionu urzędu!"],
        //     },
        //   ])
      })
  }, [entity])

  useEffect(() => {
    const fetchUSS = async () => {
      try {
        const {
          data: { stateObject },
        } = await API.get(`/CustomerPanel/getTaxOfficeCollection`)

        const ussOptions = stateObject.map((elem, y) => ({ id: y, value: elem.name, label: elem.name }))
        setUssAll(stateObject)
        setUss(ussOptions)
      } catch (error) {
        console.error(error)
      }
    }

    const defaultEntityId = 1
    let [newEntity] = []

    if (formToUpdate && Object.keys(formToUpdate).length > 0) {
      ;[newEntity] = allEntities.filter((entity) => entity.id === formToUpdate.related_party_type)
      if (!formToUpdate.related_party_type) {
        ;[newEntity] = allEntities.filter((entity) => entity.id === 1)
      }
    } else {
      ;[newEntity] = allEntities.filter((entity) => entity.id === defaultEntityId)
    }

    setEntity(newEntity)
    fetchNip()
    fetchUSS()
  }, [])

  useEffect(() => {
    if (!state.submitEntity) return
    form.current.submit()
  }, [state.submitEntity])

  useEffect(() => {
    if (!confirm) return

    setConfirm(false)
    history.push("/panel-administratora")
    return dispatch({ type: "handleCancelEntity" })
  }, [confirm])

  useEffect(() => {
    if (!cancel) return

    setConfirm(false)
    dispatch({ type: "handleState", payload: false, field: "showEntityCancelModal" })
  }, [cancel])

  const update = formToUpdate && Object.keys(formToUpdate).length > 0

  const onFinish = (values) => {
    setLoading(true)
    const update = formToUpdate && Object.keys(formToUpdate).length > 0
    const [dd, mm, yyyy] = entity.id !== 2 ? [, , ,] : values.birth_date.split("/")
    const dobFormat = dd && mm ? `${yyyy}-${mm}-${dd}` : values.birth_date
    const data = {
      ...values,
      ...(update && {
        id: state.visibleEntities.length > 0 ? state.visibleEntities[0].id : state.entityToFill[0].id,
      }),
      related_party_type: entity.id,
      organization_guid: ctx.currentOrganization.guid,
      ...(entity.id === 2 && {
        birth_date: dobFormat,
      }),
    }
    const postRoute = !update ? `/CustomerPanel/AddRelatedParty` : `/CustomerPanel/UpdateRelatedParty`
    API.post(postRoute, data)
      .then((response) => {
        if (response.status === 400) {
          form.current.setFields([
            {
              name: "nip",
              errors: response.data.errors,
            },
          ])
          setLoading(false)
          return
        }
        if (response.status === 200) {
          setLoading(false)
          dispatch({ type: "handleCancelEntity" })
        }
        return response
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
      })
  }

  const onFinishFailed = () => {
    dispatch({ type: "handleState", payload: true, field: "entityErrors" })
  }

  const handleSetNip = (nipValue) => {
    if (state.allRelatedParties.find((e) => e.nip === nipValue)) {
      form.current.setFields([
        {
          name: "nip",
          errors: ["Dla wybranego NIP został już utworzony podmiot powiązany"],
        },
      ])
      return
    }
  }

  const handleDateChange = (_, t) => {
    form.current.setFields([{ name: "birth_date", value: t }])
  }

  const handleSetUSName = (val, d) => {
    const { name, code } = ussAll[d.key]
    form.current.setFields([{ name: "tax_office_code", value: code }])
  }

  const dateFormat = "DD/MM/YYYY"

  if (!uss.length) return <Loading />

  return (
    <div className="content-wrapper inner-shadow">
      {loading && <Loading fullSize />}
      <div className={`ClientPanel`}>
        <div className="header">
          <span>Podmioty</span>
          <div className="actions">
            <img
              alt="action icon"
              className="action-icon"
              src={closeIcon}
              onClick={() => dispatch({ type: "handleCheckCancelEntity", payload: !!state.tmpEntity })}
            />
            <img
              alt="action icon"
              className="action-icon"
              src={saveIcon}
              onClick={() => {
                tracker("Panel administratora - Podmioty - Zapisz")
                dispatch({ type: "handleSaveEntity" })
              }}
            />
          </div>
        </div>
        <div className="single-entity-edit">
          <FormModal
            visible={state.showEntityCancelModal}
            content={`Czy chcesz porzuić zmiany?`}
            onAccept={setConfirm}
            onDecline={setCancel}
            addClass="delModal"
            okButtonName="Porzuć"
          />
          <div className="select-holder">
            <span className="fake-label">Rodzaj podmiotu</span>
            <CustomSelect
              placeholder="Wybierz typ podmiotu"
              className="cutom-select"
              onChange={setEntity}
              value={entity}
              options={allEntities}
              styles={customStyles}
            />
          </div>

          {initial && (
            <Form
              {...layout}
              name="EntityEdit"
              initialValues={initial}
              ref={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={async ({ nip }) => {
                if (nip) {
                  try {
                    if (state.allRelatedParties.find((e) => e.nip === nip)) return

                    const { code, name } = await fetchUSCode()

                    form.current.setFields([
                      { name: "tax_office_code", value: code },
                      { name: "tax_office_name", value: name },
                    ])
                  } catch (error) {
                    console.error(error)
                  }
                }
              }}
            >
              {formFields.map((section, index) => (
                <div key={section} className="form-section">
                  <h2>{sectionNames[index]}</h2>
                  {section.map((fieldName) => (
                    <Form.Item
                      key={fieldName}
                      label={fieldsKeys[fieldName]}
                      name={fieldName}
                      hasFeedback
                      validateTrigger="onBlur"
                      rules={rules[fieldName]}
                    >
                      {fieldName === "birth_date" ? (
                        <Space direction="vertical">
                          <DatePicker
                            onChange={handleDateChange}
                            locale={locale}
                            defaultValue={
                              initial.birth_date &&
                              moment(new Date(initial.birth_date).toLocaleDateString(), dateFormat)
                            }
                            format={dateFormat}
                            placeholder="DD/MM/RRRR"
                            showToday={false}
                            disabledDate={(d) => !d || d.isAfter("2002-12-31")}
                            defaultPickerValue={
                              initial.birth_dates
                                ? moment(new Date(initial.birth_date).toLocaleDateString(), dateFormat)
                                : moment("2002/01/01", dateFormat)
                            }
                          />
                        </Space>
                      ) : fieldName === "tax_office_name" ? (
                        <Select
                          placeholder="Wybierz Urząd Skarbowy"
                          name="tax_office_name"
                          onChange={handleSetUSName}
                          className="dropdown-select"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                          showSearch
                        >
                          {uss.map((option) => (
                            <Option key={option.id} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                      ) : fieldName === "nip" ? (
                        <MaskedInput disabled={update} name="nip" mask="111-111-11-11" placeholder="NIP" />
                      ) : (
                        <Input name={fieldName} disabled={fieldName === "tax_office_code"} />
                      )}
                    </Form.Item>
                  ))}
                </div>
              ))}
            </Form>
          )}
        </div>
      </div>
    </div>
  )
}

const CreateDefaultEntity = () => {
  const dispatch = useContext(DispatchContext)
  const state = useContext(StateContext)

  return (
    <div className="filler-center">
      <span>Uzupełnij dane tego podmiotu powiązanego</span>
      <button
        className="form-button"
        type="button"
        styling="button-large"
        onClick={() => dispatch({ type: "handleFillEntity", payload: state.visibleEntities })}
      >
        Uzupełnij
      </button>
    </div>
  )
}

const rules = {
  nip: [
    (e) => ({
      required: true,
      validator() {
        const [takenMsg] = e.getFieldError("nip")
        if (takenMsg === "Dla wybranego NIP został już utworzony podmiot powiązany") {
          return Promise.reject("Dla wybranego NIP został już utworzony podmiot powiązany")
        }

        const nipValue = e.getFieldValue("nip")

        if (nipValue && nipValue.replace(/[-_]/g, "").length === 10) {
          return Promise.resolve()
        }

        return Promise.reject("NIP musi się składać z 10 cyfr!")
      },
    }),
  ],
  // walidator email?
  email: [
    {
      required: true,
      message: "Podaj e-mail!",
    },
    {
      max: 255,
      min: 3,
      message: "Wprowadzony e-mail jest niepoprawny!",
    },
    {
      type: "email",
      message: "Podaj poprawny e-mail!",
    },
  ],

  // czym jest kod us?
  // tax_office_code: [
  //   { required: true, message: "Podaj kod urzędu skarbowego!", whitespace: true },
  //   {
  //     pattern: /\d{3}/,
  //     message: "Kod urzędu skarbowego musi się składać z trzech cyfr!",
  //   },
  // ],
  tax_office_name: [{ required: true, message: "Wybierz urząd skarbowy!" }],
  full_name: [
    { required: true, message: "Podaj pełną nazwę!", whitespace: true },
    {
      max: 240,
      message: "Wprowadzona pełna nazwa jest niepoprawna!",
    },
  ],
  telefon: [
    {
      required: true,
      message: "Podaj numer telefonu!",
    },
    {
      pattern: /(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/,
      message: "Numer telefonu jest niepoprawny!",
    },
    {
      max: 16,
      message: "Wprowadzony numer telefonu jest niepoprawny!",
    },
  ],
  // CZY TO POWINIEN BYC WYBÓR DATY
  birth_date: [
    (e) => ({
      required: true,
      validator() {
        const dob = e.getFieldValue("birth_date")

        if (dob && dob.length >= 10) {
          return Promise.resolve()
        }

        return Promise.reject("Podaj datę urodzenia!")
      },
    }),
  ],
  name: [
    {
      whitespace: true,
      required: true,
      message: "Podaj imię!",
    },
    {
      max: 255,
      message: "Wprowadzone imię jest niepoprawne!",
    },
  ],
  surname: [
    {
      whitespace: true,
      required: true,
      message: "Podaj nazwisko!",
    },
    {
      max: 255,
      message: "Wprowadzone nazwisko jest niepoprawne!",
    },
  ],
}

export default AffiliatedEntityTable
