import React, { useContext } from "react"
import "../Navbar.less"
import { UserStore } from "../../../mobx/userStore"
import { SwitcherStore } from "../../../mobx/switcherStore"
import { Link } from "react-router-dom"

import x from "../../../images/x.svg"
import switcherDots from "../../../images/switcher_dots.svg"
import exampleApps from "./exampleApps"

import Context from "../../../context/index"

import getAppUrl from "../../../helpers/getAppUrl"

const AppSwitcher = () => {
  const { ctx, setCtx } = useContext(Context)

  const setApplication = (application) => {
    UserStore.setApplication(application)
  }

  const buttonClicked = (name) => {
    setCtx({ ...ctx, currentApp: name })
    SwitcherStore.setSwitcherVisible(false)
  }

  const { stateObject: applications } = ctx

  const enhancedApps = applications && applications.filter(({ name }) => name !== "Weryfikator kontrahenta")

  if (!applications || !applications.length) return <></>

  return (
    <div className="switcher-container">
      <div className="button-container">
        <img src={x} alt="close button icon" onClick={() => SwitcherStore.setSwitcherVisible(false)} className="x" />
      </div>
      <div className="Navbar-app-switcher">
        <Link key={"Dashboard"} to={"dashboard"} onClick={() => buttonClicked("Dashboard")}>
          <button
            className={ctx.currentApp === "Dashboard" ? "dashboard-app current-app" : "dashboard-app"}
            onClick={() => setApplication("Dashboard")}
          >
            <img src={switcherDots} alt="Four small dots in modal corner" />
            DASHBOARD
          </button>
        </Link>
        {enhancedApps
          .filter((app) => app.name !== "Dashboard")
          .map((app) => {
            const { name, colorTheme } = app
            const fixedName = name === "Sprawdź JPK_VAT" ? "J" : name[0]
            return (
              <Link
                key={name}
                to={getAppUrl(name)}
                onClick={() => buttonClicked(name)}
              >
                <div
                  className={
                    name.toLowerCase() === ctx.currentApp.toLowerCase() ? "Navbar-app current-app" : "Navbar-app"
                  }
                  onClick={() => setApplication(name)}
                >
                  <div className="char" style={{ backgroundColor: colorTheme ? colorTheme : "#2D98DA" }}>
                    {fixedName}
                  </div>
                  {name}
                </div>
              </Link>
            )
          })}
      </div>
    </div>
  )
}

export default AppSwitcher
