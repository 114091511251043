import React, { useEffect, useContext, useCallback } from "react"

import { DispatchContext, StateContext } from "components/userContext"
import API from "components/common/api"
import { Checkbox, Form } from "antd"

import infoIcon from "../../../images/info.svg"

const GTUEdit = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const fetchData = useCallback(async () => {
    // dictionary fetch once
    try {
      const {
        data: { stateObject },
      } = await API.get(`/VatRecordSale/getGtusDictionary`, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid"),
        },
      })

      dispatch({ type: "handleState", field: "gtuDictionary", payload: stateObject })
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (state.gtuDictionary) return
    fetchData()
  }, [])

  if (!state.gtuDictionary || !state.sellValues) return <></>

  return (
    <div className="transaction scroll">
      {state.gtuDictionary.map(({ code, short_description, full_description }) => {
        return (
          <div key={code} className="gtu-row">
            <Form.Item name={code.toLowerCase()} hasFeedback className="details-item" valuePropName="checked">
              <Checkbox>
                <>
                  <span style={{ fontWeight: "bold" }}>{code}</span>
                  <span>{short_description}</span>
                  <img src={infoIcon} alt="info" data-tip={full_description} />
                </>
              </Checkbox>
            </Form.Item>
          </div>
        )
      })}
    </div>
  )
}

export default GTUEdit
