import React from "react"
import { VerificationStatusMap, VerificationReasonMap } from "../VerificationStatusMap"
import { Tabs } from "antd"
import "./NipVerifierRowDetails.less"
import { VerificationStatusMfMap, VerificationStatusVIESMap } from "../VerificationStatusMap"

const { TabPane } = Tabs

const check = str => {
  if (str === undefined) return "undefined"
  if (str === null) return "-"
  return str
}

const getShortDate = (str) => {
  const checked = check(str)
  return (checked === str) ? str.substring(0, 10) : checked
}

function NipVerifierRowDetails(props) {

  let statusName = "-"
  let statusClass = ""
  const status = props.row.mf_status
  if (Object.keys(props.row).length === 0) return <></>
  if (props.row.mf_status != null) {
    statusName = VerificationStatusMfMap[props.row.mf_status].name
    statusClass = VerificationStatusMfMap[props.row.mf_status].style
  }

  if (props.row.vies_status != null) {
    statusName = VerificationStatusVIESMap[props.row.vies_status].name
    statusClass = VerificationStatusVIESMap[props.row.vies_status].style
  }

  return <div className="NipDetails-box">
    <Tabs>
      <TabPane tab="Dane zaimportowane" key="1" forceRender>
        <div className="nv-details">
          <ul>
            <li>
              <span className="label">Nazwa kontrahenta</span>
              <span className="label">{check(props.row.entity_name)}</span>
            </li>
            <li>
              <span className="label">Kod kraju</span>
              <span className="label">{check(props.row.country_code)}</span>
            </li>
            <li>
              <span className="label">NIP</span>
              <span className="label">{check(props.row.nip)}</span>
            </li>
            <li>
              <span className="label">Data importu</span>
              <span className="label">{getShortDate(props.row.import_date)}</span>
            </li>
            <li>
              <span className="label">Źródło importu</span>
              <span className="label">{check(props.row.data_source)}</span>
            </li>
          </ul>
        </div>
      </TabPane>
      <TabPane tab="Weryfikacja NIP" key="2" forceRender>
        <div className="nv-details">
          <ul>
            <li>
              <span className="label">NIP weryfikowany</span>
              <span className="label">{check(props.row.nip)}</span>
            </li>
            <li>
              <span className="label">Data weryfikacji</span>
              <span className="label">{getShortDate(props.row.verification_date)}</span>
            </li>
            {/* <li>
              <span className="label">Id weryfikacji z MF</span>
              <span className="label">{check(props.row.mf_verification_request_id)}</span>
            </li> */}
            <li>
              <span className="label">Id weryfikacji z VIES</span>
              <span className="label">{check(props.row.vies_verification_request_id)}</span>
            </li>
            <li>
              <span className="label">Status weryfikacji NIP w MF</span>
              <span className={".nv-details span.label " + VerificationStatusMfMap[props.row.mf_status].style}>{VerificationStatusMfMap[props.row.mf_status].name}</span>
            </li>
            <li>
              <span className="label">Status weryfikacji NIP w VIES</span>
              <span className={".nv-details span.label " + VerificationStatusVIESMap[props.row.vies_status].style}>{VerificationStatusVIESMap[props.row.vies_status].name}</span>
            </li>
          </ul>
        </div>
      </TabPane>
    </Tabs>
  </div >
}

export default NipVerifierRowDetails