import React, { useContext } from "react"

import { Select } from "antd"

import { DispatchContext, StateContext } from "../../../components/userContext"

const { Option } = Select

const SellTypeHeader = ({ options, handlerName, width = "180px" }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const handleSetType = (type) => {
    let filtered = []

    if (!type) {
      filtered = [...state[handlerName].filtered.filter((e) => e.id !== "type")]
    } else {
      filtered = [
        ...state[handlerName].filtered.filter((e) => e.id !== "type"),
        { id: "type", value: type.map((r) => r).join("|") },
      ]
    }

    dispatch({
      type: "handleState",
      field: handlerName,
      payload: { ...state[handlerName], filtered },
    })
  }

  return (
    <Select
      style={{ width }}
      dropdownStyle={{
        backgroundColor: "#FFFFFF",
        border: "1px solid #DDDDDD",
        borderRadius: "5px",
        padding: "15px 10px",
      }}
      mode="multiple"
      allowClear
      showArrow
      showSearch={false}
      placeholder="Typ dokumentu"
      name="typ_dokumentu"
      onChange={handleSetType}
      className="dropdown-select"
    >
      {options.map((option) => {
        return (
          <Option key={option} value={option}>
            {option}
          </Option>
        )
      })}
    </Select>
  )
}

export default SellTypeHeader
