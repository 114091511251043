import React, { useContext } from "react"
import "./Navbar.less"
import { Modal } from "antd"
import { SwitcherStore } from "../../mobx/switcherStore"
import { useObserver } from "mobx-react"

import AppSwitcher from "./subcomponents/AppSwitcher"
import SwitcherButton from "./subcomponents/OrgSwitcher/SwitcherButton"
import Context from "../../context/index"

import switcherDots from "../../images/switcher_dots.svg"

export const Navbar = () => {
  const { ctx } = useContext(Context)

  const index = ctx.stateObject && ctx.stateObject.findIndex((x) => x.name === ctx.currentApp)

  let theme = ""
  if (index >= 0) {
    theme = ctx.stateObject[index].colorTheme
  }

  return useObserver(() => {
    return (
      <>
        <div className="Navbar">
          <div className="header-container">
            {ctx.currentApp && (ctx.currentApp === "Dashboard" || ctx.currentApp === "JobsHistory") ? (
              <>
                <img
                  src={switcherDots}
                  alt="dashboard icon"
                  style={{
                    marginRight: "22px",
                  }}
                />
                <h1 className="Navbar-header">{"Dashboard"}</h1>
              </>
            ) : ctx.currentApp && ctx.currentApp !== "Panel Użytkownika" ? (
              <>
                <div className="char" style={{ backgroundColor: theme ? theme : "#2D98DA" }}>
                  {ctx.currentApp && ctx.currentApp === "Sprawdź JPK_VAT" ? "J" : ctx.currentApp[0]}
                </div>
                <h1 className="Navbar-header">{ctx.currentApp || "Dashboard"}</h1>
              </>
            ) : (
              <h1 className="Navbar-header">{ctx.currentApp || "Dashboard"}</h1>
            )}
          </div>
          <SwitcherButton />
        </div>
        <Modal
          style={{ top: 0, left: 0, display: "inline-block" }}
          visible={SwitcherStore.getSwitcherVisible()}
          footer=""
          width="fit-content"
          mask={false}
          bodyStyle={{ padding: 0 }}
          closable={false}
          onOk={() => SwitcherStore.setSwitcherVisible(false)}
          onCancel={() => SwitcherStore.setSwitcherVisible(false)}
        >
          <AppSwitcher />
        </Modal>
      </>
    )
  })
}

export default Navbar
