import React, { useContext } from "react"

import { StateContext } from "components/userContext"

import { Form, InputNumber } from "antd"

import infoIcon from "images/info.svg"

import { dash, p_38, p_48 } from "./DeclarationsTabFix"

const SummarySection = () => {
  const state = useContext(StateContext)

  return (
    <div className="Calc-summary">
      {[p_38, p_48].map((fieldName: string) => {
        const { full_description: FD } = state.declarationDictionaryFix.find(
          (elem: any) => elem.code === fieldName.toUpperCase()
        )

        return (
          <div className="calc-row content-row" key={fieldName} style={{ margin: "20px 0" }}>
            <div className="Tax-col Calc-col_one">
              <p>{fieldName === p_38 ? "Suma podatku należnego" : "Suma podatku naliczonego"}</p>
            </div>
            <div className="Tax-col Calc-col_two">
              <span className="const-name">{fieldName.toUpperCase()}</span>
              <span className="const-value">{state.declarationData[fieldName] || dash}</span>
              <div
                className="value"
                style={{
                  width: "auto",
                  paddingRight: "initial",
                }}
              >
                <Form.Item name={fieldName} hasFeedback validateTrigger="onBlur">
                  <InputNumber
                    type="number"
                    step={1}
                    bordered={false}
                    readOnly={true}
                    precision={0}
                    placeholder={dash}
                  />
                </Form.Item>
              </div>
              <div className="info">
                <img src={infoIcon} data-tip={fieldName.toUpperCase() + ": " + FD} />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default SummarySection
