import React from "react"
import "./Expired.less"
import Logo from "../common/Logo"
import { Link } from "react-router-dom"

const Expired = () => {
  return (
    <div className="Expired">
      <Logo style={{ marginBottom: 20 }} />
      <h1 style={{ fontWeight: 700, marginBottom: 15 }}>Link nieaktywny</h1>
      <p className="paragraph">Link do resetu hasła jest już nieaktualny. Może to wynikać z następujących powodów:</p>
      <p>1. Wygasł termin ważności linka</p>
      <p>2. Link został już wykorzystany do zmiany hasła</p>
      <p>3. Dla tego adresu email wygenerowany został nowszy link do resetu hasła.</p>
      <Link to="/login" className="ant-btn form-button" style={{ marginTop: "30px" }}>
        Zaloguj
      </Link>
    </div>
  )
}

export default Expired
