import React from "react"

export const objToMap = {
  data_zakupu: "Data zakupu",
  nr_dok_wew: "Numer dokumentu wewnętrznego",
  data_wplywu: "Data otrzymania",
  kod_kraju_nadania_tin: "Kod kraju",
  nazwa_dostawcy: "Nazwa kontrahenta",
  dowod_zakupu: "Numer dokumentu",
  nr_dostawcy: "Numer kontrahenta",
  clear: "",
  dokument_zakupu: "Typ dokumentu",
}

export const sortedKeys = [
  "kod_kraju_nadania_tin",
  "dowod_zakupu",
  "nr_dostawcy",
  "data_zakupu",
  "nazwa_dostawcy",
  "data_wplywu",
  "clear",
  "dokument_zakupu",
]

const ShoppingDefault = ({ elem }) => {
  return (
    <div className="details-box-default">
      {sortedKeys.map((key) => {
        let value = elem[key]

        if (key.includes("data")) {
          if (!elem[key]) {
            value = "-"
          } else {
            const [YY, MM, D] = elem[key].split("-")
            const [DD] = D.split("T")

            value = `${YY}.${MM}.${DD}`
          }
        }

        if (!elem[key]) {
          value = "-"
        }

        if (key === "clear") {
          value = ""
        }

        return (
          <div key={key} className="details-item present">
            <span>{objToMap[key]}</span>
            <span>{value}</span>
          </div>
        )
      })}
    </div>
  )
}

export default ShoppingDefault
