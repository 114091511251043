import React from "react"
import NotificationsModal from "./NotificationsModal"

const GetNotificatonModal = (modalType, data, closeModal) => {
    if (modalType === 'showErrors') {
        const { errors, message } = data
        let line1 = message
        let line2 = null

        if (message !== undefined && message.includes("Zobacz")) {
            line1 = message.split("Zobacz")[0]
            line2 = message.substring(message.indexOf("Zobacz"))
        }
        return <NotificationsModal title="Raport Błędów" closeModal={closeModal}
            messageLine1={line1}
            messageLine2={line2}
            contentLines={errors}
            fileLines={errors}
            fileName="errorLog.txt" />
    }
    if (modalType === 'ShowNonEuNips') {
        const request = JSON.parse(data.request)
        const lines = Object.keys(request).map((k) => k + " " + request[k])
        const content = Object.keys(request).map((k) => <><span className="NonEuNip">{k}</span><span>{request[k]}</span></>)
        return <NotificationsModal title="Raport" closeModal={closeModal}
            messageLine1="Poniższe Numery Identyfikacji Podatkowej nie zostały ujęte w deklaracji VAT UE."
            contentLines={content}
            fileLines={lines}
            fileName="Raport NIP inne niż unijne.txt"
            borderColor="#0FB9B1"
            titleColor="#0FB9B1" />
    }

    return ""
}

export default GetNotificatonModal