import React, { useContext, useEffect, useState } from "react"

//@ts-ignore
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"

import { columns } from "./columns"
import { DispatchContext, StateContext } from "components/userContext"
import { rowInfoType } from "./types"

import "./GateTable.less"

interface GateTableProps {
  rowsInfo: rowInfoType[]
}

export const GateTable = (props: GateTableProps) => {
  const { rowsInfo } = props

  const [step, setStep] = useState(1)

  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  //@ts-ignore
  const calculateCurrentStep = (rowsInfo) => {
    let tmpStep = 0

    if (rowsInfo["pelnaNazwa"]) {
      tmpStep = 1
    }

    if (rowsInfo["dataWygenerowania"]) {
      tmpStep = 2
    }

    if (rowsInfo["dataPodpisaniaMetadanych"]) {
      tmpStep = 3
    }

    if (rowsInfo["dataWyslania"]) {
      tmpStep = 4
    }

    if (rowsInfo["dataPobraniaUpo"]) {
      tmpStep = 5
    }

    return setStep(tmpStep)
  }

  //@ts-ignore
  const originalHandleClick = (_, handleOriginal) => {
    if (handleOriginal) {
      handleOriginal()
    }
  }

  //@ts-ignore
  const removeItem = (data) => {
    const tmp = [...state.selectedRowsGate]
    const index = tmp.indexOf(data)

    if (index !== -1) {
      tmp.splice(index, 1)
      dispatch({ type: "handleState", field: "selectedRowsGate", payload: tmp })
    }
  }

  //@ts-ignore
  const addItem = (index) => {
    dispatch({ type: "handleState", field: "selectedRowsGate", payload: [...state.selectedRowsGate, index] })
  }

  useEffect(() => {
    if (!rowsInfo) return

    calculateCurrentStep(rowsInfo[0])
  }, [rowsInfo])

  return (
    <div className="table">
      <VisualReprezentation step={step} />
      <ReactTable
        className="clean gateTable"
        showPagination={false}
        resizable={false}
        noDataText={""}
        NoDataComponent={() => null}
        data={rowsInfo}
        columns={columns}
        minRows={1}
        //@ts-ignore
        getTdProps={(_, rowInfo, column) => {
          return {
            onClick: ({ shiftKey }: { shiftKey: boolean }) => {
              // Expand row on expand cell click
              if (typeof rowInfo !== "undefined") {
                // add row selection on row click, except click on expand arrow
                if (column.id && state.selectedRowsGate.includes(rowInfo.original.id)) {
                  removeItem(rowInfo.original.id)
                } else {
                  if (shiftKey) {
                    addItem(rowInfo.original.id)
                  } else if (column.id) {
                    dispatch({ type: "handleState", field: "selectedRowsGate", payload: [rowInfo.original.id] })
                  }
                }
              }
            },
          }
        }}
        //@ts-ignore
        getTrProps={(_, rowInfo) => {
          if (typeof rowInfo !== "undefined") {
            return {
              style: {
                fontWeight: state.selectedRowsGate.includes(rowInfo.original.id) && "bold",
                background: state.selectedRowsGate.includes(rowInfo.original.id) && "#f4f4f4",
              },
            }
          } else {
            return {
              onClick: originalHandleClick,
            }
          }
        }}
      />
    </div>
  )
}

// @ts-ignore
const VisualReprezentation = ({ step }) => {
  const allSteps = [1, 2, 3, 4, 5]

  const activeArr = allSteps.filter((x) => x <= step)
  const active = activeArr.length

  return (
    <div className="gate-data-chart">
      {allSteps.map((s) => (
        <div className="dot-wrapper" key={s}>
          <div className={s <= active ? "active dot" : "dot"} />
        </div>
      ))}
    </div>
  )
}
