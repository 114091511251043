import React, { useEffect, useCallback, useContext, useState } from "react"
import ReactTooltip from "react-tooltip"

import infoIcon from "images/info.svg"

import API from "components/common/api"
import { DispatchContext, StateContext } from "components/userContext"

import { Checkbox, DatePicker } from "antd"
import { Loading } from "components/Main/Loading"

import moment from "moment"

const dateFormat = "DD.MM.YYYY"

const SalesTransactions = ({ formVariant }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  useEffect(() => {
    ReactTooltip.rebuild()

    if (state.sellDictionary) {
      setLoading(false)
      return
    }

    fetchData()
  }, [])

  const fetchData = useCallback(async () => {
    // dictionary fetch once
    setLoading(true)
    try {
      const {
        data: { stateObject },
      } = await API.get(`/VatRecordSale/getSellTransactionDictionary`, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid"),
        },
      })
      setLoading(false)
      dispatch({ type: "handleState", field: "sellDictionary", payload: stateObject })
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }, [])

  if (loading) return <Loading />

  if (!state.sellDictionary || !state.sellValues) return <></>

  const { sellValues } = state
  const codesWithVals = []

  const hasAnyK = Object.keys(sellValues)
    .map((key) => {
      if (!key.includes("k_")) return
      if (key === "nr_dok_wew") return
      if (sellValues[key] === null) return

      if (!isNaN(sellValues[key])) {
        codesWithVals.push(key)
        return true
      }
      return false
    })
    .filter(Boolean)

  const middleSectionDash = codesWithVals.filter((e) => e.split("k_")[1] * 1 >= 33 && e.split("k_")[1] * 1 <= 36)
  const showHeaderSection = codesWithVals.filter((e) => e.split("k_")[1] * 1 < 33).length > 0

  const [hasAnyVals] = [...new Set(hasAnyK)]

  return (
    <div className="transaction">
      <ReactTooltip
        effect="solid"
        className="tooltip-vat"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />

      {showHeaderSection && (
        <>
          <span className="transaction-header">Rozliczenie podatku należnego</span>

          <div className="transaction-header-values">
            <span />
            <div>
              <span>
                Podstawa
                <br /> opodatkowania w zł
              </span>
              <span>Podatek należny w zł</span>
            </div>
          </div>
        </>
      )}

      {hasAnyVals
        ? state.sellDictionary.map(({ code, short_description, full_description }, index) => {
          // SKIP IF NULL VALUE
          if (sellValues[code.toLowerCase()] === null) {
            return
          }

          console.log(code)
          const ignored = ["korekta_podstawy_opodt", "sprzedaz_vat_marza", "data_zaplaty", "termin_platnosci"]
          if (ignored.includes(code)) return <></>


          const [, codeNumber] = code.split("_")
          const onlySecondRow = codeNumber >= 33

          const paddingLeft = short_description.includes("w tym") && "20px"

          const nextElem = state.sellDictionary[index + 1]
          const prevElem = state.sellDictionary[index - 1]

          const anything = !isNaN(sellValues[code.toLowerCase()]) ? sellValues[code.toLowerCase()] : ""
          const antythingNext =
            nextElem && !isNaN(parseFloat(sellValues[nextElem.code.toLowerCase()]))
              ? nextElem
                ? sellValues[nextElem.code.toLowerCase()]
                : ""
              : ""

          const elemValue = anything.toString().replace(".", ",")
          const nextValue = antythingNext.toString().replace(".", ",")

          // SKIP DUPLICATED PREV ROWS
          if (prevElem && prevElem.short_description === short_description) return

          // HANDLE DOUBLE-VALUE ROWS
          if (nextElem && short_description === nextElem.short_description) {
            return (
              <div key={code} className={`transaction-row`}>
                <span>{short_description}</span>
                <div>
                  <span>
                    <span className="code-name-custom">{code}</span>
                    {elemValue}
                    <img src={infoIcon} alt="info" data-tip={full_description} data-place="left" />
                  </span>
                  <span>
                    <span className="code-name-custom">{nextElem.code}</span>
                    {nextValue}
                    <img src={infoIcon} alt="info" data-tip={nextElem.full_description} />
                  </span>
                </div>
              </div>
            )
          }

          // HANDLE SINGLE LAST ROWS
          if (onlySecondRow) {
            return (
              <div
                key={code}
                className={`transaction-row custom ${middleSectionDash[0] === code.toLowerCase() ? "has-dash" : ""}`}
              >
                <span>{short_description}</span>
                <div>
                  <span />
                  <span>
                    <span className="code-name-custom">{code}</span>
                    {elemValue}
                    <img src={infoIcon} alt="info" data-tip={full_description} />
                  </span>
                </div>
              </div>
            )
          }

          // HANDLE REGULAR ROW
          return (
            <div key={code} className={`transaction-row`}>
              <span
                style={{
                  paddingLeft,
                }}
              >
                {short_description}
              </span>
              <span>
                <span className="code-name-custom">{code}</span>
                {elemValue}
                <img src={infoIcon} alt="info" data-tip={full_description} />
              </span>
            </div>
          )
        })
        : !sellValues.korekta_podstawy_opodt &&
          (isNaN(sellValues.sprzedaz_vat_marza) || sellValues.sprzedaz_vat_marza === null)
          ? "Brak transakcji"
          : ""}

      {(sellValues.korekta_podstawy_opodt || !isNaN(sellValues.sprzedaz_vat_marza)) && (
        <>
          {sellValues.korekta_podstawy_opodt ? (
            <>
              <div className={`transaction-row checkbox ${hasAnyVals ? "has-dash" : ""}`}>
                <span>Korekta podstawy opodatkowania</span>
                <span>
                  <Checkbox checked={sellValues.korekta_podstawy_opodt} />
                  <img
                    src={infoIcon}
                    alt="info"
                    data-tip={state.sellDictionary.find(({ code }) => code === "korekta_podstawy_opodt").full_description}
                  />
                </span>
              </div>

            </>
          ) : (
            ""
          )}

          {sellValues.data_zaplaty ? (
            <div className={`transaction-row`}>
              <span>Data zapłaty</span>
              <div>
                <span>{moment(new Date(sellValues.data_zaplaty)).format(dateFormat)}</span>
                <span>
                  <img
                    src={infoIcon}
                    alt="info"
                    data-tip={state.sellDictionary.find(({ code }) => code === "data_zaplaty").full_description}
                  />
                </span>
              </div>
            </div>
          ) : (
            ""
          )}

          {sellValues.termin_platnosci ? (
            <div className={`transaction-row`}>
              <span>Termin płatności</span>
              <div>
                <span>{moment(new Date(sellValues.termin_platnosci)).format(dateFormat)}</span>
                <span>
                  <img
                    src={infoIcon}
                    alt="info"
                    data-tip={state.sellDictionary.find(({ code }) => code === "termin_platnosci").full_description}
                  />
                </span>
              </div>
            </div>
          ) : (
            ""
          )}

          {!isNaN(sellValues.sprzedaz_vat_marza) && sellValues.sprzedaz_vat_marza !== null && (
            <div className={`transaction-row custom ${!sellValues.korekta_podstawy_opodt ? "has-dash" : ""}`}>
              <span>Wartość sprzedaży brutto na zasadach marży (art. 119 i 120 ustawy)</span>
              <div>
                <span />
                <span>
                  {sellValues.sprzedaz_vat_marza}
                  <img
                    src={infoIcon}
                    alt="info"
                    data-tip={state.sellDictionary.find(({ code }) => code === "sprzedaz_vat_marza").full_description}
                  />
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default SalesTransactions
