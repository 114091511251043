import React, { useContext, useRef, useEffect, useState } from "react"
import Context from "../../context/index"
import { Form, Input } from "antd"
import MaskedInput from "antd-mask-input"
import Countries from "../Register/subcomponents/Countries"
import API from "../common/api"
import Rules from "../common/Rules"
import "./ClientEdit.less"

import SavedChanges from "../ClientPanelModals/SavedChanges"
import UnsavedChanges from "../ClientPanelModals/UnsavedChanges"
import FormModal from "../common/FormModal"
import { Loading } from "components/Main/Loading"

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const ClientEdit = () => {
  const { ctx, setCtx } = useContext(Context)
  const [country, setCountry] = useState(ctx.organizationDetalis.country)
  const [confirm, setConfirm] = useState(false)
  const [cancel, setCancel] = useState(false)

  const initialValues = {
    organization_name: ctx.organizationDetalis.organization_name,
    nip: ctx.organizationDetalis.nip,
    city: ctx.organizationDetalis.city,
    address: ctx.organizationDetalis.address,
    post_code: ctx.organizationDetalis.post_code,
    country: ctx.organizationDetalis.country,
  }

  const [blockModal, setBlockModal] = useState(false)

  const [loading, setLoading] = useState(false)

  const form = useRef(null)

  const onFinish = (values) => {
    const tmpVals = { ...values, country }

    setCtx({ ...ctx, clientEditValues: tmpVals })
  }

  const onFinishFailed = (errorInfo) => {
    setCtx({ ...ctx, errors: true })
  }

  const handleRestoreState = ({ clientEditModal }) =>
    setCtx({
      ...ctx,
      save: false,
      clientEditValues: null,
      clientEditModal: clientEditModal,
      handleSubmitClientForm: false,
      clientEditModalMiddleware: false,
      errors: false,
      cancelModal: false,
    })

  useEffect(() => {
    if (ctx.handleSubmitClientForm) {
      form.current.submit()
    }
  }, [ctx.handleSubmitClientForm])

  useEffect(() => {
    if (!ctx.save && ctx.errors) {
      // FORM ERRORS CANCEL ACTION

      setCtx({ ...ctx, cancelModal: true })
      return
    }

    // FORM ERRORS SAVE ACTION
    return handleRestoreState({ clientEditModal: true })
  }, [ctx.errors])

  useEffect(() => {
    if (!confirm) return

    setConfirm(false)

    return setCtx({
      ...ctx,
      clientEditValues: null,
      clientEditModal: false,
      handleSubmitClientForm: false,
      clientEditModalMiddleware: false,
      errors: null,
      cancelModal: false,
    })
  }, [confirm])

  useEffect(() => {
    if (!cancel) return

    setConfirm(false)

    return handleRestoreState({ clientEditModal: true })
  }, [cancel])

  useEffect(() => {
    if (ctx.clientEditValues) {
      const inputNames = Object.keys(ctx.clientEditValues)
      const hasChanged = []

      inputNames.map((name) => {
        if (ctx.clientEditValues[name] !== ctx.organizationDetalis[name]) {
          hasChanged.push(name)
        }
      })

      if (!hasChanged.length) {
        return setCtx({
          ...ctx,
          clientEditValues: null,
          clientEditModal: false,
          handleSubmitClientForm: false,
          clientEditModalMiddleware: false,
        })
      }

      if (!ctx.save) {
        // ANULUJ
        return setCtx({ ...ctx, cancelModal: true })
      } else {
        // ZAPISZ
        // POST WITH DATA TO SEND
        handleDataSend()
      }
    }
  }, [ctx.clientEditValues])

  const handleBlockModal = () => {
    if (form && form.current) {
      form.current.setFieldsValue({
        organization_name: ctx.organizationDetalis.organization_name,
        nip: ctx.organizationDetalis.nip,
        city: ctx.organizationDetalis.city,
        address: ctx.organizationDetalis.address,
        post_code: ctx.organizationDetalis.post_code,
        country: ctx.organizationDetalis.country,
      })
    }

    setBlockModal(false)
    handleRestoreState({ clientEditModal: true })
  }

  // API POST
  const handleDataSend = async () => {
    setLoading(true)
    try {
      const clientEditData = { ...ctx.clientEditValues, guid: ctx.currentOrganization.guid }
      const response = await API.post("/Dashboard/organizationDetalis", clientEditData)

      setLoading(false)

      if (response.data.isValid) {
        setCtx({
          ...ctx,
          handleSubmitClientForm: false,
          save: false,
          organizationDetalis: { ...response.data.stateObject },
          clientEditValues: null,
          currentOrganization: { ...ctx.currentOrganization, name: response.data.stateObject.organization_name },
          clientEditModal: false,
          clientEditModalMiddleware: false,
          errors: false,
        })
      }
    } catch (error) {
      if (error.response.status === 403) {
        setBlockModal(true)
        setLoading(false)
        return
      }

      if (error.response.status === 400) {
        setBlockModal(true)
        setLoading(false)
        return
      }

      setLoading(false)
      console.error(error)
    }
  }

  if (loading) return <Loading />

  return (
    <>
      <FormModal
        visible={blockModal}
        onAccept={handleBlockModal}
        content="Nie możesz zmienić numeru NIP organizacji ponieważ na powiązanym podmiocie zostały już wykonane operacje ewidencji"
      />
      <Form
        {...layout}
        name="ClientEdit"
        ref={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="ClientEdit has-before content"
      >
        <div className="image-container">
          <div className="initials-img">{ctx.organizationDetalis.organization_name}</div>
        </div>
        <Form.Item
          label="Nazwa organizacji"
          name="organization_name"
          hasFeedback
          validateTrigger="onBlur"
          rules={Rules.organization_name}
        >
          <Input />
        </Form.Item>

        <Form.Item label="NIP" name="nip" hasFeedback validateTrigger="onBlur" rules={Rules.nip}>
          <MaskedInput mask="111-111-11-11" name="nip" placeholder="123-456-78-90" />
        </Form.Item>

        <Form.Item label="Adres firmy" name="address" hasFeedback validateTrigger="onBlur" rules={Rules.address}>
          <Input placeholder="Ulica, nr budynku, nr lokalu" />
        </Form.Item>

        <Form.Item label="Kod pocztowy" name="post_code" hasFeedback validateTrigger="onBlur" rules={Rules.post_code}>
          <MaskedInput mask="11-111" name="postCode" placeholder="00-000" />
        </Form.Item>

        <Form.Item label="Miejscowość" name="city" rules={Rules.city} hasFeedback validateTrigger="onBlur">
          <Input placeholder="Miejscowość" />
        </Form.Item>

        <Form.Item label="Kraj" name="country" hasFeedback validateTrigger="onBlur" rules={Rules.country}>
          <Countries handleCountry={setCountry} country={country} />
        </Form.Item>
      </Form>

      <FormModal
        visible={ctx.cancelModal}
        onAccept={setConfirm}
        onDecline={setCancel}
        content="Czy chcesz porzucić zmiany?"
        cancelButton
      />
      {/* <UnsavedChanges setConfirm={setConfirm} setCancel={setCancel} /> */}
    </>
  )
}

export default ClientEdit
