import React from "react"
import { history } from "App"

import arrowIcon from "images/icons/arrow.svg"
import { tracker } from "helpers/tracker"

import linia from "../../../../images/linia.svg"
import plik_do_podpisu from "../../../../images/plik_do_podpisu.svg"
import blad_wysylki from "../../../../images/blad_wysylki.svg"
import plik_bledny from "../../../../images/plik_bledny.svg"
import pobierz_upo from "../../../../images/pobierz_upo.svg"
import podglad_upo from "../../../../images/podglad_upo.svg"
import plik_do_wysylki from "../../../../images/plik_podpisany.svg"

const statusToIconMap = {
  "-": linia,
  "plik do podpisu": plik_do_podpisu,
  "plik do wysyłki": plik_do_wysylki,
  "błąd wysyłki": blad_wysylki,
  "pobierz UPO": pobierz_upo,
  "plik błędny": plik_bledny,
  "podgląd UPO": podglad_upo,
}

const statusToTipMap = {
  "plik do podpisu": "plik do podpisu",
  "plik do wysyłki": "plik do wysyłki",
  "błąd wysyłki": "błąd wysyłki",
  "pobierz UPO": "pobierz UPO",
  "plik błędny": "plik błędny",
  "podgląd UPO": "podgląd UPO",
}

export const columns = (ctx) => {
  return [
    {
      expander: true,
      Expander: ({ isExpanded, ...row }) => {
        if (!row.original.skorygowana) {
          return null
        } else {
          return (
            <div>
              <span className={`expandIcon ${isExpanded ? "open" : "close"}`}>
                <img src={arrowIcon} />
              </span>
            </div>
          )
        }
      },
    },
    {
      Header: () => (
        <>
          Rok <br />i miesiąc
        </>
      ),
      accessor: "rok_i_miesiac",
      width: 100,
      Cell: (props) => {
        return (
          <span
            className="react-date"
            onClick={() => {
              tracker("Sprawdź_JPK - Szczegóły ewidencji")
              history.push(`?id=${props.row._original.id}`)
            }}
          >
            {props.original.rok}.{props.original.miesiac}
          </span>
        )
      },
    },
    {
      Header: () => (
        <>
          Cel
          <br /> złożenia
        </>
      ),
      accessor: "cel_zlozenia",
      Cell: ({ value }) => {
        return <span> {value === 1 ? "złożenie" : "korekta"} </span>
      },
    },
    {
      Header: () => (
        <>
          Data eksportu
          <br /> pliku JPK_V7
        </>
      ),
      accessor: "data_wytworzenia_jpk",

      Cell: ({ value }) => {
        return <span> {value ? value : "-"} </span>
      },
    },
    {
      Header: "Pełna nazwa",
      accessor: "pelna_nazwa",
    },
    {
      Header: "NIP",
      accessor: "nip",
    },
    {
      Header: () => (
        <>
          Dokumenty
          <br /> sprzedaży
        </>
      ),

      accessor: "liczba_wierszy_sprzedazy",
    },
    {
      Header: () => (
        <>
          Podatek
          <br /> należny
        </>
      ),
      accessor: "podatek_nalezny",
      Cell: (row) => {
        return row.value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          .replace(".", ",")
      },
    },
    {
      Header: () => (
        <>
          Dokumenty
          <br /> zakupu
        </>
      ),
      accessor: "liczba_wierszy_zakupow",
    },
    {
      Header: () => (
        <>
          Podatek
          <br /> naliczony
        </>
      ),
      accessor: "podatek_naliczony",
      Cell: (row) => {
        return row.value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          .replace(".", ",")
      },
    },
    {
      Header: () => (
        <>
          Status
          <br /> wysyłki
        </>
      ),
      width: 100,
      accessor: "status_bramki",
      show: !ctx.featureFlags.Generator_GateDisabled,
      className: "status-icons-position",
      Cell: (row) => {
        return (
          <img
            className="status-icons-position"
            data-tip={statusToTipMap[row.value] ? statusToTipMap[row.value] : null}
            src={statusToIconMap[row.value]}
            alt="status icon"
            className="status-header"
          />
        )
      },
    },
    {},
  ]
}

export const subColumns = (ctx) => [
  {
    Header: () => (
      <>
        Status
        <br /> wysyłki
      </>
    ),
    accessor: "status_bramki",
    show: !ctx.featureFlags.Generator_GateDisabled,
    Cell: (row) => {
      return (
        <img
          data-tip={statusToTipMap[row.value] ? statusToTipMap[row.value] : null}
          src={statusToIconMap[row.value]}
          alt="status icon"
          className="status-header"
        />
      )
    },
  },
  ...columns,
]

export const originalHandleClick = (_, handleOriginal) => {
  if (handleOriginal) {
    handleOriginal()
  }
}

export const inputStyles = {
  padding: "3px 15px",
  margin: "10px auto 10px 35px",
  WIDTH: "150PX",
  height: "40px",
  outline: "none",
  borderRadius: "5px",
  border: "1px solid #dedede",
}
