import React, { useContext, useState, useEffect } from "react"
import ReactTable from "react-table-v6"
import Button from "components/common/Button"
import ReactTooltip from "react-tooltip"

import Context from "context"
import { DispatchContext, StateContext } from "components/userContext"

import { history } from "App"

import { Loading } from "components/Main/Loading"
import CustomPagination from "components/CustomPagination/CustomPagination"

import API from "components/common/api"

import { columns } from "./JobsData"

import "./JobsHistory.less"
import JobsHistoryFilters from "./JobsHistoryFilters"
import { getFilterSegment, getDateTimeRangeFilter } from "./UrlHelper"
import { Space } from "antd"


const JobsHistory = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const { ctx, setCtx } = useContext(Context)

  const [filterState, setFilterState] = useState({})
  const [showClearFiltersButton, setShowClearFiltersButton] = useState(false)

  useEffect(() => {
    setCtx({ ...ctx, currentApp: "JobsHistory" })
  }, [])
  useEffect(() => {
    ReactTooltip.rebuild()
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const anyFilterIsSet = state.jobsTableOptions.filtered.length > 0 ||
      state.jobsTableOptions.filteredUsers.length > 0 ||
      state.jobsTableOptions.filteredOperationStatuses.length > 0 ||
      state.jobsTableOptions.filteredApplications.length > 0 ||
      state.jobsTableOptions.filteredOperations.length > 0 ||
      state.jobsTableOptions.filterDateTimeFrom !== null ||
      state.jobsTableOptions.filterDateTimeUntil !== null
    setShowClearFiltersButton(anyFilterIsSet)
  }, [state.jobsTableOptions])



  function handleFiltersButtonClick() {
    dispatch({
      type: "handleState",
      field: "jobsTableOptions",
      payload: {
        ...state.jobsTableOptions,
        filtersPanelExpanded: !state.jobsTableOptions.filtersPanelExpanded
      }
    })
  }

  const getJobsDtoStatuses = (indices) => {
    let ret = []
    if (indices.includes(1)) ret = ret.concat([0, 1, 2])
    if (indices.includes(2)) ret = ret.concat([3, 4])
    if (indices.includes(3)) ret = ret.concat([5])
    return ret
  }

  const getFilterQuery = () => {
    const filter = state.jobsTableOptions.filtered.length > 0 ? state.jobsTableOptions.filtered[0].value : ""
    const allFiltersQuery = [
      filter !== "" ? "search@=" + filter : "",
      getFilterSegment("userId", state.jobsTableOptions.filteredUsers),
      getFilterSegment("operationName", state.jobsTableOptions.filteredOperations),
      getFilterSegment("status", getJobsDtoStatuses(state.jobsTableOptions.filteredOperationStatuses)),
      getFilterSegment("applicationId", state.jobsTableOptions.filteredApplications)
    ].filter(x => x.length > 0)

    let filterQuery = ""
    if (allFiltersQuery.length > 0) {
      filterQuery = "filters=" + allFiltersQuery.join(",")
    }

    const dateTimeRangeQuery = getDateTimeRangeFilter(state.jobsTableOptions.filterDateTimeFrom, state.jobsTableOptions.filterDateTimeUntil)
    if (dateTimeRangeQuery !== "") {
      if (filterQuery !== "") {
        filterQuery = filterQuery + "&"
      }
      filterQuery = filterQuery + dateTimeRangeQuery
    }

    return filterQuery
  }

  useEffect(() => {
    //Ten warunek powinien zapewnić, żeby nie próbował jeszcze raz wołać danych podczas gdy te są już ładowane
    if (!loading &&
        Object.entries(filterState).length !== 0 && 
        (state.commandEvent && state.commandEvent.actionName === "jobs_fetch_data")) 
    {
      fetchSortData(filterState);
    }
  }, [state.commandEvent]);

  let [currentPageIndex, setCurrentPageIndex] = useState(0)

  const fetchSortData = (sortData) => {
    setLoading(true)

    const filterQuery = getFilterQuery()
    var sorts = "-creationDateTime"
    if (sortData.sortedValue !== undefined && sortData.sortedValue !== '') {
      sorts = sortData.sortedValue
    }
    const url = `/Jobs/getJobs?CurrentOrganizationGuid=${ctx.currentOrganization.guid}&sorts=${sorts}&page=${sortData.innerPage + 1}&pageSize=${sortData.innerPageSize}&${filterQuery}`

    API.get(url)
      .then(
        ({
          data: {
            stateObject: { results: stateObject, ...tableParams },
          },
        }) => {
          stateObject.forEach(element => {
            element.creationTime = element.creationDateTime.substring(11, 16)
          });
          dispatch({
            type: "handleState",
            field: "jobsData",
            payload: stateObject,
          })
          dispatch({
            type: "handleState",
            field: "jobsTableOptions",
            payload: {
              ...state.jobsTableOptions,
              ...tableParams,
              currentPage: sortData.innerPage + 1,
              pageSize: sortData.innerPageSize
            },
          })
          dispatch({
            type: "handleState",
            field: "jobsCounter",
            payload: 0
          })
          setLoading(false)
        }
      )
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const clearFilters = () => {
    dispatch({
      type: "handleState",
      field: "jobsTableOptions",
      payload: {
        ...state.jobsTableOptions,
        filtered: [],
        filteredUsers: [],
        filteredOperationStatuses: [],
        filteredApplications: [],
        filteredOperations: [],
        filterDateTimeFrom: null,
        filterDateTimeUntil: null
      },
    })
  }

  const showFiltersPanel = state.jobsTableOptions.filtersPanelExpanded

  return <div className="content-wrapper inner-shadow">
    <div className="JobsHistory">
      <ReactTooltip
        effect="solid"
        className="tooltip"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />
      <div className="header">
        <span>Historia operacji</span>
        <div>
          {showClearFiltersButton ? <span className="clear-filters noselect" onClick={() => clearFilters()}>wyczyść wszystko</span> : null}


          <Button
            name="Filtruj"
            type="button"
            styling="button-small-white"
            txtColor="#4b6584"
            onClick={handleFiltersButtonClick}
          />
        </div>
      </div>

      {showFiltersPanel ? <JobsHistoryFilters tableOptionsId="jobsTableOptions"> </JobsHistoryFilters> : null}

      <ReactTable
        manual
        className="jobsTable wlTable_records"
        style={{
          height: (showFiltersPanel ? "calc(100% - 170px)" : "100%")
        }}
        data={state.jobsData}
        columns={columns(history, state, dispatch)}
        multiSort={false}
        loading={loading}
        noDataText={""}
        NoDataComponent={() => null}
        LoadingComponent={(props) => {
          if (props.loading) {
            return <Loading absolute />
          }
          return <></>
        }}
        resizable={false}
        PaginationComponent={(params) => (
          <CustomPagination currentPage={state.jobsTableOptions.currentPage} {...params} />
        )}
        expanded={state.jobsTableOptions.expanded}
        //page={currentPageIndex}
        onPageChange={newPage => {
          currentPageIndex = newPage
          setCurrentPageIndex(newPage)
        }}
        pages={state.jobsTableOptions.totalPagesNumber}
        defaultPageSize={10}
        filtered={state.jobsTableOptions.filtered}
        onFetchData={(innerState) => {
          setLoading(true)

          const filterValue = (innerState.filtered.length && innerState.filtered[0].value) || ""
          const sortedValue = innerState.sorted.length === 1 ?
            (innerState.sorted[0].desc ? "-" : "") + innerState.sorted[0].id : ""
          const filters = {
            filterValue,
            sortedValue,
            innerPage: currentPageIndex,
            innerPageSize: innerState.pageSize,
          }
          setFilterState(filters)
          fetchSortData(filters)
        }}
      />
    </div>
  </div>
}

export default JobsHistory