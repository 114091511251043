import React from "react"
import ReactDOM from "react-dom"

import LogRocket from "logrocket"

import * as Sentry from "@sentry/react"
import * as serviceWorker from "./serviceWorker"

import { Integrations } from "@sentry/tracing"

import { isLogrocketHidden } from "./components/common/api"

import App from "./App"

import "./index.css"

// PROD ON

Sentry.init({
  dsn: "https://a1fbc200fcdf47eea71ee8093712f362@o514709.ingest.sentry.io/5618365",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

if (!isLogrocketHidden) {
  LogRocket.init("ec2/insight")
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL)
    })
  })
}

ReactDOM.render(<App />, document.getElementById("root"))
serviceWorker.unregister()
