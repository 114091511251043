import { observable } from "mobx";

interface IUser {
  username: string;
  roles: [string];
  organizations: [IOrganizations];
  token: string;
  refreshToken: string;
}

interface IOrganizations {
  guid: string;
  licences: [ILicences];
  name: string;
  organizationRoles: [string];
}

interface ILicences {
  applicationId: number;
  applicationName: string;
  applicationRoles: [string];
  expirationDate: Date;
}

export const UserStore = {
  token: observable.box<string>(""),
  setToken: (token: string) => UserStore.token.set(token),
  getToken: () => UserStore.token.get(),

  refreshToken: observable.box<string>(""),
  setRefreshToken: (token: string) => UserStore.refreshToken.set(token),
  getRefreshToken: () => UserStore.refreshToken.get(),

  username: observable.box<string>(""),
  setUsername: (email: string) => UserStore.username.set(email),
  getUsername: () => UserStore.username.get(),

  application: observable.box<string>(""),
  setApplication: (application: string) =>
    UserStore.application.set(application),
  getApplication: () => UserStore.application.get(),

  organization: observable.box<string>(""),
  setOrganization: (organization: string) =>
    UserStore.organization.set(organization),
  getOrganization: () => UserStore.organization.get(),

  users: observable(new Array<IUser>()),
  addUser: (user: IUser) => {
    UserStore.users.push(user);
  },

  // removeUser: (id: string) => {
  //   UserStore.users.replace(UserStore.users.filter((note) => note.id !== id));
  // },
};

export const createUserStore = () => {
  return UserStore;
};
export type IUserStores = ReturnType<typeof createUserStore>;
