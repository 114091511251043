import React, { FC } from "react"
import "./common.less"
import { Button } from "antd"
import { Spin } from "antd"

interface Props {
  name?: string
  loading?: boolean
  style?: React.CSSProperties
  onClick?: any
  type?: any
  handleClick?: () => void
}

const Logo: FC<Props> = (props) => {
  return (
    <Button
      type={props.type || "submit"}
      htmlType={props.type || "submit"}
      className="form-button"
      style={props.style}
      onClick={props.handleClick}
      disabled={props.loading}
    >
      <span>
        {props.loading && <Spin />}
        {props.name}
      </span>
    </Button>
  )
}

export default Logo
