import React, { useContext } from "react"
import { Dropdown } from "antd"
import "../../Navbar.less"

import SwitcherContent from "./SwitcherContent"
import Context from "../../../../context/index"
import { tracker } from "helpers/tracker"

const SwitcherButton = () => {
  const { ctx, setCtx } = useContext(Context)

  const handleVisibleChange = (flag: boolean) => {
    tracker("Org switcher")
    setCtx({ ...ctx, orgSwircherVisible: flag })
  }

  return (
    <div>
      {ctx && ctx.otherOrganizations && ctx.otherOrganizations.length ? (
        <Dropdown
          overlay={() => <SwitcherContent />}
          placement="bottomCenter"
          trigger={["click"]}
          onVisibleChange={handleVisibleChange}
          visible={ctx.orgSwircherVisible}
        >
          <div className="buttonContainer">
            <button className="orgSwitcherButton">
              <div className="char">{ctx.currentOrganization.name[0]}</div> <span>{ctx.currentOrganization.name}</span>
            </button>
          </div>
        </Dropdown>
      ) : (
        <Dropdown
          overlay={() => <div />}
          placement="bottomCenter"
          trigger={["click"]}
          onVisibleChange={handleVisibleChange}
        >
          <div className="buttonContainer">
            <button className="orgSwitcherButton">
              <div className="char">{ctx.currentOrganization.name[0]}</div> <span>{ctx.currentOrganization.name}</span>
            </button>
          </div>
        </Dropdown>
      )}
    </div>
  )
}

export default SwitcherButton
