import React from "react"

import { GoBack } from "./GoBack"
import { GateActionButtons } from "./GateActionButtons"
import { gateInfoType, rowInfoType } from "./types"

import "./GateNavigation.less"

interface GateNavigationProps {
  rowsInfo: rowInfoType[]
  gateInfo: gateInfoType
  fetchData: () => void
}

export const GateNavigation = (props: GateNavigationProps) => {
  const { rowsInfo, gateInfo, fetchData } = props
  return (
    <div className="navigation">
      <GoBack />
      <GateActionButtons rowsInfo={rowsInfo} gateInfo={gateInfo} fetchData={fetchData} />
    </div>
  )
}
