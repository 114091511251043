import React, { useContext, useEffect, useState, useMemo, useRef } from "react"
import { DatePicker, TimePicker, Divider, Space, Button } from "antd"

import locale from "antd/es/date-picker/locale/pl_PL"
import moment from "moment"
import "moment/locale/pl"

const { RangePicker } = DatePicker;
const dateFormat = "DD.MM.YYYY HH:mm"

export const CloudTaxRangePicker = ({ handleDates, from, to, className, table }) => {
    const [isPickerOpened, setIsPickerOpened] = useState(false)
    const datePickerRef = useRef(null)
    const [fromTime, setFromTime] = useState(moment('00:00', 'HH:mm'))
    const [toTime, setToTime] = useState(moment('23:59', 'HH:mm'))

    const dateFormat = 'YYYY/MM/DD HH:mm';

    let [fromDate, setFromDate] = useState(from === null ? undefined : from)
    let [toDate, setToDate] = useState(to === null ? undefined : to)

    console.log("from " + from)
    console.log("to " + to)
    // if (datePickerRef !== null && datePickerRef.current !== null) {
    //     if (from !== fromDate) {
    //         setFromDate(from)
    //         console.log("set from " + from)
    //     }
    //     if (to !== toDate) {
    //         setToDate(to)
    //     }
    // }

    const mergeMoments = (date, time) => {
        const newMoment = moment({
            year: date.year(), month: date.month(), date: date.date(),
            hour: time.hour(), minute: time.minute(), second: 0
        })
        return newMoment
    }

    const timePicker = ({ defaultValue, setter, date, dateSetter }) => <TimePicker defaultValue={defaultValue}
        showNow={false}
        onOk={m => {
            setter(m)
            const newMoment = mergeMoments(date, m)
            dateSetter(newMoment)
        }}
        format="HH:mm" allowClear={false} suffixIcon={() => { }}></TimePicker>

    const timeRangePicker = <>
        <Space className="picker-time-range-row">
            <span>od:</span>
            {timePicker({ defaultValue: fromTime, setter: setFromTime, date: fromDate, dateSetter: setFromDate })}
            <span>do:</span>
            {timePicker({ defaultValue: toTime, setter: setToTime, date: toDate, dateSetter: setToDate })}
        </Space>
        <Space style={{
            "display": "flex",
            "justify-content": "flex-end"
        }}>
            <Button className="ant-btn ant-btn-primary ant-btn-sm" onClick={() => {
                setIsPickerOpened(false)
                handleDates(fromDate, toDate)
            }}>OK</Button>
        </Space>
    </>

    return <RangePicker
        dropdownClassName="JobsHistoryRangePicker"
        className={className}
        style={{
            "display": "flex",
            "justifyContent": "space-between",
            "alignItems": "center",
            "color": "#233B53",
            "borderColor": "#DDDDDD",
        }}
        name={"rangePicker"}
        ref={datePickerRef}
        locale={locale}

        onChange={(m, _, e) => {
            if (m === null) {
                setFromDate(null)
                setToDate(null)
                return
            }
            if (m[0] !== null) {
                const f = mergeMoments(m[0], fromTime)
                fromDate = f
                setFromDate(f)
            }
            if (m[1] !== null) {
                const t = mergeMoments(m[1], toTime)
                toDate= t
                setToDate(t)
            }
        }
        }
        format={dateFormat}
        value={[table.filterDateTimeFrom, table.filterDateTimeUntil]}
        showToday={false}
        renderExtraFooter={() => timeRangePicker}
        open={isPickerOpened}
        onOpenChange={(open) => {
            if (open === true) {
                setIsPickerOpened(open)
            }

        }}
    // onBlur={e => {

    //     setIsPickerOpened(false)
    // }}
    />
}