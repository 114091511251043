import React from "react"
import "./common.less"

import logo from "../../images/CLOUDTAX-GWW.png"

const LeftSide = ({ center, small }) => {
  return (
    <div className={center ? "left-side center" : small ? "left-side-small" : "left-side"}>
      <div className="top">
        <img src={logo} alt="logo" className="logo" />
      </div>
    </div>
  )
}

export default LeftSide
