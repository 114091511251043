import React, { useEffect, useCallback, useContext, useState } from "react"

import SalesDefault from "./SalesDefault"
import SalesTransactions from "./SalesTransactions"
import SalesGtu from "./SalesGtu"
import SalesProcedure from "./SalesProcedure"

import API from "components/common/api"
import { DispatchContext } from "components/userContext"

import { Tabs } from "antd"
import { Loading } from "components/Main/Loading"
import moment from "moment"
const { TabPane } = Tabs

const DetailsSalesBox = ({ elem, formVariant }) => {
  const dispatch = useContext(DispatchContext)
  const [loading, setLoading] = useState(true)

  const fetchValues = useCallback(async () => {
    setLoading(true)
    try {
      const response = await API.get(
        `VatRecordSale/getVatRecordSale?id=${elem.id}`,
        {},
        {
          headers: {
            tenant_id: localStorage.getItem("currentGuid"),
          },
        }
      )

      let {
        data: { stateObject },
      } = response

      stateObject = {
        ...stateObject,
        termin_platnosci: stateObject.termin_platnosci !== null ? moment(stateObject.termin_platnosci) : null,
        data_zaplaty: stateObject.data_zaplaty !== null ? moment(stateObject.data_zaplaty) : null
      }

      setLoading(false)

      dispatch({ type: "handleState", field: "sellValues", payload: stateObject })
      dispatch({ type: "handleState", field: "salesElem", payload: stateObject })
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }, [elem.id])

  useEffect(() => {
    fetchValues()
  }, [elem.id])

  if (loading) return <Loading />

  return (
    <div className="details-box">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Dane podstawowe" key="1">
          <SalesDefault elem={elem} />
        </TabPane>
        <TabPane tab="Rodzaj transakcji" key="2">
          <SalesTransactions formVariant={formVariant} />
        </TabPane>
        <TabPane tab="GTU" key="3">
          <SalesGtu />
        </TabPane>
        <TabPane tab="Procedury" key="4">
          <SalesProcedure />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default DetailsSalesBox
