import React from "react"
import Button from "components/common/Button"

const NoSubjectInfo = ({ onClose }) => {
  return (
    <div className="nosubject-info">
      <p>Musisz najpierw dodać podmiot powiązany</p>
      <Button name="ok" type="button" styling="button-small" onClick={onClose} txtColor="#4b6584" />
    </div>
  )
}

export default NoSubjectInfo
