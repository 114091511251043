import React, { useEffect, useCallback, useContext, useState } from "react"

import ShoppingDefault from "./ShoppingDefault"
import ShoppingTransactions from "./ShoppingTransactions"
import ShoppingProcedure from "./ShoppingProcedure"

import API from "components/common/api"
import { DispatchContext } from "components/userContext"

import { Tabs } from "antd"
import { Loading } from "components/Main/Loading"
const { TabPane } = Tabs

const DetailsShoppingBox = ({ elem }) => {
  const dispatch = useContext(DispatchContext)

  const [loading, setLoading] = useState(false)

  const fetchValues = useCallback(async () => {
    setLoading(true)
    try {
      const {
        data: { stateObject },
      } = await API.get(
        `VatRecordPurchase/getVatRecordPurchase?id=${elem.id}`,
        {},
        {
          headers: {
            tenant_id: localStorage.getItem("currentGuid"),
          },
        }
      )
      setLoading(false)
      dispatch({ type: "handleState", field: "shoppingValues", payload: stateObject })
      dispatch({ type: "handleState", field: "shoppingElem", payload: stateObject })
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }, [elem.id])

  useEffect(() => {
    fetchValues()
  }, [elem.id])

  if (loading) return <Loading />

  return (
    <div className="details-box">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Dane podstawowe" key="1">
          <ShoppingDefault elem={elem} />
        </TabPane>
        <TabPane tab="Rodzaj transakcji" key="2">
          <ShoppingTransactions />
        </TabPane>
        <TabPane tab="Procedury" key="3">
          <ShoppingProcedure />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default DetailsShoppingBox
