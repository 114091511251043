import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import ReactTooltip from "react-tooltip"

import { Tabs, Form } from "antd"

import API from "components/common/api"

import { DispatchContext, StateContext } from "components/userContext"

import { Loading } from "components/Main/Loading"

import * as bluePrint from "./dataBlueprintFix"

import TaxCalculationFix from "./TaxCalculationFix"
import TabRenderer from "./TabRenderer"

import VatDetailsEditIcons from "../DetailsTab/VatDetailsEditIcons"

import { p53dependencyArr } from "../DeclarationsTab/dataBlueprint"
import { TaxAdditionalInfo } from "./TaxAdditionalInfo"

import { Modals } from "./Modals"

import warningIcon from "images/uwaga.svg"

import "./DeclarationsTabFix.less"

type DeclarationsTabFixProps = {
  id: string
  message?: string
  handleCalc: () => void
  blockDetailsEdit: boolean
  type: number
  declaractionCount: number,
  formVariant: number
}

const { TabPane } = Tabs

export const dash = "-"

export const p_37 = "p_37"
export const p_38 = "p_38"
export const p_48 = "p_48"

export const p_39 = "p_39"
export const p_49 = "p_49"

export const p_50 = "p_50"
export const p_51 = "p_51"
export const p_52 = "p_52"
export const p_53 = "p_53"
export const p_54 = "p_54"
export const p_540 = "p_540"

export const p_55 = "p_55"
export const p_56 = "p_56"
export const p_560 = "p_560"
export const p_57 = "p_57"
export const p_58 = "p_58"

export const p_59 = "p_59"

export const p_60 = "p_60"

export const p_61 = "p_61"

export const p_62 = "p_62"

export const radioButtonsSchema1 = [p_55, p_56, p_57, p_58]
export const radioButtonsSchema2 = [p_540, p_55, p_56, p_560, p_57, p_58]

export const p_63 = "p_63"
export const p_64 = "p_64"
export const p_65 = "p_65"
export const p_66 = "p_66"
export const p_660 = "p_660"
export const p_67 = "p_67"

export const p_68 = "p_68"
export const p_69 = "p_69"

export const p_ORDZU = "p_ORDZU"
export const pouczenie = "pouczenie"

export const myIdea = true

export const initialConfirmation = {
  p_37: false,
  p_38: false,
  p_48: false,
  p_51: false,
  p_53: false,
  p_62: false,
}

type SummaryField = "p_37" | "p_38" | "p_48" | "p_51" | "p_53"
type Summary51 = "p_38" | "p_48" | "p_49" | "p_50"
type Summary62 = "p_53" | "p_54"
 
const DeclarationsTabFix = ({
  id,
  message,
  handleCalc,
  blockDetailsEdit,
  type,
  declaractionCount,
  formVariant
}: DeclarationsTabFixProps) => {
  const dispatch = useContext(DispatchContext)
  const state = useContext(StateContext)

  const declarationFormFixRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [tabError, setTabError] = useState<number[]>([])
  const [initialValues, setInitialValues] = useState([])
  const [checkboxChecked, setCheckboxChecked] = useState()
  const [confirmationExitModal, setConfirmationExitModal] = useState(false)

  const [infoState, setInfoState] = useState(false)

  const [editConfirmed, setEditConfirmed] = useState(initialConfirmation)
  const [confirmEditModal, setConfirmEditModal] = useState(initialConfirmation)

  useEffect(() => {
    if (message !== "") {
      setInfoState(true)
    } else {
      setInfoState(false)
    }
    return () => {
      setInfoState(false)
    }
  }, [message])

  useEffect(() => {
    if (!state.declarationData || !Object.keys(state.declarationData).length) return

    const updatedInitialValues = { ...state.declarationData }
    const radio_buttons = [p_55, p_56, p_57, p_58].find((e) => state.declarationData[e]) || null

    bluePrint.checkboxFields.map((fieldName) => {
      if (state.declarationData[fieldName]) {
        updatedInitialValues[fieldName] = true
      }
    })

    updatedInitialValues.radio_buttons = radio_buttons
    setCheckboxChecked(updatedInitialValues[p_59])
    setInitialValues(updatedInitialValues)
    if (declarationFormFixRef && declarationFormFixRef.current) {
      //@ts-ignore
      declarationFormFixRef.current.setFieldsValue(updatedInitialValues)
    }
  }, [state.declarationData])

  useEffect(() => {
    if (!state.declarationData || !Object.keys(state.declarationData).length) return

    const updatedInitialValues = { ...state.declarationData }
    const radio_buttons = [p_55, p_56, p_57, p_58].find((e) => state.declarationData[e]) || null

    bluePrint.checkboxFields.map((fieldName) => {
      if (state.declarationData[fieldName]) {
        updatedInitialValues[fieldName] = true
      }
    })

    updatedInitialValues.radio_buttons = radio_buttons
    setCheckboxChecked(updatedInitialValues[p_59])

    if (declarationFormFixRef && declarationFormFixRef.current) {
      //@ts-ignore
      declarationFormFixRef.current.setFieldsValue(updatedInitialValues)
    }
  }, [declaractionCount])

  const handleFinish = async (values: any) => {
    setTabError([])

    const updatedDeclaration = {}

    Object.keys(state.declarationData).map((fieldName) => {
      if (fieldName.includes("W")) return

      if (typeof values[fieldName] === "boolean") {
        //@ts-ignore
        return (updatedDeclaration[fieldName] = values[fieldName] ? 1 : 0)
      }

      if ([p_38, p_51].includes(fieldName)) {
        if (values[fieldName] === null) {
          //@ts-ignore
          return (updatedDeclaration[fieldName] = 0)
        }
      }

      //@ts-ignore
      return (updatedDeclaration[fieldName] = values[fieldName])
    })

    const radioButtonsValue = values.radio_buttons

    if (radioButtonsValue) {
      //@ts-ignore
      updatedDeclaration[radioButtonsValue] = 1
    }

    //@ts-ignore
    updatedDeclaration.jpk_vat_record_id = parseInt(id)

    //@ts-ignore
    updatedDeclaration.form_variant = formVariant

    try {
      const response = await API.post("/VatGeneratorDeclaration/updateDeclaration", updatedDeclaration)

      // handleError
      if (response.status !== 200) {
        alert(response.data.errors[0])
      }

      // handleSuccess
      if (response.status === 200) {
        setEditMode(false)
        setInitialValues(response.data.stateObject)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleErrors = (errors: any) => {
    //@ts-ignore
    const errorFieldsArr = errors.errorFields.map(({ name }) => name[0])

    const newErrorTabs = []
    //@ts-ignore
    if (bluePrint.firstTab.some((fieldName) => errorFieldsArr.includes(fieldName))) {
      newErrorTabs.push(1)
    }

    //@ts-ignore
    if (bluePrint.secondTab.some((fieldName) => errorFieldsArr.includes(fieldName))) {
      newErrorTabs.push(2)
    }

    //@ts-ignore
    if (bluePrint.thirdTab.some((fieldName) => errorFieldsArr.includes(fieldName))) {
      newErrorTabs.push(3)
    }

    //@ts-ignore
    if (bluePrint.fourthTab.some((fieldName) => errorFieldsArr.includes(fieldName))) {
      newErrorTabs.push(4)
    }

    setTabError(newErrorTabs)
  }

  const handleRenderError = (tab: number) => {
    return tabError.includes(tab) ? <img src={warningIcon} alt="warning" /> : null
  }

  const getDeclaration = async (fromUpdate = false) => {
    setLoading(true)

    try {
      const {
        data: { stateObject: data },
      } = await API.get(`/VatGeneratorDeclaration/getDeclaration?jpk_vat_record_id=${id}`)

      dispatch({
        type: "handleState",
        field: "declarationData",
        payload: data,
      })

      if (!fromUpdate) {
        getDeclarationDictionary()
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const getDeclarationDictionary = async () => {
    setLoading(true)

    try {
      const {
        data: { stateObject: dictionary },
      } = await API.get(`/VatGeneratorDeclaration/getDeclarationPartDictionary`)
      setLoading(false)
      dispatch({
        type: "handleState",
        field: "declarationDictionaryFix",
        payload: dictionary,
      })

      setFetched(true)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const handleCalculateSummary = useCallback(
    (fieldName: string, tab: number, rightCol, getUpdatedValues) => {
      if (!declarationFormFixRef.current) {
        return
      }

      //@ts-ignore
      const formValues = declarationFormFixRef.current.getFieldsValue()

      // ALLOW CHANGING BLOCKED FIELD VALUE DIRECTLY
      if ([p_37, p_38, p_48, p_51, p_62].includes(fieldName)) {
        if ([p_38, p_48].includes(fieldName)) {
          const taxDueValuesRight = Object.keys(formValues)
            .filter((key: string) => [p_38, p_48, p_49, p_50].includes(key))
            .reduce((obj, value: string) => ({ ...obj, [value]: formValues[value] }), {})

          const areAllRightNulls = Object.values(taxDueValuesRight).every((e) => e === null)
          //@ts-ignore
          const total51 = Object.values(taxDueValuesRight).reduce((acc, curr) => acc - curr)
          // @ts-ignore
          declarationFormFixRef.current.setFields([
            {
              name: p_51,
              value: areAllRightNulls ? null : total51,
            },
          ])

          return
        }

        if ([p_53, p_54].includes(fieldName)) {
          const taxValues = Object.keys(formValues)
            .filter((key: string) => [p_53, p_54].includes(key))
            .reduce((obj, value: string) => ({ ...obj, [value]: formValues[value] }), {})

          // @ts-ignore
          const total62 = Object.values(taxValues).reduce((acc, curr) => acc - curr)

          // @ts-ignore
          declarationFormFixRef.current.setFields([
            {
              name: p_62,
              // @ts-ignore
              value: taxValues.p_53 <= 0 ? 0 : total62,
            },
          ])
        }

        return
      }

      if (myIdea) {
        // USER EDITING LEFT COLUMN
        if (bluePrint.leftDueSum.includes(fieldName)) {
          setEditConfirmed({
            ...editConfirmed,
            p_37: false,
          })
        }

        // // USER EDITING RIGHT COLUMN
        if (bluePrint.rightDueSum.includes(fieldName)) {
          setEditConfirmed({
            ...editConfirmed,
            p_38: false,
          })
        }

        // // USER EDITING RIGHT COLUMN
        if (tab === 2 && rightCol.includes(fieldName)) {
          setEditConfirmed({
            ...editConfirmed,
            p_48: false,
          })
        }

        // // USER EDITING P51
        if (tab === 3 && [p_38, p_48, p_49, p_50].includes(fieldName)) {
          setEditConfirmed({
            ...editConfirmed,
            p_51: false,
          })
        }

        // // USER EDITING P51
        if (tab === 3 && p_62 === fieldName) {
          setEditConfirmed({
            ...editConfirmed,
            p_62: false,
          })
        }
      }

      const toSubtractArr = ["p_35", "p_36"]

      const taxDueValuesLeft = Object.keys(formValues)
        .filter((key: string) => bluePrint.leftDueSum.includes(key))
        .reduce((obj, value: string) => ({ ...obj, [value]: formValues[value] }), {})
      const rightColSum =
        tab === 1 ? bluePrint.rightDueSum.filter((e) => !toSubtractArr.includes(e)) : bluePrint.inputTaxSum

      const taxDueValuesRight = Object.keys(formValues)
        .filter((key: string) => rightColSum.includes(key))
        .reduce((obj, value: string) => ({ ...obj, [value]: formValues[value] }), {})

      const taxDueRightSubtract = Object.keys(formValues)
        .filter((key: string) => toSubtractArr.includes(key))
        .reduce((obj, value: string) => ({ ...obj, [value]: formValues[value] }), {})

      // HANDLE CHECK IF COLUMNS ARE EMPTY
      const areAllLeftNulls = Object.values(taxDueValuesLeft).every((e) => e === null)
      const areAllRightNulls = Object.values(taxDueValuesRight).every((e) => e === null)

      // HANDLE CALCULATE SUM OF COLUMNS
      //@ts-ignore
      const totalLeft = Object.values(taxDueValuesLeft).reduce((acc, curr) => acc + curr, 0)
      //@ts-ignore
      const totalRight: number = Object.values(taxDueValuesRight).reduce((acc, curr) => acc + curr, 0)
      //@ts-ignore
      const toSubtract: number = Object.values(taxDueRightSubtract).reduce((acc, curr) => acc - curr, 0)

      // HANDLE TOTAL LEFT and RIGHT COLUMN TAB 1
      if (tab === 1) {
        //@ts-ignore
        declarationFormFixRef.current.setFields([
          {
            ...(bluePrint.leftDueSum.includes(fieldName) && {
              name: p_37,
              value: areAllLeftNulls ? null : totalLeft,
            }),
          },
          {
            ...(bluePrint.rightDueSum.includes(fieldName) && {
              name: p_38,
              value: areAllRightNulls ? null : totalRight + toSubtract,
            }),
          },
        ])
      }

      // HANDLE TOTAL RIGHT COLUMN TAB 2
      if (tab === 2) {
        // @ts-ignore
        declarationFormFixRef.current.setFields([
          {
            name: p_48,
            value: areAllRightNulls ? null : totalRight,
          },
        ])
      }

      const updatedValues = getUpdatedValues()
      const p51Values = Object.keys(updatedValues)
        .filter((key: string) => [p_38, p_48, p_49, p_50].includes(key))
        .reduce((obj, value: string) => ({ ...obj, [value]: updatedValues[value] }), {})
      const areAllSummary51Nulls = Object.values(p51Values).every((e) => e === null)
      //@ts-ignore
      const total51: number = Object.values(p51Values).reduce((acc, curr) => acc - curr)
      const p62Values = Object.keys(getUpdatedValues())
        .filter((key: string) => [p_53, p_54].includes(key))
        .reduce((obj, value: string) => ({ ...obj, [value]: updatedValues[value] }), {})
      //@ts-ignore
      const total62: number = Object.values(p62Values).reduce((acc, curr) => acc - curr)
      if ([p_38, p_48, p_49, p_50].includes(fieldName)) {
        // @ts-ignore
        declarationFormFixRef.current.setFields([
          {
            name: p_51,
            value: areAllSummary51Nulls ? null : total51 <= 0 ? 0 : total51,
          },
        ])
      }

      // @ts-ignore
      const { p_38: p38, p_48: p48, p_52: p52 } = Object.keys(updatedValues)
        .filter((key: string) => [p_38, p_48, p_52].includes(key))
        .reduce((obj, value: string) => ({ ...obj, [value]: updatedValues[value] }), {})
      // jeśli (P_48 + P_52 - P_38) <= 0 wtedy P_53 = 0
      // Jeśli (P_48 + P_52 - P_38) > 0 wtedy P_53 = P_48 + P_52 - P_38"
      const p53value = p48 + p52 - p38 <= 0 ? 0 : p48 + p52 - p38

      if (p53dependencyArr.includes(fieldName)) {
        // @ts-ignore
        declarationFormFixRef.current.setFields([
          {
            name: p_53,
            value: p53value,
          },
          {
            name: p_62,
            // @ts-ignore
            value: p53value <= 0 ? 0 : p53value - getUpdatedValues().p_54,
          },
        ])
      }

      if ([p_53, p_54].includes(fieldName)) {
        // @ts-ignore
        declarationFormFixRef.current.setFields([
          {
            name: p_62,
            // @ts-ignore
            value: getUpdatedValues().p_53 <= 0 ? 0 : total62,
          },
        ])
      }
    },
    [editConfirmed, setEditConfirmed]
  )

  const handleSummaryChange = useCallback(
    (fieldName: SummaryField) => {
      if (!editConfirmed) {
        setConfirmEditModal({
          ...confirmEditModal,
          [fieldName]: true,
        })
        return
      }
    },
    [editConfirmed, setConfirmEditModal, confirmEditModal]
  )

  const isBlocked = (fieldName: SummaryField) => {
    return !editConfirmed[fieldName]
  }

  useEffect(() => {
    getDeclaration()
  }, [declaractionCount])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!fetched) return <Loading fullSize={false} absolute={false} />

  if (!state.declarationData) {
    console.error("no declarations data")
    return null
  }

  return (
    <div className="Declarations">
      <Modals
        infoState={infoState}
        message={message}
        handleCalc={handleCalc}
        setInfoState={setInfoState}
        setEditMode={setEditMode}
        confirmationExitModal={confirmationExitModal}
        setConfirmationExitModal={setConfirmationExitModal}
        declarationFormFixRef={declarationFormFixRef}
        confirmEditModal={confirmEditModal}
        setConfirmEditModal={setConfirmEditModal}
        setEditConfirmed={setEditConfirmed}
        editConfirmed={editConfirmed}
      />
      <ReactTooltip
        effect="solid"
        className="tooltip-vat"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />
      <div className="Declarations-header">
        <VatDetailsEditIcons
          editMode={editMode}
          onCancel={() => {
            //@ts-ignore
            const hasBeenTouched = declarationFormFixRef.current.isFieldsTouched()
            if (!hasBeenTouched) {
              setEditMode(false)
            } else {
              setConfirmationExitModal(true)
            }
          }}
          onSave={() => {
            //@ts-ignore
            declarationFormFixRef.current.submit()
          }}
          onEdit={() => setEditMode(true)}
          blockEdit={blockDetailsEdit}
        />

        <Form
          scrollToFirstError={{
            behavior: "smooth",
          }}
          name="declaration"
          className="Declarations-form"
          ref={declarationFormFixRef}
          initialValues={initialValues}
          onFinish={(values) => handleFinish(values)}
          onFinishFailed={(errors) => handleErrors(errors)}
        >
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={
                <span className={tabError.includes(1) ? "withWarning" : ""}>
                  Podatek należny
                  {handleRenderError(1)}
                </span>
              }
              key="1"
              forceRender
            >
              <div className="Taxdue">
                <div className="Tax-header">
                  <div className="Tax-col Tax-col_one"></div>
                  <div className="Tax-col Tax-col_two">
                    <p>Podstawa opodatkowania w zł</p>
                  </div>
                  <div className="Tax-col Tax-col_third">
                    <p>Podatek należny w zł</p>
                  </div>
                </div>
                <div className="Tax-content">
                  <TabRenderer
                    tab={1}
                    editMode={editMode}
                    isBlocked={isBlocked}
                    data={bluePrint.taxDueArray}
                    rightCol={bluePrint.rightDueSum}
                    editConfirmed={editConfirmed}
                    setEditConfirmed={setEditConfirmed}
                    confirmEditModal={confirmEditModal}
                    declarationFormFixRef={declarationFormFixRef}
                    handleCalculateSummary={handleCalculateSummary}
                    setConfirmEditModal={setConfirmEditModal}
                    handleSummaryChange={handleSummaryChange}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={
                <span className={tabError.includes(2) ? "withWarning" : ""}>
                  Podatek naliczony
                  {handleRenderError(2)}
                </span>
              }
              key="2"
              forceRender
            >
              <div className="Taxinput">
                <div className="Tax-header">
                  <div className="Tax-col Tax-col_one"></div>
                  <div className="Tax-col Tax-col_two">
                    <p>Kwota netto</p>
                  </div>
                  <div className="Tax-col Tax-col_third">
                    <p>Podatek naliczony w zł</p>
                  </div>
                </div>
                <div className="Tax-content">
                  <TabRenderer
                    tab={2}
                    editMode={editMode}
                    isBlocked={isBlocked}
                    editConfirmed={editConfirmed}
                    data={bluePrint.taxInputArray}
                    rightCol={bluePrint.inputTaxSum}
                    setEditConfirmed={setEditConfirmed}
                    confirmEditModal={confirmEditModal}
                    handleCalculateSummary={handleCalculateSummary}
                    declarationFormFixRef={declarationFormFixRef}
                    setConfirmEditModal={setConfirmEditModal}
                    handleSummaryChange={handleSummaryChange}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={
                <span className={tabError.includes(3) ? "withWarning" : ""}>
                  Wyliczenie zobowiązania
                  {handleRenderError(3)}
                </span>
              }
              key="3"
              forceRender
            >
              <TaxCalculationFix
                editMode={editMode}
                isBlocked={isBlocked}
                editConfirmed={editConfirmed}
                rightCol={bluePrint.inputTaxSum}
                checkboxChecked={checkboxChecked}
                setEditConfirmed={setEditConfirmed}
                confirmEditModal={confirmEditModal}
                initialData={state.declarationData}
                setCheckboxChecked={setCheckboxChecked}
                setConfirmEditModal={setConfirmEditModal}
                handleSummaryChange={handleSummaryChange}
                declarationFormFixRef={declarationFormFixRef}
                handleCalculateSummary={handleCalculateSummary}
                formVariant={formVariant}
              />
            </TabPane>
            <TabPane
              tab={
                <span className={tabError.includes(4) ? "withWarning" : ""}>
                  Informacje dodatkowe
                  {handleRenderError(4)}
                </span>
              }
              key="4"
              forceRender
            >
              <TaxAdditionalInfo
                editMode={editMode}
                initialValues={initialValues}
                declarationFormFixRef={declarationFormFixRef}
                type={type}
                formVariant={formVariant}
              />
            </TabPane>
          </Tabs>
        </Form>
      </div>
    </div>
  )
}

export default DeclarationsTabFix
