import React, { useContext, useEffect, useState } from "react"
import { Form, InputNumber, Select } from "antd"

import Button from "components/common/Button"
import NoSubjectInfo from "./subcomponents/noSubjectInfo"

import "./AddNewVatEntity.less"

import selectArrow from "images/icons/select_arrow.png"

import API from "components/common/api"
import { DispatchContext } from "../userContext"

const { Option } = Select

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const keys = {
  year: "Rok",
  month: "Miesiąc",
  nip: "Podmiot",
}

const objMapper = {
  tax_office_code: "kod_urzedu",
  year: "rok",
  month: "miesiac",
  surname: "nazwisko",
  name: "imie_pierwsze",
  birth_date: "data_urodzenia",
  telefon: "telefon",
  full_name: "pelna_nazwa",
  related_party_type: "typ_podmiotu",
}

const sections = ["Dane deklaracji", "Dane podmiotu"]
const fields = [["year", "month"], ["nip"]]

const initialYear = new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear()
const initialMonth = new Date().getMonth() === 0 ? 12 : ("0" + new Date().getMonth()).slice(-2)

const AddNewVatEntity = ({ form, setBlock, blockEdit, fetchSortData, fromEdit, onClose }) => {
  const dispatch = useContext(DispatchContext)

  const [data, setData] = useState([])
  const [options, setOptions] = useState(null)
  const [activeData, setActiveData] = useState({})
  const [addEntityLoading, setAddEntityLoading] = useState(false)

  const handleFinish = async (values) => {
    setAddEntityLoading(true)

    const baseFields = Object.keys(activeData)
    const additionalFields = [
      "year",
      "month",
      "kod_formularza",
      "wariant_formularza",
      "nazwa_systemu",
      "cel_zlozenia",
      "related_party_id",
    ]

    const allFields = [...baseFields, ...additionalFields]
    const tmp = {}

    const defaultData = {
      id: 0,
      kod_formularza: "JPK_V7M",
      wariant_formularza: 1,
      nazwa_systemu: navigator.userAgent,
      cel_zlozenia: 1,
      related_party_id: activeData.id,
    }

    allFields.map((fieldName) => {
      if (fieldName === "nip") return (tmp.nip = activeData.nip)
      if (fieldName === "id") return (tmp.id = 0)
      if (fieldName === "month") return (tmp.miesiac = parseInt(values[fieldName]))

      tmp[objMapper[fieldName] || fieldName] = activeData[fieldName] || values[fieldName] || defaultData[fieldName]
    })

    try {
      const response = await API.post(`/VatGenerator/addVatRecord`, tmp)

      if (response.status === 200) {
        setAddEntityLoading(false)
        fetchSortData()
        dispatch({
          type: "handleState",
          field: "showAddEntity",
          payload: false,
        })

        setBlock(true)
      } else {
        if (response.status === 400) {
          alert(response.data.errors[0])
          setAddEntityLoading(false)
          setBlock(true)
        }
      }
    } catch (error) {
      setAddEntityLoading(false)
      setBlock(true)
      form &&
        form.current &&
        form.current.setFields([
          {
            name: "nip",
            errors: ["Coś poszło nie tak."],
          },
        ])
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { stateObject },
        } = await API.get(`/CustomerPanel/getRelatedPartiesForVatRecord`, {
          headers: {
            tenant_id: localStorage.getItem("currentGuid"),
          },
        })

        setData(stateObject)
      } catch (error) {
        console.error(error)
      }
    }

    if (fromEdit) return

    fetchData()
  }, [])

  useEffect(() => {
    if (!data.length) return

    const tmp = []
    data.map((e) => tmp.push({ id: e.id, label: `${e.nip} - ${e.fullName}`, value: e.id }))

    // IF ONLY 1 NIP SET AS DEFAULT
    if (data.length === 1) {
      handleSetNip(data[0].id, true)
    }

    setOptions(tmp)
  }, [data])

  useEffect(() => {
    if (!Object.keys(activeData).length) return

    handleCheckIfIsValid(activeData)
  }, [activeData])

  const handleSetNip = async (value, set = false) => {
    const id = data.find((e) => e.id === value).id

    try {
      const {
        data: { stateObject },
      } = await API.get(
        `/CustomerPanel/GetRelatedParty?id=${id}&organizationGuid=${localStorage.getItem("currentGuid")}`
      )

      setActiveData(stateObject)

      // IF ONLY 1 NIP SET AS DEFAULT
      if (set) {
        form.current.setFields([
          {
            name: "nip",
            value: [stateObject.id],
          },
        ])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSetError = (msg, hasLink) => {
    let actionMsg = null
    let infoMsg = msg

    if (msg.includes("tutaj")) {
      actionMsg = msg.substring(msg.indexOf("tutaj"))
      infoMsg = msg.split("tutaj")[0]
    }

    const errorElement = hasLink ? (
      <div
        style={{
          lineHeight: "1.4",
          marginTop: "5px",
        }}
      >
        {infoMsg}{" "}
        <span
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
          onClick={() => {
            window.location.href = `/panel-administratora?id=${activeData.id}`
          }}
        >
          {actionMsg}
        </span>
      </div>
    ) : (
      msg
    )

    form.current.setFields([
      {
        name: "nip",
        errors: [errorElement],
      },
    ])
  }

  const handleCheckIfIsValid = (entityObj) => {
    const { isComplete, canEdit, message } = data.find((el) => el.id === entityObj.id)

    if (!isComplete) {
      // USER NOT ALLOWED
      setBlock(true)
      if (!canEdit) {
        return handleSetError(message, false)
      } else {
        handleSetError(
          "Brak wymaganych danych dla wybranego podmiotu. Proszę uzupełnić brakujące dane klikając tutaj.",
          true
        )
      }
    } else {
      setBlock(false)
      form.current.setFields([
        {
          name: "nip",
          errors: [],
        },
      ])
    }
  }

  if (!fromEdit && options && !options.length) {
    return <NoSubjectInfo onClose={onClose} />
  }

  if (fromEdit) return "tutaj edycja"

  return (
    <Form
      {...layout}
      ref={form}
      name="EntityAdd"
      initialValues={{
        year: initialYear,
        month: initialMonth,
      }}
      className="Addentity-form"
      onFinish={handleFinish}
      onFinishFailed={() => console.error("fail")}
    >
      <h3 className="Addentity-title">Aby utworzyć nową ewidencję, wypełnij następujące dane:</h3>
      <div className="Addentity-sections">
        {sections.map((sectionName, index) => (
          <React.Fragment key={sectionName}>
            {fields[index].map((fieldName) => {
              return (
                <Form.Item
                  key={fieldName}
                  label={keys[fieldName]}
                  name={fieldName}
                  hasFeedback
                  validateTrigger="onBlur"
                  labelAlign="left"
                  rules={rules[fieldName]}
                >
                  {index === 0 ? (
                    <InputNumber
                      name={fieldName}
                      step={1}
                      min={fieldName === "year" ? "2020" : "01"}
                      max={fieldName === "month" ? 12 : fieldName === "year" ? new Date().getFullYear() : ""}
                      formatter={(value) => {
                        if (fieldName === "month") {
                          return ("0" + value).slice(-2)
                        }
                        return value
                      }}
                    />
                  ) : (
                    <Select
                      style={{ width: 248 }}
                      dropdownStyle={{
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #DDDDDD",
                        borderRadius: "5px",
                        padding: "15px 10px",
                      }}
                      placeholder="Wybierz NIP podmiotu"
                      name="nip"
                      onChange={handleSetNip}
                      suffixIcon={<img src={selectArrow} />}
                    >
                      {options &&
                        options.map((option) => (
                          <Option key={option.id} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              )
            })}
          </React.Fragment>
        ))}
      </div>

      <div className="Addentity-buttons">
        <Button name="Anuluj" type="button" styling="button-small-white" onClick={onClose} txtColor="#4b6584" />
        <Button name="Zapisz" type="submit" styling="button-small" loading={addEntityLoading} disabled={blockEdit} />
      </div>
    </Form>
  )
}

const rules = {
  year: [
    {
      whitespace: true,
      required: true,
      pattern: /\d{4}/,
      message: "Rok musi się składać z czterech cyfr!",
    },
    // {
    //   min: 2020,
    //   message: "Minimalna wartość pola to 2020!",
    // },
  ],
  month: [{ required: true, message: "Miesiąć jest obowiązkowy!" }],
}

export default AddNewVatEntity
