import React, { useContext, useEffect, useState } from "react"
import { cloneDeep } from "lodash"

import details from "images/szczegoly_small.svg"
import del from "images/usun_small.svg"
import lockClose from "images/lock_close.svg"
import lockOpen from "images/lock_open.svg"

import { history } from "App"
import { DispatchContext, StateContext } from "components/userContext"
import API from "components/common/api"
import { tracker } from "helpers/tracker"

const ActionButtons = ({ row, setDelModal, setSelectedRows }) => {
  const rowElement = row.hasOwnProperty("_original") ? row._original : row

  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [loading, setLoading] = useState(false)
  const [elem, setElem] = useState({})

  const [blocked, setBlocked] = useState()

  useEffect(() => {
    const element = state.vatTableData.find(({ id }) => id === rowElement.id)

    if (typeof element !== "undefined") {
      setElem(element)
    } else {
      setElem(rowElement)
    }
  }, [state.vatTableData])

  useEffect(() => {
    if (!elem) return
    setBlocked(() => {
      return elem.zablokowana || elem.skorygowana || (elem.status_bramki !== undefined && elem.status_bramki !== "-")
    })
  }, [elem])

  const handleBlockEvidence = (event, row) => {
    event.stopPropagation()
    // SEND API REQUEST TO BLOCK EVIDENCE

    setLoading(true)

    API.post("/VatGenerator/blockVatRecord", { id: [rowElement.id] })
      .then(() => {
        setBlocked(true)

        if (row.cel_zlozenia === 2) {
          let listToChange = cloneDeep(state.correctionList)

          listToChange.forEach((element) => {
            element.data.forEach((item) => {
              if (item.id === rowElement.id) {
                item.zablokowana = true
              }
            })
          })

          return listToChange
        }

        return state.vatTableData.map((element) => {
          if (element.id !== rowElement.id) {
            return element
          }

          return {
            ...element,
            zablokowana: true,
          }
        })
      })
      .then((updatedTable) => {
        if (row.cel_zlozenia === 2) {
          dispatch({
            type: "handleState",
            payload: updatedTable,
            field: "correctionList",
          })
          setSelectedRows([])
          setLoading(false)
          return
        }

        dispatch({
          type: "handleState",
          payload: updatedTable,
          field: "vatTableData",
        })
        setSelectedRows([])
        setLoading(false)
      })
      .catch((error) => {
        setSelectedRows([])
        setLoading(false)
        alert(error.response.data.errors[0] || "Error")
      })
  }

  const handleUnblockEvidence = (event, id) => {
    event.stopPropagation()

    // SEND API REQUEST TO UNBLOCK EVIDENCE
    setLoading(true)

    API.post("/VatGenerator/unblockVatRecord", { id: [id] })
      .then(() => {
        setBlocked(false)

        if (row.cel_zlozenia === 2) {
          let listToChange = cloneDeep(state.correctionList)

          listToChange.forEach((element) => {
            element.data.forEach((item) => {
              if (item.id === id) {
                item.zablokowana = false
              }
            })
          })

          return listToChange
        }

        return state.vatTableData.map((element) => {
          if (element.id !== id) return element

          return {
            ...element,
            zablokowana: false,
          }
        })
      })
      .then((updatedTable) => {
        if (row.cel_zlozenia === 2) {
          dispatch({
            type: "handleState",
            payload: updatedTable,
            field: "correctionList",
          })
          setSelectedRows([])
          setLoading(false)
          return
        }

        dispatch({
          type: "handleState",
          payload: updatedTable,
          field: "vatTableData",
        })
        setSelectedRows([])
        setLoading(false)
      })
      .catch((error) => {
        alert(error.response.data.errors[0] || "Error")
        setSelectedRows([])
        setLoading(false)
      })
  }

  return (
    <div className="table-action-buttons">
      <button
        onClick={() => {
          tracker("Sprawdź_JPK - Szczegóły ewidencji")
          history.push(`?id=${rowElement.id}`)
        }}
      >
        <img src={details} alt="details icon" />
      </button>

      <button
        disabled={blocked}
        onClick={() => {
          tracker("Sprawdź_JPK - Usuń ewidencję")
          setDelModal({ visible: true, data: [rowElement.id] })
        }}
      >
        <img src={del} alt="delete icon" />
      </button>

      {blocked ? (
        // zablokowana
        <button
          disabled={loading || !elem || elem.skorygowana || (elem.status_bramki !== undefined && elem.status_bramki !== "-")}
          onClick={(event) => handleUnblockEvidence(event, rowElement.id)}
        >
          <img src={lockClose} alt="lock icon" />
        </button>
      ) : (
        // odblokowana
        <button
          disabled={loading}
          onClick={(event) => {
            handleBlockEvidence(event, row)
            tracker("Sprawdź_JPK - Zablokuj ewidencję")
          }}
        >
          <img src={lockOpen} alt="unlock icon" />
        </button>
      )}
    </div>
  )
}

export default ActionButtons
