import React from "react"
import { Select, ConfigProvider } from "antd"

import customizeRenderEmpty from "components/common/CustomEmptySelect/CustomEmptySelect"

import "../Register.less"
import { countries } from "./data"

const { Option } = Select

const Countries = (props) => {
  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Select
        showSearch
        onChange={props.handleCountry}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        placeholder="Wybierz kraj"
        defaultValue={props.country || "Polska"}
        dropdownStyle={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #DDDDDD",
          borderRadius: "5px",
          padding: "15px 10px",
        }}
      >
        {countries.map((country) => (
          <Option value={country} key={country}>
            {country}
          </Option>
        ))}
      </Select>
    </ConfigProvider>
  )
}

export default Countries
