import React, { ChangeEvent, useRef } from "react"

import IconBox from "components/common/IconBox/IconBox"

import "./ActionSection.less"

interface ActionSectionProps {
  sectionName: string
  hasSeparator?: boolean
  content: {
    title: string
    handler: (e: ChangeEvent<HTMLInputElement>) => void
    icon: string
    disabled: boolean
    fakeInput?: boolean
  }[]
  fileRef?: any
}

export const ActionSection = (props: ActionSectionProps) => {
  const { sectionName, content, hasSeparator, fileRef } = props

  return (
    <div className="action-section">
      <span>{sectionName}</span>
      <div className={`buttons ${hasSeparator ? "separator" : ""}`}>
        {content.map(({ title, handler, icon, disabled, fakeInput }) => {
          if (fakeInput) {
            return (
              <div className="fake-button" key={title}>
                <input ref={fileRef} id="import-signed" onChange={handler} type="file" disabled={disabled} />
                <label htmlFor="import-signed">
                  <img src={icon} alt="import button" />
                  {title}
                </label>
              </div>
            )
          } else {
            return <IconBox name={title} icon={icon} click={handler} disabled={disabled} key={title} />
          }
        })}
      </div>
    </div>
  )
}
