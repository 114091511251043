import React, { useContext, useRef } from "react"
import { Form, Input } from "antd"
import "./ChangeEmail.less"

import { DispatchContext } from "../userContext"

import Button from "../common/Button"
import API from "../common/api"

const statusCodeOk = 200
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const ChangeEmail = () => {
  const [loading, setLoading] = React.useState(false)
  const [errorMessages, setErrorMessages] = React.useState([])

  const dispatch = useContext(DispatchContext)

  const form = useRef(null)

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const resp = await API.post("/Dashboard/changeEmail", {
        email: values.email,
        organizationGuid: localStorage.getItem("currentGuid"),
      })

      if (resp.status === statusCodeOk) {
        handleOkEmail()
        setLoading(false)
      }

      if (resp.status === 400) {
        alert(resp.data.errors[0])
        setLoading(false)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErrorMessages(error.response.data.errors)
      console.error(error)
    }
  }

  const handleCancelEmail = () => {
    dispatch({ type: "handleCloseEmail" })
    form.current.resetFields()
    setErrorMessages([])
  }

  const handleOkEmail = () => {
    dispatch({ type: "handleOpenNotofication" })
    form.current.resetFields()
  }

  const userEmail = localStorage.getItem("userEmail")

  return (
    <div className="ChangeEmail">
      <Form {...layout} name="login" initialValues={{ remember: true }} ref={form} onFinish={onFinish}>
        <h3
          style={{
            marginBottom: "30px",
            font: "normal normal bold 18px/24px Lato",
            color: "#233B53",
          }}
        >
          Zmień e-mail
        </h3>

        <Form.Item
          name="email"
          hasFeedback
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Wprowadź nowy e-mail!",
            },
            {
              type: "email",
              message: "Wprowadzony e-mail jest niepoprawny!",
            },
            () => ({
              validator(rule, value) {
                if (userEmail !== value) {
                  return Promise.resolve()
                }

                return Promise.reject("Wpisany email jest identyczny z obecnym - wybierz inny adres email!")
              },
            }),
          ]}
        >
          <Input placeholder="Nowy e-mail" />
        </Form.Item>

        <div className="edit-action-buttons">
          <Button
            name="Anuluj"
            type="button"
            styling="button-small-white"
            loading={loading}
            style={{
              marginRight: "35px",
            }}
            onClick={handleCancelEmail}
          />

          <Button name="Zapisz" type="submit" styling="button-small" loading={loading} />
        </div>
      </Form>

      {errorMessages &&
        errorMessages.map((errorMessage) => (
          <h2 key={errorMessage} className="errorMessage" style={{ color: "red" }}>
            {errorMessage}
          </h2>
        ))}
    </div>
  )
}

export default ChangeEmail
