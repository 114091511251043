import React from "react"
import NipVerifierDropDown from "../NipVerifierMain/NipVerifierDropDown"
import { tracker } from "helpers/tracker"
import { VerificationStatusMfMap, VerificationStatusVIESMap } from "../VerificationStatusMap"

const mfDropDownSettings = [
    { id: "Active", label: "czynny", style: "wl-status-right", notSelectedStyle: "wl-status-not-selected" },
    { id: "Unregistered", label: "niezarejestrowany", style: "", notSelectedStyle: "wl-status-not-selected" },
    { id: "Discharged", label: "zwolniony", style: "", notSelectedStyle: "wl-status-not-selected" },
    { id: "Wrong", label: "błędny", style: "wl-status-wrong", notSelectedStyle: "wl-status-not-selected" },
    { id: "Error", label: "błąd weryfikacji", style: "wl-status-wrong", notSelectedStyle: "wl-status-not-selected" },
    { id: "Unexecuted", label: "niewykonana", style: "wl-status-error", notSelectedStyle: "wl-status-not-selected" },
  ]
  
  const viesDropDownSettings = [
    { id: "Registered", label: "zarejestrowany", style: "wl-status-right", notSelectedStyle: "wl-status-not-selected" },
    { id: "Unregistered", label: "niezarejestrowany", style: "", notSelectedStyle: "wl-status-not-selected" },
    { id: "Wrong", label: "błędny", style: "wl-status-wrong", notSelectedStyle: "wl-status-not-selected" },
    { id: "Error", label: "błąd weryfikacji", style: "wl-status-wrong", notSelectedStyle: "wl-status-not-selected" },
    { id: "Unexecuted", label: "niewykonana", style: "wl-status-error", notSelectedStyle: "wl-status-not-selected" },
  ]

export const historyVerificationColumns = [
  {
    expander: true,
    Expander: ({ isExpanded, ...row }) => {
      return null
    },
  },
  {
    Header: "Data importu",
    accessor: "import_date",
    Cell: ({ value }) => {
      return <span> {value ? value.substring(0, 10) : "-"} </span>
    },
  },
  {
    Header: "Źródło importu",
    accessor: "data_source",
  },
  {
    Header: "NIP weryfikowany",
    accessor: "nip",
  },
  {
    Header: "Data weryfikacji",
    accessor: "verification_date",
    Cell: ({ value }) => {
      return <span> {value ? value.substring(0, 16).replace("T", " ") : "-"} </span>
    },
  },
  {
    Header: "Id weryfikacji z VIES",
    accessor: "vies_verification_request_id",
  },
  {
    accessor: "mf_status",
    showWithDetails: false,

    Header: ({ value }) => {
      return <NipVerifierDropDown
        tableOptionsId="nipVerificationHistoryOptions"
        settings={mfDropDownSettings}
        filter = "MF"
      ></NipVerifierDropDown>
    },
    Cell: ({ value }) => {
      if (value == null) return <span>-</span>
      const n = VerificationStatusMfMap[value]
      return <span className={n.style}>{n.name}</span>
    }
  },
  {
    accessor: "vies_status",
    showWithDetails: false,

    Header: ({ value }) => {
      return <NipVerifierDropDown
        tableOptionsId="nipVerificationHistoryOptions"
        settings={viesDropDownSettings}
        filter = "VIES"
      ></NipVerifierDropDown>
    },
    Cell: ({ value }) => {
      if (value == null) return <span>-</span>
      const n = VerificationStatusVIESMap[value]
      return <span className={n.style}>{n.name}</span>
    }
  }
]


export const originalHandleClick = (_, handleOriginal) => {
  if (handleOriginal) {
    handleOriginal()
  }
}

export const inputStyles = {
  padding: "3px 15px",
  margin: "10px auto 10px 35px",
  WIDTH: "150PX",
  height: "40px",
  outline: "none",
  borderRadius: "5px",
  border: "1px solid #dedede",
}
