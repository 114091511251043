import React, { useContext, useEffect, useState, useMemo, useRef, useCallback } from "react"
import { Modal, Input, Form, Checkbox } from "antd"
import FormModal from "components/common/FormModal"
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"

import { cloneDeep, debounce } from "lodash"

import ReactTooltip from "react-tooltip"

import AddNewVatEntity from "components/AddNewVatEntity/AddNewVatEntity"
import CustomModal from "components/common/CustomModal/CustomModal"
import { Tabs } from "antd"

import VatDetails from "components/VatDetails"
import CustomPagination from "components/CustomPagination/CustomPagination"
import ActionButtons from "./subcomponents/ActionButtons"

import VatRow from "./subcomponents/VatRow"
import { columns as getColumns, originalHandleClick } from "./subcomponents/data"
import { Gate } from "../../VatDetails/Gate/Gate"
import VatUEKModalContent from "./VatUEKModalContent"

import "../ReactTable.less"

import API, { routes } from "components/common/api"
import Context from "context"
import { DispatchContext, StateContext } from "components/userContext"

import weryfikacja from "images/weryfikacja.svg"
import imp from "images/import.svg"
import eksport from "images/eksport.svg"
import obliczenia from "images/obliczenia.svg"
import ewidencja from "images/ewidencja.svg"
import korekta from "images/korekta.svg"
import send from "images/send.svg"
import szczegoly from "images/szczegoly.svg"
import usun from "images/usun.svg"
import research from "images/research.svg"
import vatUE from "images/vat_ue.svg"
import ReactGA from "react-ga"
import verifyIcon from "images/weryfikuj.svg"

import { history } from "App"

import * as vatHelpers from "./VatTableHelpers"

import { Loading } from "components/Main/Loading"
import { tracker } from "helpers/tracker"
import { getFileNameFromContentDisposition } from "helpers/httpHelpers"
import SelectVerificationModal from "../../NipVerifier/Actions/Verification/SelectVerificationModal"

const { Search } = Input

const { TabPane } = Tabs

const queryString = require("query-string")

const CheckboxGroup = Checkbox.Group

const VatTable = () => {
  const dispatch = useContext(DispatchContext)
  const state = useContext(StateContext)

  const [loading, setLoading] = useState(true)

  const { ctx, setCtx } = useContext(Context)

  const [selectedRows, setSelectedRows] = useState([])

  const [modalVisible, setModalVisible] = useState(false)
  const [delModal, setDelModal] = useState({ visible: false })

  const [block, setBlock] = useState(true)
  // const [blockEdit, setBlockEdit] = useState(true)

  const [disabledUpload, setDisabledUpload] = useState(false)
  const [fetched, setFetched] = useState([])

  const [filtersState, setFilterState] = useState({})
  const [detailsBack, setDetailsBack] = useState(false)
  const [exportFile, setExportFile] = useState(false)

  const [activeElem, setActiveElem] = useState(null)
  const [researchModal, setResearchModal] = useState(false)
  const [demoModal, setDemoModal] = useState(false)
  const [vatUEModal, setVatUEModal] = useState(false)
  const [vatUEModalWarning, setVatUEModalWarning] = useState(false)
  const [vatUeData, setVatUeData] = useState(null)

  const [vatUEKModal, setVatUEKModal] = useState(false)
  const [vatUEKTitle, setVatUEKTitle] = useState("")
  const [vatUekData, setVatUekData] = useState(null)
  const [vatUEKCorrectionId, setVatUEKCorrectionId] = useState(null)
  const [anyVatUEKSelected, setAnyVatUEKSelected] = useState(false)
  const [id, setId] = useState(null)

  const [verifyNip, setVerifyNip] = useState(false)
  const [verificationModal, setVerificationModal] = useState({ visible: false })
  const [verificationInProgress, setVerificationInProgress] = useState(false)
  const [reportInProgress, setReportInProgress] = useState(false)

  let [currentPageIndex, setCurrentPageIndex] = useState(0)

  useEffect(() => {
    const { id } = queryString.parse(window.location.search)

    ReactGA.pageview(window.location.pathname)

    setId(id)
  }, [window.location.search])

  const blockInputButtons = (bool) => setDisabledUpload(bool)

  const form = useRef(null)
  // const formEdit = useRef(null)
  const researchRef = useRef(null)
  const vatUERef = useRef(null)
  const vatUEKRef = useRef(null)

  const [errorModal, showErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const debounceHanlderInput = useMemo(() => debounce(() => handleFillterAll(inputRef.current.input.value), 800))
  const [columns, setColumns] = useState(getColumns(ctx))
  const inputRef = useRef(null)

  const [searchDefault, setSearchDefault] = useState("")

  const restoreSearchValue = () => {
    let searchValue = state.vatTableOptions.filtered.find(x => x.id === "all")
    if (searchValue !== undefined) {
      setSearchDefault(searchValue.value)
    }
  }

  const handleFillterAll = (elem) => {
    const filtered = [{ id: "all", value: elem }]
    dispatch({
      type: "handleState",
      field: "vatTableOptions",
      payload: { ...state.vatTableOptions, filtered },
    })
  }

  const removeItem = (data) => {
    const tmp = [...selectedRows]
    const index = tmp.indexOf(data)

    if (index !== -1) {
      tmp.splice(index, 1)
      setSelectedRows(tmp)
    }
  }

  const addItem = (index) => {
    setSelectedRows([...selectedRows, index])
  }

  // FETCH JPK CORRECTION LIST BASED ON ORG. GUID AND DATA ID
  const fetchCorrectionList = async (id) => {
    try {
      const {
        data: { stateObject },
      } = await API.get(`/VatGenerator/GetJpkVatCorrectionList?parentJpkId=${id}`)

      vatHelpers.formatExportData(stateObject)

      const updatedCorrectionList = cloneDeep(state.correctionList)

      if (state.correctionList.some((el) => el.data[0].id === stateObject[0].id)) {
        const [correctionToUpdate] = updatedCorrectionList.filter((e) => e.parentId === id)
        correctionToUpdate.data = stateObject
      } else {
        updatedCorrectionList.push({ parentId: id, data: stateObject })
      }

      dispatch({
        type: "handleState",
        field: "correctionList",
        payload: updatedCorrectionList,
      })
    } catch (error) {
      window.location.href = "/login/ewidencja-vat"
    }
  }

  const fetchSortData = (sortData, addedCorrection, wasDelete) => {
    setLoading(true)

    let expandedObject = {}

    setSelectedRows([])

    if ((addedCorrection && !detailsBack) || wasDelete) {
      expandedObject = {
        ...state.vatTableOptions.expanded,
        [addedCorrection]: true,
      }
    }

    if (!addedCorrection && detailsBack) {
      expandedObject = {
        ...state.vatTableOptions.expanded,
      }
    }

    API.get(
      `/VatGenerator/getJpkVatList?sorts=${sortData.sortedValue}&page=${sortData.innerPage + 1}&pageSize=${sortData.innerPageSize
      }&filters=search@=${sortData.filterValue}`
    )
      .then(
        ({
          data: {
            stateObject: { results: stateObject, ...tableParams },
          },
        }) => {
          vatHelpers.formatExportData(stateObject)
          if (stateObject.length === 0 && sortData.innerPage > 0) { // load previous page if current is empty after last deletion [CL-846]
            fetchSortData({ ...sortData, innerPage: sortData.innerPage - 1 }, addedCorrection, wasDelete)
          } else {
            dispatch({
              type: "handleState",
              field: "vatTableData",
              payload: stateObject,
            })
            dispatch({
              type: "handleState",
              field: "vatTableOptions",
              payload: {
                ...state.vatTableOptions,
                ...tableParams,
                currentPage: sortData.innerPage + 1,
                pageSize: sortData.innerPageSize,
                expanded: expandedObject,
              },
            })
            setLoading(false)
            setDetailsBack(false)
          }
        }
      )
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  useEffect(() => {
    const tmpCols = columns
    tmpCols.splice(-1, 1)
    tmpCols.push({
      Header: "",
      id: "buttons",
      sortable: false,
      width: 110,
      Cell: ({ row }) => <ActionButtons row={row} setDelModal={setDelModal} setSelectedRows={setSelectedRows} />,
    })

    setColumns(tmpCols)
  }, [filtersState])

  const fetchResearchDict = async () => {
    setLoading(true)
    try {
      if (state.researchDict.length) {
        setLoading(false)
        return
      }

      const {
        data: { stateObject: researchDict },
      } = await API.get(`/VatGenerator/getRequirementForResearchDictionary`)

      setTimeout(() => setLoading(false), 550)

      dispatch({
        type: "handleState",
        field: "researchDict",
        payload: researchDict,
      })
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  // FETCH JPK VAT LIST BASED ON ORG. GUID
  useEffect(() => {
    // handle active and visible entities
    dispatch({ type: "handleState", payload: true, field: "vatTableVisible" })
    setCtx({ ...ctx, currentApp: "Sprawdź JPK_VAT" })

    return () => {
      setActiveElem(null)
      dispatch({
        type: "handleState",
        payload: false,
        field: "vatTableVisible",
      })
      setCtx({ ...ctx, currentApp: "Dashboard" })
    }
  }, [])

  useEffect(() => {
    // handle active and visible vats

    const arr = []

    state.correctionList.forEach((el) => el.data.forEach((item) => arr.push(item)))

    const combinedData = [...state.vatTableData, ...arr]
    const updatedVisibleVats = combinedData.filter(({ id }) => selectedRows.includes(id))

    setActiveElem(state.vatTableData.find(({ id }) => selectedRows.includes(id)))
    dispatch({
      type: "handleState",
      field: "visibleVats",
      payload: updatedVisibleVats,
    })
  }, [selectedRows])

  useEffect(() => {
    // remove row expansion and row selection on every filter change

    dispatch({
      type: "handleState",
      field: "vatTableOptions",
      payload: { ...state.vatTableOptions, expanded: {} },
    })

    setSelectedRows([])
  }, [state.vatTableOptions.filtered])

  useEffect(() => {
    // show modal when there are error array
    state.vatTableError && state.vatTableError.length > 0 && setModalVisible(true)
  }, [state.vatTableError])

  useEffect(() => {
    // show verify Nip only if has active application and ff is off
    const verifyNipLicence = ctx.currentOrganization.licences.filter(l => l.applicationId === 7)

    if(!ctx.featureFlags.NipVerificationInGeneratorDisabled && 
      verifyNipLicence.length > 0 &&
        Date.parse(verifyNipLicence[0].expirationDate) > Date.now())
    {
      setVerifyNip(true);
    }
  },[])

  useEffect(() => {
    //Ten warunek powinien zapewnić, żeby nie próbował jeszcze raz wołać danych podczas gdy te są już ładowane
    if (state.jobsCounter !== 0 && 
      Object.entries(filtersState).length !== 0 && 
      !loading && (state.commandEvent && state.commandEvent.actionName === "jobs_fetch_data")) 
    {
      fetchSortData(filtersState);
    }
  }, [state.jobsCounter]);

  const handleErrorModal = () => {
    // hide modal on OkClick and reset error array
    setModalVisible(false)
    dispatch({ type: "handleState", payload: [], field: "vatTableError" })
  }

  const handleSetExpanded = (index) => {
    dispatch({
      type: "handleState",
      field: "vatTableOptions",
      payload: {
        ...state.vatTableOptions,
        expanded: {
          ...state.vatTableOptions.expanded,
          [index]: !state.vatTableOptions.expanded[index],
        },
      },
    })
  }

  const closeModal = () => {
    dispatch({
      type: "handleState",
      field: "showAddEntity",
      payload: false,
    })

    setBlock(true)
  }

  const addCorrectionHandler = useCallback(async () => {
    setBlock(false)
    setLoading(true)

    tracker("Sprawdź_JPK - Dodaj korektę")

    const recordId = visibleVats[0].id
    const getRecordIndex = state.vatTableData.findIndex((el) => el.id === recordId)

    try {
      const response = await API.post("/VatGenerator/AddCorrection", {
        id: recordId,
      })

      if (response.status === 200) {
        let listToChange = cloneDeep(state.correctionList)
        vatHelpers.formatExportData([response.data.stateObject])

        if (listToChange.some((el) => el.parentId === response.data.stateObject.parentVatRecordId)) {
          listToChange.forEach((el) => {
            if (el.parentId === response.data.stateObject.parentVatRecordId) {
              el.data[el.data.length - 1].skorygowana = true
              el.data.push(response.data.stateObject)
            }
          })
        } else {
          listToChange.push({
            parentId: recordId,
            data: [response.data.stateObject],
          })
        }

        dispatch({
          type: "handleState",
          payload: listToChange,
          field: "correctionList",
        })

        setBlock(true)
        fetchSortData(filtersState, getRecordIndex)
      } else {
        if (response.status === 400) {
          alert(response.data.errors[0])
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
      setBlock(true)
    }
  })

  const delRecorHandler = useCallback(async () => {
    setDelModal({ visible: false })
    setLoading(true)
    try {
      const response = await API.post("/VatGenerator/deleteVatRecords", {
        ids: delModal.data,
      })

      if (response.status === 200) {
        let listToChange = cloneDeep(state.correctionList)

        listToChange.forEach((el, index) => {
          el.data.forEach((item, idx) => {
            delModal.data.forEach((delElement) => {
              if (item.id === delElement) {
                el.data.splice(idx, 1)
                if (el.data.length === 0) {
                  listToChange.splice(index, 1)
                } else {
                  el.data[idx - 1].skorygowana = false
                }
              }
            })
          })
        })

        dispatch({
          type: "handleState",
          payload: listToChange,
          field: "correctionList",
        })

        fetchSortData(filtersState, null, true)
      } else {
        if (response.status === 400) {
          alert(response.data.errors[0])
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setDelModal({ visible: false })
      console.error(error)
    }
  })

  const handleExport = () => {
    tracker("Sprawdź_JPK - Export")
    setExportFile(!exportFile)
  }

  const handleCalc = async (fromDetails) => {
    setLoading(true)
    try {
      const response = await API.post(`/VatGeneratorDeclaration/calculateDeclaration`, {
        id: parseInt((activeElem && activeElem.id) || id),
      })

      setLoading(false)

      if (response.status === 200 && fromDetails) {
        dispatch({
          type: "handleState",
          payload: true,
          field: "refreshDetails",
        })
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const handleResearch = async () => {
    tracker("Sprawdź_JPK - Badania")
    if (ctx.currentOrganization.licenceType === "CloudTaxFree") {
      return setDemoModal(true)
    }

    setResearchModal(true)
  }

  const handleVerify = async () => {
    tracker("Generator - Weryfikacja NIP - Weryfikuj")
    setVerificationModal({ visible: true })
  }

  const handleVatUE = async () => {
    let selectedRowId = 0
    let selectedRecord
    let correction
    if (selectedRows.length === 1) {
      selectedRowId = selectedRows[0]
    } else if (selectedRows.length === 0) {
      const url = new URL(window.location.href)
      const idFromUrl = url.searchParams.get("id")
      if (idFromUrl !== null) {
        selectedRowId = parseInt(idFromUrl)
      }
    }

    if (state.vatTableData !== null && state.vatTableData.length > 0 && state.vatTableData.find(x => x.id === selectedRowId) !== undefined) {
      selectedRecord = state.vatTableData.find(x => x.id === selectedRowId)
      for (let index = 0; index < state.correctionList.length; index++) {
        const element = state.correctionList[index];
        correction = element.data.find(x => x.id === selectedRowId)
        if (correction !== undefined) {
          setVatUEKCorrectionId(correction.id)
          break
        }
      }
    } else { // occurs when you enter the site directly on /ewidencja-vat?id=ID
      const resp = await API.get(`VatGenerator/getVatRecord?id=${selectedRowId}`)

      if (resp.data.stateObject.cel_zlozenia === 1) {
        selectedRecord = resp.data.stateObject
      } else {
        setVatUEKCorrectionId(selectedRowId)
        correction = resp.data.stateObject
      }
    }

    if (selectedRecord !== undefined) {
      setVatUeData(selectedRecord)

      if (selectedRecord.cel_zlozenia === 1) {
        tracker("Sprawdź_JPK - VAT UE")
        setVatUEModal(true)
      }
      else {
        if (!selectedRecord.skorygowana){
          tracker("Sprawdź_JPK - VAT UEK - Brak innych ewidencji")
          setVatUEModalWarning(true)
        }
        else{
          tracker("Sprawdź_JPK - VAT UEK")
          setVatUEKTitle(<span>Wykonaj obliczenia VAT UEK dla {selectedRecord.rok}.{selectedRecord.miesiac} {selectedRecord.pelna_nazwa}<br></br>w odniesieniu do ewidencji</span>)
          setLoading(true)
          const url = `/VatGenerator/relatedVatRecordsWithoutSelectedCorrection?id=` + selectedRecord.id
          API.get(url, {
          }).then((response) => {
            if (response.status !== 200) return
            setVatUekData(response.data.stateObject)
            setVatUEKModal(true)
          })
            .catch(error => console.log(error))
            .finally(() => {
              setLoading(false)
          })
        }
      }
    } else {
      tracker("Sprawdź_JPK - VAT UEK")
      setVatUEKTitle(<span>Wykonaj obliczenia VAT UEK dla {correction.rok}.{correction.miesiac} {correction.pelna_nazwa}<br></br>w odniesieniu do ewidencji</span>)
      setLoading(true)
      const url = `/VatGenerator/relatedVatRecordsWithoutSelectedCorrection?id=` + correction.id
      API.get(url, {
      }).then((response) => {
        if (response.status !== 200) return
        setVatUekData(response.data.stateObject)
        setVatUEKModal(true)
      })
        .catch(error => console.log(error))
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const getVatUEModal = () => <FormModal
    visible={vatUEModal}
    destroyOnClose
    afterClose={() => {
      setVatUEModal(false)
    }}
    header={<h3>Wykonaj obliczenia VAT UE dla:</h3>}
    hasForm={
      <VatUEModalContent
        record={vatUeData}
        loading={loading}
        vatUERef={vatUERef}
        id={id}
        setVatUEModal={setVatUEModal}
      />
    }
    onAccept={() => vatUERef.current.submit()}
    onDecline={() => {
      Modal.destroyAll()
      setVatUEModal(false)
    }}
    okButtonName="Oblicz"
  />

  const getVatUEModalWarning = () => <FormModal
    visible={vatUEModalWarning}
    destroyOnClose
    afterClose={() => {
      setVatUEModalWarning(false)
    }}
    hasForm={
      <VatUEModalWarningContent
        record={vatUeData}
        loading={loading}
        vatUERef={vatUERef}
        setVatUEModalWarning={setVatUEModalWarning}
      />
    }
    onAccept={() => vatUERef.current.submit()}
    okButtonName="OK"
  />

  const getVatUEKModal = () =>
    <FormModal
      visible={vatUEKModal}
      destroyOnClose
      afterClose={() => {
        setVatUEKModal(false)
        setAnyVatUEKSelected(false)
      }}
      header={<h3>{vatUEKTitle}</h3>}
      hasForm={
        <VatUEKModalContent
          data={vatUekData}
          loading={loading}
          formRef={vatUEKRef}
          setModalVisible={setVatUEKModal}
          correctionId={vatUEKCorrectionId}
          showExportColumn={!ctx.featureFlags.Generator_GateDisabled}
          setAntSelected={setAnyVatUEKSelected}
        />
      }
      onAccept={() => vatUEKRef.current.submit()}
      acceptDisabled={!anyVatUEKSelected}
      onDecline={() => {
        Modal.destroyAll()
        setVatUEKModal(false)
      }}
      okButtonName="Oblicz"
      addClass="vatUEKForm"
    />

  const getVerifyModal = () =>
    verificationModal.visible ? (<SelectVerificationModal
                                    onDecline={() => setVerificationModal({ visible: false })}
                                    onAccept={(selected) => { executeVerification(selected) }}>
                                 </SelectVerificationModal>) 
                              : null

 
  const executeVerification = (selectedMethods) => {
    setVerificationModal({ visible: false })
    const now = new Date().toISOString()
    setVerificationInProgress(true)
    let url = "/NipVerification/VerifyEntitiesGenerator"
    let selectedRowId = 0

    if (selectedRows.length === 1) {
      selectedRowId = selectedRows[0]
    } else if (selectedRows.length === 0) {
      const url = new URL(window.location.href)
      const idFromUrl = url.searchParams.get("id")
      if (idFromUrl !== null) {
        selectedRowId = parseInt(idFromUrl)
      }
    }

    API.post(url, {
      vatRecordId: selectedRowId,
      verificationDate: now,
      mfVerification: selectedMethods.mf,
      viesVerification: selectedMethods.vies,
    }).finally(() => {
      setVerificationInProgress(false)

      dispatch({
        type: "handleState",
        payload: true,
        field: "refreshDetails",
      })
    })
  }

  const getReport = (ids, CheckupStatusDataFilter, VerificationKindDataFilter) => {
    setReportInProgress(true)
    let url = "/NipVerification/Report?"

    API.post(url, {
      ids,
      CheckupStatusDataFilter,
      VerificationKindDataFilter
    }, {
      responseType: 'blob', //important
    }).then((response) => {
      if (response.status !== 200) return
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      let fileName = getFileNameFromContentDisposition(response.headers["content-disposition"])
      if (!fileName) {
        fileName = "CloudTax - raport"
      }
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }).finally(() => {
      setReportInProgress(false)
    })
  }

  useEffect(() => {
    let clearEvent = false
    if (state.notificationEvent && state.notificationEvent.actionName === "CreateVerificationReport") {
      getReport(state.notificationEvent.args.Ids,
        state.notificationEvent.args.CheckupStatusDataFilter,
        state.notificationEvent.args.VerificationKindDataFilter,
        true)
      clearEvent = true
    }
    if (clearEvent) {
      dispatch({
        type: "handleState",
        field: "notificationEvent",
        payload: null
      })
    }
  }, [state.notificationEvent])

  // BRAMKA
  if (window.location.href.includes("bramka")) {
    return <Gate id={id} />
  }

  if (window.location.search.includes("id")) {
    return (
      <>
        <FormModal
          visible={demoModal}
          content={`Badania merytoryczne służą do identyfikacji obszarów, które stanowią lub mogą stanowić  nieprawidłowości w rozliczeniach podatkowych rzutujących na wysokość zobowiązania podatkowego. Sprawdź dostępne licencje CloudTax w celu uzyskania dostępu do badań`}
          onAccept={() => window.open(ctx.currentOrganization.resellerLink, "_blank")}
          onDecline={() => setDemoModal(false)}
          addClass="delModal demoModal"
          okButtonName="Licencje"
        />

        <FormModal
          visible={researchModal}
          destroyOnClose
          afterClose={() => {
            setResearchModal(false)
          }}
          header={<h3>Wybierz badania, które chcesz wykonać dla danej ewidencji:</h3>}
          hasForm={
            <ResearchModalContent
              loading={loading}
              fetchResearchDict={fetchResearchDict}
              researchRef={researchRef}
              id={id}
              setResearchModal={setResearchModal}
            />
          }
          onAccept={() => researchRef.current.submit()}
          onDecline={() => {
            Modal.destroyAll()
            setResearchModal(false)
          }}
          addClass="exportModal researchModal"
          okButtonName="Wykonaj"
        />

        <ExportModal visible={exportFile} setVisible={() => setExportFile(false)} id={parseInt(id)} />

        {getVatUEModal()}

        {getVatUEModalWarning()}

        {getVatUEKModal()}

        {getVerifyModal()}

        <div className="content-wrapper inner-shadow">
          <VatDetails
            setDetailsBack={(v) => {
              setDetailsBack(v)
              restoreSearchValue()
            }}
            handleExport={handleExport}
            handleCalc={handleCalc}
            handleResearch={handleResearch}
            handleVatUE={handleVatUE}
            verifyNip={verifyNip}
            handleVerify={handleVerify}
          />
        </div>
      </>
    )
  }

  const { visibleVats } = state

  return (
    <div className="vatTableContainer content-wrapper inner-shadow">
      {/* ERROR MODAL FROM IMPORT */}

      <FormModal
        visible={errorModal}
        content={errorMessage}
        onAccept={() => {
          window.open(ctx.currentOrganization.resellerLink, "_blank")
        }}
        onDecline={() => {
          showErrorModal(false)
          setErrorMessage()
        }}
        addClass="delModal demoModal"
        okButtonName="Licencje i pakiety"
      />

      {/* ERROR MODAL FROM IMPORT */}
      <ExportModal visible={exportFile} setVisible={() => setExportFile(false)} />

      <FormModal
        visible={demoModal}
        content={`Badania merytoryczne służą do identyfikacji obszarów, które stanowią lub mogą stanowić  nieprawidłowości w rozliczeniach podatkowych rzutujących na wysokość zobowiązania podatkowego. Sprawdź dostępne licencje CloudTax w celu uzyskania dostępu do badań`}
        onAccept={() => window.open(ctx.currentOrganization.resellerLink, "_blank")}
        onDecline={() => setDemoModal(false)}
        addClass="delModal demoModal"
        okButtonName="Licencje"
      />

      <FormModal
        visible={researchModal}
        destroyOnClose
        afterClose={() => {
          setResearchModal(false)
        }}
        header={<h3>Wybierz badania, które chcesz wykonać dla danej ewidencji:</h3>}
        hasForm={
          <ResearchModalContent
            loading={loading}
            fetchResearchDict={fetchResearchDict}
            researchRef={researchRef}
            id={id}
            setResearchModal={setResearchModal}
          />
        }
        onAccept={() => researchRef.current.submit()}
        onDecline={() => {
          Modal.destroyAll()
          setResearchModal(false)
        }}
        addClass="exportModal researchModal"
        okButtonName="Wykonaj"
      />

      {getVatUEModal()}

      {getVatUEModalWarning()}

      {getVatUEKModal()}

      {getVerifyModal()}

      {/* del record modal */}
      {delModal.visible ? (
        <FormModal
          visible={delModal.visible}
          content={`Czy na pewno chcesz usunąć ${selectedRows > 1 ? "wybraną ewidencję" : "wybrane ewidencje"} ?`}
          onAccept={delRecorHandler}
          onDecline={() => setDelModal({ visible: false })}
          addClass="delModal"
          okButtonName="Usuń"
        />
      ) : null}

      <ReactTooltip
        effect="solid"
        className="tooltip"
        backgroundColor="#fff"
        textColor="#163567"
        borderColor="#163567"
        border
        html
      />

      <div className="table-action-section">
        <div className="search-input">
          <Search
            allowClear
            placeholder={null}
            onSearch={debounceHanlderInput}
            onChange={debounceHanlderInput}
            style={{ width: 169, height: 38 }}
            ref={inputRef}
            className="broken-search"
            defaultValue={searchDefault}
          />
        </div>

        <div className="action-section-buttons">
          <VeryficationButton isDisabled={disabledUpload} blockInputButtons={blockInputButtons} />
          <div className="dash" />
          <ImportButton
            showErrorModal={showErrorModal}
            setErrorMessage={setErrorMessage}
            isDisabled={disabledUpload}
            blockInputButtons={blockInputButtons}
            fetchSortData={() => fetchSortData(filtersState)}
          />
          <button onClick={handleExport} disabled={!vatHelpers.handleSingleVisible(visibleVats)}>
            <img src={eksport} alt="eksport button" />
            export
          </button>
          <button
            onClick={() => {
              tracker("Sprawdź_JPK - Obliczenia")
              handleCalc(false)
            }}
            disabled={!vatHelpers.handleCorrectionButton(visibleVats) || !block}
          >
            <img src={obliczenia} alt="count button" />
            obliczenia
          </button>
          <button onClick={handleResearch} disabled={!vatHelpers.handleSingleVisible(visibleVats)}>
            <img src={research} alt="research button" />
            badania
          </button>

          {verifyNip && (
            <button 
              onClick={() => {
                tracker("Generator - Weryfikacja NIP - Weryfikuj")
                setVerificationModal({ visible: true })
              }}
              disabled={!vatHelpers.handleSingleVisible(visibleVats)}
            >
              <img src={verifyIcon} alt="" />
              weryfikuj NIP
            </button>
          )}

          {!ctx.featureFlags.Generator_GateDisabled && (
            <button
              onClick={() => {
                tracker("Sprawdź_JPK - Wyślij")
                history.push(`/bramka?id=${state.visibleVats[0].id}`)
              }}
              disabled={!vatHelpers.handleSingleVisible(visibleVats)}
            >
              <img src={send} alt="send button" />
              wyślij
            </button>
          )}

          <div className="dash" />
          <button
            // disabled={vatHelpers.handleMultiVisible(visibleVats) || vatHelpers.handleSingleVisible(visibleVats)}
            onClick={() => {
              tracker("Sprawdź_JPK - Dodaj ewidencję")
              dispatch({
                type: "handleState",
                field: "showAddEntity",
                payload: true,
              })
            }}
          >
            <img src={ewidencja} alt="entity button" />
            ewidencja
          </button>
          <button onClick={addCorrectionHandler} disabled={!vatHelpers.handleSingleVisible(visibleVats)}>
            <img src={korekta} alt="fix button" />
            korekta
          </button>
          {!ctx.featureFlags.VatUeUekDisabled && (
            <button onClick={handleVatUE} disabled={!vatHelpers.handleSingleVisible(visibleVats)}>
              <img src={vatUE} alt="vat UE" />
              VAT UE
            </button>
          )}

          <div className="dash" />
          <button
            onClick={() => {
              tracker("Sprawdź_JPK - Szczegóły ewidencji")
              history.push(`?id=${state.visibleVats[0].id}`)
            }}
            disabled={!vatHelpers.handleSingleVisible(visibleVats)}
          >
            <img src={szczegoly} alt="details button" />
            szczegóły
          </button>
          <button
            onClick={() => {
              tracker("Sprawdź_JPK - Usuń ewidencję")
              setDelModal({ visible: true, data: selectedRows })
            }}
            disabled={!vatHelpers.handleDeleteButton(visibleVats)}
          >
            <img src={usun} alt="delete button" />
            usuń
          </button>
        </div>
      </div>
      <div className="EntityPanel">
        {/* DODAJ NOWA */}
        {state.showAddEntity ? (
          <Modal
            visible={state.showAddEntity}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            footer={null}
          >
            <CustomModal>
              <AddNewVatEntity
                form={form}
                setBlock={setBlock}
                fetchSortData={() => fetchSortData(filtersState)}
                onClose={closeModal}
                blockEdit={block}
              />
            </CustomModal>
          </Modal>
        ) : null}

        <FormModal
          visible={modalVisible}
          title="Błąd w ewidencji"
          content={state.vatTableError && state.vatTableError.join(",\n ")}
          onOk={handleErrorModal}
        />

        <ReactTable
          manual
          className="vatTable vatTable_records"
          data={state.vatTableData}
          columns={columns}
          multiSort={false}
          loading={loading}
          noDataText={""}
          NoDataComponent={() => null}
          LoadingComponent={(props) => {
            if (props.loading) {
              return <Loading absolute />
            }
            return <></>
          }}
          resizable={false}
          PaginationComponent={(params) => (
            <CustomPagination currentPage={state.vatTableOptions.currentPage} {...params} />
          )}
          expanded={state.vatTableOptions.expanded}
          page={currentPageIndex}
          onPageChange={newPage => {
            currentPageIndex = newPage
            setCurrentPageIndex(newPage)
          }}
          pages={state.vatTableOptions.totalPagesNumber}
          defaultPageSize={state.vatTableOptions.pageSize}
          filtered={state.vatTableOptions.filtered}
          onFetchData={(innerState) => {
            setLoading(true)

            const filterValue = (innerState.filtered.length && innerState.filtered[0].value) || ""
            const sortedValue = vatHelpers.handleSortedField(innerState.sorted)
            const filters = {
              filterValue,
              sortedValue,
              innerPage: currentPageIndex,
              innerPageSize: innerState.pageSize,
            }

            if (detailsBack && Object.entries(filtersState).length !== 0 && filtersState.constructor === Object) {
              fetchSortData(filtersState)
            } else {
              fetchSortData(filters)
              setFilterState(filters)
            }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: ({ ctrlKey }) => {
                // Expand row on expand cell click
                if (typeof rowInfo !== "undefined") {
                  if (!column.id && rowInfo.original.skorygowana === true) {
                    handleSetExpanded(rowInfo.viewIndex)
                  }
                  // add row selection on row click, except click on expand arrow
                  if (column.id && selectedRows.includes(rowInfo.original.id) && column.id !== "buttons") {
                    removeItem(rowInfo.original.id)
                  } else {
                    if (ctrlKey && column.id !== "buttons") {
                      addItem(rowInfo.original.id)
                    } else if (column.id && column.id !== "buttons") {
                      setSelectedRows([rowInfo.original.id])
                    }
                  }
                }
              },
            }
          }}
          getTrProps={(_, rowInfo) => {
            if (typeof rowInfo !== "undefined") {
              return {
                className: rowInfo.original.zablokowana || rowInfo.original.skorygowana ? "blocked" : "",
                style: {
                  fontWeight: selectedRows.includes(rowInfo.original.id) && "bold",
                  background: selectedRows.includes(rowInfo.original.id) && "#f4f4f4",
                },
              }
            } else {
              return {
                onClick: originalHandleClick,
              }
            }
          }}
          SubComponent={(row) => {
            return (
              <VatRow
                data={row}
                correctionListRow={state.correctionList.filter((e) => e.parentId === row.original.id)}
                fetched={fetched}
                filtersState={filtersState}
                setFetched={setFetched}
                fetchCorrectionList={fetchCorrectionList}
                originalHandleClick={originalHandleClick}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setDelModal={setDelModal}
                addItem={addItem}
                removeItem={removeItem}
              />
            )
          }}
        />
      </div>
    </div>
  )
}

const VeryficationButton = ({ isDisabled, blockInputButtons }) => {
  const inputEl = useRef(null)
  const getXmlFile = async (event) => {
    blockInputButtons(true)

    const fileTosend = Array.from(event.target.files)
    const data = new FormData()

    data.append("file", fileTosend[0], fileTosend[0].name)

    try {
      await API.post("/Validate/Validate", data, {
        headers: {
          "Content-Type": `multipart/form-data boundary=${data._boundary}`,
        },
      })

      blockInputButtons(false)
      inputEl.current.value = null
    } catch (error) {
      blockInputButtons(false)
      console.error(error)
    }
  }

  return (
    <div className="fake-button">
      <input
        onChange={(event) => getXmlFile(event)}
        type="file"
        accept="text/xml"
        id="file-input"
        ref={inputEl}
        disabled={isDisabled}
      />
      <label
        htmlFor="file-input"
        onClick={() => {
          tracker("Sprawdź_JPK - Weryfikacja")
        }}
      >
        <img src={weryfikacja} alt="veryfi button" />
        weryfikacja
      </label>
    </div>
  )
}

const ImportButton = ({ isDisabled, blockInputButtons, fetchSortData, showErrorModal, setErrorMessage }) => {
  const {
    ctx: {
      currentOrganization: { licenceType },
    },
  } = useContext(Context)

  const fileRef = useRef(null)

  const handleImportEntity = useCallback(
    async (event) => {
      blockInputButtons(true)

      const fileTosend = Array.from(event.target.files)
      const data = new FormData()
      const [file] = fileTosend

      //TODO - make the file size be compared with the actual value stored by the license
      //for the time bing just a hardcoded value of 50mb
      const validFile = file.size <= 52428800 && file.size > 0 && file.type === "text/xml"

      if (!validFile) {
        try {
          API.post(`${routes.signalApi}api/Notyfication/SentToUser`, {
            message:
              "Wskazany plik przekracza limit rozmiaru dla obecnej licencji. Sprawdź dostępne licencje CloudTax w celu zwiększenia limitu.",
            notyficationType: 3,
          })

          blockInputButtons(false)
          return
        } catch (error) {
          console.error(error)
          return
        }
      }

      data.append("file", fileTosend[0], fileTosend[0].name)

      API.post(`${routes.signalApi}api/Notyfication/SentToUser`, {
        message: "Rozpoczęto import pliku...",
        notyficationType: 1,
        isSuccess: true,
      })

      try {
        const response = await API.post("/VatGenerator/Import", data, {
          headers: {
            "Content-Type": `multipart/form-data boundary=${data._boundary}`,
          },
        })

        // DELETE FILE
        fileRef.current.value = null

        //To póki co zakomentowuje, ale wydaje mi się zbębne bo przy użyciu azure oczekiwaną zwrotką będzie to, że jak podbiło nam stan licznika to useeffect woła przeładowanie danych
        //w obecnym scenariuszu przeładowywałoby podwójnie, gdzie to poniższe przeładowanie nie zwróci nam stanu zawierającego dodany rekord
        // if (response && response.status === 200) {
        //   fetchSortData()
        // }

        blockInputButtons(false)
      } catch (error) {
        if (error.response.status === 418) {
          // HANDLE LIMIT ERROR MESSAGE
          showErrorModal(true)
          setErrorMessage(error.response.data.errors[0])
        }

        console.error(error)
        blockInputButtons(false)
      }
    },
    [fileRef, fetchSortData]
  )

  return (
    <div className="fake-button">
      <input
        ref={fileRef}
        id="import-jpk"
        onChange={handleImportEntity}
        type="file"
        accept="text/xml"
        disabled={isDisabled}
      />
      <label
        htmlFor="import-jpk"
        onClick={() => {
          tracker("Sprawdź_JPK - Import")
        }}
      >
        <img src={imp} alt="import button" />
        import
      </label>
    </div>
  )
}

const ExportModal = ({ visible, setVisible, id = null }) => {
  const exportRef = useRef(null)

  const [ewidencja, setEwidencja] = useState(false)
  const [deklaracja, setDeklaracja] = useState(false)

  const setters = {
    setEwidencja,
    setDeklaracja,
  }
  return (
    <>
      <FormModal
        destroyOnClose
        afterClose={() => {
          setEwidencja(false)
          setDeklaracja(false)
        }}
        visible={visible}
        header={<h3>Wybierz elementy ewidencji do exportu:</h3>}
        hasForm={
          <ExportModalContent
            exportRef={exportRef}
            ewidencja={ewidencja}
            deklaracja={deklaracja}
            setters={setters}
            setVisible={setVisible}
            id={id}
          />
        }
        onAccept={() => exportRef.current.submit()}
        onDecline={() => {
          Modal.destroyAll()
          setVisible()
        }}
        addClass="exportModal"
        okButtonName="Potwierdź"
        acceptDisabled={!ewidencja && !deklaracja}
      />
    </>
  )
}

const ResearchModalContent = ({ researchRef, id, setResearchModal, fetchResearchDict, loading }) => {
  const { visibleVats, researchDict } = useContext(StateContext)

  useEffect(() => {
    fetchResearchDict()
  }, [])

  const handleDownloadFileXLSX = useCallback((xmltext, fileName) => {
    const pom = document.createElement("a")
    const bb = new Blob([xmltext], { type: "text/plain" })

    pom.setAttribute("href", window.URL.createObjectURL(bb))
    pom.setAttribute("download", fileName)

    pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":")
    pom.draggable = true
    pom.classList.add("dragout")

    pom.click()
    setResearchModal(false)
  }, [])

  const salesList = [...researchDict.filter(({ typ }) => typ === "S").map(({ nazwa, opis }) => `${nazwa} ${opis}`)]
  const purchaseList = [...researchDict.filter(({ typ }) => typ === "Z").map(({ nazwa, opis }) => `${nazwa} ${opis}`)]

  const [checkedSalesList, setCheckedSalesList] = useState([])
  const [checkedPurchaseList, setCheckedPurchaseList] = useState([])

  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAllSales, setCheckAllSales] = useState(false)
  const [checkAllPurchase, setCheckAllPurchase] = useState(false)

  const onChangeSales = (list) => {
    setCheckedSalesList(list)
    setIndeterminate(!!list.length && list.length < salesList.length)
    setCheckAllSales(list.length === salesList.length)
  }

  const onChangePurchase = (list) => {
    setCheckedPurchaseList(list)
    setIndeterminate(!!list.length && list.length < purchaseList.length)
    setCheckAllPurchase(list.length === purchaseList.length)
  }

  const onCheckAllSalesChange = (e) => {
    setCheckedSalesList(e.target.checked ? salesList : [])
    setIndeterminate(false)
    setCheckAllSales(e.target.checked)
  }

  const onCheckAllPurchaseChange = (e) => {
    setCheckedPurchaseList(e.target.checked ? purchaseList : [])
    setIndeterminate(false)
    setCheckAllPurchase(e.target.checked)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className="edit-sales">
      <Form
        ref={researchRef}
        layout={{
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        }}
        name="researchForm"
        onFinish={async () => {
          try {
            const allSales = checkedSalesList.map((e) => e.split(" ")[0])
            const allPurchase = checkedPurchaseList.map((e) => e.split(" ")[0])

            const allValues = [...allSales, ...allPurchase]

            setResearchModal(false)

            if (!allValues.length) return

            const elemId = parseInt(id) || parseInt(visibleVats[0].id)

            const response = await API.post(
              `/VatGenerator/ResearchXlsx`,
              {
                id: elemId,
                researchNameList: allValues,
              },
              // { responseType: "blob" }
            )

            const { data, status, headers } = response

            // error handler
            if (status === 200) {
              // const fileName = headers["content-disposition"]
              //   ? headers["content-disposition"].split("filename=")[1].split(";")[0].replace(/['"]+/g, "")
              //   : "file.xlsx"

              // handleDownloadFileXLSX(data, fileName)
            } else {
              setResearchModal(false)
            }
          } catch (error) {
            console.error(error)
            setResearchModal(false)
          }
        }}
        className="sales-edit-form"
      >
        <Tabs>
          <TabPane tab="Sprzedaż" key="1" forceRender>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllSalesChange} checked={checkAllSales}>
              Zaznacz wszystkie
            </Checkbox>
            <CheckboxGroup options={salesList} value={checkedSalesList} onChange={onChangeSales} />
          </TabPane>
          <TabPane tab="Zakupy" key="2" forceRender>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllPurchaseChange} checked={checkAllPurchase}>
              Zaznacz wszystkie
            </Checkbox>

            <CheckboxGroup options={purchaseList} value={checkedPurchaseList} onChange={onChangePurchase} />
          </TabPane>
        </Tabs>
      </Form>
    </div>
  )
}

const VatUEModalContent = ({ record, vatUERef, id, setVatUEModal }) => {
  const period = record.miesiac
  const year = record.rok
  const name = record.pelna_nazwa

  const handleDownloadFileZIP = useCallback((xmltext, fileName) => {
    const pom = document.createElement("a")
    const bb = new Blob([xmltext], { type: "text/plain" })

    pom.setAttribute("href", window.URL.createObjectURL(bb))
    pom.setAttribute("download", fileName)

    pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":")
    pom.draggable = true
    pom.classList.add("dragout")

    pom.click()
    setVatUEModal(false)
  }, [])

  return (
    <div className="edit-sales">
      <h3>{year}.{period} {name}</h3>
      <Form
        ref={vatUERef}
        layout={{
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        }}
        name="vatUEForm"
        onFinish={async () => {
          try {

            setVatUEModal(false)

            const elemId = parseInt(id) || parseInt(record.id)

            const response = await API.post(
              `/VatGenerator/ExportVatUE`,
              {
                id: elemId
              },
              { responseType: "blob" }
            )

            const { data, status, headers } = response

            // error handler
            if (status === 200) {
              const fileName = getFileNameFromContentDisposition(headers["content-disposition"])
              handleDownloadFileZIP(data, fileName)
            } else {
              setVatUEModal(false)
            }
          } catch (error) {
            console.error(error)
            setVatUEModal(false)
          }
        }}
        className="sales-edit-form"
      >
      </Form>
    </div>
  )
}

const VatUEModalWarningContent = ({ record, vatUERef, setVatUEModalWarning }) => {
  const name = record.pelna_nazwa

  return (
    //Todo className
    <div className="edit-sales"> 
      <h3>Nie posiadasz innych ewidencji dla podmiotu {name} dla tego samego okresu.<br></br> W celu wykonania obliczeń dla VAT UEK utwórz kolejne ewidencje.</h3>
      <Form
        ref={vatUERef}
        layout={{
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        }}
        name="vatUEFormWarning"
        onFinish={async () => {
          setVatUEModalWarning(false)
        }}
        //Todo className
        className="sales-edit-form"
      >
      </Form>
    </div>
  )
}

const ExportModalContent = ({
  exportRef,
  ewidencja,
  deklaracja,
  setters: { setEwidencja, setDeklaracja },
  setVisible,
  id,
}) => {
  const { visibleVats } = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const [cont, setCont] = useState(false)

  const [fromContinue, setFromContinue] = useState(false)

  const handleDownloadFileXml = useCallback((xmltext, fileName) => {
    const pom = document.createElement("a")
    const bb = new Blob([xmltext], { type: "text/plain" })

    pom.setAttribute("href", window.URL.createObjectURL(bb))
    pom.setAttribute("download", fileName)

    pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":")
    pom.draggable = true
    pom.classList.add("dragout")

    pom.click()
  }, [])

  return (
    <>
      <FormModal
        visible={cont}
        content={`Eksportowana deklaracja posiada wartości, które nie są zgodne z zasadami wypełniania deklaracji. W celu poprawienia wartości, przejdź do szczegółów ewidencji, następnie zakładki Deklaracja oraz: 
        
          1.Wykonaj obliczenie deklaracji.
          2.Włącz tryb edycji. Przy próbie zapisu aplikacja wskaże niezgodności z zasadami wypełnienia deklaracji.

        Jeśli chcesz wyeksportować deklarację bez wprowadzania zmian, wybierz przycisk ‘Kontynuuj’.`}
        onAccept={() => {
          setFromContinue(true)
          exportRef.current.submit()
        }}
        onDecline={() => {
          setCont(false)
          setFromContinue(false)
          setVisible()
        }}
        addClass="delModal demoModal"
        okButtonName="Kontynuuj"
      />
      <Form
        ref={exportRef}
        layout={{
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        }}
        name="SalesEdit"
        initialValues={{
          ewidencja,
          deklaracja,
        }}
        onFinish={async () => {
          try {
            if (!deklaracja) {
              setVisible()
            }

            const response = await API.post(`/VatGenerator/ExportXml`, {
              id: id || visibleVats[0].id,
              export_vatrecord: ewidencja,
              export_declaration: deklaracja,
              consent_to_validation: fromContinue,
            })

            const { data, status, headers } = response

            // error handler
            if (status === 200) {
              setCont()
              const fileName = headers["content-disposition"]
                ? headers["content-disposition"].split("filename=")[1].split(";")[0].replace(/['"]+/g, "")
                : "file.xml"

              handleDownloadFileXml(data, fileName)
              dispatch({
                type: "handleState",
                payload: true,
                field: "refreshDetails",
              })
              setVisible()
            } else {
              // 204 USED TO BE 204
              if (status === 418) {
                setCont(true)
              } else {
                setVisible()
                setCont()
              }
            }
            // setVisible()
          } catch (error) {
            if (error.response.status === 418) {
              setCont(true)
            }

            console.error(error)
          }
        }}
        className="sales-edit-form"
      >
        <Form.Item name="ewidencja" hasFeedback className="details-item" valuePropName="checked">
          <Checkbox checked={ewidencja} onChange={() => setEwidencja(!ewidencja)}>
            <span className="label">Ewidencja - dokumenty sprzedazy i zakupu</span>
          </Checkbox>
        </Form.Item>

        <Form.Item name="deklaracja" hasFeedback className="details-item" valuePropName="checked">
          <Checkbox checked={deklaracja} onChange={() => setDeklaracja(!deklaracja)}>
            <span className="label">Deklaracja</span>
          </Checkbox>
        </Form.Item>
      </Form>
    </>
  )
}

export default VatTable
