import axios from "axios"

import LocalStorageService from "../../helpers/localStorageService"

const localStorageService = LocalStorageService.getService()

const getEnvironmentByHostname = () => {
  const host = document.location.hostname.toLowerCase()

  switch (host) {
    case "cloudtax.pl":
    case "cloudfortax.pl":
    case "cloudtax.eu":
    case "app-cloud-tax-production-web-client.azurewebsites.net":
      return "Production"
    case "app-cloud-tax-staging-web-client.azurewebsites.net":
      return "Staging"
    case "app-cloud-tax-tests-acceptance-web-client.azurewebsites.net":
      return "TestsAcceptance"
    case "localhost":
      return "Local"
    default:
      return "Local";
  }
}

const getEnvironment = () => {
  if (!process.env.REACT_APP_ENVIRONMENT) return "Development"
  if (process.env.REACT_APP_ENVIRONMENT === "Auto") return getEnvironmentByHostname()
  return process.env.REACT_APP_ENVIRONMENT
}

export const isProductionEnvironment = getEnvironment() === "Production"
export const isStagingEnvironment = getEnvironment() === "Staging"

export const isLogrocketHidden = isProductionEnvironment

const routesObj = {
  Local: {
    coreApi: `https://localhost:44377/`,
    generatorApi: `https://localhost:44328/`,
    signalApi: `https://localhost:44367/`,
    whiteListApi: `https://localhost:44332/`,
    nipVerificationApi: `https://localhost:44342/`,
    googleSiteKey: `6LdfLmcaAAAAABo-PVozBao4cNCoEMMfhXnBtONG`,
  },
  Development: {
    coreApi: `https://app-cloud-tax-tests-acceptance-api-core.azurewebsites.net/`,
    generatorApi: `https://app-cloud-tax-tests-acceptance-api-generator.azurewebsites.net/`,
    signalApi: `https://app-cloud-tax-tests-acceptance-api-signalr.azurewebsites.net/`,
    whiteListApi: `https://app-cloud-tax-tests-acceptance-api-white-list.azurewebsites.net/`,
    nipVerificationApi: `https://app-cloud-tax-tests-acceptance-api-nip-verification.azurewebsites.net/`,
    googleSiteKey: `6LdfLmcaAAAAABo-PVozBao4cNCoEMMfhXnBtONG`,
  },
  TestsAcceptance: {
    coreApi: `https://app-cloud-tax-tests-acceptance-api-core.azurewebsites.net/`,
    generatorApi: `https://app-cloud-tax-tests-acceptance-api-generator.azurewebsites.net/`,
    signalApi: `https://app-cloud-tax-tests-acceptance-api-signalr.azurewebsites.net/`,
    whiteListApi: `https://app-cloud-tax-tests-acceptance-api-white-list.azurewebsites.net/`,
    nipVerificationApi: `https://app-cloud-tax-tests-acceptance-api-nip-verification.azurewebsites.net/`,
    googleSiteKey: `6LdfLmcaAAAAABo-PVozBao4cNCoEMMfhXnBtONG`,
  },
  Staging: {
    coreApi: `https://app-cloud-tax-staging-api-core.azurewebsites.net/`,
    generatorApi: `https://app-cloud-tax-staging-api-generator.azurewebsites.net/`,
    signalApi: `https://app-cloud-tax-staging-api-signalr.azurewebsites.net/`,
    whiteListApi: `https://app-cloud-tax-staging-api-white-list.azurewebsites.net/`,
    nipVerificationApi: `https://app-cloud-tax-staging-api-nip-verification.azurewebsites.net/`,
    googleSiteKey: `6LfK84kaAAAAAK0xcyG0DFjp-FT5Z6dM9tkHo39e`,
  },
  Production: {
    coreApi: `https://app-cloud-tax-production-api-core.azurewebsites.net/`,
    generatorApi: `https://app-cloud-tax-production-api-generator.azurewebsites.net/`,
    signalApi: `https://app-cloud-tax-production-api-signalr.azurewebsites.net/`,
    whiteListApi: `https://app-cloud-tax-production-api-white-list.azurewebsites.net/`,
    nipVerificationApi: `https://app-cloud-tax-production-api-nip-verification.azurewebsites.net/`,
    googleSiteKey: `6Lcn9YkaAAAAAM8JLazA95-ksJdyf-hd9KCWO6Ao`,
  },
}

export const routes = routesObj[getEnvironment()]

export const regulationsPDF = routes.coreApi + `files/CloudTax_Regulamin_01042021.pdf`
export const cookiesPDF = routes.coreApi + `files/CloudTax_Polityka_prywatności_i_cookies_01042021.pdf`

export const baseApiURL = routes.coreApi + `api`
export const generatorApiURL = routes.generatorApi + `api`
export const baseURL = routes.coreApi
export const whiteListApiURL = routes.whiteListApi
export const nipVerificationApiURL = routes.nipVerificationApi + `api`

const API = axios.create({
  baseURL: baseApiURL,
  headers: {
    "Content-Type": "application/json",
  },
})

API.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken()

    if (token) {
      config.headers["Authorization"] = "Bearer " + token
    }

    if (
      config.url.includes("VatGenerator") ||
      config.url.includes("Validate") ||
      config.url.includes("VatRecordSale") ||
      config.url.includes("VatRecordPurchase") ||
      config.url.includes("VatGeneratorDeclaration")
    ) {
      config.baseURL = routes.generatorApi + `api`

      config.headers.tenant_id = localStorage.getItem("currentGuid")
    } else if (config.url.includes("WhiteList")) {
      config.baseURL = routes.whiteListApi + `api`
      config.headers.tenant_id = localStorage.getItem("currentGuid")
    } else if (config.url.includes("NipVerification")) {
      config.baseURL = routes.nipVerificationApi + `api`
      config.headers.tenant_id = localStorage.getItem("currentGuid")
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

API.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 400) {
      return error.response
    }

    const originalRequest = error.config

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      return axios
        .post(`${baseApiURL}/Users/getToken`, {
          refreshToken: localStorageService.getRefreshToken(),
        })
        .then(async (res) => {
          if (res.status === 200) {
            await localStorageService.setToken({
              token: res.data.stateObject.token,
              refresh_token: res.data.stateObject.refreshToken,
            })

            const upadtedReq = {
              ...originalRequest,
              headers: { ...originalRequest.headers, Authorization: "Bearer " + res.data.stateObject.token },
            }
            return axios(upadtedReq)
          }
        })
        .catch((err) => {
          // localStorageService.clearToken()
          // window.location.href = "/login"
        })
    }

    return Promise.reject(error)
  }
)

export default API
