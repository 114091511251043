import React, { useContext, useState } from "react"

import { Form, InputNumber, Radio, Checkbox } from "antd"

import SummarySection from "./SummarySection"
import { StateContext } from "components/userContext"
import {
  dash,
  myIdea,
  p_38,
  p_39,
  p_48,
  p_49,
  p_50,
  p_51,
  p_52,
  p_53,
  p_54,
  p_540,
  p_55,
  p_56,
  p_560,
  p_57,
  p_58,
  p_59,
  p_60,
  p_61,
  p_62,
  radioButtonsSchema1,
  radioButtonsSchema2
} from "./DeclarationsTabFix"

import infoIcon from "images/info.svg"
import TextArea from "antd/lib/input/TextArea"
import FormItem from "antd/lib/form/FormItem"

const TaxCalculationFix = (props: any) => {
  const {
    editMode,
    handleCalculateSummary,
    rightCol,
    editConfirmed,
    setEditConfirmed,
    confirmEditModal,
    setConfirmEditModal,
    handleSummaryChange,
    isBlocked,
    declarationFormFixRef,
    initialData,
    checkboxChecked,
    setCheckboxChecked,
    formVariant
  } = props

  const state = useContext(StateContext)

  const getUpdatedValues = () => {
    if (declarationFormFixRef && declarationFormFixRef.current) {
      return declarationFormFixRef.current.getFieldsValue()
    }

    return initialData
  }

  const updatedValues = getUpdatedValues()
  const [radiosActive, setRadiosActive] = useState(!editMode && updatedValues[p_54] && !isNaN(updatedValues[p_54]))

  var radioButtons = formVariant === 1 ? radioButtonsSchema1 : radioButtonsSchema2

  return (
    <div className="Taxcalc">
      <div className="Tax-header">
        <h4>Obliczenie wysokości zobowiązania podatkowego lub kwoty zwrotu</h4>
      </div>
      <div className="Tax-content">
        <SummarySection />

        <div className="Calc-inputs">
          {[p_39, p_49, p_50, p_51, p_52, p_53, p_54].map((fieldName: string) => {
            const { short_description: SD, full_description: FD } = state.declarationDictionaryFix.find(
              (row: { code: string }) => row.code === fieldName.toUpperCase()
            )

            const summary51 = fieldName === p_51
            const summary53 = fieldName === p_53

            return (
              <div className="content-row" key={fieldName}>
                <div className="Tax-col Tax-col_one" style={{ padding: "0 100px 0 0" }}>
                  <p>{SD}</p>
                </div>
                <div className="Tax-col Tax-col_two" style={{ flexBasis: "200px" }}>
                  <span className="const-name">{fieldName.toUpperCase()}</span>
                  <span className="const-value">{state.declarationData[fieldName] || dash}</span>

                  <div className="value">
                    {summary51 && editMode && (
                      <div
                        className={`${editConfirmed[p_51] ? "lock-open" : "lock-closed"}`}
                        onClick={() => {
                          if (!editConfirmed.p_51) {
                            setConfirmEditModal({
                              ...confirmEditModal,
                              p_51: true,
                            })
                          } else {
                            setEditConfirmed({ ...editConfirmed, p_51: !editConfirmed[p_51] })
                          }
                        }}
                      />
                    )}

                    {summary53 && editMode && (
                      <div
                        className={`${editConfirmed[p_53] ? "lock-open" : "lock-closed"}`}
                        onClick={() => {
                          if (!editConfirmed.p_53) {
                            setConfirmEditModal({
                              ...confirmEditModal,
                              p_53: true,
                            })
                          } else {
                            setEditConfirmed({ ...editConfirmed, p_53: !editConfirmed[p_53] })
                          }
                        }}
                      />
                    )}

                    <Form.Item
                      name={fieldName}
                      hasFeedback
                      validateTrigger="onBlur"
                      rules={[
                        (e) => ({
                          validator(_, elemValue) {
                            const fieldValue = e.getFieldValue(fieldName)
                            const fieldValueParsed = parseInt(fieldValue)

                            const p38value = e.getFieldValue(p_38)
                            const p48value = e.getFieldValue(p_48)

                            const p49value = e.getFieldValue(p_49)
                            const p50value = e.getFieldValue(p_50)
                            const p52value = e.getFieldValue(p_52)
                            const p53value = e.getFieldValue(p_53)
                            const p54value = e.getFieldValue(p_54)

                            const p38valueParsed = parseInt(e.getFieldValue(p_38))
                            const p48valueParsed = parseInt(e.getFieldValue(p_48))

                            const p49valueParsed = parseInt(e.getFieldValue(p_49))
                            const p50valueParsed = parseInt(e.getFieldValue(p_50))
                            const p51valueParsed = parseInt(e.getFieldValue(p_51))
                            const p53valueParsed = parseInt(e.getFieldValue(p_53))
                            const p54valueParsed = parseInt(e.getFieldValue(p_54))

                            const hasValue = (fieldName: string) =>
                              e.getFieldValue(fieldName) !== null && !isNaN(fieldValueParsed)

                            if (fieldName === p_39) {
                              if (elemValue < 0) {
                                return Promise.reject("Wartość musi być większa lub równa 0!")
                              }
                            }

                            // VALIDATE P49 FIELD
                            if (fieldName === p_49) {
                              //RETURN IF FILED EMPTY
                              if (!hasValue(p_49)) {
                                return Promise.resolve()
                              }

                              // HANDLE NEGATIVE VALUE
                              if (p49valueParsed < 0) {
                                return Promise.reject("Wartość musi być większa lub równa 0!")
                              }

                              // HANDLE VALUE BIGGER THAN SUMAMRY
                              // if (p49value > p38value - p48value) {
                              if (p38value - p48value > 0 && p49value > p38value - p48value) {
                                return Promise.reject("Podana kwota nie może przekraczać różnicy kwot P_38 - P_48!")
                              }
                              // }

                              // HANDLE NO SUMMARY VALUES
                              if (p38value - p48value <= 0) {
                                if (p49valueParsed > 0) {
                                  return Promise.reject("Podana kwota musi się równać 0!")
                                }
                              }
                            }

                            // VALIDATE P50 FIELD
                            if (fieldName === p_50) {
                              //RETURN IF FILED EMPTY
                              if (!hasValue(p_50)) {
                                return Promise.resolve()
                              }

                              // HANDLE VALUE BIGGER THAN SUMAMRY - 49
                              if (p50value > p38value - p48value - p49value) {
                                return Promise.reject(
                                  "Podana kwota nie może przekraczać różnicy kwot P_38 - P_48 - P_49!"
                                )
                              }
                              // }

                              // HANDLE NEGATIVE VALUE
                              if (p50valueParsed < 0) {
                                return Promise.reject("Wartość musi być większa lub równa 0!")
                              }
                            }

                            // VALIDATE P51 FIELD
                            if (fieldName === p_51) {
                              //RETURN IF FILED EMPTY
                              if (!hasValue(p_51)) {
                                return Promise.reject("Pole wymagane!")
                              }

                              // HANDLE NEGATIVE VALUE
                              if (p51valueParsed < 0) {
                                return Promise.reject("Wartość musi być większa lub równa 0!")
                              }
                            }

                            // VALIDATE P52 FIELD
                            if (fieldName === p_52) {
                              //RETURN IF FILED EMPTY
                              if (!hasValue(p_52)) {
                                return Promise.resolve()
                              }

                              // Jeśli P_38-P_48 > 0
                              // Oraz P_38-P_48-P_49 > 0 wtedy P_52 = 0
                              if (p52value > 0 && p38value - p48value > 0 && p38value - p48value - p49value > 0) {
                                return Promise.reject("Wartość musi być równa 0!")
                              }

                              if (p52value < 0) {
                                return Promise.reject("Wartość musi być większa lub równa 0!")
                              }
                            }

                            // VALIDATE P53 FIELD
                            if (fieldName === p_53) {
                              //RETURN IF FILED EMPTY
                              if (!hasValue(p_53)) {
                                return Promise.resolve()
                              }

                              // HANDLE NEGATIVE VALUE
                              if (p53valueParsed < 0) {
                                return Promise.reject("Wartość musi być większa lub równa 0!")
                              }
                            }

                            // VALIDATE P54 FIELD
                            if (fieldName === p_54) {
                              //RETURN IF FILED EMPTY
                              if (!hasValue(p_54)) {
                                return Promise.resolve()
                              }

                              // HANDLE NEGATIVE VALUE
                              if (p54value < 0) {
                                return Promise.reject("Wartość musi być większa lub równa 0!")
                              }

                              if ((p53value === null && !isNaN(p54valueParsed)) || p54value > p53value) {
                                return Promise.reject("Wartość musi być mniejsza lub równa wartości w polu P_53!")
                              }
                            }

                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        type="number"
                        step={1}
                        bordered={editMode}
                        readOnly={!editMode}
                        precision={0}
                        placeholder={!editMode ? dash : ""}
                        onBlur={() => {
                          if (editMode) {
                            if (fieldName === p_39) {
                              handleCalculateSummary(fieldName, 2, rightCol, getUpdatedValues)
                            }

                            if ([p_49, p_50].includes(fieldName)) {
                              handleCalculateSummary(fieldName, 3, [...rightCol, p_49, p_50], getUpdatedValues)
                            }

                            if ([p_48, p_52].includes(fieldName)) {
                              handleCalculateSummary(fieldName, 3, [...rightCol, p_48, p_52], getUpdatedValues)
                            }

                            if ([p_53, p_54].includes(fieldName)) {
                              handleCalculateSummary(fieldName, 3, [p_53, p_54], getUpdatedValues)
                            }

                            if (fieldName === p_54) {
                              const p54value = declarationFormFixRef.current.getFieldValue(p_54)

                              setRadiosActive(p54value !== null && p54value > 0)

                              if (!p54value) {
                                setCheckboxChecked(false)
                                declarationFormFixRef.current.setFields([
                                  {
                                    name: "radio_buttons",
                                    value: null,
                                    errors: [],
                                  },
                                  {
                                    name: p_59,
                                    value: false,
                                  },
                                  {
                                    name: p_60,
                                    value: null,
                                    errors: [],
                                  },
                                  {
                                    name: p_61,
                                    value: null,
                                    errors: [],
                                  },
                                  {
                                    name: p_62,
                                    errors: [],
                                  },
                                ])
                              }
                            }
                          }

                          if (summary51 && myIdea) {
                            const p51value = declarationFormFixRef.current.getFieldValue(p_51)

                            if (p51value >= 0 && p51value !== null) {
                              setEditConfirmed({
                                ...editConfirmed,
                                p_51: false,
                              })
                            }
                          }

                          if (summary53 && myIdea) {
                            const p53value = declarationFormFixRef.current.getFieldValue(p_53)

                            if (p53value >= 0) {
                              setEditConfirmed({
                                ...editConfirmed,
                                p_53: false,
                              })
                            }
                          }
                        }}
                        onChange={() => {
                          summary51 && handleSummaryChange(p_51)
                          summary53 && handleSummaryChange(p_53)
                        }}
                        disabled={summary51 ? isBlocked(p_51) : summary53 ? isBlocked(p_53) : false}
                      />
                    </Form.Item>
                  </div>
                  <div
                    className="info"
                    style={{
                      right: "-10px",
                      position: "relative",
                    }}
                  >
                    <img src={infoIcon} data-tip={fieldName.toUpperCase() + ": " + FD} />
                  </div>
                </div>
              </div>
            )
          })}

          <FormItem name="schema_version"></FormItem>

          <Form.Item
            className="radio-buttons"
            name="radio_buttons"
            rules={[{ required: radiosActive, message: formVariant === 1 ? "Jedno z pól P_55, P_56, P_57, P_58 jest wymagane!" : "Jedno z pól P_540, P_55, P_56, P_560, P_57, P_58 jest wymagane!" }]}
          >
            <Radio.Group disabled={!radiosActive || !editMode}>
              {radioButtons.map((fieldName) => {
                const { short_description: SD, full_description: FD } = state.declarationDictionaryFix.find(
                  (row: { code: string }) => row.code === fieldName.toUpperCase()
                )

                return (
                  <div className="content-row radio" key={fieldName}>
                    <div className="Tax-col Tax-col_one" style={{ padding: "0 100px 0 0" }}>
                      <p>{SD}</p>
                    </div>
                    <div className="Tax-col Tax-col_two" style={{ flexBasis: "200px" }}>
                      <span className="const-name">{fieldName.toUpperCase()}</span>

                      <div className="value">
                        <Radio value={fieldName} name={fieldName}></Radio>
                      </div>
                    </div>
                    <div
                      className="info"
                      style={{
                        right: "-10px",
                        position: "relative",
                      }}
                    >
                      <img src={infoIcon} data-tip={fieldName.toUpperCase() + ": " + FD} />
                    </div>
                  </div>
                )
              })}
            </Radio.Group>
          </Form.Item>

          <Form.Item name={p_59} className="radio-buttons checkbox" valuePropName="checked">
            <div className="content-row radio" key={p_59}>
              <div className="Tax-col Tax-col_one" style={{ padding: "0 100px 0 0" }}>
                <p>Zaliczenie zwrotu podatku na poczet przyszłych zobowiązań podatkowych</p>
              </div>
              <div className="Tax-col Tax-col_two" style={{ flexBasis: "200px" }}>
                <span className="const-name">{p_59.toUpperCase()}</span>

                <div className="value">
                  <Checkbox
                    disabled={!editMode || !radiosActive}
                    checked={checkboxChecked}
                    onChange={() => {
                      setCheckboxChecked(!checkboxChecked)
                      declarationFormFixRef.current.setFields([
                        {
                          name: p_60,
                          value: null,
                          errors: [],
                        },
                        {
                          name: p_61,
                          value: null,
                          errors: [],
                        },
                      ])
                    }}
                  />
                </div>
              </div>
              <div
                className="info"
                style={{
                  right: "-10px",
                  position: "relative",
                }}
              >
                <img
                  src={infoIcon}
                  data-tip={
                    p_59.toUpperCase() +
                    ": " +
                    "Zaliczenie zwrotu podatku na poczet przyszłych zobowiązań podatkowych. Do zaznaczenia w przypadku wnioskowania przez podatnika o zaliczenie zwrotu podatku na poczet przyszłych zobowiązań podatkowych, zgodnie z art. 76 § 1 i art. 76b § 1 ustawy z dnia 29 sierpnia 199"
                  }
                />
              </div>
            </div>
          </Form.Item>

          <div className="content-row" key={p_60}>
            <div className="Tax-col Tax-col_one" style={{ padding: "0 100px 0 0" }}>
              <p>Wysokość zwrotu do zaliczenia na poczet przyszłych zobowiązań podatkowych.</p>
            </div>
            <div className="Tax-col Tax-col_two" style={{ flexBasis: "200px" }}>
              <span className="const-name">{p_60.toUpperCase()}</span>
              <span className="const-value">{state.declarationData[p_60] || dash}</span>
              <div className="value">
                <Form.Item
                  name={p_60}
                  hasFeedback
                  validateTrigger="onBlur"
                  rules={[
                    (e) => ({
                      validator() {
                        const fieldValue = e.getFieldValue(p_60)

                        const p54value = e.getFieldValue(p_54)

                        if (checkboxChecked) {
                          if (fieldValue === null) {
                            return Promise.reject("W przypadku zaznaczenia P_59, wypełnienie pola jest wymagane!")
                          }
                        }
                        if (checkboxChecked) {
                          if (fieldValue <= 0) {
                            return Promise.reject("Wartość musi być większa od 0!")
                          }

                          if (fieldValue > p54value) {
                            return Promise.reject(
                              "Podana kwota musi być mniejsza bądź równa wartości podanej w polu P_54!"
                            )
                          }
                        }

                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    type="number"
                    step={1}
                    bordered={editMode}
                    readOnly={!editMode}
                    disabled={!checkboxChecked}
                    precision={0}
                    placeholder={!editMode ? dash : ""}
                  />
                </Form.Item>
              </div>
              <div
                className="info"
                style={{
                  right: "-10px",
                  position: "relative",
                }}
              >
                <img
                  src={infoIcon}
                  data-tip={
                    p_60.toUpperCase() +
                    ": " +
                    "Wysokość zwrotu do zaliczenia na poczet przyszłych zobowiązań podatkowych.Podaje się wysokość zwrotu podatku do zaliczenia na poczet przyszłych zobowiązań podatkowych.Pole wymagane wyłącznie w przypadku wyboru zaliczenia zwrotu podatku na poczet przyszłych zobowiązań podatkowych(P_59). Kwota musi być większa lub równa 0, lecz mniejsza niż wysokość nadwyżki do zwrotu na rachunek wskazany przez podatnika(P_54)."
                  }
                />
              </div>
            </div>
          </div>

          <div className="content-row " key={p_61}>
            <div className="Tax-col Tax-col_one" style={{ padding: "0 100px 0 0" }}>
              <p>Rodzaj przyszłego zobowiązania podatkowego</p>
            </div>
            <div className="Tax-col Tax-col_two" style={{ flexBasis: "200px" }}>
              <span className="const-name">{p_61.toUpperCase()}</span>
              {/* <span className="const-value">{state.declarationData[p_61] || dash}</span> */}
              <span className="const-value"></span>
              <div className="value">
                <Form.Item
                  name={p_61}
                  hasFeedback
                  className="textarea"
                  rules={[
                    (e) => ({
                      validator() {
                        const fieldValue = e.getFieldValue(p_61)

                        if (checkboxChecked) {
                          if (!fieldValue || !fieldValue.trim().length) {
                            return Promise.reject("W przypadku zaznaczenia P_59, wypełnienie pola jest wymagane!")
                          }
                        }

                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <TextArea bordered readOnly={!editMode} disabled={!checkboxChecked || !editMode} maxLength={240} />
                </Form.Item>
              </div>
              <div
                className="info"
                style={{
                  right: "-10px",
                  position: "relative",
                }}
              >
                <img
                  src={infoIcon}
                  data-tip={
                    p_61.toUpperCase() +
                    ": " +
                    "Rodzaj przyszłego zobowiązania podatkowego.Podaje się rodzaj przyszłego zobowiązania podatkowego, na poczet którego zalicza się zwrot podatku.Pole wymagane wyłącznie w przypadku wyboru zaliczenia zwrotu podatku na poczet przyszłych zobowiązań podatkowych(P_59)."
                  }
                />
              </div>
            </div>
          </div>

          {[p_62].map((fieldName) => {
            const { short_description: SD, full_description: FD } = state.declarationDictionaryFix.find(
              (row: { code: string }) => row.code === fieldName.toUpperCase()
            )

            return (
              <div className="content-row " key={p_62}>
                <div className="Tax-col Tax-col_one" style={{ padding: "0 100px 0 0" }}>
                  <p>{SD}</p>
                </div>
                <div className="Tax-col Tax-col_two" style={{ flexBasis: "200px" }}>
                  <span className="const-name">{p_62.toUpperCase()}</span>
                  <span className="const-value">{state.declarationData[p_62] || dash}</span>

                  <div className="value">
                    {editMode && (
                      <div
                        className={`${editConfirmed[p_62] ? "lock-open" : "lock-closed"}`}
                        onClick={() => {
                          if (!editConfirmed.p_62) {
                            setConfirmEditModal({
                              ...confirmEditModal,
                              p_62: true,
                            })
                          } else {
                            setEditConfirmed({ ...editConfirmed, p_62: !editConfirmed[p_62] })
                          }
                        }}
                      />
                    )}

                    <Form.Item
                      name={fieldName}
                      hasFeedback
                      validateTrigger="onBlur"
                      rules={[
                        (e) => ({
                          validator() {
                            const fieldValue = e.getFieldValue(fieldName)
                            const p53value = e.getFieldValue(p_53)

                            if (fieldValue < 0) {
                              return Promise.reject("Wartość musi być większa lub równa 0!")
                            }

                            if (fieldValue > p53value) {
                              return Promise.reject(
                                "Podana kwota musi być mniejsza bądź równa wartości podanej w polu P_53!"
                              )
                            }

                            setEditConfirmed({
                              ...editConfirmed,
                              p_62: false,
                            })

                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        type="number"
                        step={1}
                        bordered={editMode}
                        readOnly={!editMode}
                        precision={0}
                        placeholder={!editMode ? dash : ""}
                        onBlur={() => {
                          if (editMode) {
                            handleCalculateSummary(fieldName, 3, [p_53, p_54], getUpdatedValues)
                          }
                        }}
                        disabled={isBlocked(p_62)}
                      />
                    </Form.Item>
                  </div>
                  <div
                    className="info"
                    style={{
                      right: "-10px",
                      position: "relative",
                    }}
                  >
                    <img src={infoIcon} data-tip={fieldName.toUpperCase() + ": " + FD} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TaxCalculationFix
