import React from "react"
import { Redirect, Route } from "react-router-dom"

const UnAuthenticated = ({ component: C, appProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!appProps.isAuthenticated ? <C {...props} {...appProps} /> : <Redirect to="/" />)}
  />
)

export default UnAuthenticated
