import React from "react"
import "./common.less"
import { Button } from "antd"
import { Spin } from "antd"

const Logo = ({
  name,
  type,
  style,
  onClick,
  loading,
  disabled,
  styling,
  txtColor = "#fff",
}) => {
  return (
    <Button
      type={type || "button"}
      htmlType={type || "button"}
      style={style}
      onClick={onClick}
      disabled={loading || disabled}
      className={styling}
    >
      <span>
        {loading && <Spin />}
        {name}
      </span>
    </Button>
  )
}

export default Logo
