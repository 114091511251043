import React, { useContext, useState } from "react";
import { Menu, Dropdown, Button, message, Space, Tooltip } from "antd";
import { DownOutlined, CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { DispatchContext, StateContext } from "components/userContext"

const activeFilterStyle = "wl-status-button-filter-active"

function handleMenuClick(e) {
    e.domEvent.stopPropagation()
}

function handleButtonClick(e) {
    e.stopPropagation()
}

function NipVerifierDropDown(props) {
    const dispatch = useContext(DispatchContext)
    const state = useContext(StateContext)
    let filteredVerificationState = props.filter === "MF" ? 
                                    state[props.tableOptionsId].filteredVerificationStateMf : 
                                    state[props.tableOptionsId].filteredVerificationStateVies

    const saveFilter = (filteredVerificationState) => {
        const filtered = [...state[props.tableOptionsId].filtered]
        props.filter === "MF" ? 
        dispatch({
            type: "handleState",
            field: props.tableOptionsId,
            payload: { ...state[props.tableOptionsId], filtered, filteredVerificationStateMf: filteredVerificationState },
        }) :
        dispatch({
            type: "handleState",
            field: props.tableOptionsId,
            payload: { ...state[props.tableOptionsId], filtered, filteredVerificationStateVies: filteredVerificationState },
        })
    }

    const handleOkFilterClick = (e, n) => {
        if (e.domEvent.altKey) {
            const newState = {}
            Object.keys(filteredVerificationState).forEach(element => {
                newState[element] = {
                    value: filteredVerificationState[element].value,
                    selected: element === n
                }
            })
            filteredVerificationState = newState
        }
        else {
            filteredVerificationState = {
                ...filteredVerificationState,
            }
            filteredVerificationState[n] = {
                value: filteredVerificationState[n].value,
                selected: !filteredVerificationState[n].selected
            }
        }

        saveFilter(filteredVerificationState)

        e.domEvent.stopPropagation()
        return false
    }

    const clearFilter = (e) => {
        const newState = {}
        Object.keys(filteredVerificationState).forEach(element => {
            newState[element] = {
                value: filteredVerificationState[element].value,
                selected: element === false
            }
        })
        saveFilter(newState)
        e.stopPropagation()
    }

    let menuItems = []
    let selectedCount = 0
    let singleSelected = ""
    for (let i = 0; i < props.settings.length; i++) {
        const element = props.settings[i];
        const isChecked = filteredVerificationState[element.id].selected
        if (isChecked) {
            selectedCount++
            singleSelected = element.label
        }
        menuItems.push(<Menu.Item key={"menuItem" + i} className={"wl-import-status " + element.style + (isChecked ? " wl-status-selected" : " " + element.notSelectedStyle)}
            onClick={e => (handleOkFilterClick(e, element.id))}>
            {element.label}
            <CheckOutlined className={"wl-status-symbol wl-status-dropdown-row-symbol-" + (isChecked ? "checked" : "unchecked")} />
        </Menu.Item>)
    }

    const menu = (
        <Menu onClick={handleMenuClick} className="noselect">
            {menuItems}
        </Menu>
    );

    const status = selectedCount == 0 ? ((props.filter === "MF" ? "Status weryfikacji NIP w MF" : "Status weryfikacji NIP w VIES")) : (selectedCount === 1) ? singleSelected : (selectedCount + " wybrane")

    return <Dropdown overlay={menu}
        trigger="click"
        className="wl-status-dropdown"
        overlayStyle={{
            border: "1px solid #DDDDDD",
            borderRadius: "1px",
        }}>
        <Button onClick={handleButtonClick} className="wl-status-button"
            style={{
                "minWidth": "135px",
                "display": "flex",
                "justifyContent": "space-between",
                "alignItems": "center",
                "color": "#233B53",
                "borderColor": "#DDDDDD"
            }}>
            {status}
            {selectedCount === 0 && <DownOutlined />}
            {selectedCount !== 0 && <CloseCircleOutlined onClick={clearFilter} />}
        </Button>
    </Dropdown>
}

export default NipVerifierDropDown