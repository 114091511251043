import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
//import { loadReCaptcha } from "react-recaptcha-google"
import { Form, Input, Checkbox } from "antd"
import MaskedInput from "antd-mask-input"

import { regulationsPDF } from "../../components/common/api"

import API from "components/common/api"
//import Captcha from "components/common/Captcha/Captcha"
import FormButton from "components/common/FormButton"
import FormModal from "components/common/FormModal"
import Rules from "components/common/Rules"
import Countries from "./subcomponents/Countries"

import backArrow from "images/icons/back-arrow.svg"
import successIcon from "images/icons/green-ok.svg"

import ReactGA from "react-ga"

import "./Register.less"
import { tracker } from "helpers/tracker"

const Register = () => {
  const [errorMessages, setErrorMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalInfo, setModalInfo] = useState("")
  const [country, setCountry] = useState("Polska")
  //const [token, setToken] = useState("")

  const [step, setStep] = useState(1)
  const [stepInfo, setStepInfo] = useState(false)

  const history = useHistory()

  useEffect(() => {
    //loadReCaptcha()
    ReactGA.pageview(window.location.pathname)
  }, [])

  const handleOk = () => {
    history.push("/login")
    setModalVisible(false)
  }

  const postForm = (values) => {
    setLoading(true)
    const data = { ...values }

    API.post("/Users/register", data)
      .then((response) => {
        if (response.status === 400) {
          setLoading(false)
          setModalInfo(response.data.errors[0])
          setErrorMessages(response.data.errors)
          setModalVisible(true)
        } else {
          setLoading(false)
          setModalInfo("Na podany adres e-mail zostały wysłane informacje niezbędne do zakończenia rejestracji.")
          setErrorMessages([])
          setModalVisible(true)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
        setErrorMessages(error.response.data.errors)
        setModalInfo(error.response.data.errors)
        setModalVisible(true)
      })
  }

  const onFinish = (values) => {
    tracker("Rejestracja - Potwierdzenie rejestracji")

    postForm({
      organization_name: values.organization_name,
      nip: values.nip,
      city: values.city,
      address: values.address,
      post_code: values.post_code,
      name: values.name,
      surname: values.surname,
      email: values.email,
      country,
      // phone: `${values.phone}`,
      password: values.password,
      confirm_password: values.confirm_password,
      personal_data_process_consent: values.personal_data_process_consent,
      cookies_policy: values.cookies_policy,
    })
  }

  const onFinishFailed = (errorInfo) => { }

  const onStepFoward = () => {
    const stepOneInputs = Array.from(document.querySelectorAll(".Form-step__one .ant-row"))

    const stepFilled = stepOneInputs.every((e) => e.classList.contains("ant-form-item-has-success"))

    if (stepFilled) {
      setStep(2)
      tracker("Rejestracja - Przejście na zakładkę firma")
      setStepInfo(false)
    } else {
      setStepInfo(true)
    }
  }

  const onStepBack = () => setStep(1)

  return (
    <div className="Register">
      <div className="Register-return">
        <Link to="/login">
          <img src={backArrow} />
          Wróć do logowania
        </Link>
      </div>
      <div className="Register-header">
        <div className="header-box">
          <div className={`elipse ${step === 2 && "success"}`}>{step === 2 ? <img src={successIcon} /> : 1}</div>
          <h2>Dane użytkownika</h2>
        </div>
        <div className={`header-box ${step === 1 && "blocked"}`}>
          <div className="elipse">2</div>
          <h2>Dane firmy</h2>
        </div>
      </div>
      <div className="Register-form">
        <div className={`Arrow-box back ${step === 1 && "block"}`} onClick={step === 2 ? onStepBack : null}></div>
        <div className={`Arrow-box foward ${step === 2 && "block"}`} onClick={step === 1 ? onStepFoward : null}></div>
        <Form
          name="register"
          initialValues={{ remember: true, country: "Polska" }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className={`Form-step Form-step__one ${step === 2 && "hidden"}`}>
            <Form.Item name="name" hasFeedback validateTrigger="onBlur" rules={Rules.name}>
              <Input placeholder="imię" />
            </Form.Item>

            <Form.Item name="surname" hasFeedback validateTrigger="onBlur" rules={Rules.surname}>
              <Input placeholder="nazwisko" />
            </Form.Item>

            {/* <Form.Item name="phone" hasFeedback validateTrigger="onBlur" rules={Rules.phone}>
              <Input placeholder="nr telefonu" />
            </Form.Item> */}

            <Form.Item name="email" hasFeedback validateTrigger="onBlur" rules={Rules.email}>
              <Input placeholder="e-mail" />
            </Form.Item>

            <Form.Item name="password" hasFeedback validateTrigger="onBlur" rules={Rules.password}>
              <Input.Password placeholder="hasło" />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              dependencies={["password"]}
              hasFeedback
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Potwierdź swoje hasło!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve()
                    }

                    return Promise.reject("Wprowadzone hasła nie są identyczne!")
                  },
                }),
              ]}
            >
              <Input.Password placeholder="potwierdź hasło" />
            </Form.Item>

            <Form.Item
              className="absolute"
              name="personal_data_process_consent"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject("Aby kontynuować zaakceptuj regulamin."),
                },
              ]}
            >
              <Checkbox>
                <p>
                  Zapoznałem/am się i akceptuję{" "}
                  <ReactGA.OutboundLink
                    eventLabel="Rejestracja - podgląd regulaminu"
                    // to={regulationsPDF}
                    to="https://taxinsight.pl/regulamin-cloudtax/"
                    target="_blank"
                  >
                    Regulamin{" "}
                  </ReactGA.OutboundLink>
                  oraz{" "}
                  <ReactGA.OutboundLink
                    eventLabel="Rejestracja - podgląd umowa"
                    // to={regulationsPDF}
                    to="https://taxinsight.pl/umowa-powierzenia-cloudtax/"
                    target="_blank"
                  >
                    Umowę powierzenia przetwarzania danych osobowych.{" "}
                  </ReactGA.OutboundLink>
                </p>
              </Checkbox>
            </Form.Item>

            <Form.Item
              className="absolute"
              name="cookies_policy"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject("Aby kontynuować zaakceptuj politykę prywatności i cookies"),
                },
              ]}
            >
              <Checkbox>
                <p>
                  Zapoznałem/am się i akceptuję{" "}
                  <ReactGA.OutboundLink
                    eventLabel="Rejestracja - podgląd cooklies"
                    // to={regulationsPDF}
                    to="https://taxinsight.pl/polityka-prywatnosci-cloudtax/"
                    target="_blank"
                  >
                    Politykę prywatności i cookies,{" "}
                  </ReactGA.OutboundLink>
                  zawierającą informacje dotyczące przetwarzania moich danych osobowych.{" "}
                </p>
              </Checkbox>
            </Form.Item>

            {stepInfo && <p className="Step-info">Aby przejść dalej wypełnij wszystkie pola</p>}
          </div>
          <div className={`Form-step Form-step__two ${step === 1 && "hidden"}`}>
            <Form.Item name="nip" hasFeedback validateTrigger="onBlur" rules={Rules.nip}>
              <MaskedInput mask="111-111-11-11" name="nip" placeholder="NIP" />
            </Form.Item>

            <Form.Item name="organization_name" hasFeedback validateTrigger="onBlur" rules={Rules.organization_name}>
              <Input placeholder="nazwa firmy" />
            </Form.Item>

            <Form.Item name="address" hasFeedback validateTrigger="onBlur" rules={Rules.address}>
              <Input placeholder="adres firmy" />
            </Form.Item>

            <Form.Item name="post_code" hasFeedback validateTrigger="onBlur" rules={Rules.post_code}>
              <MaskedInput mask="11-111" name="postCode" placeholder="kod pocztowy" />
            </Form.Item>

            <Form.Item name="city" validateTrigger="onBlur" rules={Rules.city} hasFeedback>
              <Input placeholder="miejscowość" />
            </Form.Item>

            <Form.Item name="country" hasFeedback validateTrigger="onBlur" rules={Rules.country}>
              <Countries country={country} handleCountry={setCountry} />
            </Form.Item>
            <div className="register-buttons">
              <Form.Item className="register-button-container">
                <FormButton name="Zarejestruj się" loading={loading} />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>

      {/* {errorMessages &&
        errorMessages.map(errorMessage => (
          <h2 key={errorMessage} className="errorMessage">
            {errorMessage}
          </h2>
        ))} */}

      <FormModal visible={modalVisible} onAccept={handleOk} content={modalInfo} />
      {/* <Captcha action="register" setToken={setToken} /> */}
    </div>
  )
}

export default Register
