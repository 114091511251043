import React from "react"
import { notification } from "antd"
import { DownloadFileWithoutNotifications } from "helpers/cloudTax"
import NotificationTrigger from "components/SignalR/subcomponents/NotificationTrigger.jsx"
/*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
export const openNotification = (data, ctx, state, dispatch, history, key) => {
    if (data.notyficationType === 5) { // command

        if (data.actionName === "refresh") {
            window.location.reload();
        } else if (data.actionName === "instant_download") {
            let args = JSON.parse(data.request)
            DownloadFileWithoutNotifications(args.id, args.name, args.source)
        } else if (data.actionName === "jobs_counter") {
            let args = JSON.parse(data.request)

            dispatch({
                type: "handleState",
                field: "jobsCounter",
                payload: args.value,
            })
            if (args.value !== 0) {
                dispatch({
                    type: "handleState",
                    field: "commandEvent",
                    payload: { actionName: "jobs_fetch_data" },
                })
            }
        }
        else {
            let args = JSON.parse(data.request)
            dispatch({
                type: "handleState",
                field: "commandEvent",
                payload: { actionName: data.actionName, args },
            })
        }
        return
    }

    const args = {
        description: (
            <NotificationTrigger
                ctx={ctx}
                notification={data}
                {...data}
                state={state}
                dispatch={dispatch}
                closeAction={() => notification.close(key)}
                handleNavigate={async (route) => {
                    await history.push(route)
                    notification.close(key)
                }}
                handleNavigateOutside={(route) => {
                    window.open(route, "_blank")
                    notification.close(key)
                }}
            />
        ),
        duration: data.hasAction ? 0 : 5,
        placement: "bottomRight",
        className: "Trigger-wrapper",
        key,
    }
    // if (data.notyficationType === 5) return // command
    notification.open(args)
}

const showSimpleNotification = (type, msg, state, dispatch) => openNotification({ hasAction: false, notyficationType: type, message: msg }, state, dispatch, null, Math.random().toString())

export const NotificationsHelper = (state, dispatch) => {
    return {
        showInfo: (msg) => showSimpleNotification(1, msg, state, dispatch),
        showWarning: (msg) => showSimpleNotification(2, msg, state, dispatch),
        showError: (msg) => showSimpleNotification(3, msg, state, dispatch),
    }
}