import React, { useCallback, useState } from "react"
import { Form, Radio } from "antd"
import { Loading } from "components/Main/Loading"
import { getFileNameFromContentDisposition } from "helpers/httpHelpers"
import API from "components/common/api"
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"

import "./VatUEKModalContent.less"

export const columns = [
  {
    Header: "",
    accessor: "id",
    width: 20,
    Cell: ({ value }) => {
      return <Radio value={value}></Radio>
    }
  },
  {
    Header: () => "NIP:",
    accessor: "nip",
    width: 110
  },
  {
    Header: () => "Pełna nazwa:",
    accessor: "pelna_nazwa"
  },
  {
    Header: () => "Rok:",
    accessor: "rok",
    width: 50
  },
  {
    Header: () => "Miesiąc:",
    accessor: "miesiac",
    width: 60
  },
  {
    Header: () => "Cel złożenia:",
    accessor: "cel_zlozenia",
    Cell: ({ value }) => {
      return <>{(value === 1 ? "złożenie" : (value === 2 ? "korekta" : "?"))}</>
    },
    width: 80
  },
  {
    Header: () => "Dokumenty sprzedaży:",
    accessor: "liczba_wierszy_sprzedazy",
  },
  {
    Header: () => "Podatek należny:",
    accessor: "podatek_nalezny",
    Cell: ({ value }) => {
      return <>
      {(value.toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ","))
      }</>
    },
  },
  {
    Header: () => "Dokumenty zakupu:",
    accessor: "liczba_wierszy_zakupow",
  },
  {
    Header: () => "Podatek naliczony:",
    accessor: "podatek_naliczony",
    Cell: ({ value }) => {
      return <>
      {(value.toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ","))
      }</>
    },
  },
  {
    Header: () => <>Data eksportu <br></br>JPK:</>,
    accessor: "data_wytworzenia_jpk",
    Cell: ({ value }) => <span> {value ? value.substring(0, 10).replaceAll("-", ".") : "-"} </span>,
  }
]

export const columnsWithExport = [...columns,
{
  Header: () => <>Data wysyłki <br></br>JPK:</>,
  accessor: "bramka_data_wysylki",
  Cell: ({ value }) => <span> {value ? value.substring(0, 10).replaceAll("-", ".") : "-"} </span>
},]

const handleDownloadFileZIP = (data, fileName) => {
  const pom = document.createElement("a")
  const bb = new Blob([data], { type: "text/plain" })

  pom.setAttribute("href", window.URL.createObjectURL(bb))
  pom.setAttribute("download", fileName)

  pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":")
  pom.draggable = true
  pom.classList.add("dragout")

  pom.click()
}

const VatUEKModalContent = ({ formRef: ref, data, setModalVisible, loading, correctionId, showExportColumn, setAntSelected }) => {
  const [selected, setSelected] = useState(null)

  const columnsToShow = showExportColumn ? columnsWithExport : columns

 

  if (loading) {
    return <Loading />
  }

  return (
    <div className="vatUEKmodal">

      <Form
        ref={ref}
        layout={{
          labelCol: { span: 8 },
          wrapperCol: { span: 16 },
        }}
        onFinish={async () => {
          if (selected === null) return
          setModalVisible(false)
          API.post(`/VatGenerator/ExportVatUEK`, {
            sourceId: correctionId,
            correctionId: selected
          }, {
            responseType: 'blob', //important
          }).then((response) => {
            if (response.status !== 200) return
            const fileName = getFileNameFromContentDisposition(response.headers["content-disposition"])
            handleDownloadFileZIP(response.data, fileName)
            setModalVisible(false)
          })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              setModalVisible(false)
            })
        }}
      >

        <Radio.Group onChange={(args) => {
          setSelected(args.target.value)
          setAntSelected(true)
        }} >
          <ReactTable
            className="clean vatUEKTable"
            showPagination={false}
            resizable={false}
            noDataText={""}
            NoDataComponent={() => null}
            data={data}
            columns={columnsToShow}
            minRows={1}
            style={{
              maxHeight: "500px",
              width: "98vw"
            }}>
          </ReactTable>
        </Radio.Group>
      </Form>
    </div>
  )
}

export default VatUEKModalContent