export const getAppUrl = (name) => {
    var url = "";
    if (name === "Sprawdź JPK_VAT") url = "ewidencja-vat";
    else if (name === "Biała lista") url = "biala-lista";
    else if (name === "Weryfikacja NIP") url = "weryfikacja-nip";
    else url = name.replace(" ", "-").toLowerCase();
    return `/${url}`;
}


export default getAppUrl;
