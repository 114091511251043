import React from "react"
import { VerificationStatusMap, VerificationReasonMap } from "../VerificationStatusMap"


function WhiteListRowDetails(props) {

  let statusName = "-"
  let statusClass = ""
  const status = props.row.verification_status
  if (props.row.verification_status != null) {
    statusName = VerificationStatusMap[props.row.verification_status].name
    statusClass = VerificationStatusMap[props.row.verification_status].style
  }

  return <div className="wl-details">
    <span className="details-header">Szczegóły</span>
    <ul>
      <li>
        <span className="label">Nazwa kontrahenta</span>
        <span className="label">{props.row.contractor_name}</span>
      </li>
      <li>
        <span className="label">NIP</span>
        <span className="label">{props.row.nip}</span>
      </li>
      <li>
        <span className="label">REGON</span>
        <span className="label">{props.row.regon}</span>
      </li>
      <li>
        <span className="label">Data importu</span>
        <span className="label">{props.row.import_date == null ? "-" : props.row.import_date.substring(0, 10)}</span>
      </li>
      <li>
        <span className="label">Źródło importu</span>
        <span className="label">{props.row.data_source}</span>
      </li>
      <li>
        <span className="label">Nr rachunku bankowego</span>
        <span className="label">{props.row.bank_account_no}</span>
      </li>
      <li>
        <span className="label">Data weryfikacji</span>
        <span className="label">{props.row.verification_date == null ? "-" : props.row.verification_date.substring(0, 16).replace("T", " ")}</span>
      </li>
      <li>
        <span className="label">Data sprawdzenia na dzień</span>
        <span className="label">{props.row.reference_date == null ? "-" : props.row.reference_date.substring(0, 10)}</span>
      </li>
      <li>
        <span className="label">Podstawa weryfikacji</span>
        <span className="label">{props.row.verification_reason == null ? "-" : VerificationReasonMap[props.row.verification_reason]}</span>
      </li>
      <li>
        <span className="label">Id weryfikacji</span>
        <span className="label">{props.row.verification_request_id ?? "-"}</span>
      </li>
      <li>
        <span className="label">Status weryfikacji</span>
        <span className={statusClass}>{statusName}</span>
      </li>
    </ul>
  </div>;
}

export default WhiteListRowDetails