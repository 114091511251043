import React from "react"
import FormModal from "components/common/FormModal"

interface DeleteModalProps {
  visible: boolean
  handlePostDelete: () => void
  closeModal: () => void
  loading: boolean
}

export const DeleteModal = (props: DeleteModalProps) => {
  const { visible, handlePostDelete, closeModal, loading } = props

  return (
    //@ts-ignore
    <FormModal
      loading={loading}
      destroyOnClose
      visible={visible}
      content={"Czy na pewno chcesz usunąć wybrane pliki oraz powiązane z nim informacje?"}
      // @ts-ignore
      onAccept={handlePostDelete}
      addClass="exportModal gateExportModal"
      onDecline={closeModal}
      okButtonName="Usuń"
    />
  )
}
