import React, { useContext, useEffect, useState, useMemo, useRef } from "react"
import { Modal, Input, Button } from "antd"
import FormModal from "components/common/FormModal"
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"
import { debounce } from "lodash"
import ReactTooltip from "react-tooltip"
import CustomPagination from "components/CustomPagination/CustomPagination"


import WhiteListRow from "./WhiteListRow"
import WhiteListRowDetails from "./WhiteListRowDetails"
import { getVerificationFilter, getImportSourceFilter, getExceptedIds } from "../UrlHelper"
import { getFileNameFromContentDisposition } from "helpers/httpHelpers"

import DownloadTemplateModal from "./DownloadTemplateModal"

import { columns, originalHandleClick } from "./data"

import API from "components/common/api"
import Context from "context"
import { DispatchContext, StateContext } from "components/userContext"

import templateIcon from "images/szablon.svg"
import importIcon from "images/import.svg"
import verifyIcon from "images/weryfikuj.svg"
import reportIcon from "images/eksport.svg"
import historyIcon from "images/historia.svg"
import deleteIcon from "images/usun.svg"
import closeIcon from "images/close-menu.svg"

import ReactGA from "react-ga"

import { Loading } from "components/Main/Loading"
import { tracker } from "helpers/tracker"

import "../../Tables/ReactTable.less"
import "./WhiteList.less"

import { useRowsSelector, shouldShowRowDetailsHelper, setSelectedSingleItemWhenSelectAllClicked } from "helpers/rowsSelector"

const { Search } = Input

const downloadTemplateFile = async (csv) => {
  let url = "/WhiteList/GetTemplate"
  if (csv) {
    url += "?type=csv"
  }
  await API
    .request({
      url: url,
      method: "GET",
      responseType: 'blob', //important
    })
    .then((response) => {
      if (response.status !== 200) return
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      let fileName = getFileNameFromContentDisposition(response.headers["content-disposition"])
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
}

// FILTER AFTER IMPORT

export const setWhiteListLastImportData = (state, dispatch, lastImportData) => {
  const filtered = [...state.wlTableOptions.filtered]
  let filteredImportIds = {}
  let filteredVerificationState = { ...state.wlTableOptions.filteredVerificationState }
  Object.keys(filteredVerificationState).forEach(key => {
    filteredVerificationState[key] = { ...filteredVerificationState[key], selected: false }
  });
  const fixedDate = lastImportData.import_date.substring(0, 27)
  filteredImportIds[fixedDate] = lastImportData.data_source

  dispatch({
    type: "handleState",
    field: "wlTableOptions",
    payload: { ...state.wlTableOptions, filtered, filteredVerificationState, filteredImportIds },
  })
}


const WhiteListTable = (props) => {
  const dispatch = useContext(DispatchContext)
  const state = useContext(StateContext)

  const [loading, setLoading] = useState(true)

  const [fetchInProgress, setFetchInProgress] = useState(false)

  const { ctx, setCtx } = useContext(Context)

  const [selectedSingleItem, setSelectedSingleItem] = useState({})

  const [delModal, setDelModal] = useState({ visible: false })

  const [downloadTemplateModal, setDownloadTemplateModal] = useState({ visible: false })

  const [anyDownloadTypeSelected, setAnyDownloadTypeSelected] = useState(false)

  const [csvTemplateTypeSelected, setCsvTemplateTypeSelected] = useState(false)

  const [fetched, setFetched] = useState([])

  const [filtersState, setFilterState] = useState({})

  const [reportInProgress, setReportInProgress] = useState(false)

  const [verificationInProgress, setVerificationInProgress] = useState(false)

  const selector = useRowsSelector(() => state.wlTableOptions.rowCount)

  const fileRef = useRef(null)

  let [currentPageIndex, setCurrentPageIndex] = useState(0)

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [window.location.search])


  useEffect(() => {
    selector.deselectAllRows()
  }, [state.wlTableOptions.filtered, state.wlTableOptions.filteredVerificationState, state.wlTableOptions.filteredImportIds])

  useEffect(() => setSelectedSingleItemWhenSelectAllClicked(selector, state.whiteListData, selectedSingleItem, setSelectedSingleItem), [selector.allSelectedMode])

  const debounceHanlderInput = useMemo(() => debounce(() => handleFillterAll(inputRef.current.input.value), 800))

  const inputRef = useRef(null)

  useEffect(() => {
    let searchValue = state.wlTableOptions.filtered.find(x => x.id === "all")
    if (searchValue !== undefined) {
      inputRef.current.state.value = searchValue.value
    }
  }, [])

  const handleFillterAll = (elem) => {
    const filtered = [{ id: "all", value: elem }]
    dispatch({
      type: "handleState",
      field: "wlTableOptions",
      payload: { ...state.wlTableOptions, filtered },
    })
  }

  const getFilterQuery = () => {
    const filter = state.wlTableOptions.filtered.length > 0 ? state.wlTableOptions.filtered[0].value : ""
    const verificationFilterQuery = [
      filter !== "" ? "search@=" + filter : "",
      getVerificationFilter(state.wlTableOptions.filteredVerificationState),
    ].filter(x => x.length > 0)

    let filterQuery = ""
    if (verificationFilterQuery.length > 0) {
      filterQuery = "filters=" + verificationFilterQuery.join(",")
    }
    const importSourceQuery = getImportSourceFilter(state.wlTableOptions.filteredImportIds)
    if (importSourceQuery !== "") {
      if (filterQuery !== "") {
        filterQuery = filterQuery + "&"
      }
      filterQuery = filterQuery + importSourceQuery
    }
    if (selector.allSelectedMode && selector.markedRows.length > 0) {
      const exceptIds = getExceptedIds(selector.markedRows)
      if (filterQuery !== "") {
        filterQuery = filterQuery + "&"
      }
      filterQuery = filterQuery + exceptIds
    }
    return filterQuery
  }

  const fetchSortData = (sortData) => {
    if (fetchInProgress) return
    setFetchInProgress(true)
    setLoading(true)
    const filterQuery = getFilterQuery()
    selector.resetLastClick()
    const url = `/WhiteList/GetContractors?sorts=${sortData.sortedValue}&page=${sortData.innerPage + 1}&pageSize=${sortData.innerPageSize}&${filterQuery}`
    API.get(url)
      .then(
        ({
          data: {
            stateObject: { results: stateObject, ...tableParams },
          },
        }) => 
        {
          if (stateObject.length === 0 && sortData.innerPage > 0) { 
            fetchSortData({ ...sortData, innerPage: sortData.innerPage - 1 })
          } else {
            dispatch({
              type: "handleState",
              field: "whiteListData",
              payload: stateObject,
            })
            dispatch({
              type: "handleState",
              field: "wlTableOptions",
              payload: {
                ...state.wlTableOptions,
                ...tableParams,
                currentPage: sortData.innerPage + 1,
                pageSize: sortData.innerPageSize
              },
            })
          }
        }
      )
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
      .finally(() => {
        setFetchInProgress(false)
        setLoading(false)
      })
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  useEffect(() => {
    if (state.commandEvent && state.commandEvent.actionName === "wl_fetch_data") {
      fetchLastImports()
      fetchSortData(filtersState)
    }
  }, [state.commandEvent])

  // filter after import
  useEffect(() => {
    let clearEvent = false

    if (state.notificationEvent && state.notificationEvent.actionName === "GetContractors") {
      setWhiteListLastImportData(state, dispatch, state.notificationEvent.args)
      //fetchSortData(filtersState)
      clearEvent = true
    }
    else if (state.notificationEvent && state.notificationEvent.actionName === "CreateVerificationReport") {
      getReport(state.notificationEvent.args.Ids, state.notificationEvent.args.DataFilter)
      clearEvent = true
    }
    if (clearEvent) {
      dispatch({
        type: "handleState",
        field: "notificationEvent",
        payload: null
      })
    }
  }, [state.notificationEvent])

  const fetchLastImports = () => {
    return API.get(`WhiteList/GetUserImports?page=1&pageSize=5&sorts=&data_source`)
      .then((res) => {
        dispatch({
          type: "handleState",
          field: "wlLastImports",
          payload: res.data.stateObject.results
        })
      })
      .catch(e => {
        console.log(e)
      })
  }


  // Get last inports 
  useEffect(() => {
    if (state.wlLastImports === null) {
      fetchLastImports()
    }
  }, [])


  const downloadTemplate = async () => {
    setDownloadTemplateModal({ visible: true })
  }

  const importFile = async (event) => {
    tracker("Biała lista - Import")
    const data = new FormData()
    const fileTosend = Array.from(event.target.files)
    if (fileTosend.length === 0) return
    const [file] = fileTosend
    data.append("file", fileTosend[0], fileTosend[0].name)

    await API.post("/WhiteList/Import", data, {
      headers: {
        "Content-Type": `multipart/form-data boundary=${data._boundary}`,
      },
    }).then(() => {
      // fetch after import is now triggered by notification
      // fetchSortData(filtersState)
      // fetchLastImports()
    }).catch(() => {

    })
  }

  const askDeleteSelected = async () => {
    if (selector.countSelected() === 0) return
    setDelModal({ visible: true, data: selector.markedRows })
  }

  const deleteSelected = async () => {
    let allSelectedModeUrlPart = ""
    if (selector.allSelectedMode) {
      allSelectedModeUrlPart = "ByFilter?" + getFilterQuery()
    }
    API.post("/WhiteList/DeleteContractors" + allSelectedModeUrlPart, {
      ids: selector.markedRows
    }).then(() => {
      fetchLastImports()
      fetchSortData(filtersState)
    })
    setDelModal({ visible: false })
    selector.deselectAllRows()
  }

  const getReport = (ids, dataFilter) => {
    setReportInProgress(true)
    let allSelectedModeUrlPart = ""
    if (selector.allSelectedMode) {
      allSelectedModeUrlPart = "ByFilter?" + getFilterQuery()
    }
    const url = "/WhiteList/CreateVerificationReport" + allSelectedModeUrlPart
    API.post(url, {
      ids,
      dataFilter
    }, {
      responseType: 'blob', //important
    }).then((response) => {
      if (response.status !== 200) return
    }).finally(() => {
      setReportInProgress(false)
    })
  }

  const showHistory = () => {
    setCtx({ ...ctx, currentContractor: selectedSingleItem })
    props.showReport()
  }

  const verifySelected = () => {
    setVerificationInProgress(true)
    let allSelectedModeUrlPart = ""
    if (selector.allSelectedMode) {
      allSelectedModeUrlPart = "ByFilter?" + getFilterQuery()
    }
    const now = new Date().toISOString()
    const url = "/WhiteList/VerifyBankAccounts" + allSelectedModeUrlPart
    API.post(url, {
      ids: selector.markedRows,
      referenceDate: now,
      verificationReason: 0
    }).then(() => {
      fetchSortData(filtersState)
    }).finally(() => {
      setVerificationInProgress(false)
    })
  }

  const shouldShowRowDetails = () => {
    return shouldShowRowDetailsHelper(selector, state.whiteListData)
  }


  return (
    <div className="table-wrapper">
      <div className="vatTableContainer content-wrapper inner-shadow">


        <ReactTooltip
          effect="solid"
          className="tooltip"
          backgroundColor="#fff"
          textColor="#163567"
          borderColor="#163567"
          border
          html
        />

        <div className="table-action-section">
          <div className="search-input" style={{ display: "flex" }}>
            <Search
              allowClear
              placeholder={null}
              onSearch={debounceHanlderInput}
              onChange={debounceHanlderInput}
              style={{ width: 169, height: 38 }}
              ref={inputRef}
              className="broken-search"
            />
          </div>

          <div className="action-section-buttons">

            <button onClick={downloadTemplate} disabled={false}>
              <img src={templateIcon} alt="" />
              szablon
            </button>

            <button
              //onClick={importFile} 
              disabled={false}>


              <div>
                <input
                  ref={fileRef}
                  id="import-wl"
                  onChange={importFile}
                  type="file"
                  accept=".csv,.xlsx"
                />
                <label
                  htmlFor="import-wl"
                  onClick={() => {

                  }}
                >
                  <img src={importIcon} alt="import button" />
                  import
                </label>
              </div>

            </button>



            <div className="dash" />

            {/* <button onClick={selectAll} disabled={false}>
              <img src={selectAllIcon} alt="Zaznacz wszystko" />
                     zaznacz
                  </button> */}

            <button onClick={() => verifySelected()} disabled={(verificationInProgress || selector.countSelected() === 0)}>
              <img src={verifyIcon} alt="" />
              weryfikuj
            </button>

            <button onClick={() => getReport(selector.markedRows, 0)} disabled={(reportInProgress || selector.countSelected() === 0)}>
              <img src={reportIcon} alt="" />
              raport
            </button>

            <div className="dash" />

            <button onClick={() => showHistory()} disabled={selector.countSelected() !== 1}>
              <img src={historyIcon} alt="" />
              historia
            </button>

            <button onClick={askDeleteSelected} disabled={selector.countSelected() === 0}>
              <img src={deleteIcon} alt="" />
              usuń
            </button>
          </div>
        </div>

        <div className="WhiteListEntityPanel">
          <div className="WhiteListSelectionBox">
            {selector.countSelected() === 0 && <div className="WhiteListSelectionButton noselect" onClick={selector.selectAllRows}>Zaznacz wszystko</div>}
            {selector.countSelected() !== 0 && <>
              <div className="WhiteListSelectionButton noselect" onClick={selector.deselectAllRows}>Odznacz wszystko</div>
              <div className="WhiteListSelectionCounter">{"Zaznaczono: " + selector.countSelected()}</div></>
            }
          </div>
          {/* del record modal */}
          {delModal.visible ? (
            <FormModal
              visible={delModal.visible}
              content={`Czy na pewno usunąć wskazanych kontrahentów ?`}
              onAccept={deleteSelected}
              onDecline={() => setDelModal({ visible: false })}
              addClass="delModal"
              okButtonName="Usuń"
            />
          ) : null}

          {/* download template modal */}
          {downloadTemplateModal.visible ? (
            <FormModal
              visible={downloadTemplateModal.visible}
              acceptDisabled={!anyDownloadTypeSelected}
              hasForm={<DownloadTemplateModal
                setAnyDownloadTypeSelected={setAnyDownloadTypeSelected}
                setCsvTemplateTypeSelected={setCsvTemplateTypeSelected}
              />}
              onAccept={() => {
                Modal.destroyAll()
                downloadTemplateFile(csvTemplateTypeSelected)
                setAnyDownloadTypeSelected(false)
                setDownloadTemplateModal({ visible: false })
              }}
              onDecline={() => {
                setAnyDownloadTypeSelected(false)
                setDownloadTemplateModal({ visible: false })
              }}
              addClass="templateModal"
              okButtonName="Pobierz"
            />
          ) : null}

          <div className="WhiteListTableWithDetails">
            <ReactTable
              manual
              className="vatTable wlTable_records"
              data={state.whiteListData}
              columns={columns.filter(c => c.showWithDetails === true || !shouldShowRowDetails())}
              multiSort={false}
              loading={loading}
              NoDataComponent={() => null}
              noDataText={""}
              LoadingComponent={(props) => {
                if (props.loading) {
                  return <Loading absolute />
                }
                return <></>
              }}
              resizable={false}
              PaginationComponent={(params) => (
                <CustomPagination currentPage={state.wlTableOptions.currentPage} {...params} />
              )}
              expanded={state.wlTableOptions.expanded}
              //page={currentPageIndex}
              onPageChange={newPage => {
                currentPageIndex = newPage
                setCurrentPageIndex(newPage)
              }}
              pages={state.wlTableOptions.totalPagesNumber}
              defaultPageSize={state.wlTableOptions.pageSize}
              filtered={state.wlTableOptions.filtered}
              onFetchData={(innerState) => {
                setLoading(true)

                const filterValue = (innerState.filtered.length && innerState.filtered[0].value) || ""
                const sortedValue = innerState.sorted.length === 1 ?
                  (innerState.sorted[0].desc ? "-" : "") + innerState.sorted[0].id : ""
                const filters = {
                  filterValue,
                  sortedValue,
                  innerPage: currentPageIndex,
                  innerPageSize: innerState.pageSize,
                }

                setFilterState(filters)
                fetchSortData(filters)

              }}
              getTdProps={(tabeState, rowInfo, column, instance) => {
                return {
                  onClick: ({ shiftKey, ctrlKey }) => {
                    //if (allSelectedMode) return

                    if (typeof rowInfo !== "undefined") {
                      if (column.id) {
                        let rowId = rowInfo.original.id
                        selector.onRowClick(rowId,
                          state.whiteListData ? state.whiteListData.map(x => x.id) : []
                          , shiftKey, ctrlKey)
                      }
                      //if (shouldShowRowDetails()) {
                      setSelectedSingleItem(rowInfo.original);
                      // }
                    }
                  },
                }
              }}
              getTrProps={(_, rowInfo) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    style: {
                      fontWeight: (selector.isSelected(rowInfo.original.id)) && "bold",
                      background: (selector.isSelected(rowInfo.original.id)) && "#f4f4f4",
                    },
                  }
                } else {
                  return {
                    onClick: originalHandleClick,
                  }
                }
              }}
              SubComponent={(row) => {
                return (
                  <WhiteListRow
                    data={row}
                    fetched={fetched}
                    setFetched={setFetched}
                  />
                )
              }}
            />
            {shouldShowRowDetails() && (
              <div className="wl-table-details">
                <Button className="closeButton" onClick={() => {
                  selector.deselectAllRows()
                }}><img src={closeIcon} alt="" /></Button>
                <WhiteListRowDetails row={selectedSingleItem}></WhiteListRowDetails>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhiteListTable