import React, { useEffect, useState, useContext } from "react"
import { Switch, Route } from "react-router-dom"
import { Layout } from "antd"

import ClientPanel from "../ClientPanel/ClientPanel"
import ClientVeryficator from "../ClientVeryficator"
import Dashboard from "../Dashboard/Dashboard"
import Navbar from "../Navbar/Navbar"
import SignalR from "../SignalR/SignalR"
import Sidebar from "../Sidebar/Sidebar"
import Tools from "../Tools/Tools"
import UserPanel from "../UserPanel/UserPanel"
import VatTable from "../Tables/VatTable/VatTable"
import WhiteList from "../WhiteList/WhiteList"
import NipVerifier from "../NipVerifier/NipVerifierMain"
import JobsHistory from "../JobsHistory/JobsHistory"

import "./Main.less"

import dashboard from "../../images/dashboard.svg"
import generatorjpk from "../../images/generatorjpk.svg"
import panelklienta from "../../images/panelklienta.svg"
import weryfikatorkontrahenta from "../../images/weryfikatorkontrahenta.svg"
import weryfikacjaNip from "../../images/weryfikacjaNIP.svg"

import API, { baseApiURL } from "../common/api"
import Context from "../../context/index"

import { Loading } from "./Loading"

import LogRocket from "logrocket"

const Main = () => {
  const [fetched, setFetched] = useState(false)
  const [orgFetched, setOrgFetched] = useState(false)
  const [loading, setLoading] = useState(true)

  const { ctx, setCtx } = useContext(Context)

  const checkIfExpired = (appName) => {
    const { currentOrganization } = ctx
    const { licences } = currentOrganization

    const licencesDate = licences.find(({ applicationName }) => applicationName === appName)

    if (!licencesDate) return true

    return new Date().getTime() >= new Date(licencesDate.expirationDate).getTime()
  }

  const checkIfHasFlag = (flagName) => ctx.featureFlags[flagName]

  useEffect(() => {
    if (!orgFetched) return

    // GET ORG APPLICATIONS
    const fetchOrg = async () => {
      try {
        const {
          data: { stateObject: tmpObj = [] },
        } = await API.get(`${baseApiURL}/Dashboard/getApps?CurrentOrganizationGuid=${ctx.currentOrganization.guid}`)

        const whiteListDisabled = ctx.featureFlags.WhiteListDisabled
        const nipVerificationDisabled = ctx.featureFlags.NipVerificationDisabled

        const stateObject = tmpObj
          .map((app) => {
            if (!app) return {}

            if (whiteListDisabled && app.name === "Biała lista") return null
            if (nipVerificationDisabled && app.name === "Weryfikacja NIP") return null

            switch (app.name) {
              case "Dashboard":
                return {
                  ...app,
                  colorTheme: "#FA8231",
                  image: dashboard,
                  description:
                    "Zarządzaj danymi organizacji oraz użytkownikami, aplikacjami i podmiotami dostępnymi w licencji.",
                }
              case "Sprawdź JPK_VAT":
                return {
                  ...app,
                  name: "Sprawdź JPK_VAT",
                  colorTheme: "#0FB9B1",
                  image: generatorjpk,
                  description: "Zaimportuj, sprawdź oraz wygeneruj poprawny plik JPK_V7M.",
                }
              case "Weryfikator Kontrahenta":
                return {
                  ...app,
                  name: "Weryfikator kontrahenta",
                  colorTheme: "#EB3B5A",
                  image: weryfikatorkontrahenta,
                  description:
                    "Topping dessert soufflé cake icing dessert marshmallow caramels. Chocolate gummies muffin cookie.",
                }
              case "Panel Administratora":
                return {
                  ...app,
                  name: "Panel administratora",
                  colorTheme: "#F7B731",
                  image: panelklienta,
                  description:
                    "Zarządzaj danymi organizacji oraz użytkownikami, aplikacjami i podmiotami dostępnymi w licencji.",
                }
              case "Biała lista":
                return {
                  ...app,
                  name: "Biała lista",
                  colorTheme: "#EA3D5B",
                  image: weryfikatorkontrahenta,
                  description: "Sprawdź czy rachunki bankowe Twoich kontrahentów są na białej liście.",
                }
              case "Weryfikacja NIP":
                return {
                  ...app,
                  name: "Weryfikacja NIP",
                  colorTheme: "#8854D0",
                  image: weryfikacjaNip,
                  description: "Sprawdź czy Twój kontrahent jest czynnym podatnikiem VAT.",
                }
              default:
                return null
            }
          })
          .filter((x) => x != null)

        setCtx({ ...ctx, stateObject })

        setFetched(true)
        setOrgFetched(false)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setFetched(true)
        setLoading(false)
      }
    }

    fetchOrg()
  }, [orgFetched])

  // GET USER GUID
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await API.get(`${baseApiURL}/Dashboard/getOrganizations`)
        if (!response) return

        const {
          data: { stateObject },
        } = response

        let guid
        let name
        let otherOrganizations
        let owner

        const tmp = [...stateObject]

        // SETUP DEFAULT ORG, FIRST ARRAY HERE

        // SAVE CURRENT GUID LOCALSTORAGE

        const [curr] = stateObject.filter((apps) => apps.guid === localStorage.getItem("currentGuid"))
        if (localStorage.getItem("currentGuid") && curr !== undefined) {
          guid = curr.guid
          name = curr.name
          owner = curr.owner

          otherOrganizations = tmp.filter((apps) => apps.guid !== localStorage.getItem("currentGuid"))
        } else {
          guid = stateObject[0].guid
          name = stateObject[0].name
          owner = stateObject[0].owner

          tmp.shift()
          otherOrganizations = tmp

          localStorage.setItem("currentGuid", guid)
        }

        const currentOrg = stateObject.find((e) => e.guid === guid)
        const licenceType = currentOrg.licenceType
        const licenceDate = currentOrg.licences[0].expirationDate
        const licenceDateFormated = licenceDate.split("T")[0]
        const roles = stateObject.find((e) => e.guid === guid).organizationRoles
        const licences = stateObject.find((e) => e.guid === guid).licences
        const resellerLink = stateObject.find((e) => e.guid === guid).resellerLink || "https://home.pl/cloudtax/"
        // SETUP REST ORGS, REST
        setCtx({
          ...ctx,
          currentOrganization: {
            guid,
            name,
            licenceType,
            licenceDate: licenceDateFormated,
            roles,
            owner,
            licences,
            resellerLink,
          },
          otherOrganizations,
        })
        setOrgFetched(true)

        LogRocket.identify(name, {
          guid: guid,
          licenceType: licenceType,
        })
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchUser()
  }, [ctx.fetchNewOrg])

  useEffect(() => {
    if (ctx.currentApp === "Panel administratora") return

    setCtx({ ...ctx, clientEdit: false, clientEditModal: false })
  }, [ctx.currentApp])

  if (loading || !fetched) return <Loading fullSize />

  const { Content } = Layout

  return (
    <Layout className="Main">
      <SignalR />
      <Sidebar />
      <Layout>
        <Navbar />
        <Layout className="Main-layout">
          <Content className="Main-site-layout-background">
            <Switch>
              <Route exact path="/panel-administratora" component={ClientPanel} />

              <Route path="/bramka" component={VatTable} />
              {!checkIfExpired("Sprawdź JPK_VAT") && <Route path="/ewidencja-vat" component={VatTable} />}
              <Route path="/panel-uzytkownika" component={UserPanel} />
              {!checkIfExpired("Biała lista") && !checkIfHasFlag("WhiteListDisabled") && (
                <Route path="/biala-lista" component={WhiteList} />
              )}
              {!checkIfExpired("Weryfikacja NIP") && !checkIfHasFlag("WhiteListDisabled") && (
                <Route path="/weryfikacja-nip" component={NipVerifier} />
              )}
              {!checkIfHasFlag("JobsHistoryDisabled") && (
                <Route path="/historia-operacji" component={JobsHistory} />
              )}
              <Route path="/" component={Dashboard} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Main
