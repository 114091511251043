import { NotificationsHelper } from "components/Notifications/Notifications.jsx"
import API from "components/common/api"

export const AppIds = {
    JPK_VAT: 3,
    WHITE_LIST: 5,
    NIP_VERIFICATION: 7
}

export const DownloadFile = async (fileId, fileName, source, state, dispatch) => {
    let urlPrefix = ""
    switch (source) {
        case AppIds.JPK_VAT:
            urlPrefix = "/VatGenerator"
            break
        case AppIds.WHITE_LIST:
            urlPrefix = "/WhiteList"
            break;
        case AppIds.NIP_VERIFICATION:
            urlPrefix = "/NipVerification"
            break;
        default:
            break;
    }
    const url = urlPrefix + "/GetFile/" + fileId

    const notificationHelper = NotificationsHelper(state, dispatch)
    notificationHelper.showInfo("Pobieranie pliku " + fileName + " rozpoczęte…")
    await API
        .request({
            url: url,
            method: "GET",
            responseType: 'blob', //important
        })
        .then((response) => {
            if (response.status !== 200) return
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            notificationHelper.showInfo("Pobieranie pliku " + fileName + " zakończone.")
        })
        .catch(() => {
            notificationHelper.showError("Wystąpił błąd, spróbuj ponownie.")
        })
}

export const DownloadFileWithoutNotifications = async (fileId, fileName, source) => {
    let urlPrefix = ""
    switch (source) {
        case AppIds.JPK_VAT:
            urlPrefix = "/VatGenerator"
            break
        case AppIds.WHITE_LIST:
            urlPrefix = "/WhiteList"
            break;
        case AppIds.NIP_VERIFICATION:
            urlPrefix = "/NipVerification"
            break;
        default:
            break;
    }
    const url = urlPrefix + "/GetFile/" + fileId

    await API
        .request({
            url: url,
            method: "GET",
            responseType: 'blob', //important
        })
        .then((response) => {
            if (response.status !== 200) return
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(() => {
        })
}

export const DownloadTextFileFromString = async (fileName, str) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([str], { type: 'text/plain' }))
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
}