import React, { useState, useEffect, useContext } from "react"
import * as signalR from "@microsoft/signalr"
import { openNotification } from "components/Notifications/Notifications.jsx"
import GetNotificatonModal from "./subcomponents/NotificationsModalProvider"
import Context from "context"

import { DispatchContext, StateContext } from "../userContext"
import { history } from "App"

import { routes } from "../../components/common/api"
import { stat } from "fs"



const SignalR = () => {
  const [hubConnection] = useState(
    new signalR.HubConnectionBuilder()
      .withUrl(
        `${routes.signalApi}notification?access_token=${localStorage.getItem("token")}`,
        signalR.HttpTransportType.WebSockets
      )
      .withAutomaticReconnect()
      .build()
  )

  useEffect(() => {
    const start = async () => {
      try {
        await hubConnection.start()
      } catch (err) {
        setTimeout(() => start(), 5000)
      }
    }

    start()
  }, [])

  if (!localStorage.getItem("token")) return null

  return <Stack stackProps={hubConnection} />
}

const Stack = ({ stackProps }) => {
  const { ctx, setCtx } = useContext(Context)
  const dispatch = useContext(DispatchContext)
  const state = useContext(StateContext)
  const { isModal, modalType, notification } = state.notifications

  useEffect(() => {
    stackProps.on("NotyficationReceived", (stack) => {
      const obj = JSON.parse(stack)

      const key = `open${Date.now()}`
      const currentNotificationKeys = state.notificationKeys
      state.notificationKeys = [...currentNotificationKeys, key]

      openNotification(obj, ctx, state, dispatch, history, key)
    })

    history.listen((location) => {
      state.notificationKeys.forEach(nk => {
        if (notification !== undefined) {
          notification.close(nk)
        }
      });
     // state.notificationKeys = []
      dispatch({
        type: "handleState",
        payload: [],
        field: "notifications",
      })
    }, [])
  }, [])

  const closeModal = () => {
    dispatch({
      type: "handleState",
      payload: { ...state.notifications, isModal: false, modalType: "", notification: null },
      field: "notifications",
    })
  }

  const modal = isModal ? GetNotificatonModal(modalType, notification, closeModal) : null

  return <>{modal}</>
}

export default SignalR
