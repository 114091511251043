import React, { useContext, useState, useEffect } from "react"
import { Radio, Space, Button, Modal, Form, Input, Checkbox } from "antd"
import Context from "context/index"
import API from "../../common/api"
import "./ConsentsModal.less"
import ReactGA from "react-ga"

const WienerUrl = "https://taxinsight.pl/warunki-ogolne-ubezpieczenia-cloudtax/"


const WienerConsentModal = ({ helpRef, closeModal, setWienerAnyOptionSelected }) => {
  const [wienerChecked, setWienerChecked] = useState(false)
  const { ctx } = useContext(Context)

  return (
    <div>
      <p>
        <span>W ramach wykupionej licencji przysługuje Ci prawo do ubezpieczenia dla wykonywanych badań merytorycznych plików JPK VAT.<br></br>Aby skorzystać z ubezpieczenia niezbędne jest zaakceptowanie<br></br></span>

        <ReactGA.OutboundLink
          eventLabel="Komunikat Wiener - OWU 1"
          to={WienerUrl}
          target="_blank" rel="noopener noreferrer">Ogólnych warunków ubezpieczenia Wiener. </ReactGA.OutboundLink>

        <span>Możesz pominąć ten krok teraz i zaakceptować OWU w późniejszym czasie, w panelu<br></br>Zgody i oświadczenia.</span>
      </p>
      <p><span>Czy akceptujesz </span>
        <ReactGA.OutboundLink
          eventLabel="Komunikat Wiener - OWU 1"
          to={WienerUrl}
          target="_blank" rel="noopener noreferrer">Ogólne warunki ubezpieczenia?</ReactGA.OutboundLink>
      </p>
      <Form
        ref={helpRef}
        name="ConsentEdit"
        onFinish={async (values) => {
          await API.post(
            `/Dashboard/saveUserConsents?CurrentOrganizationGuid=${ctx.currentOrganization.guid}`,
            { "wiener": wienerChecked },
            { headers: { tenant_id: localStorage.getItem("currentGuid") } }
          ).then((resp) => {
            if (resp.status === 200) {
              closeModal()
            }
          }).catch(e => {
            console.error(e)
          })
        }
        }
      >
        <Form.Item>
          <Radio.Group onChange={e => {
            setWienerAnyOptionSelected(true)
            setWienerChecked(e.target.value === "true")
          }}>
            <Space direction="vertical">
              <Radio value="true">tak</Radio>
              <Radio value="false">nie</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

      </Form>
    </div>
  )

}


export default WienerConsentModal