import React, { useEffect, useContext } from "react"

import { columns as getColumns } from "./data"
import ActionButtons from "./ActionButtons"

import correctionArrow from "images/sort.svg"

import { history } from "App"

import linia from "../../../../images/linia.svg"
import plik_do_podpisu from "../../../../images/plik_do_podpisu.svg"
import blad_wysylki from "../../../../images/blad_wysylki.svg"
import plik_bledny from "../../../../images/plik_bledny.svg"
import pobierz_upo from "../../../../images/pobierz_upo.svg"
import podglad_upo from "../../../../images/podglad_upo.svg"
import plik_do_wysylki from "../../../../images/plik_podpisany.svg"
import Context from "context"

const statusToIconMap = {
  "-": linia,
  "plik do podpisu": plik_do_podpisu,
  "plik do wysyłki": plik_do_wysylki,
  "błąd wysyłki": blad_wysylki,
  "pobierz UPO": pobierz_upo,
  "plik błędny": plik_bledny,
  "podgląd UPO": podglad_upo,
}

const statusToTipMap = {
  "plik do podpisu": "plik do podpisu",
  "plik do wysyłki": "plik do wysyłki",
  "błąd wysyłki": "błąd wysyłki",
  "pobierz UPO": "pobierz UPO",
  "plik błędny": "plik błędny",
  "podgląd UPO": "podgląd UPO",
}

const VatRow = ({
  data,
  fetched,
  setFetched,
  fetchCorrectionList,
  correctionListRow,
  selectedRows,
  removeItem,
  addItem,
  setSelectedRows,
  setDelModal,
}) => {
  const { ctx } = useContext(Context)
  const columns = getColumns(ctx)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchCorrectionList(data.original.id)
        setFetched([...fetched, data.original.id])
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  if (!columns.length) return <></>

  const selectRowHandler = (id, event) => {
    if (selectedRows.includes(id)) {
      removeItem(id)
    } else {
      if (event.shiftKey) {
        addItem(id)
      } else {
        setSelectedRows([id])
      }
    }
  }

  const showCorrectionList = (array) => {
    return array.data.map((correction, idx) => {
      const {
        id,
        rok,
        miesiac,
        cel_zlozenia,
        data_wytworzenia_jpk,
        pelna_nazwa,
        nip,
        liczba_wierszy_sprzedazy,
        liczba_wierszy_zakupow,
        podatek_nalezny,
        podatek_naliczony,
        skorygowana,
        status_bramki,
      } = correction

      const selected = selectedRows.includes(id)
      const blocked = correction.zablokowana

      return (
        <div
          className={`vatRow${selected ? " vatRow_selected" : ""}`}
          key={id}
          onClick={(event) => selectRowHandler(id, event)}
        >
          <div className="rt-table">
            <div className="rt-tbody">
              <div className="rt-tr-group">
                <div className={`rt-tr${blocked || skorygowana ? " blocked" : ""}`}>
                  <div className="correction-arrow">
                    <img className={`${selected ? "show" : ""}`} src={correctionArrow} alt="" />
                  </div>
                  <div role="gridcell" className="rt-td date" role="gridcell">
                    <span
                      className="react-date"
                      onClick={() => {
                        history.push(`?id=${id}`)
                      }}
                    >
                      {rok}.{miesiac}
                    </span>
                  </div>
                  <div role="gridcell" className="rt-td purpose">
                    {cel_zlozenia === 2 ? "korekta" : "złożenie"}
                  </div>
                  <div role="gridcell" className="rt-td exportDate">
                    {data_wytworzenia_jpk ? data_wytworzenia_jpk : "-"}
                  </div>
                  <div role="gridcell" className="rt-td name">
                    {pelna_nazwa}
                  </div>
                  <div role="gridcell" className="rt-td nip">
                    {nip}
                  </div>
                  <div role="gridcell" className="rt-td sellDoc">
                    {liczba_wierszy_sprzedazy}
                  </div>
                  <div role="gridcell" className="rt-td dueTax">
                    {podatek_nalezny
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      .replace(".", ",")}
                  </div>
                  <div role="gridcell" className="rt-td buyDoc">
                    {liczba_wierszy_zakupow}
                  </div>
                  <div role="gridcell" className="rt-td inputTax">
                    {podatek_naliczony
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      .replace(".", ",")}
                  </div>
                  {!ctx.featureFlags.Generator_GateDisabled && (
                    <div role="gridcell" className="rt-td status status-icons-position">
                      <img
                        data-tip={statusToTipMap[status_bramki] ? statusToTipMap[status_bramki] : null}
                        src={statusToIconMap[status_bramki] || statusToIconMap["-"]}
                        alt="status icon"
                      />
                    </div>
                  )}
                  <div role="gridcell" className="rt-td custom-action-icons">
                    <ActionButtons row={correction} setDelModal={setDelModal} setSelectedRows={setSelectedRows} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return <>{correctionListRow.length ? showCorrectionList(correctionListRow[0]) : null}</>
}

export default VatRow
