// Helpers for VatTable component

const handleSingleVisible = (visibleVats) => visibleVats.length === 1
const handleMultiVisible = (visibleVats) => visibleVats.length > 1

const handleOneIsCorrected = (visibleVats) => visibleVats.some((el) => el.skorygowana)
const handleOneIsBlocked = (visibleVats) => visibleVats.some((el) => el.zablokowana)

const handleDeleteButton = (visibleVats) => {
  const oneIsChosen = handleSingleVisible(visibleVats)
  const multipleIsChosen = handleMultiVisible(visibleVats)

  if (oneIsChosen) {
    return oneIsChosen && !handleOneIsBlocked(visibleVats) && !handleOneIsCorrected(visibleVats)
  }

  if (multipleIsChosen) {
    return !visibleVats.some((el) => el.zablokowana || el.skorygowana)
  }
}

const handleCorrectionButton = (visibleVats) => {
  const oneIsChosen = handleSingleVisible(visibleVats)

  let isBlockedOrCorrected

  if (oneIsChosen) {
    isBlockedOrCorrected = visibleVats[0].skorygowana || visibleVats[0].zablokowana
  }

  return oneIsChosen && !isBlockedOrCorrected
}

const handleSortedField = (value) => {
  if (!value.length) return ""

  const elem = value[0]
  const direction = elem.desc ? "" : "-"

  if (filedsToSearch.includes(elem.id)) {
    return `${direction}${elem.id}`
  } else {
    if (elem.id === "rok_i_miesiac") {
      return `${direction}rok,${direction}miesiac`
    }
  }
}

const formatExportData = (data) => {
  data.forEach((el) => {
    const getDateExport = new Date(el.data_wytworzenia_jpk)
    if (el.data_wytworzenia_jpk) {
      const exportDate =
        getDateExport.getFullYear() +
        "." +
        ("0" + (getDateExport.getMonth() + 1)).slice(-2) +
        "." +
        ("0" + getDateExport.getDate()).slice(-2)

      el.data_wytworzenia_jpk = exportDate
    }
  })
}

const filedsToSearch = [
  "rok",
  "miesiac",
  "cel_zlozenia",
  "data_wytworzenia_jpk",
  "data_utworzenia",
  "liczba_wierszy_sprzedazy",
  "liczba_wierszy_zakupow",
  "nip",
  "pelna_nazwa",
  "podatek_nalezny",
  "podatek_naliczony",
  "skorygowana",
  "zablokowana",
  "status_bramki"
]

export {
  filedsToSearch,
  formatExportData,
  handleSortedField,
  handleCorrectionButton,
  handleDeleteButton,
  handleOneIsBlocked,
  handleOneIsCorrected,
  handleMultiVisible,
  handleSingleVisible,
}
