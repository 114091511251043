import React from "react"
import FormModal from "components/common/FormModal"

interface ExportModalProps {
  visible: boolean
  handlePostExport: () => void
  loading: boolean
}

export const ExportModal = (props: ExportModalProps) => {
  const { visible, handlePostExport, loading } = props

  return (
    //@ts-ignore
    <FormModal
      loading={loading}
      destroyOnClose
      visible={visible}
      content="Przypominamy, że właściciel podpisu musi posiadać pełnomocnictwo do podpisywania deklaracji składanej za pomocą środków komunikacji elektronicznej (UPL-1)"
      // @ts-ignore
      onAccept={handlePostExport}
      addClass="exportModal gateExportModal"
    />
  )
}
