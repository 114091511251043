import { objectValues } from "@azure/ms-rest-js/es/lib/util/utils";

export const getVerificationFilter = (options, filterKind) => {
    const entries = Object.entries(options)
    let allTrue = true
    if (entries !== undefined && entries.length > 0) {
        let selectedIndices = []
        for (let i = 0; i < entries.length; i++) {
            const element = entries[i][1];
            if (element.selected) {
                selectedIndices.push(element.value ?? "null")
            } else {
                allTrue = false
            }
        }
        if (!allTrue && selectedIndices.length > 0) {
            const status = (filterKind === "verificationMf" ? "mf_status==" : (filterKind === "verificationVies" ? "vies_status==" : "verification_status"))
            return status + selectedIndices.join("|")
        }
    }
    return "";
}

export const getImportSourceFilter = (filteredImportIds) => {
    const keys = Object.keys(filteredImportIds)
    if (keys.length === 0) return ""
    let i = 0
    const segments = keys.map(x => "userImports[" + i++ + "]=" + encodeURIComponent(x))
    return segments.join("&")
}

export const getDeleteEntitiesArray = (selectedRows) =>{
    const values = Object.values(selectedRows)
    let i = 0
    const segments = values.map(x => "ids[" + i++ + "]=" + encodeURIComponent(x))
    return segments.join("&")
}

export const getExceptedIds = (exceptedIds) => {
    let i = 0
    const segments = exceptedIds.map(x => "exceptIds[" + i++ + "]=" + x)
    return segments.join("&")
}