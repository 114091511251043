import React, { useEffect, useCallback, useContext, useState } from "react"

import { DispatchContext, StateContext } from "components/userContext"

import { Input, Form, Checkbox, DatePicker, Radio } from "antd"

import infoIcon from "../../../images/info.svg"
import API from "components/common/api"
import locale from "antd/es/date-picker/locale/pl_PL"

const TransactioneAdd = ({ addForm, purchase, formVariant }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const dictionary = !purchase ? "sellDictionary" : "shoppingDictionary"

  const [checked, setChecked] = useState()
  const [k16, setK16] = useState()
  const [k18, setK18] = useState()
  const [k20, setK20] = useState()

  if (addForm?.current && formVariant === 2) {
    const tmp = (addForm.current.getFieldValue("korekta_podstawy_opodt")) > 0
    if (tmp !== checked) {
      setChecked(tmp)
    }
    const k16Tmp = addForm.current.getFieldValue("k_16")
    const k18Tmp = addForm.current.getFieldValue("k_18")
    const k20Tmp = addForm.current.getFieldValue("k_20")
    if (k16Tmp && k16Tmp !== k16) setK16(k16Tmp)
    if (k18Tmp && k18Tmp !== k18) setK18(k18Tmp)
    if (k20Tmp && k20Tmp !== k20) setK20(k20Tmp)
  }

  const updateField = (code, value) => {
    const v = value.target.valueAsNumber
    if (isNaN(v)) return
    if (code === "K_16") setK16(v)
    if (code === "K_18") setK18(v)
    if (code === "K_20") setK20(v)
  }

  const fetchData = useCallback(async () => {
    // dictionary fetch once
    try {
      const route = !purchase
        ? `/VatRecordSale/getSellTransactionDictionary`
        : `/VatRecordPurchase/getPurchaseTransactionDictionary`

      const {
        data: { stateObject },
      } = await API.get(route, {
        headers: {
          tenant_id: localStorage.getItem("currentGuid"),
        },
      })

      dispatch({ type: "handleState", field: dictionary, payload: stateObject })
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (state[dictionary]) return
    fetchData()
  }, [])

  if (!purchase ? !state.sellDictionary : !state.shoppingDictionary) return <></>

  return (
    <div className="transaction-edit-form">
      <div className="transaction scroll">
        <span className="transaction-header">
          {!purchase
            ? "Rozliczenie podatku należnego"
            : "Nabycie towarów i usług oraz podatek naliczony z uwzględnieniem korekt"}
        </span>

        <div className="transaction-header-values">
          <span />
          <div>
            {!purchase ? (
              <>
                <span>
                  Podstawa
                  <br /> opodatkowania w zł
                </span>
                <span>Podatek należny w zł</span>
              </>
            ) : (
              <div style={{ marginTop: "30px", width: "100%" }}>
                <span>Wartość netto w zł</span>
                <span>Podatek naliczony w zł</span>
              </div>
            )}
          </div>
        </div>

        {state[dictionary].map(({ code, short_description, full_description }, index) => {
          if (code === "korekta_podstawy_opodt") return
          if (code === "sprzedaz_vat_marza") return
          if (code === "data_zaplaty" || code === "termin_platnosci") return <></>

          const [, codeNumber] = code.split("_")
          const onlySecondRow = !purchase ? codeNumber >= 33 : false
          const hasDash = !purchase ? parseInt(codeNumber) === 33 : parseInt(codeNumber) === 44
          const paddingLeft = short_description.includes("w tym") && "20px"

          const nextElem = state[dictionary][index + 1]
          const prevElem = state[dictionary][index - 1]

          // SKIP DUPLICATED PREV ROWS
          if (prevElem && prevElem.short_description === short_description) return

          // HANDLE DOUBLE-VALUE ROWS
          if (nextElem && short_description === nextElem.short_description) {
            return (
              <div key={code} className="transaction-row">
                <span>{short_description}</span>
                <div>
                  <Form.Item
                    hasFeedback
                    name={code.toLowerCase()}
                    label={<small className="code-name">{code}</small>}
                    validateTrigger="onBlur"
                    onChange={(v) => updateField(code, v)}
                    rules={[
                      (e) => ({
                        validator() {
                          const msg = purchase ? "Uzupełnij kwotę podatku!" : "Uzupełnij kwotę podatku!"
                          const elemCode = code.toLowerCase()
                          const nextElemCode = nextElem.code.toLowerCase()

                          const elementValue = parseInt(e.getFieldValue(elemCode))
                          const nextElementValue = parseInt(e.getFieldValue(nextElemCode))

                          const clearVal = e.getFieldValue(elemCode)
                          const nextClearVal = e.getFieldValue(nextElemCode)

                          const areValid =
                            (!isNaN(elementValue) && !isNaN(nextElementValue)) ||
                            (clearVal === "" && nextClearVal === "") ||
                            (clearVal === null && nextClearVal === null) ||
                            (clearVal === undefined && nextClearVal === undefined)

                          if (areValid) {
                            addForm.current.setFields([
                              {
                                name: nextElemCode,
                                errors: [],
                              },
                            ])

                            return Promise.resolve()
                          }

                          if (nextClearVal && !clearVal) {
                            const msg = purchase ? "Uzupełnij kwotę netto!" : "Uzupełnij kwotę podstawy!"
                            return Promise.reject(msg)
                          }

                          addForm.current.setFields([
                            {
                              name: nextElemCode,
                              errors: [msg],
                            },
                          ])
                        },
                      }),
                    ]}
                  >
                    <Input type="number" suffix={<img src={infoIcon} alt="info" data-tip={full_description} />} />
                  </Form.Item>

                  <Form.Item
                    hasFeedback
                    name={nextElem.code.toLowerCase()}
                    label={<small className="code-name">{nextElem.code}</small>}
                    validateTrigger="onBlur"
                    onChange={(v) => updateField(code, v)}
                    rules={[
                      (e) => ({
                        validator() {
                          const msg = purchase ? "Uzupełnij kwotę netto" : "Uzupełnij kwotę podstawy!"
                          const elemCode = nextElem.code.toLowerCase()
                          const prevElemCode = code.toLowerCase()

                          const elementValue = parseInt(e.getFieldValue(elemCode))
                          const prevElementValue = parseInt(e.getFieldValue(prevElemCode))

                          const clearVal = e.getFieldValue(elemCode)
                          const prevClearVal = e.getFieldValue(prevElemCode)

                          const areValid =
                            (!isNaN(elementValue) && !isNaN(prevElementValue)) ||
                            (clearVal === "" && prevClearVal === "") ||
                            (clearVal === null && prevClearVal === null) ||
                            (clearVal === undefined && prevClearVal === undefined)

                          if (areValid) {
                            addForm.current.setFields([
                              {
                                name: prevElemCode,
                                errors: [],
                              },
                            ])
                            return Promise.resolve()
                          }

                          if (prevClearVal && !clearVal) {
                            return Promise.reject("Uzupełnij kwotę podatku!")
                          }

                          addForm.current.setFields([
                            {
                              name: prevElemCode,
                              errors: [msg],
                            },
                          ])

                          // return Promise.reject(msg)
                        },
                      }),
                    ]}
                  >
                    <Input
                      type="number"
                      suffix={<img src={infoIcon} alt="info" data-tip={nextElem.full_description} />}
                    />
                  </Form.Item>
                </div>
              </div>
            )
          }

          // HANDLE SINGLE LAST ROWS
          if (onlySecondRow) {
            return (
              <div key={code} className={`transaction-row custom ${hasDash ? "has-dash" : ""} `}>
                <span>{short_description}</span>

                <Form.Item
                  label={<small className="code-name">{code}</small>}
                  hasFeedback
                  name={code.toLowerCase()}
                  validateTrigger="onBlur"
                  onChange={(v) => updateField(code, v)}
                >
                  <Input type="number" suffix={<img src={infoIcon} alt="info" data-tip={full_description} />} />
                </Form.Item>
              </div>
            )
          }

          // HANDLE REGULAR ROW
          return (
            <div key={code} className={`transaction-row ${hasDash ? "has-dash" : ""}`}>
              <span
                style={{
                  paddingLeft,
                }}
              >
                {short_description}
              </span>

              <Form.Item
                label={code !== "zakup_vat_marza" ? <small className="code-name">{code}</small> : null}
                hasFeedback
                name={code.toLowerCase()}
                validateTrigger="onBlur"
                onChange={(v) => updateField(code, v)}
              >
                <Input type="number" suffix={<img src={infoIcon} alt="info" data-tip={full_description} />} />
              </Form.Item>
            </div>
          )
        })}

        {!purchase && (
          <>
            <div className="transaction-row checkbox has-dash">
              <span>Korekta podstawy opodatkowania</span>

              <Form.Item name="korekta_podstawy_opodt" hasFeedback valuePropName="checked">
                <Checkbox onChange={(e) => {
                  setChecked(e.target.checked)
                }}>
                  <></>
                </Checkbox>
              </Form.Item>
            </div>

            {formVariant === 2 && (
              <>
                <div className={`transaction-row-date`}  >
                  <span>Data zapłaty</span>
                  <div className="transaction-row-date value">
                    <Form.Item name="data_zaplaty">
                      <DatePicker locale={locale}
                        disabled={!(checked && (k16 > 0 || k18 > 0 || k20 > 0))}
                        onChange={(v) => {
                          addForm.current.setFieldsValue({ termin_platnosci: null })
                        }}></DatePicker>
                    </Form.Item>
                    <span>
                      <img
                        src={infoIcon}
                        alt="info"
                        data-tip={state.sellDictionary.find(({ code }) => code === "data_zaplaty").full_description}
                      />
                    </span>
                  </div>
                </div>

                <div className={`transaction-row-date`}>
                  <span>Termin płatności</span>

                  <div className="transaction-row-date value">
                    <Form.Item name="termin_platnosci">
                      <DatePicker locale={locale}
                        disabled={!(checked && (k16 < 0 || k18 < 0 || k20 < 0))}
                        onChange={(v) => {
                          addForm.current.setFieldsValue({ data_zaplaty: null })
                        }}></DatePicker>
                    </Form.Item>
                    <span>
                      <img
                        src={infoIcon}
                        alt="info"
                        data-tip={state.sellDictionary.find(({ code }) => code === "termin_platnosci").full_description}
                      />
                    </span>
                  </div>
                </div>
              </>
            )}


            <div className="transaction-row custom ">
              <span>Wartość sprzedaży brutto na zasadach marży (art. 119 i 120 ustawy)</span>
              <Form.Item hasFeedback name="sprzedaz_vat_marza" validateTrigger="onBlur">
                <Input
                  type="number"
                  suffix={
                    <img
                      src={infoIcon}
                      alt="info"
                      data-tip={state[dictionary].find(({ code }) => code === "sprzedaz_vat_marza").full_description}
                    />
                  }
                />
              </Form.Item>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default TransactioneAdd
