import React from "react"
import WhiteListDropDown from "./WhiteListDropDown"
import ImportsDropDown from "./ImportsDropDown"
import { tracker } from "helpers/tracker"
import { VerificationStatusMap } from "../VerificationStatusMap"

const settings = [
  { id: "Right", label: "zgodny", style: "wl-status-right", notSelectedStyle: "wl-status-not-selected" },
  { id: "Wrong", label: "niezgodny", style: "wl-status-wrong", notSelectedStyle: "wl-status-not-selected" },
  { id: "Error", label: "błąd weryfikacji", style: "wl-status-error", notSelectedStyle: "wl-status-not-selected" },
  { id: "Unexecuted", label: "niewykonana", style: "wl-status-error", notSelectedStyle: "wl-status-not-selected" }
]

export const columns = [
  {
    showWithDetails: true,
    expander: true,
    Expander: ({ isExpanded, ...row }) => {
      return null
    },
  },
  {
    Header: "Nazwa kontrahenta",
    accessor: "contractor_name",
    showWithDetails: true
  },
  {
    Header: "NIP",
    accessor: "nip",
    showWithDetails: true,
  },
  {
    Header: "REGON",
    accessor: "regon",
    showWithDetails: true,
  },
  {
    Header: "Data importu",
    accessor: "import_date",
    showWithDetails: true,
    Cell: ({ value }) => {
      return <span> {value ? value.substring(0, 10) : "-"} </span>
    },
  },
  {
    accessor: "data_source",
    showWithDetails: false,
    Header: ({ value }) => {
      return <ImportsDropDown
        tableOptionsId="wlTableOptions"
        header="Źródło importu"
        lastImportsName="wlLastImports"
        lastImportsSerachName="wlLastImportsSearch"
      ></ImportsDropDown>
    },
  },
  {
    Header: "Data weryfikacji",
    accessor: "verification_date",
    showWithDetails: false,
    Cell: ({ value }) => {
      return <span> {value ? value.substring(0, 10) : "-"} </span>
    },
  },
  {
    accessor: "verification_status",
    showWithDetails: false,

    Header: ({ value }) => {
      return <WhiteListDropDown
        tableOptionsId="wlTableOptions"
        settings={settings}
      ></WhiteListDropDown>
    },

    Cell: ({ value }) => {
      if (value == null) return <span>-</span>
      const n = VerificationStatusMap[value]
      return <span className={n.style}>{n.name}</span>
    },
  }
]

// export const subColumns = [...columns]

// subColumns[0] = {
//   expander: false,
//   Expander: () => {
//     return null
//   },
// }

export const originalHandleClick = (_, handleOriginal) => {
  if (handleOriginal) {
    handleOriginal()
  }
}

export const inputStyles = {
  padding: "3px 15px",
  margin: "10px auto 10px 35px",
  WIDTH: "150PX",
  height: "40px",
  outline: "none",
  borderRadius: "5px",
  border: "1px solid #dedede",
}
