import React from "react"
import API from "components/common/api"
import { DownloadFileWithoutNotifications } from "helpers/cloudTax"

import closeIcon from "images/icons/close-notification.svg"
import successIcon from "images/icons/green-ok.svg"

const NotificationType = {
  INFO: 1,
  WARNING: 2,
  ERROR: 3,
  CUSTOM: 4,
  COMMAND: 5
}

const NotificationTrigger = ({
  notification,
  ctx,
  state,
  dispatch,
  message,
  notyficationType,
  hasAction,
  isSuccess,
  actionUrl,
  closeAction,
  handleNavigate,
  handleNavigateOutside,
  actionName,
  request
}) => {
  const boxClasses = ["Trigger-box"]

  switch (notyficationType) {
    case NotificationType.INFO:
      boxClasses.push("success")
      break
    case notyficationType.WARNING:
      boxClasses.push("warning")
      break
    case NotificationType.ERROR:
      boxClasses.push("error")
      break
    case NotificationType.CUSTOM:
      if (actionName === "ShowNonEuNips") {
        boxClasses.push("vat")
      }
      break
    default:
      break
  }

  const actionHandler = (type, state, dispatch) => {
    if (request !== null) {
      let args = JSON.parse(request)
      if (args.job_id !== undefined) {
        const url = `/Jobs/ackJob?CurrentOrganizationGuid=${ctx.currentOrganization.guid}&jobId=${args.job_id}`
        API.put(url)
      }
    }

    if (actionName === "download") {
      let args = JSON.parse(request)
      DownloadFileWithoutNotifications(args.id, args.name, args.source)
      closeAction()
      return
    }

    if (actionName && type !== NotificationType.CUSTOM) {
      let args = JSON.parse(request)
      dispatch({
        type: "handleState",
        field: "notificationEvent",
        payload: { actionName, args },
      })
      closeAction()
      return
    }

    if (type === NotificationType.INFO) {
      handleNavigate(actionUrl)
    }

    if (type === NotificationType.WARNING) {
      handleNavigateOutside(actionUrl)
    }

    if (type === NotificationType.ERROR) {
      dispatch({
        type: "handleState",
        field: "notifications",
        payload: { ...state.notifications, isModal: true, modalType: "showErrors", notification },
      })
      closeAction()
    }

    if (type === NotificationType.CUSTOM) {
      dispatch({
        type: "handleState",
        field: "notifications",
        payload: { ...state.notifications, isModal: true, modalType: actionName, notification },
      })
      closeAction()
    }
  }

  return (
    <div className={boxClasses.join(" ")}>
      <img className="close-icon" src={closeIcon} onClick={closeAction} alt="zamknij" />
      <p style={hasAction ? { cursor: "pointer" } : null}
        onClick={(hasAction || actionName) ? () => actionHandler(notyficationType, state, dispatch) : null}>
        {message}
      </p>
      {isSuccess && <img className="success-icon" src={successIcon} alt="sukces" />}
    </div>
  )
}

export default NotificationTrigger
