import React, { createContext } from "react"
import { useImmerReducer } from "use-immer"

import * as actionTypes from "../../helpers/types"
import * as APPS from "../../helpers/appNames"

import { AllVerificationStatus } from "components/WhiteList/VerificationStatusMap"
import { VerificationStatusMF, VerificationStatusVIES } from "components/NipVerifier/VerificationStatusMap"

const userReducer = (draft, { type, payload, field }) => {
  switch (type) {
    case "handleState": {
      draft[field] = payload
      return
    }

    case "fetchUserData": {
      draft.userData = payload.data
      draft.showEditButton = payload.buttonVisible
      return
    }

    case "handleUserEdit": {
      draft.cancelButtonVisible = true
      draft.cancelModal = false

      return
    }

    case "handleSaveUser": {
      draft.savedModal = false
      draft.userData = payload
      draft.newUserData = null
      draft.cancelModal = false
      draft.checkForm = false
      draft.save = false
      draft.showEditModal = false
      draft.cancelButtonVisible = false
      return
    }

    case "handleCancelEditChecker": {
      draft.checkForm = true
      draft.save = false
      draft.cancelModal = true
      return
    }

    case "handleSave": {
      draft.save = true
      draft.checkForm = true
      draft.errors = false
      return
    }

    case "handleError": {
      draft.errors = true
      draft.checkForm = false
      draft.editButtonVisible = true
      draft.showAdditionalBtns = false
      draft.errors = null
      draft.save = false

      return
    }

    case "saveUserData": {
      draft.newUserData = payload

      return
    }

    case "handleEdit": {
      draft.showEditModal = true
      draft.editButtonVisible = true
      draft.showAdditionalBtns = false
      return
    }

    case "handleRestoreEdit": {
      draft.checkForm = false
      draft.editButtonVisible = true
      draft.cancelButtonVisible = false
      draft.showEditModal = false
      draft.showAdditionalBtns = false
      draft.newUserData = null
      draft.errors = null
      draft.cancelModal = false
      draft.save = false

      return
    }

    case "handleCloseEmail": {
      draft.editEmailModal = false
      // draft.handleNotification = true
      return
    }

    case "handleOpenNotofication": {
      draft.editEmailModal = false
      draft.handleNotification = true
      return
    }

    case "handleCloseModal": {
      draft.cancelModal = false
      draft.checkForm = false
      draft.save = true
      draft.savedModal = false

      return
    }

    case "handleCleanBtn": {
      draft.editButtonVisible = false
      draft.cancelButtonVisible = false
      draft.showAdditionalBtns = false
      draft.newUserData = null
      draft.cancelModal = false
      draft.selectedModule = []

      return
    }

    case "mountClientPanel": {
      draft.selectedModule = ["1"]

      draft.moduleOneFrom = {
        path: "",
        count: 0,
        visible: true,
      }

      draft.moduleTwoFrom = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.moduleThreeFrom = {
        path: "",
        count: 0,
        visible: false,
      }
      return
    }

    case "mountUserPanel": {
      draft.selectedModule = ["1"]
      draft.editButtonVisible = true
      draft.moduleOneFromUserPanel = {
        path: "",
        count: 0,
        visible: true,
      }

      draft.moduleTwoFromUserPanel = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.moduleThreeFromUserPanel = {
        path: "",
        count: 0,
        visible: false,
      }
      return
    }

    // HANDLER 1ST MODULE CLIENT PANEL SECTION
    case actionTypes.HANDLE_MODULE_ONE: {
      if (payload.url !== APPS.CLIENT_PANEL) return

      draft.moduleOneFrom = {
        path: payload.url,
        count: draft.moduleTwoFrom.count + 1,
        visible: payload.visible,
      }

      draft.moduleThreeFrom = {
        count: 0,
        visible: false,
      }

      draft.moduleTwoFrom = {
        count: 0,
        visible: false,
      }

      draft.moduleTwoFromUserPanel = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.selectedModule = ["1"]

      return
    }

    // HANDLER 2ND MODULE CLIENT PANEL SECTION
    case actionTypes.HANDLE_MODULE_TWO: {
      if (payload.url !== APPS.CLIENT_PANEL) return

      draft.moduleTwoFrom = {
        path: payload.url,
        count: draft.moduleTwoFrom.count + 1,
        visible: payload.visible,
      }

      draft.selectedModule = ["2"]

      draft.moduleOneFrom = {
        count: 0,
        visible: false,
      }

      draft.moduleThreeFrom = {
        count: 0,
        visible: false,
      }

      draft.moduleTwoFromUserPanel = {
        path: "",
        count: 0,
        visible: false,
      }

      return
    }

    // HANDLER 3RD MODULE CLIENT PANEL SECTION
    case actionTypes.HANDLE_MODULE_THREE: {
      if (payload.url !== APPS.CLIENT_PANEL) return

      draft.moduleThreeFrom = {
        path: payload.url,
        count: draft.moduleThreeFrom.count + 1,
        visible: payload.visible,
      }

      draft.selectedModule = ["3"]

      draft.moduleOneFrom = {
        count: 0,
        visible: false,
      }
      draft.moduleTwoFromUserPanel = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.moduleTwoFrom = {
        count: 0,
        visible: false,
      }

      return
    }

    case actionTypes.HANDLE_RESET_MODULE: {
      draft.moduleOneFrom = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.sidebarVisible = false

      draft.moduleThreeFrom = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.moduleTwoFrom = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.selectedModule = []
      return
    }

    // HANDLER FIRST MODULE USER PANEL SECTION
    case actionTypes.HANDLE_MODULE_ONE_USER_PANEL: {
      draft.moduleOneFromUserPanel = {
        path: payload.url,
        count: draft.moduleOneFromUserPanel.count + 1,
        visible: payload.visible,
      }

      draft.moduleTwoFromUserPanel = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.moduleThreeFromUserPanel = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.selectedModule = ["1"]

      return
    }

    case actionTypes.HANDLE_MODULE_TWO_USER_PANEL: {
      draft.moduleTwoFromUserPanel = {
        path: payload.url,
        count: draft.moduleTwoFromUserPanel.count + 1,
        visible: payload.visible,
      }

      draft.preSelectedId = payload.preSelectedId || null

      draft.moduleOneFromUserPanel = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.moduleThreeFromUserPanel = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.selectedModule = ["4"]
      return
    }

    case "handleFillEntity": {
      draft.showEntityPanel = true
      draft.entityToFill = payload
      return
    }

    // ADD/EDIT PANEL VISIBLE
    case "handleAddEntity": {
      draft.visibleEntities = null
      draft.showEntityPanel = true

      return
    }

    // HANDLE SAVE ENTITY
    case "handleSaveEntity": {
      draft.submitEntity = draft.submitEntity + 1
      return
    }

    // HANDLE DELETE ENTITY
    case "handleDeleteEntity": {
      draft.showEntityDeleteModal = payload
      return
    }

    case "handleResetEntity": {
      draft.moduleTwoFromUserPanel = {
        path: "",
        count: 0,
        visible: false,
      }

      draft.visibleEntities = null
      draft.showEntityPanel = false
      draft.entityToFill = null
      draft.tmpEntity = null
      draft.submitEntity = 0
      draft.showEntityCancelModal = false
      return
    }

    // HANDLE CANEL EDITION ENTITY
    case "handleCancelEntity": {
      draft.visibleEntities = null
      draft.showEntityPanel = false
      // draft.relatedParties = null
      draft.preSelectedId = null
      draft.allRelatedParties = []
      draft.entityToFill = null
      draft.tmpEntity = null
      draft.submitEntity = 0
      draft.showEntityCancelModal = false
      draft.fetchEntitiesData = draft.fetchEntitiesData + 1
      return
    }

    case "handleCheckCancelEntity": {
      draft.showEntityCancelModal = payload
      if (!payload) {
        draft.visibleEntities = null
        draft.showEntityPanel = false
        draft.entityToFill = null
        draft.tmpEntity = null
        draft.submitEntity = 0
        draft.showEntityCancelModal = false
      }
      return
    }

    case "handleEntity": {
      draft.tmpEntity = payload
      return
    }

    case "handleAllRelatedParties": {
      draft.allRelatedParties = payload
      // // TMP GET FIRST RELATED PARTY MVP
      // if (payload.length) {
      //   draft.relatedParties = payload.map((e) => e.id)
      // } else {
      //   draft.relatedParties = null
      // }

      return
    }

    case "handleShowEditVat": {
      draft.showVatDetails = false
      draft.showEditVatId = payload
      return
    }

    case "handleSalesCleanUp": {
      draft.salesEdit = false
      draft.salesElem = null
      draft.lastActiveVatTab = "1"
      draft.selectedRowsSale = []

      return
    }

    case "handleDeclarationCleanUp": {
      draft.declarationsData = []
      draft.declarationsDictionary = []
      draft.declarationsFormFields = []
      draft.fieldToChange = ""
      draft.refreshDetails = false

      return
    }

    case "handleClearEditSells": {
      draft.activeEditKey = null
      draft.basicSellEdit = {}
      draft.tabsWithError = []
      draft.tabsWithErrorAdd = []

      return
    }

    case "handleReturnSaveEdit": {
      draft.tabsWithError = []
      draft.tabsWithErrorAdd = []
      draft.activeEditKey = null
      draft.basicSellEdit = {}
      draft.salesEdit = false
      draft.shoppingEdit = false
      draft.lastActiveVatTab = payload || "2"

      return
    }

    case "handleReturnSaveAdd": {
      draft.tabsWithError = []
      draft.tabsWithErrorAdd = []
      draft.activeEditKey = null
      draft.basicSellEdit = {}
      draft.salesEdit = false
      draft.lastActiveVatTab = payload || "2"
      draft.showAddNewDocument = false
      draft.showAddNewDocumentPurchase = false
      return
    }

    case "handleCancelEditSalesConfirm": {
      draft.salesEdit = false
      draft.shoppingEdit = false
      draft.handleCloseEditSections = false
      return
    }

    case "handleCancelAddSalesConfirm": {
      draft.showAddNewDocument = false
      draft.showAddNewDocumentPurchase = false
      draft.handleCloseAddSalesPurchase = false
      return
    }

    case "handleClearActiveRows": {
      draft.selectedRowsSale = []
      draft.selectedRowsShopping = []
      return
    }

    default:
      return
  }
}

const initialState = {
  userData: {
    email: "",
    roles: [],
  },
  vatTableData: [],
  whiteListData: [],
  usersTableData: [],
  detailsTabledata: [],
  detailsTabledataShopping: [],
  correctionList: [],
  visibleVats: [],
  isLoading: false,
  errors: "",
  submitEntity: 0,
  visibleEntities: [],
  moduleOneFrom: {
    path: "",
    count: 0,
    visible: false,
  },
  moduleThreeFrom: {
    path: "",
    count: 0,
    visible: false,
  },
  moduleTwoFrom: {
    path: "",
    count: 0,
    visible: false,
  },
  moduleOneFromUserPanel: {
    path: "",
    count: 0,
    visible: false,
  },
  moduleTwoFromUserPanel: {
    path: "",
    count: 0,
    visible: false,
  },
  fetchEntitiesData: 0,
  notifications: {
    isModal: false,
    notifications: [],
  },
  vatTableOptions: {
    sorted: [],
    currentPage: 1,
    totalPagesNumber: -1,
    pageSize: 10,
    expanded: {},
    filtered: [],
  },
  wlTableOptions: {
    sorted: [],
    currentPage: 1,
    totalPagesNumber: -1,
    pageSize: 10,
    expanded: {},
    filtered: [],
    filteredVerificationState: { ...AllVerificationStatus },
    filteredImportIds: {}
  },
  wlVerificationHistoryOptions: {
    sorted: [],
    currentPage: 1,
    totalPagesNumber: -1,
    pageSize: 10,
    expanded: {},
    filtered: [],
    filteredVerificationState: { ...AllVerificationStatus },
  },
  wlImportsHistoryOptions: {
    sorted: [],
    currentPage: 1,
    totalPagesNumber: -1,
    pageSize: 10,
    expanded: {},
    filtered: []
  },
  wlLastImports: null,
  nipTableOptions: {
    sorted: [],
    currentPage: 1,
    totalPagesNumber: -1,
    pageSize: 10,
    expanded: {},
    filtered: [],
    filteredVerificationStateMf: { ...VerificationStatusMF },
    filteredVerificationStateVies: { ...VerificationStatusVIES },
    filteredImportIds: {}
  },  
  jobsTableOptions: {
    sorted: ["-creationDateTime"],
    currentPage: 1,
    totalPagesNumber: -1,
    pageSize: 10,
    filtersPanelExpanded: false,
    filtered: [],
    filteredUsers: [],
    filteredOperationStatuses: [],
    filteredApplications: [],
    filteredOperations: [],
    filterDateTimeFrom: null,
    filterDateTimeUntil: null,
    users: []
  },
  nipVerificationHistoryOptions: {
    sorted: [],
    currentPage: 1,
    totalPagesNumber: -1,
    pageSize: 10,
    expanded: {},
    filtered: [],
    filteredVerificationStateMf: { ...VerificationStatusMF },
    filteredVerificationStateVies: { ...VerificationStatusVIES }
  },
  nipLastImports: null,
  usersTableOptions: {
    sorted: [],
    currentPage: 1,
    totalPagesNumber: -1,
    pageSize: 10,
    expanded: {},
    filtered: [],
  },
  detailsTableOptions: {
    sorted: [],
    currentPage: 1,
    totalPagesNumber: -1,
    pageSize: 10,
    expanded: {},
    filtered: [],
  },
  detailsTableShoppingOptions: {
    sorted: [],
    currentPage: 1,
    totalPagesNumber: -1,
    pageSize: 10,
    expanded: {},
    filtered: [],
  },
  sellDictionary: null,
  shoppingDictionary: null,
  sellValues: null,
  shoppingValues: null,
  procedureDictionary: null,
  shoppingProcedureDictionary: null,
  salesEdit: false,
  shoppingEdit: false,
  salesElem: null,
  shoppingElem: null,
  lastActiveVatTab: "1",
  selectedRowsSale: [],
  selectedRowsShopping: [],
  selectedRowsGate: [],
  basicSellEdit: {},
  tabsWithError: [],
  tabsWithErrorAdd: [],
  researchDict: [],

  // Declarations
  declarationsData: [],
  declarationsDictionary: [],
  declarationDictionaryFix: [],
  declarationData: {},
  declarationsFormFields: [],
  fieldToChange: "",
  refreshDetails: false,
  returnFromEdit: false,

  //Klucze powiadomień użytkownika
  notificationKeys: [],

  //Licznik nieodczytanych jobów z serwera
  jobsCounter: 0
}

export const StateContext = createContext()
export const DispatchContext = createContext()

const Context = ({ children }) => {
  const [state, dispatch] = useImmerReducer(userReducer, initialState)

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export default Context
