import React from "react"
import WhiteListDropDown from "../WhiteListMain/WhiteListDropDown"
import { tracker } from "helpers/tracker"
import { VerificationStatusMap, VerificationReasonMap } from "../VerificationStatusMap"

const dropDownSettings = [
  { id: "Right", label: "zgodny", style: "wl-status-right", notSelectedStyle: "wl-status-not-selected" },
  { id: "Wrong", label: "niezgodny", style: "wl-status-wrong", notSelectedStyle: "wl-status-not-selected" },
  { id: "Error", label: "błąd weryfikacji", style: "wl-status-error", notSelectedStyle: "wl-status-not-selected" },
]

export const historyVerificationColumns = [
  {
    expander: true,
    Expander: ({ isExpanded, ...row }) => {
      return null
    },
  },
  {
    Header: "Data importu",
    accessor: "import_date",
    Cell: ({ value }) => {
      return <span> {value ? value.substring(0, 10) : "-"} </span>
    },
  },
  {
    Header: "Źródło importu",
    accessor: "data_source",
  },
  {
    Header: "Nr rachunku bankowego",
    accessor: "bank_account_no",
  },
  {
    Header: "Data weryfikacji",
    accessor: "verification_date",
    Cell: ({ value }) => {
      return <span> {value ? value.substring(0, 16).replace("T", " ") : "-"} </span>
    },
  },
  {
    Header: "Data sprawdzenia na dzień",
    accessor: "reference_date",
    Cell: ({ value }) => {
      return <span> {value ? value.substring(0, 10) : "-"} </span>
    },
  },
  {
    Header: "Podstawa weryfikacji",
    accessor: "verification_reason",
    Cell: ({ value }) => {
      return <span> {VerificationReasonMap[value]} </span>
    },
  },
  {
    Header: "Id weryfikacji",
    accessor: "verification_request_id",
    Cell: ({ value }) => {
      return <span> {value !== null ? value : "-"} </span>
    },
  },
  {
    accessor: "verification_status",
    showWithDetails: false,

    Header: ({ value }) => {
      return <WhiteListDropDown
        tableOptionsId="wlVerificationHistoryOptions"
        settings={dropDownSettings}
      ></WhiteListDropDown>
    },
    Cell: ({ value }) => {
      if (value == null) return <span>-</span>
      const n = VerificationStatusMap[value]
      return <span className={n.style}>{n.name}</span>
    }
  }
]


export const originalHandleClick = (_, handleOriginal) => {
  if (handleOriginal) {
    handleOriginal()
  }
}

export const inputStyles = {
  padding: "3px 15px",
  margin: "10px auto 10px 35px",
  WIDTH: "150PX",
  height: "40px",
  outline: "none",
  borderRadius: "5px",
  border: "1px solid #dedede",
}
