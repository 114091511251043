import React, { useState } from "react"
import { Space, Form, Checkbox } from "antd"
import FormModal from "components/common/FormModal"

import "./SelectVerificationModal.less"

const SelectVerificationForm = ({ defaultSelected, setAnyTypeSelected, setSelection: setSelected }) => {

    return (
        <div className="selectVerificationModalContent">
            <h3>Wybierz zakres weryfikacji<br></br> wskazanych kontrahentów.</h3>

            <Form name="SelectVerificationForm">
                <Form.Item>
                    <Checkbox.Group
                        defaultValue={defaultSelected}
                        onChange={e => {
                            console.log(e)
                            setAnyTypeSelected(e.length !== 0)
                            setSelected({ mf: e.includes("mf"), vies: e.includes("vies") })
                        }}>
                        <Space direction="vertical">
                            <Checkbox value="mf"><span className="templateTypeName">Rejestr Ministerstwa Finansów</span></Checkbox>
                            <Checkbox value="vies"><span className="templateTypeName">Rejestr VIES</span></Checkbox>
                        </Space>
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </div>
    )
}

const SelectVerificationModal = ({ onDecline, onAccept }) => {
    const [anyTypeSelected, setAnyTypeSelected] = useState(true)
    const [selected, setSelected] = useState({ mf: true, vies: true })
    return <FormModal
        visible={true}
        acceptDisabled={!anyTypeSelected}
        onAccept={() => onAccept(selected)}
        onDecline={() => onDecline()}
        addClass="selectVerificationModal"
        okButtonName="Weryfikuj"
        hasForm={<SelectVerificationForm
            defaultSelected={["mf", "vies"]}
            setAnyTypeSelected={setAnyTypeSelected}
            setSelection={setSelected}
        />}
    />
}

export default SelectVerificationModal