import React from "react"

import { declarationMessage } from "../dataBlueprint"
import closeIcon from "images/close-notification.svg"

const TaxNotification = ({
  message,
  setInfoState,
  handleCalc,
}) => {
  const info =
    message === "obliczona"
      ? declarationMessage.obliczonaAction
      : declarationMessage.edytowanaAction

  const resetMessage = event => {
    event.stopPropagation()
    setInfoState(false)
  }

  const onCalcHandler = () => {
    handleCalc(true)
    setInfoState(false)
  }

  return (
    <div className="TaxNotification" onClick={() => onCalcHandler()}>
      <div className="noti-inner">
        <div className="noti-img" onClick={event => resetMessage(event)}>
          <img src={closeIcon} alt="" />
        </div>
        <p>{info}</p>
      </div>
    </div>
  )
}

export default TaxNotification
