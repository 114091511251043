import React from "react"
import { tracker } from "helpers/tracker"

export const historyImportColumns = [
  {
    expander: true,
    Expander: ({ isExpanded, ...row }) => {
      return null
    },
  },
  {
    Header: "Data importu",
    accessor: "import_date",
    Cell: ({ value }) => {
      return <span> {value ? value.substring(0, 10) : "-"} </span>
    },
  },
  {
    Header: "Źródło importu",
    accessor: "data_source",
  },
  {
    Header: "Nazwa kontrahenta",
    accessor: "contractor_name",
    
    Cell: ({ value }) => {
      return <span> {value ? value : "-"} </span>
    },
  },
  {
    Header: "NIP",
    accessor: "nip",
  },
  {
    Header: "REGON",
    accessor: "regon",
  },
  {
    Header: "Nr rachunku bankowego",
    accessor: "bank_account_no",
  },
]

export const subColumns = [...historyImportColumns]

subColumns[0] = {
  expander: false,
  Expander: () => {
    return null
  },
}

export const originalHandleClick = (_, handleOriginal) => {
  if (handleOriginal) {
    handleOriginal()
  }
}

export const inputStyles = {
  padding: "3px 15px",
  margin: "10px auto 10px 35px",
  WIDTH: "150PX",
  height: "40px",
  outline: "none",
  borderRadius: "5px",
  border: "1px solid #dedede",
}
