import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react"

import { ActionSection } from "./ActionSection"
import { gateInfoType, rowInfoType } from "./types"
import { StateContext } from "components/userContext"

import API, { generatorApiURL } from "components/common/api"

import { GenerateModal } from "./GenerateModal"
import { ExportModal } from "./ExportModal"
import { DeleteModal } from "./DeleteModal"
import { SendModal } from "./SendModal"
import { BeforeDeleteModal } from "./BeforeDeleteModal"

import imp from "images/import.svg"
import eksport from "images/eksport.svg"
import send from "images/send.svg"
import usun from "images/usun.svg"
import wygeneruj from "images/wygeneruj.svg"
import numer from "images/numer.svg"
import podglad from "images/podglad.svg"
import pobierz from "images/pobierz.svg"

import "./GateActionButtons.less"
import Axios, { AxiosResponse } from "axios"

interface GateActionButtonsProps {
  rowsInfo: rowInfoType[]
  gateInfo: gateInfoType
  fetchData: () => void
}

export interface handlePostGenerateType {
  generate_vatrecord: boolean
  generate_declaration: boolean
}

export const GateActionButtons = (props: GateActionButtonsProps) => {
  const { rowsInfo, gateInfo, fetchData } = props
  const [selectedRowInfo] = rowsInfo

  const { selectedRowsGate } = useContext(StateContext)
  const [active, setActive] = useState(false)
  const [generateModal, setGenerateModal] = useState(false)
  const [exportModal, setExportModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [beforeDeleteModal, setBeforeDeleteModal] = useState(false)
  const [sendModal, setSendModal] = useState(false)

  const fileRef = useRef<HTMLInputElement | null>(null)

  const handlePostGenerate = async (values: handlePostGenerateType) => {
    const { generate_vatrecord, generate_declaration } = values
    setLoading(true)

    try {
      const response = await API.post(
        `${generatorApiURL}/VatRecordGate/generateJPK_V7M`,
        {
          id: gateInfo.id,
          generate_vatrecord,
          generate_declaration,
        },
        {
          headers: {
            tenant_id: localStorage.getItem("currentGuid") ?? "",
          },
        }
      )

      if (!response) {
        setLoading(false)
        return
      }

      setLoading(false)

      if (response.status === 200) {
        fetchData()
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const handlePostExport = async () => {
    try {
      const response = await API.post(
        `${generatorApiURL}/VatRecordGate/exportJPK_V7M?id=${gateInfo.id}`,
        {},
        {
          headers: {
            tenant_id: localStorage.getItem("currentGuid") ?? "",
          },
        }
      )

      if (!response) {
        setLoading(false)
        return
      }

      if (response.status === 400) {
        alert(response.data.errors[0])
        setExportModal(false)
      }

      if (response.status === 200) {
        const xmltext = response.data
        const fileName = response.headers["content-disposition"]
          ? response.headers["content-disposition"].split("filename=")[1].split(";")[0].replace(/['"]+/g, "")
          : "file.xlsx"

        const pom = document.createElement("a")
        const bb = new Blob([xmltext], { type: "text/plain" })

        pom.setAttribute("href", window.URL.createObjectURL(bb))
        pom.setAttribute("download", fileName)

        pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":")
        pom.draggable = true
        pom.classList.add("dragout")

        pom.click()

        setExportModal(false)
      }

      setLoading(false)
    } catch (error) {
      console.error(error)
      alert("error")
      setExportModal(false)
      setLoading(false)
    }
  }

  const handlePostDelete = async () => {
    try {
      setLoading(true)
      const response = await API.post(
        `${generatorApiURL}/VatRecordGate/deleteJPK_V7M?id=${gateInfo.id}`,
        {},
        {
          headers: {
            tenant_id: localStorage.getItem("currentGuid") ?? "",
          },
        }
      )

      if (!response) {
        setLoading(false)
        return
      }

      if (response.status === 200) {
        fetchData()
      }

      setDeleteModal(false)

      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const handleClickGenerate = async () => {
    // złozenie pierwotne [?!]
    if (gateInfo.cel_zlozenia === 1) {
      handlePostGenerate({ generate_vatrecord: true, generate_declaration: true })
    }

    if (gateInfo.cel_zlozenia === 2) {
      setGenerateModal(true)
    }
  }

  const handleClickExport = () => {
    setExportModal(true)
  }

  const handlePostBeforeDelete = () => {
    setBeforeDeleteModal(false)
    setDeleteModal(true)
  }

  const handleClickImport = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return

    const [file] = Array.from(event.target.files)
    const data = new FormData()

    data.append("file", file, file.name)

    try {
      const response = await API.post(`${generatorApiURL}/VatRecordGate/importJPK_V7M?id=${gateInfo.id}`, data, {
        headers: {
          //@ts-ignore
          "Content-Type": `multipart/form-data boundary=${data._boundary}`,
          tenant_id: localStorage.getItem("currentGuid") ?? "",
        },
      })

      if (!response) {
        setLoading(false)
        return
      }

      if (response.status === 400) {
        console.error(`ERROR => `, response.data.errors[0])
      }

      if (response.status === 200) {
        fetchData()
      }

      if (fileRef && fileRef.current) {
        fileRef.current.value = ""
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickSend = () => {
    setSendModal(true)
  }

  const handlePostSend = async () => {
    try {
      setLoading(true)
      const response = await API.post(
        `${generatorApiURL}/VatRecordGate/sendJPK_V7M?id=${gateInfo.id}`,
        {},
        {
          headers: {
            tenant_id: localStorage.getItem("currentGuid") ?? "",
          },
        }
      )

      if (!response) {
        setLoading(false)
        return
      }

      if (response.status === 200) {
        handleClickDownload()
      } else {
        console.error("error send jpk")
      }

      setSendModal(false)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
      setSendModal(false)
    }
  }

  const handleClickDownload = async () => {
    try {
      setLoading(true)
      const response = await API.post(
        `${generatorApiURL}/VatRecordGate/download_upo?id=${gateInfo.id}`,
        {},
        {
          headers: {
            tenant_id: localStorage.getItem("currentGuid") ?? "",
          },
        }
      )

      if (!response) {
        setLoading(false)
        return
      }

      if (response.status === 200) {
        fetchData()
      } else {
        console.error("error download jpk")
      }

      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const handleClickCopy = async () => {
    try {
      setLoading(true)
      const response = await API.post(
        `${generatorApiURL}/VatRecordGate/copyRefenceNumber?id=${gateInfo.id}`,
        {},
        {
          headers: {
            tenant_id: localStorage.getItem("currentGuid") ?? "",
          },
        }
      )

      if (!response) {
        setLoading(false)
        return
      }

      if (response.status === 200) {
        navigator.clipboard.writeText(response.data.stateObject)
      } else {
        alert(response.data.errors[0])
      }

      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const handleIsDisabled = useCallback(
    (condition: boolean) => {
      if (!active || loading) {
        return true
      }

      return condition
    },
    [active, loading]
  )

  //@ts-ignore
  const getFileName = (headers) => {
    const fileName = headers["content-disposition"]
      ? headers["content-disposition"].split("filename=")[1].split(";")[0].replace(/['"]+/g, "")
      : "file.xlsx"

    return fileName
  }

  const handleClickPreview = async () => {
    try {
      setLoading(true)
      const response = await API.get(`${generatorApiURL}/VatRecordGate/preview_upo?id=${gateInfo.id}`, {
        responseType: "arraybuffer",
        headers: {
          tenant_id: localStorage.getItem("currentGuid") ?? "",
        },
      })

      if (response && response.status === 200) {
        const fileName = getFileName(response.headers)
        const pom = document.createElement("a")
        const type: string = response.headers["content-type"]
        const data: BlobPart = response.data

        const bb = new Blob([data], { type })

        pom.setAttribute("href", window.URL.createObjectURL(bb))
        pom.setAttribute("download", fileName)
        pom.dataset.downloadurl = [type, pom.download, pom.href].join(":")
        pom.click()
        // window.open(URL.createObjectURL(bb), "_blank")
      }

      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setActive(selectedRowsGate.includes(selectedRowInfo.id))
  }, [selectedRowsGate])

  return (
    <div className="gate-action-buttons">
      {/* MODALS */}
      <GenerateModal
        loading={loading}
        visible={generateModal}
        closeModal={() => setGenerateModal(false)}
        handlePostGenerate={handlePostGenerate}
      />
      <ExportModal loading={loading} visible={exportModal} handlePostExport={handlePostExport} />
      <DeleteModal
        loading={loading}
        visible={deleteModal}
        handlePostDelete={handlePostDelete}
        closeModal={() => {
          setDeleteModal(false)
        }}
      />

      <SendModal
        loading={loading}
        visible={sendModal}
        handlePostSend={handlePostSend}
        closeModal={() => {
          setSendModal(false)
        }}
      />
      <BeforeDeleteModal
        loading={loading}
        visible={beforeDeleteModal}
        handlePostBeforeDelete={handlePostBeforeDelete}
        closeModal={() => {
          setBeforeDeleteModal(false)
        }}
      />
      {/* MODALS */}

      {/* BUTTONS */}
      <ActionSection
        sectionName="plik do wysyłki"
        hasSeparator
        content={[
          {
            title: "wygeneruj",
            disabled: handleIsDisabled(Boolean(selectedRowInfo.dataWygenerowania)),
            icon: wygeneruj,
            handler: handleClickGenerate,
          },
          {
            title: "usuń",
            disabled: handleIsDisabled(!Boolean(selectedRowInfo.dataWygenerowania)),
            icon: usun,
            handler: () => (selectedRowInfo.dataWyslania ? setBeforeDeleteModal(true) : setDeleteModal(true)),
          },
        ]}
      />
      <ActionSection
        sectionName="podpis"
        hasSeparator
        fileRef={fileRef}
        content={[
          {
            title: "eksportuj plik do podpisu",
            disabled:
              handleIsDisabled(!Boolean(selectedRowInfo.dataWygenerowania)) ||
              handleIsDisabled(Boolean(selectedRowInfo.dataPodpisaniaMetadanych)) ||
              handleIsDisabled(Boolean(selectedRowInfo.dataWyslania)) ||
              handleIsDisabled(Boolean(selectedRowInfo.dataPobraniaUpo)),
            icon: eksport,
            handler: handleClickExport,
          },
          {
            fakeInput: true,
            title: "importuj plik z podpisem",
            disabled:
              handleIsDisabled(!Boolean(selectedRowInfo.dataWygenerowania)) ||
              handleIsDisabled(Boolean(selectedRowInfo.dataPodpisaniaMetadanych)) ||
              handleIsDisabled(Boolean(selectedRowInfo.dataWyslania)) ||
              handleIsDisabled(Boolean(selectedRowInfo.dataPobraniaUpo)),
            icon: imp,
            handler: handleClickImport,
          },
        ]}
      />

      <ActionSection
        sectionName="wysyłka"
        hasSeparator
        content={[
          {
            title: "wyślij",
            disabled: handleIsDisabled(
              !Boolean(selectedRowInfo.dataPodpisaniaMetadanych) || Boolean(selectedRowInfo.dataWyslania)
            ),
            icon: send,
            handler: handleClickSend,
          },
          {
            title: "kopiuj nr referencyjny",
            disabled: handleIsDisabled(!Boolean(selectedRowInfo.dataWyslania)),
            icon: numer,
            handler: handleClickCopy,
          },
        ]}
      />
      <ActionSection
        sectionName="upo"
        content={[
          {
            title: "pobierz",
            disabled: handleIsDisabled(!Boolean(selectedRowInfo.dataWyslania)),
            icon: pobierz,
            handler: handleClickDownload,
          },
          {
            title: "podgląd",
            disabled: handleIsDisabled(!Boolean(selectedRowInfo.dataPobraniaUpo)),
            icon: podglad,
            handler: handleClickPreview,
          },
        ]}
      />
      {/* BUTTONS */}
    </div>
  )
}
