import React, { useContext, useState, useEffect } from "react"
import Context from "context"
import { DispatchContext, StateContext } from "components/userContext"
import { Tabs } from "antd"
import { historyImportColumns } from "./history_imports_data"
import { historyVerificationColumns } from "./history_verifications_data"
import { getVerificationFilter } from "../UrlHelper"

import NipVerifierHistoryTable from "./NipVerifierHistoryTable"
import API from "components/common/api"

import "./NipVerifierHistory.less"
import backIcon from "images/back_circle.svg"
const { TabPane } = Tabs

const NipVerifierHistory = (props) => {
  const { ctx, setCtx } = useContext(Context)

  const state = useContext(StateContext)
  const [loading, setLoading] = useState(true)

  const dispatch = useContext(DispatchContext)

  const fetchImportData = (sortData) => {
    setLoading(true)
    API.get(
      `/NipVerification/EntitiesDataHistory/?Id=` + ctx.currentContractor.id +
      `&sorts=${sortData.sortedValue}&page=${sortData.innerPage + 1}&pageSize=${sortData.innerPageSize}`
    )
      .then(
        ({
          data: {
            stateObject: { results: stateObject, ...tableParams },
          },
        }) => {

          dispatch({
            type: "handleState",
            field: "whiteListImportHistoryData",
            payload: stateObject,
          })
          dispatch({
            type: "handleState",
            field: "wlImportsHistoryOptions",
            payload: {
              ...state.wlImportsHistoryOptions,
              ...tableParams,
              currentPage: sortData.innerPage + 1,
              pageSize: sortData.innerPageSize
            },
          })

          // setWlData(stateObject)
          setLoading(false)
        }
      )
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const fetchVerificationData = (sortData) => {
    setLoading(true)
    const verificationFilterQuery = [
        getVerificationFilter(state.nipVerificationHistoryOptions.filteredVerificationStateMf, "verificationMf"),
        getVerificationFilter(state.nipVerificationHistoryOptions.filteredVerificationStateVies, "verificationVies")
      ].filter(x => x.length > 0)
  
    let filterQuery = ""
    if (verificationFilterQuery.length > 0) {
      filterQuery = "&filters=" + verificationFilterQuery.join(",")
    }

    const url = `/NipVerification/EntitiesVerificationHistory/?Id=` + ctx.currentContractor.id +
      `&sorts=${sortData.sortedValue}&page=${sortData.innerPage + 1}&pageSize=${sortData.innerPageSize}` +
      filterQuery
    API.get(url)
      .then(
        ({
          data: {
            stateObject: { results: stateObject, ...tableParams },
          },
        }) => {

          dispatch({
            type: "handleState",
            field: "whiteVerificationHistoryData",
            payload: stateObject,
          })
          dispatch({
            type: "handleState",
            field: "nipVerificationHistoryOptions",
            payload: {
              ...state.nipVerificationHistoryOptions,
              ...tableParams,
              currentPage: sortData.innerPage + 1,
              pageSize: sortData.innerPageSize
            },
          })

          // setWlData(stateObject)
          setLoading(false)
        }
      )
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }


  return <div className="NipVerifierHistory content-wrapper">
    <div className="WlDetails-navbar">
      <div className="left-panel">
        <div className="arrow-wrap" onClick={props.goBack}>
          <img src={backIcon} alt="powrót do listy ewidencji" />
        </div>
        <h3>{ctx.currentContractor.entity_name}</h3>
      </div>
    </div>
    <div className="VatDetails-box">  <Tabs>
      <TabPane tab="Dane zaimportowane" key="1" forceRender>
        <NipVerifierHistoryTable
          data={state.whiteListImportHistoryData}
          columns={historyImportColumns}
          multiSort={false}
          loading={loading}
          options={state.wlImportsHistoryOptions}
          fetchSortData={filters => fetchImportData(filters)}
        />
      </TabPane>
      <TabPane tab="Weryfikacja NIP" key="2" forceRender>
        <NipVerifierHistoryTable
          data={state.whiteVerificationHistoryData}
          columns={historyVerificationColumns}
          multiSort={false}
          loading={loading}
          options={state.nipVerificationHistoryOptions}
          fetchSortData={filters => fetchVerificationData(filters)}
        />
      </TabPane>
    </Tabs>
    </div>
  </div>
}


export default NipVerifierHistory