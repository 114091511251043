import React from "react"

import SellTypeHeader from "./SellTypeHeader"

export const columns = [
  {},
  {
    Header: "L.P.",
    accessor: "lp_sprzedazy",
    maxWidth: 50,
  },
  {
    Header: "Nazwa kontrahenta",
    accessor: "nazwa_kontrahenta",
  },
  {
    Header: "Data wystawienia",
    accessor: "data_wystawienia",
    Cell: (row) => {
      if (!row.value) return "-"

      const [YY, MM, DD] = row.value.split("-")
      const [D] = DD.split("T")
      return `${YY}.${MM}.${D}`
    },
  },
  {
    Header: "Numer dokumentu",
    accessor: "dowod_sprzedazy",
  },
  {
    Header: "Kod kraju",
    accessor: "kod_kraju_nadania_tin",
  },
  {
    Header: "Numer kontrahenta",
    accessor: "nr_kontrahenta",
  },
  {
    Header: "Data sprzedaży",
    accessor: "data_sprzedazy",
    Cell: (row) => {
      if (!row.value) return "-"

      const [YY, MM, DD] = row.value.split("-")
      const [D] = DD.split("T")
      return `${YY}.${MM}.${D}`
    },
  },
  {
    // RO, WEW, FP
    Header: () => {
      return <SellTypeHeader options={["RO", "WEW", "FP"]} handlerName="detailsTableOptions" width="200px" />
    },
    width: 210,
    sortable: false,
    accessor: "typ_dokumentu",
  },
]

export const columnsSmall = [
  {},
  {
    Header: "L.P.",
    accessor: "lp_sprzedazy",
    maxWidth: 50,
  },
  {
    Header: "Nazwa kontrahenta",
    accessor: "nazwa_kontrahenta",
  },
  {
    Header: "Data wystawienia",
    accessor: "data_wystawienia",
    Cell: (row) => {
      if (!row.value) return "-"

      const [YY, MM, DD] = row.value.split("-")
      const [D] = DD.split("T")

      return `${YY}.${MM}.${D}`
    },
  },
  {
    Header: "Numer dokumentu",
    accessor: "dowod_sprzedazy",
  },
]

export const columnsShoppingSmall = [
  {},
  {
    Header: "L.P.",
    accessor: "lp_zakupu",
    maxWidth: 50,
  },
  {
    Header: "Nazwa kontrahenta",
    accessor: "nazwa_dostawcy",
  },
  {
    Header: "Data otrzymania",
    accessor: "data_wplywu",
    Cell: (row) => {
      if (!row.value) return "-"
      const [YY, MM, DD] = row.value.split("-")
      const [D] = DD.split("T")
      return `${YY}.${MM}.${D}`
    },
  },
  {
    Header: "Numer dokumentu",
    accessor: "dowod_zakupu",
  },
]

export const columnsShopping = [
  {},
  {
    Header: "L.P.",
    accessor: "lp_zakupu",
    maxWidth: 50,
  },
  {
    Header: "Nazwa kontrahenta",
    accessor: "nazwa_dostawcy",
  },
  {
    Header: "Data otrzymania",
    accessor: "data_wplywu",
    Cell: (row) => {
      if (!row.value) return "-"

      const [YY, MM, DD] = row.value.split("-")
      const [D] = DD.split("T")
      return `${YY}.${MM}.${D}`
    },
  },
  {
    Header: "Numer dokumentu",
    accessor: "dowod_zakupu",
  },
  {
    Header: "Kod kraju",
    accessor: "kod_kraju_nadania_tin",
  },
  {
    Header: "Numer kontrahenta",
    accessor: "nr_dostawcy",
  },
  {
    Header: "Data zakupu",
    accessor: "data_zakupu",
    Cell: (row) => {
      if (!row.value) return "-"

      const [YY, MM, DD] = row.value.split("-")
      const [D] = DD.split("T")
      return `${YY}.${MM}.${D}`
    },
  },
  {
    // MK, VAT_RR, WEW
    Header: () => {
      return (
        <SellTypeHeader options={["MK", "VAT_RR", "WEW"]} handlerName="detailsTableShoppingOptions" width="200px" />
      )
    },
    width: 210,
    sortable: false,
    accessor: "dokument_zakupu",
  },
]
