import React from "react"

import navIcon from "images/arrow-pagination.svg"

const defaultButton = props => <button {...props}>{props.children}</button>

class CustomPagination extends React.Component {
  state = {
    visiblePages: [],
  }

  componentDidMount() {
    this.setState({
      visiblePages: this.getVisiblePages(
        this.props.currentPage,
        this.props.pages
      ),
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages),
      })
    }

    if (this.props.pageSize !== nextProps.pageSize) {
      this.setState({
        visiblePages: this.getVisiblePages(nextProps.page, nextProps.pages),
      })
    }

    this.changePage(nextProps.page + 1)
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages)
  }

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total)
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total]
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total]
      } else {
        return [1, 2, 3, 4, 5, total]
      }
    }
  }

  changePage = page => {
    const activePage = this.props.page + 1

    if (page === activePage) return

    const visiblePages = this.getVisiblePages(page, this.props.pages)
    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages),
    })

    this.props.onPageChange(page - 1)
  }

  changePageSize = size => {
    this.props.onPageSizeChange(size)
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props
    const { visiblePages } = this.state

    const activePage = this.props.currentPage || this.props.page + 1

    return (
      <React.Fragment>
        <div className="Table__pagination">
          <div className="Table__prevPageWrapper">
            <PageButtonComponent
              className="Table__pageButton"
              onClick={() => {
                if (activePage === 1) return
                this.changePage(activePage - 1)
              }}
              disabled={activePage === 1}
            >
              <img src={navIcon} alt="prev icon" className="nav-icon" />
            </PageButtonComponent>
          </div>
          <div className="Table__visiblePagesWrapper">
            {visiblePages.length > 0
              ? visiblePages.map((page, index, array) => {
                  return (
                    <PageButtonComponent
                      key={page}
                      className={
                        activePage === page
                          ? "Table__pageButton Table__pageButton--active"
                          : "Table__pageButton"
                      }
                    >
                      {array[index - 1] + 2 <= page ? (
                        <>
                          <span className="three-dots">...</span>
                          <span onClick={() => this.changePage(page)}>
                            {page}
                          </span>
                        </>
                      ) : (
                        <span onClick={() => this.changePage(page)}>
                          {page}
                        </span>
                      )}
                    </PageButtonComponent>
                  )
                })
              : 1}
          </div>
          <div className="Table__nextPageWrapper">
            <PageButtonComponent
              className="Table__pageButton"
              onClick={() => {
                if (activePage === this.props.pages) return
                this.changePage(activePage + 1)
              }}
              disabled={!this.props.pages || activePage === this.props.pages}
            >
              <img src={navIcon} alt="next icon" className="nav-icon rotated" />
            </PageButtonComponent>
          </div>
        </div>
        <div className="page-size">
          <span>Pokaż</span>
          <select
            name="page-size"
            id="page-size"
            value={this.props.pageSize}
            onChange={e => this.changePageSize(parseInt(e.target.value))}
          >
            {this.props.pageSizeOptions.map(v => {
              return (
                <option value={v} key={v}>
                  {v}
                </option>
              )
            })}
          </select>
        </div>
      </React.Fragment>
    )
  }
}

export default CustomPagination
